import styled from 'styled-components'
import theme from 'config/theme'

export const Track = styled.div`
  background: ${({ trackColor, checked }) =>
    checked ? (trackColor ? trackColor : theme.colors.purple) : theme.colors.dustyGray};
  width: 40px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
`

export const TransitionElement = styled.div`
  background: ${({ buttonColor }) => (buttonColor ? buttonColor : theme.colors.white)};
  width: 16px;
  height: 16px;
  border-radius: 10px;
  transform: ${({ checked }) => (checked ? 'translateX(22px)' : 'translateX(1.5px)')};
  transition: all 0.5s ease;
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
`

import styled from 'styled-components'

export const StyledContainer = styled.div`
  min-width: 400px;
  width: 90%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  margin-left: auto;
  margin-right: auto;
  padding: 15px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-width: 800px;
`

export const StyledDivHeaderWrapper = styled.div`
  justify-content: space-between;
  display: flex;
`

export const CrossImage = styled.img`
  width: 15px;
  height: 15px;
  top: 15px;
  right: 20px;
  cursor: pointer;
`

export const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ButtonWrapper = styled.div`
  width: 100px;
  margin: auto;
`
export const StyledDivFooterWrapper = styled.div`
  margin: 30px auto 5px auto;
`

export const ErrorLabel = styled.label`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`

export const StyledDivWrapper = styled.div`
  display: flex;
`

export const StyledTextHeader = styled.text`
  font-size: 20px;
  font-weight: bold;
`

import styled from 'styled-components'

export const StyledDivItemWrapper = styled.div`
  height: 75px;
  display: flex;
  justify-content: space-around;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ cantItems, index }) => (cantItems === index + 1 ? '' : '1px solid')};
  border-color: ${({ theme }) => theme.colors.blue};
`

export const StyledDivItem = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGrey};
`

export const StyledSelect = styled.select`
  height: 20px;
  border-radius: 3px;
  border: 1px solid;
  color: black;
  border-color: ${({ theme, isEditing }) =>
    isEditing ? theme.colors.darkBlue : theme.colors.lightGrey};
`

export const StyledImagesButton = styled.button`
  width: 60px;
  height: 60px;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`

export const StyledImg = styled.img`
  height: 60px;
  width: 60px;
`

export const NoImage = styled.div`
  height: 60px;
  width: 60px;
  border: 1px solid;
  font-weight: bold;
  padding-top: 10px;
  color: ${({ theme }) => theme.colors.darkGrey};
`

import { createActions } from 'redux-actions'

const { capacitationDetailStatus } = createActions({
  CAPACITATION_DETAIL_STATUS: data => ({ data })
})

const setCapacitationDetailStatus = status => dispatch =>
  dispatch(capacitationDetailStatus({ status }))

export { setCapacitationDetailStatus, capacitationDetailStatus }

import React, { Component } from 'react'
import { withRouter } from 'react-router'
import {
  Container,
  ContentWrapper,
  HeaderTitle,
  StyledExitButton,
  ImageWrapper,
  StyledImg,
  StyledImgLogout,
  StyledText,
} from './styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import laserenisimaLogo from 'assets/imgs/laserenisimaLogo.png'
import danoneLogo from 'assets/imgs/danoneLogo.png'
import banner from 'assets/imgs/banner.jpg'
import logoutIcon from 'assets/icons/logout.png'

import i18n from 'services/i18n'
import { allToCaps } from 'utils/strings'

import { getPage } from 'utils/url'
import { capitalizeFirstLetter } from 'utils/strings'
import { getUserInfo } from 'utils/session'

import HeaderMenu from './HeaderMenu'

import { isGeneralAdmin, isLeaderUser } from 'utils/session'

import theme from 'config/theme'

import Selector from 'components/Selector'

import { resetAdministrators } from 'actions/administrators'
import { setCapacitationDetailStatus } from 'actions/capacitationDetailStatus'
import { resetCapacitationsByFilter, resetCapacitationsById } from 'actions/capacitations'
import {
  resetIndirectEmployee,
  resetCarrierEmployeeByFilter,
  setShowingCarrier,
  resetCarrierEmployeesByDate,
} from 'actions/carrierEmployee'
import { resetCategories } from 'actions/categories'
import { resetCentersByName, resetCenters, resetCentersByDate } from 'actions/centers'
import {
  resetCourses,
  resetCoursesByDate,
  resetCoursesByCategoryId,
  resetCoursesByName,
  setShowingCourse,
} from 'actions/courses'
import {
  resetDirectEmployeeByFilter,
  resetDirectEmployee,
  resetDirectEmployeesByDate,
} from 'actions/directEmployee'
import { resetExpandedCategory } from 'actions/expandedCategory'
import { resetExpandedUserType } from 'actions/expandedUserType'
import {
  resetIndirectEmployeeByFilter,
  setShowingIndirect,
  resetIndirectEmployeesByDate,
} from 'actions/indirectEmployee'
import { resetInstructors } from 'actions/instructors'
import {
  resetLeadersByName,
  resetLeaders,
  setShowingLeader,
  resetLeadersByDate,
} from 'actions/leaders'
import {
  resetLeaderUsers,
  resetLeaderUsersById,
  resetLeaderUsersByCenterId,
} from 'actions/leaderUsers'
import { resetSelectedPage } from 'actions/page'
import { resetSelectedCapacitation } from 'actions/selectedCapacitation'
import { resetSelectedDateFilter } from 'actions/selectedDateFilter'
import { resetSelectedFilter } from 'actions/selectedFilter'
import { resetSelectedSearchedItem } from 'actions/selectedSearchedItem'
import { resetSelector } from 'actions/selector'
import { resetDisplayToast } from 'actions/toast'
import { resetTrainingDetail } from 'actions/trainingDetail'
import { resetPendingTraining } from 'actions/trainingPending'
import { resetTrainingSummary } from 'actions/trainingSummary'
import { resetWorkPosition } from 'actions/workPosition'
import { resetWorkSector } from 'actions/workSector'
import { setSelectedFilter } from 'actions/selectedFilter'
import { fetchBusiness } from 'actions/business'
import { resetSelectedUsersFilter } from 'actions/selectedUsersFilter'
import { byCategoriesReset } from 'actions/Dashboard/SummaryTraining/byCategories'
import { byCentersReset } from 'actions/Dashboard/SummaryTraining/byCenters'
import { legalReset } from 'actions/Dashboard/TrainingDetail/legal'
import { optionalReset } from 'actions/Dashboard/TrainingDetail/optional'
import { pendingTrainingReset } from 'actions/Dashboard/pendingTraining'

import { getCompanyById } from 'utils/getCompany'
class Header extends Component {
  state = { selectedBusiness: null }

  componentDidMount = () => {
    isGeneralAdmin() && this.props.fetchBusiness()
  }

  clearReducers = () => {
    this.props.resetAdministrators()
    this.props.setCapacitationDetailStatus(false)
    this.props.resetCapacitationsByFilter()
    this.props.resetIndirectEmployee()
    this.props.resetCarrierEmployeeByFilter()
    this.props.setShowingCarrier({ isShowing: false })
    this.props.resetCategories()
    this.props.resetCentersByName()
    this.props.resetCenters()
    this.props.setShowingCourse({ isShowing: false })
    this.props.resetCourses()
    this.props.resetCoursesByDate()
    this.props.resetCoursesByCategoryId()
    this.props.resetCoursesByName()
    this.props.resetDirectEmployeeByFilter()
    this.props.resetDirectEmployee()
    this.props.resetExpandedCategory()
    this.props.resetExpandedUserType()
    this.props.setShowingIndirect()
    this.props.resetIndirectEmployee()
    this.props.resetIndirectEmployeeByFilter()
    this.props.resetInstructors()
    this.props.setShowingLeader({ isShowing: false })
    this.props.resetLeadersByName()
    this.props.resetLeaders()
    this.props.resetLeaderUsers()
    this.props.resetLeaderUsersById()
    this.props.resetLeaderUsersByCenterId()
    this.props.resetSelectedPage()
    this.props.resetSelectedCapacitation()
    this.props.resetSelectedDateFilter()
    this.props.resetSelectedFilter()
    this.props.resetSelectedSearchedItem()
    this.props.resetSelector()
    this.props.resetDisplayToast()
    this.props.resetTrainingDetail()
    this.props.resetPendingTraining()
    this.props.resetTrainingSummary()
    this.props.resetWorkPosition()
    this.props.resetWorkSector()
    this.props.resetCentersByDate()
    this.props.resetLeadersByDate()
    this.props.resetDirectEmployeesByDate()
    this.props.resetCarrierEmployeesByDate()
    this.props.resetIndirectEmployeesByDate()
    this.props.resetCapacitationsById()
    this.props.resetSelectedUsersFilter()
    this.props.pendingTrainingReset()
    this.props.byCategoriesReset()
    this.props.byCentersReset()
    this.props.legalReset()
    this.props.optionalReset()
  }

  logout = (history) => {
    localStorage.clear()
    this.clearReducers()
    history.push(`/login`)
  }

  getSelectedBusiness = (selectedBusiness) => {
    if (this.hasFetchedBusiness()) {
      if (selectedBusiness) {
        return selectedBusiness
      } else {
        return this.getBusinessSelectorOptions()[0]
      }
    } else {
      return null
    }
  }

  getBusinessSelectorOptions = () => {
    const { business } = this.props
    if (this.hasFetchedBusiness()) {
      return [{ label: capitalizeFirstLetter(i18n('SELECTOR').SELECTOR_ALL), value: 0 }].concat(
        business.valuesAll.map((value) => ({ label: value.name, value: value.id }))
      )
    } else {
      return []
    }
  }

  hasFetchedBusiness = () => {
    const { business } = this.props
    return business.valuesAll && business.valuesAll.length
  }

  handleBusinessSelectorChange = (target) => {
    this.props.resetExpandedUserType()
    this.props.setSelectedFilter({ selectedBusiness: target })
  }

  handleLogo = ({ selectedBusiness }) => {
    const userInfo = getUserInfo()
    let printLSLogo = false
    if (userInfo.rol.id === 4) {
      printLSLogo = selectedBusiness && selectedBusiness.value == 1
    } else {
      printLSLogo = !!getCompanyById(1)
    }
    return printLSLogo ? <StyledImg src={laserenisimaLogo} /> : ''
  }

  render() {
    const { location, history, business, selectedFilter, capacitationDetailStatus } = this.props
    const currentPage = getPage(location.pathname)

    const IndicatorSeparator = ({ innerProps }) => {
      return <span {...innerProps} />
    }
    return (
      <Container
        style={{
          backgroundImage: `url(${banner})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <ContentWrapper>
          <HeaderTitle>
            <ImageWrapper>
              <StyledImg src={danoneLogo} />
              {this.handleLogo(selectedFilter)}
            </ImageWrapper>
          </HeaderTitle>
          {!capacitationDetailStatus.status && (
            <>
              {!isLeaderUser() && <HeaderMenu active={currentPage} />}
              {isGeneralAdmin() && (
                <Selector
                  components={{
                    IndicatorSeparator,
                  }}
                  placeholder={capitalizeFirstLetter(i18n('SELECTOR').BUSINESS)}
                  value={this.getSelectedBusiness(selectedFilter.selectedBusiness)}
                  noOptionsMessage={() => capitalizeFirstLetter(i18n('SEARCH').SEARCH_NO_DATA)}
                  options={this.getBusinessSelectorOptions()}
                  isLoading={business.isFetchingAll}
                  disabled={business.isFetchingAll || business.errorAll}
                  onChange={(target) => this.handleBusinessSelectorChange(target)}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      marginRight: '25px',
                      minHeight: '30px',
                      height: '30px',
                      width: '110px',
                      borderColor: state.isFocused ? theme.colors.purple : theme.colors.grey,
                      boxShadow: 'none',
                      '&:hover': {
                        borderColor: theme.colors.purple,
                      },
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      padding: '0px 7px',
                      overflow: 'auto',
                      position: 'initial',
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      padding: state.selectProps.menuIsOpen ? '0px 0px 0px 8px' : '0px 8px 0px 0px',
                      color: state.isFocused ? theme.colors.purple : theme.colors.dustyGray,
                      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
                      transition: 'all 0.5s ease',
                      boxShadow: 'none',
                      '&:hover': {
                        color: theme.colors.purple,
                      },
                    }),
                    menu: (base) => ({
                      ...base,
                      border: `1px solid ${theme.colors.purple}`,
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isSelected && theme.colors.purple,
                      fontSize: '15px',
                      fontFamily: 'Roboto,sans-serif',

                      '&:hover': {
                        backgroundColor: !state.isSelected && theme.colors.grey,
                      },
                    }),
                    menuList: (base) => ({
                      ...base,
                      padding: '0px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontSize: '15px',
                      fontFamily: 'Roboto,sans-serif',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      fontSize: '15px',
                      fontFamily: 'Roboto,sans-serif',
                      width: '70px',
                    }),
                  }}
                />
              )}
            </>
          )}

          {}

          <StyledExitButton onClick={() => this.logout(history)}>
            <StyledImgLogout src={logoutIcon} />
            <StyledText> {allToCaps(`${i18n('HOME').HOME_PAGE_LOGOUT}`)} </StyledText>
          </StyledExitButton>
        </ContentWrapper>
      </Container>
    )
  }
}

const mapStateToProps = ({ selectedFilter, business, capacitationDetailStatus }) => ({
  selectedFilter,
  business,
  capacitationDetailStatus,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetAdministrators,
      setCapacitationDetailStatus,
      resetCapacitationsByFilter,
      resetIndirectEmployee,
      resetCarrierEmployeeByFilter,
      setShowingCarrier,
      resetCategories,
      resetCentersByName,
      resetCenters,
      setShowingCourse,
      resetCourses,
      resetCoursesByDate,
      resetCoursesByCategoryId,
      resetCoursesByName,
      resetDirectEmployeeByFilter,
      resetDirectEmployee,
      resetExpandedCategory,
      resetExpandedUserType,
      setShowingIndirect,
      resetInstructors,
      setShowingLeader,
      resetIndirectEmployeeByFilter,
      resetLeadersByName,
      resetLeaders,
      resetLeaderUsers,
      resetLeaderUsersById,
      resetLeaderUsersByCenterId,
      resetSelectedPage,
      resetSelectedCapacitation,
      resetSelectedDateFilter,
      resetSelectedFilter,
      resetSelectedSearchedItem,
      resetSelector,
      resetDisplayToast,
      resetTrainingDetail,
      resetPendingTraining,
      resetTrainingSummary,
      resetWorkPosition,
      resetWorkSector,
      resetCentersByDate,
      resetLeadersByDate,
      resetDirectEmployeesByDate,
      resetCarrierEmployeesByDate,
      resetIndirectEmployeesByDate,
      resetCapacitationsById,
      setSelectedFilter,
      fetchBusiness,
      resetSelectedUsersFilter,
      pendingTrainingReset,
      byCategoriesReset,
      byCentersReset,
      legalReset,
      optionalReset,
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))

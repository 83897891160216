import columnsByGroup from './columnsByGroup'

export default [
  {
    label: 'Categoria',
    name: 'courseCategory',
    subProp: 'name',
  },
  ...columnsByGroup,
]

import filtersDefault from './filtersDefault'
import conformityStatus from 'config/Dashboard/conformityStatus'

export default [
  ...filtersDefault,
  {
    name: 'States',
    label: 'Estado',
    type: 'select',
    isMulti: true,
    options: [...conformityStatus],
  },
]

import { createActions } from 'redux-actions'

const { selectedUsersFilter, resetUsersFilter } = createActions({
  RESET_USERS_FILTER: () => {},
  SELECTED_USERS_FILTER: data => ({ data })
})

const setSelectedUsersFilter = status => dispatch => dispatch(selectedUsersFilter({ status }))
const resetSelectedUsersFilter = () => dispatch => dispatch(resetUsersFilter())

export { setSelectedUsersFilter, selectedUsersFilter, resetSelectedUsersFilter, resetUsersFilter }

import React from 'react'

import TextFieldMaterial from 'components/TextFieldMaterial'
import CustomSelector from 'components/CustomSelector'
import {
  employeeDirectInput,
  employeeIndirectInput,
  employeeCarrierInput,
} from 'components/EmployeeInputs'
import Selector from 'components/Selector'
import MySwitch from 'components/Switch'

import { capitalizeFirstLetter } from 'utils/strings'
import { reestructureDataArray, reestructureDataSimple } from 'utils/selectorParser'
import {
  setValuesAllCategories,
  setValuesCategory,
  setValuesAllCourses,
  setValuesAllCenters,
} from 'utils/formikSetValues'
import { multiSelector } from 'utils/selectorStyles'
import isDanone from 'utils/isDanone'
import { getCompanyObject } from 'utils/getCompany'

import i18n from 'services/i18n'

import {
  StyledSelectorWrapper,
  StyledWrapper,
  CustomCheckbox,
  Text,
  WrapperCheckbox,
  WrapperSelect,
  Select,
  SwitchWrapper,
} from './styled'

import popupKeys from 'constants/popupKeys'
import usersRoles from 'constants/usersRoles'
import userKeys from 'constants/userKeys'

export const courseInput = (values, setFieldValue, errors, selectorValues) => (
  <div>
    <StyledSelectorWrapper>
      <CustomSelector
        selectorValues={selectorValues}
        placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_CATEGORY}`)}
      />
    </StyledSelectorWrapper>
    <TextFieldMaterial
      label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`)}
      helperText={errors.name}
      margin="normal"
      value={values.name}
      error={errors.name}
      onChange={({ target: value }) => setFieldValue('name', value.value)}
      type="text"
    />
  </div>
)

export const leaderInput = (
  values,
  setFieldValue,
  errors,
  selectorCenters,
  selectorWorkSector,
  selectorWorkPosition,
  selectorLeader,
  isLeader,
  fetchLeaders
) =>
  leaderOrEmployeeDirectInput(
    values,
    setFieldValue,
    errors,
    selectorCenters,
    selectorWorkSector,
    selectorWorkPosition,
    selectorLeader,
    isLeader,
    fetchLeaders
  )

const leaderOrEmployeeDirectInput = (
  values,
  setFieldValue,
  errors,
  selectorCenters,
  selectorWorkSector,
  selectorWorkPosition,
  selectorLeader,
  isLeader,
  fetchLeaders
) =>
  employeeDirectInput(
    values,
    setFieldValue,
    errors,
    selectorCenters,
    selectorWorkSector,
    selectorWorkPosition,
    selectorLeader,
    isLeader,
    fetchLeaders
  )

const renderEmployeeTypeInput = (
  values,
  setFieldValue,
  errors,
  selectedOption,
  selectorCenters,
  selectorWorkSector,
  selectorWorkPosition,
  selectorLeader,
  setValues,
  business
) => {
  switch (selectedOption) {
    case popupKeys.EMPLOYEE.DIRECT:
      return leaderOrEmployeeDirectInput(
        values,
        setFieldValue,
        errors,
        selectorCenters,
        selectorWorkSector,
        selectorWorkPosition,
        selectorLeader
      )
    case popupKeys.EMPLOYEE.INDIRECT:
      return employeeIndirectInput(values, setFieldValue, errors, setValues, business)

    case popupKeys.EMPLOYEE.CARRIER:
      return employeeCarrierInput(values, setFieldValue, errors)
  }
}

export const employeeInput = (
  values,
  setFieldValue,
  errors,
  selectorValues,
  selectedOption,
  selectorCenters,
  selectorWorkSector,
  selectorWorkPosition,
  selectorLeader,
  setValues,
  business
) => (
  <div>
    <StyledSelectorWrapper>
      <CustomSelector
        selectorValues={selectorValues}
        placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_EMPLOYEE}`)}
      />
    </StyledSelectorWrapper>
    {renderEmployeeTypeInput(
      values,
      setFieldValue,
      errors,
      selectedOption,
      selectorCenters,
      selectorWorkSector,
      selectorWorkPosition,
      selectorLeader,
      setValues,
      business
    )}
  </div>
)

const filterCoursesBySelectedCategories = (categories, courses) => {
  let categoryName = categories && categories.map((category) => category.name)
  let result =
    courses &&
    categories &&
    categoryName.map((name) => courses.filter((course) => course.category.name === name))
  return [].concat.apply([], result)
}

const addFormBasicInputs = (
  values,
  setFieldValue,
  errors,
  adminByBusiness,
  business,
  isInstructor,
  availableCategories,
  availableCourses,
  setValues
) => (
  <>
    <div style={{ display: 'flex' }}>
      <StyledWrapper>
        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`)}
          helperText={errors.name}
          margin="normal"
          value={values.name}
          error={errors.name}
          onChange={({ target: value }) => setFieldValue('name', value.value)}
          type="text"
        />
        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_LASTNAME}`)}
          helperText={errors.lastName}
          margin="normal"
          value={values.lastName}
          error={errors.lastName}
          onChange={({ target: value }) => setFieldValue('lastName', value.value)}
          type="text"
        />
        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}
          helperText={errors.userName}
          margin="normal"
          value={values.userName}
          error={errors.userName}
          onChange={({ target: value }) => setFieldValue('userName', value.value)}
          type="text"
        />
        {adminByBusiness && (
          <TextFieldMaterial
            label={capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}
            helperText={errors.email}
            margin="normal"
            value={values.email}
            error={errors.email}
            onChange={({ target: value }) => setFieldValue('email', value.value)}
            type="text"
          />
        )}

        {isInstructor ? (
          <SwitchWrapper>
            {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_INTERN}`)}
            <MySwitch
              checked={values.isInternal}
              onClick={() => setFieldValue('isInternal', values.isInternal ? false : true)}
            />
          </SwitchWrapper>
        ) : (
          ''
        )}
      </StyledWrapper>
      <StyledWrapper>
        {adminByBusiness ? (
          <>
            {passwordValidationInputs(values, setFieldValue, errors)}
            <div style={{ marginTop: '20px' }}>
              <Selector
                placeholder={capitalizeFirstLetter(i18n('SELECTOR').BUSINESS)}
                value={values.selectedBusiness}
                options={
                  business.valuesAll &&
                  business.valuesAll.length &&
                  reestructureDataArray(business.valuesAll)
                }
                isLoading={business.isFetchingAll}
                disabled={business.errorAll}
                onChange={(target) => setFieldValue('selectedBusiness', target)}
                styles={multiSelector}
              />
            </div>
          </>
        ) : (
          <>
            <TextFieldMaterial
              label={capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}
              helperText={errors.email}
              margin="normal"
              value={values.email}
              error={errors.email}
              onChange={({ target: value }) => setFieldValue('email', value.value)}
              type="text"
            />
            {passwordValidationInputs(values, setFieldValue, errors)}
          </>
        )}

        {isInstructor ? (
          <SwitchWrapper>
            {capitalizeFirstLetter(`${i18n('USER_TYPES').PREVIEW_CAN_ADD_COURSES}`)}
            <MySwitch
              checked={values.canAddCourses}
              onClick={() => setFieldValue('canAddCourses', values.canAddCourses ? false : true)}
            />
          </SwitchWrapper>
        ) : (
          ''
        )}
      </StyledWrapper>
    </div>
    <div>
      {isInstructor && (
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Selector
            placeholder={capitalizeFirstLetter(i18n('SELECTOR').BUSINESS)}
            value={blockDefaultBusiness(values.selectedBusiness, setFieldValue)}
            options={
              business.valuesAll &&
              business.valuesAll.length &&
              reestructureDataArray(business.valuesAll)
            }
            isMulti
            isLoading={business.isFetchingAll}
            disabled={business.errorAll || business.isFetchingAll}
            onChange={(target) => setFieldValue('selectedBusiness', target)}
            styles={multiSelector(450)}
          />
        </div>
      )}

      {isInstructor && (
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Selector
            placeholder={
              values.allCategories
                ? capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)
                : capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_CATEGORY}`)
            }
            value={
              values.availableCategories && values.allCategories ? null : values.availableCategories
            }
            options={
              availableCategories.values ? reestructureDataArray(availableCategories.values) : []
            }
            isLoading={availableCategories.isFetching}
            onChange={(target) => setValuesCategory(setValues, values, target)}
            isMulti
            isDisabled={values.allCategories || availableCategories.isFetching}
            styles={multiSelector(450)}
          />
          <WrapperCheckbox style={{ marginTop: '5px' }}>
            <label style={{ margin: 'auto 0px auto 0px' }}>
              {capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
            </label>
            <CustomCheckbox
              style={{ padding: '2px 8px' }}
              checked={values.allCategories}
              onChange={() => setValuesAllCategories(setValues, values, availableCategories)}
            />
          </WrapperCheckbox>
        </div>
      )}

      {isInstructor && (
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Selector
            placeholder={
              values.allCourses
                ? capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)
                : capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_COURSE}`)
            }
            value={
              values.allCourses ? null : values.availableCourses ? values.availableCourses : null
            }
            options={
              availableCourses.valuesAll
                ? reestructureDataArray(
                    filterCoursesBySelectedCategories(
                      values.availableCategories,
                      availableCourses.valuesAll
                    )
                  )
                : []
            }
            isLoading={availableCourses.isFetchingAll}
            onChange={(target) => setFieldValue('availableCourses', target)}
            isMulti
            isDisabled={
              values.allCourses || availableCourses.isFetchingAll || !values.availableCategories
            }
            styles={multiSelector(450)}
          />
          <WrapperCheckbox style={{ marginTop: '5px' }}>
            <label style={{ margin: 'auto 0px auto 0px' }}>
              {capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
            </label>
            <CustomCheckbox
              style={{ padding: '2px 8px' }}
              checked={values.allCourses}
              disabled={!values.availableCategories}
              onChange={() =>
                setValuesAllCourses(
                  setValues,
                  values,
                  filterCoursesBySelectedCategories(
                    values.availableCategories,
                    availableCourses.valuesAll
                  )
                )
              }
            />
          </WrapperCheckbox>
        </div>
      )}
    </div>
  </>
)

const blockDefaultBusiness = (selectedBusiness, setFieldValue) => {
  if (selectedBusiness) {
    if (selectedBusiness.find((x) => x.value === getCompanyObject().Id)) {
      return selectedBusiness
    } else {
      setFieldValue('selectedBusiness', [
        reestructureDataSimple(getCompanyObject().Id, getCompanyObject().name),
        ...selectedBusiness,
      ])
    }
  } else {
    setFieldValue('selectedBusiness', [
      reestructureDataSimple(getCompanyObject().Id, getCompanyObject().name),
    ])
  }
}

export const administratorInput = (values, setFieldValue, errors, adminByBusiness, business) =>
  addFormBasicInputs(values, setFieldValue, errors, adminByBusiness, business)

export const instructorInput = (
  values,
  setFieldValue,
  errors,
  business,
  availableCategories,
  availableCourses,
  setValues
) =>
  addFormBasicInputs(
    values,
    setFieldValue,
    errors,
    false,
    business,
    true,
    availableCategories,
    availableCourses,
    setValues
  )

const isRolDirector = (values) =>
  values.leaderLevel && values.leaderLevel.value === userKeys.DIRECTOR

const isRolManager = (values) => values.leaderLevel && values.leaderLevel.value === userKeys.MANAGER

const isRolBoss = (values) => values.leaderLevel && values.leaderLevel.value === userKeys.BOSS

export const leaderUserInput = (
  values,
  setFieldValue,
  setValues,
  errors,
  selectorLeader,
  selectorCenters
) => (
  <div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex' }}>
        <StyledWrapper>
          <div style={{ marginTop: '26px' }}>
            <Selector
              placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ROLE}`)}
              value={values.leaderLevel}
              options={reestructureDataArray(usersRoles)}
              isLoading={false}
              onChange={(target) => setFieldValue('leaderLevel', target)}
            />
          </div>

          <div
            style={{ marginTop: !isRolDirector(values) && !isRolManager(values) ? '26px' : '0px' }}
          >
            {isRolDirector(values) || isRolManager(values) ? (
              ''
            ) : (
              <Selector
                placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_LEADER}`)}
                value={values.leader}
                options={
                  selectorLeader.valuesAll ? reestructureDataArray(selectorLeader.valuesAll) : []
                }
                isLoading={selectorLeader.isFetchingAll}
                onChange={(target) => setFieldValue('leader', target)}
              />
            )}
            <TextFieldMaterial
              label={capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}
              helperText={errors.userName}
              margin="normal"
              value={values.userName}
              error={errors.userName}
              onChange={({ target: value }) => setFieldValue('userName', value.value)}
              type="text"
            />
          </div>
        </StyledWrapper>
        <StyledWrapper>
          <TextFieldMaterial
            label={capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}
            helperText={errors.email}
            margin="normal"
            value={values.email}
            error={errors.email}
            onChange={({ target: value }) => setFieldValue('email', value.value)}
            type="text"
          />
          {passwordValidationInputs(values, setFieldValue, errors)}
        </StyledWrapper>
      </div>

      {isRolBoss(values) ? (
        ''
      ) : (
        <WrapperSelect>
          <Select>
            <Selector
              placeholder={
                values.allCenters
                  ? capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)
                  : capitalizeFirstLetter(
                      `${
                        isDanone()
                          ? i18n('SELECTOR').SELECTOR_MANAGEMENT
                          : i18n('SELECTOR').SELECTOR_CENTER
                      }`
                    )
              }
              value={values.allCenters ? null : values.distributionCenters}
              options={
                selectorCenters.valuesAll ? reestructureDataArray(selectorCenters.valuesAll) : []
              }
              isLoading={selectorCenters.isFetchingAll}
              onChange={(target) => setFieldValue('distributionCenters', target)}
              isMulti
              isDisabled={values.allCenters}
              styles={multiSelector}
            />
          </Select>
          <WrapperCheckbox>
            <CustomCheckbox
              style={{ padding: '2px 8px' }}
              checked={values.allCenters}
              onChange={() => setValuesAllCenters(setValues, values, selectorCenters)}
            />
            <Text>{capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}</Text>
          </WrapperCheckbox>
        </WrapperSelect>
      )}
    </div>
  </div>
)

export const defaultInput = (values, setFieldValue, errors) => (
  <TextFieldMaterial
    label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`)}
    helperText={errors.name}
    margin="normal"
    value={values.name}
    error={errors.name}
    onChange={({ target: value }) => setFieldValue('name', value.value)}
    type="text"
  />
)

const passwordValidationInputs = (values, setFieldValue, errors) => (
  <>
    <TextFieldMaterial
      label={capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_PASSWORD}`)}
      helperText={errors.password}
      margin="normal"
      value={values.password}
      error={errors.password}
      onChange={({ target: value }) => setFieldValue('password', value.value)}
      type="password"
    />
    <TextFieldMaterial
      label={capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_CONFIRM_PASSWORD}`)}
      helperText={errors.passwordConfirmation}
      margin="normal"
      value={values.passwordConfirmation}
      error={errors.passwordConfirmation}
      onChange={({ target: value }) => setFieldValue('passwordConfirmation', value.value)}
      type="password"
    />
  </>
)

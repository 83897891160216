import styled from 'styled-components'

export const DivWrapper = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 20px;
`

export const DivWrapperData = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  color: ${({ theme }) => theme.colors.blue};
`

export const DivWrapperEdit = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const DivHeaderItemWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  height: 40px;
`

export const DivItem = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 3px;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`

export const NoItemsText = styled.text`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGrey};
  padding: 7px 7px 7px 45px;
  font-size: 20px;
`

export const EmployeeTypeText = styled.text`
  font-size: 20px;
  background: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.darkBlue};
  font-weight: bold;
  padding: 7px;
  display: flex;
`

export const StyledImgIcon = styled.img`
  height: ${({ bigg }) => (bigg ? 25 : 20)}px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 7px;
`

export const StyledButton = styled.button`
  color: ${({ isEditing, theme }) => (isEditing ? theme.colors.darkBlue : 'white')};
  background-color: ${({ isEditing }) => (isEditing ? 'white' : 'transparent')};
  border-radius: 5px;
  border: 1px solid;
  border-color: ${({ isEditing, theme }) => (isEditing ? theme.colors.darkBlue : 'transparent')};
  width: 40px;
  height: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 3%;
`

export const DivWrapperError = styled.div`
  margin: 5px 0px 0px auto;
  color: red;
  fontweight: 500;
`
export const DivWrapperSaveButton = styled.div`
  width: 100px;
  margin: 10px 1% 0px auto;
`

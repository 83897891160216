import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

import dayjs from 'dayjs'

import filterKeys from 'constants/filterKeys'

import { getSimpleDate } from 'utils/date/dateParser'

import { isInstructor, getUserId } from 'utils/session'

const { fetchExcelStart, fetchExcelSuccess, fetchExcelError } = createActions({
  FETCH_EXCEL_START: () => {},
  FETCH_EXCEL_SUCCESS: (data) => ({ data }),
  FETCH_EXCEL_ERROR: (error) => ({ error }),
})

const fetchExcel = (id, type, fileName) => {
  return async (dispatch, getState) => {
    const api = await getApi(true)
    dispatch(fetchExcelStart())
    let { selectedDateFilter } = getState()
    const filterDateFrom = selectedDateFilter.filterDateFrom
      ? dayjs(selectedDateFilter.filterDateFrom)
      : null
    const filterDateTo = selectedDateFilter.filterDateTo
      ? dayjs(selectedDateFilter.filterDateTo)
      : null

    const body = {
      Filters: [],
      Sort: null,
      Pager: null,
      DateFrom: filterDateFrom ? getSimpleDate(filterDateFrom) : null,
      DateTo: filterDateTo ? getSimpleDate(filterDateTo) : null,
      Courses: type === filterKeys.COURSE.COURSE_ID ? [id] : [],
      Instructors: isInstructor() ? [getUserId()] : [],
      DistributionCenters: type === filterKeys.CENTER.CENTER_ID ? [id] : [],
      Employees: type === filterKeys.EMPLOYEE ? [id] : [],
      Leaders: type === filterKeys.LEADER.LEADER_ID ? [id] : [],
    }

    try {
      const response = await api.post(API_ROUTES.GET_EXCEL_EXPORT, body)
      var fileBlob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(fileBlob, fileName)
      } else {
        var objectURL = URL.createObjectURL(fileBlob)
        const link = document.createElement('a')
        link.href = objectURL
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
      }

      dispatch(fetchExcelSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchExcelError(error))
    }
  }
}

export { fetchExcel, fetchExcelStart, fetchExcelSuccess, fetchExcelError }

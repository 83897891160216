import axios from 'axios'
import API_ROUTES from 'config/apiRoutes'

export default isStreaming => {
  const accessToken = localStorage.getItem('@logistica/token')

  let headerConf = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  let api = axios.create({
    baseURL: API_ROUTES.BASE_URL,
    responseType: isStreaming ? 'arraybuffer' : '',
    headers: {
      common: headerConf
    }
  })

  api.interceptors.response.use(
    response => response,
    async error => {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear()
        window.location.reload()
      }
      return Promise.reject(error.response)
    }
  )

  return api
}

import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import { RemoveRedEye } from '@material-ui/icons'

import ButtonMaterial from 'components/ButtonMaterial'

import theme from 'config/theme'
import {
  Container,
  FormContainer,
  FormWrapper,
  ImageWrapper,
  ButtonWrapper,
  ErrorLabel,
  StyledInput,
  DivWrapperInput,
  StyledImgIcon,
  StyledError,
  StyleRedEyeIcon,
  StyledImgDanone
} from './styled'

import IconPerson from 'assets/icons/iconPerson.png'
import IconLock from 'assets/icons/iconLock.png'
import BackgroundImage from 'assets/imgs/loginBackground.png'
import danoneLogo from 'assets/imgs/danoneLogo.png'

import { login } from 'actions/authentication'

import roles from 'constants/roles'

import i18n from 'services/i18n'

import { capitalizeFirstLetter } from 'utils/strings'

class Login extends Component {
  state = {
    passwordIsMasked: true
  }

  renderForm = () => {
    const SignUpSchema = Yup.object().shape({
      user: Yup.string().required(
        capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_USERNAME_ERROR}`)
      ),
      password: Yup.string().required(
        capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_PASSWORD_ERROR}`)
      )
    })

    return (
      <Formik
        initialValues={{
          user: '',
          password: ''
        }}
        validateOnChange={false}
        validationSchema={SignUpSchema}
        onSubmit={this.handleSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }

  pushToPropperMainPage = role => {
    switch (role) {
      case roles.GENERAL_ADMIN:
        this.props.history.push(`/${i18n('PAGE_COURSES')}`)
        break
      case roles.ADMINISTRATOR:
        this.props.history.push(`/${i18n('PAGE_COURSES')}`)
        break
      case roles.INSTRUCTOR:
        this.props.history.push(`/${i18n('PAGE_COURSES')}`)
        break
      case roles.LEADER_USER:
        this.props.history.push(`/${i18n('PAGE_DASHBOARD')}`)
        break
    }
  }

  handleSubmit = async ({ user, password }) => {
    const role = await this.props.login(user, password);
    if (!this.props.authReducer.error) {
      this.pushToPropperMainPage(role)
    }
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }))
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <FormWrapper>
        <DivWrapperInput>
          <StyledImgIcon src={IconPerson} />
          <StyledInput
            color={theme.colors.white}
            placeholder={capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}
            value={values.user}
            onChange={({ target: value }) => setFieldValue('user', value.value)}
          />
        </DivWrapperInput>
        <StyledError>{errors.user}</StyledError>

        <DivWrapperInput>
          <StyledImgIcon src={IconLock} />
          <StyledInput
            color={theme.colors.white}
            type={this.state.passwordIsMasked ? 'password' : 'text'}
            placeholder={capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_PASSWORD}`)}
            value={values.password}
            onChange={({ target: value }) => setFieldValue('password', value.value)}
          />
          <RemoveRedEye style={StyleRedEyeIcon} onClick={this.togglePasswordMask} />
        </DivWrapperInput>
        <StyledError>{errors.password}</StyledError>

        <ButtonWrapper>
          <ButtonMaterial
            type={'submit'}
            background={theme.colors.purple}
            color={theme.colors.white}
            fontSize={'18'}
            loading={this.props.authReducer.isFetching}
            small
            display={true}
          >
            {capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_BUTTON}`)}
          </ButtonMaterial>
        </ButtonWrapper>
        {this.props.authReducer.error ? (
          this.props.authReducer.error.errors && this.props.authReducer.error.errors.length ? (
            <ErrorLabel>{capitalizeFirstLetter(this.props.authReducer.error.errors[0])}</ErrorLabel>
          ) : (
            <ErrorLabel>{capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_ERROR_MESSAGE}`)}</ErrorLabel>
          )
        ) : (
          ''
        )}
      </FormWrapper>
    </FormContainer>
  )

  render() {
    return (
      <Container
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <ImageWrapper>
          <StyledImgDanone src={danoneLogo} />
        </ImageWrapper>
        {this.renderForm()}
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    authReducer: state.auth
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Login))

import styled from 'styled-components'

export const DivWrapperItems = styled.div`
  width: 100%;
`
export const DivWrapperLoader = styled.div`
  margin-top: 5px;
`
export const DivWrapperError = styled.div`
  margin-top: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`

export const DivWrapperHome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

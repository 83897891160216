import { handleActions } from 'redux-actions'
import {
  setStaffIdStart,
  setStaffIdSuccess,
  setStaffIdError,
  setStaffIdReset
} from 'actions/directEmployeeStaffId'

const defaultState = {
  success: false,
  isSubmiting: false,
  error: null
}

const reducer = handleActions(
  {
    [setStaffIdStart]: state => ({
      ...state,
      success: false,
      isSubmiting: true,
      error: null
    }),
    [setStaffIdSuccess]: state => ({
      ...state,
      success: true,
      isSubmiting: false,
      error: null
    }),
    [setStaffIdError]: (state, error) => ({
      ...state,
      success: false,
      isSubmiting: false,
      error: error
    }),
    [setStaffIdReset]: () => defaultState
  },
  defaultState
)

export default reducer

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.lightBlue};
`

export const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const StyledImg = styled.img`
  width: 50%;
  min-width: 300px;
  max-width: 500px;
`
export const StyledImgDanone = styled.img`
  width: 28%;
  max-width: 450px;
  min-width: 300px;
`

export const StyledImgLaserenisima = styled.img`
  width: 22%;
  max-width: 350px;
  min-width: 240px;
`

export const FormContainer = styled.form`
  width: 30%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  max-width: 500px;
`

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 600px;
  margin-top: 50px;
`

export const ButtonWrapper = styled.div`
  margin-bottom: 15px;
`

export const ErrorLabel = styled.label`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  text-align: center;
`

export const DivWrapperInput = styled.div`
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 7px;
  padding: 7px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
`

export const StyledInput = styled.input`
  height: 25px;
  border: none;
  width: 80%;
  background: ${({ theme }) => theme.colors.blue};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.white};
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.white};
  }
`

export const StyledImgIcon = styled.img`
  margin: 0px 10px;
  height: 20px;
`

export const StyledError = styled.p`
  color: ${({ theme }) => theme.colors.white};
  height: 20px;
  font-size: small;
`

export const StyleRedEyeIcon = {
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: '10px',
}

export const RadioGroupStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  margin: '0px 0px 10px 0px',
}

export const FormControlLabelStyle = {
  height: '30px',
}

export const StyledLabel = styled.label`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
`

import React from 'react'
import { Paper, InputBase } from '@material-ui/core'

import ClearButton from 'components/ClearButton'
import DateInput from 'components/DateInput'
import SearchButton from 'components/SearchButton'

import { PaperStyles, InputStyles, StyledDivSearchWrapper } from './styled'

import { capitalizeFirstLetter } from 'utils/strings'

import i18n from 'services/i18n'

const SearchInput = ({
  value,
  handleInput,
  handleClickSearch,
  handleClearSearch,
  isSearchBlocked
}) => (
  <StyledDivSearchWrapper>
    <Paper style={PaperStyles}>
      <InputBase
        value={isSearchBlocked ? capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`) : value}
        style={InputStyles}
        onChange={e => handleInput(e)}
        placeholder={capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_INPUT_HINT}`)}
        disabled={isSearchBlocked}
      />
      <DateInput />
    </Paper>
    <SearchButton handleSearch={handleClickSearch} />
    <ClearButton handleClear={handleClearSearch} disabled={value || isSearchBlocked} />
  </StyledDivSearchWrapper>
)

export default SearchInput

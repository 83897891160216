import styled from 'styled-components'

export const DivWrapper = styled.div`
  width: 100%;
  height: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
`

export const PaginationButton = styled.div`
  margin-top: -1px;
  color: theme.colors.dustyGray;
`

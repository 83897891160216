import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchTrainingDetailStart,
  fetchTrainingDetailSuccess,
  fetchTrainingDetailError,
  trainingDetailReset
} = createActions({
  FETCH_TRAINING_DETAIL_START: () => {},
  FETCH_TRAINING_DETAIL_SUCCESS: data => ({ data }),
  FETCH_TRAINING_DETAIL_ERROR: error => ({ error }),
  TRAINING_DETAIL_RESET: () => {}
})

const fetchTrainingDetail = (categoryId, mode, year) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchTrainingDetailStart())
    const body = {
      Mode: mode,
      Year: year,
      CourseCategories: [categoryId],
      Courses: [],
      DistributionCenters: [],
      Leaders: [],
      WorkSectors: []
    }
    try {
      const response = await api.post(`${API_ROUTES.TRAINING_DETAIL}`, body)
      dispatch(fetchTrainingDetailSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchTrainingDetailError(error))
    }
  }
}

const resetTrainingDetail = () => dispatch => dispatch(trainingDetailReset())

export {
  fetchTrainingDetail,
  fetchTrainingDetailStart,
  fetchTrainingDetailSuccess,
  fetchTrainingDetailError,
  trainingDetailReset,
  resetTrainingDetail
}

import { createActions } from 'redux-actions'

const { selectedFilter, resetFilter } = createActions({
  RESET_FILTER: () => {},
  SELECTED_FILTER: data => ({ data })
})

const setSelectedFilter = status => dispatch => dispatch(selectedFilter({ status }))
const resetSelectedFilter = () => dispatch => dispatch(resetFilter())

export { setSelectedFilter, selectedFilter, resetSelectedFilter, resetFilter }

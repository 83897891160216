import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import Selector from 'components/Selector'
import selectorConfigs from './selectorConfigs'

const FilterSelect = (filter) => {
  const conf = selectorConfigs[filter.collection]

  const [isLoading, setIsLoading] = React.useState(false)
  const [options, setOptions] = React.useState(
    filter.options ? filter.options.map((x) => ({ label: x.label, value: x.value })) : []
  )
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [dependencyValue, setDependencyValue] = React.useState('')
  const location = useLocation()
  const query = queryString.parse(location.search)

  const dispatch = useDispatch()
  if (filter.dependency) {
    if (JSON.stringify(dependencyValue) !== JSON.stringify(query[filter.dependency])) {
      setDependencyValue(query[filter.dependency])
      setIsDisabled(false)
    } else if (!isDisabled && !dependencyValue) {
      setIsDisabled(true)
    }
  }
  const currentState = useSelector((state) => {
    if (filter.collection) {
      return state[conf.stateName]
    }
    return {}
  })

  function setStateOptions(stateOptions) {
    let newOptions = conf.map(stateOptions)
    setIsLoading(false)
    if (newOptions) {
      setOptions(newOptions)
    }
  }
  React.useEffect(() => {
    if (!isDisabled && selectorConfigs[filter.collection]) {
      if (filter.dependency && !dependencyValue) {
        return
      }
      if (filter.dependency && dependencyValue) {
        dispatch(conf.fetch(dependencyValue))
      } else if (conf.map(currentState).length) {
        setStateOptions(currentState)
      } else {
        dispatch(conf.fetch())
      }
    }
  }, [dependencyValue])

  let isFetching = true
  if (filter.collection) {
    isFetching = currentState[conf.isFetchingProp]
    if (isLoading && !isFetching) {
      setStateOptions(currentState)
    } else if (!isLoading && isFetching) {
      setIsLoading(true)
    }
  }

  let valueSelect
  if (options && query[filter.name]) {
    if (filter.isMulti) {
      if (query[filter.name] instanceof Array) {
        valueSelect = options.filter((x) => query[filter.name].indexOf(x.value.toString()) != -1)
      } else {
        valueSelect = [options.find((x) => x.value == query[filter.name])]
      }
    } else {
      valueSelect = options.find((x) => x.value == query[filter.name])
    }
  }
  return (
    <Selector
      {...filter}
      placeholder=""
      disabled={filter.disabled || isDisabled || isLoading}
      isLoading={isLoading}
      options={options}
      value={valueSelect}
    />
  )
}

export default FilterSelect

export default [
  {
    label: 'Curso',
    name: 'course',
    subProp: 'name',
    childrenProp: 'employees',
    children: [
      {
        label: 'Apellido',
        name: 'lastName',
      },
      {
        label: 'Nombre',
        name: 'name',
      },
      {
        label: 'Legajo',
        name: 'fileNumber',
      },
    ],
  },
]

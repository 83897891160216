import styled from 'styled-components'
import { breakpoints } from 'config/media-queries'

export const FiltersForm = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  label {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: ${breakpoints.mobile - 20}px) {
      width: 50%;
    }
    @media (min-width: ${breakpoints.mobile - 20}px) and (max-width: ${breakpoints.tablet}px) {
      &:not(:nth-child(2n + 0)) {
        padding-right: 10px;
      }
    }
    @media (min-width: ${breakpoints.tablet}px) {
      width: 33.3%;
    }
    @media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptop - 1}px) {
      &:not(:nth-child(3n + 0)) {
        padding-right: 10px;
      }
    }
    @media (min-width: ${breakpoints.laptop}px) {
      width: 25%;
      &:not(:nth-child(4n + 0)) {
        padding-right: 10px;
      }
    }
    & > span {
      padding: 5px;
      font-size: 0.8rem;
    }
  }
`

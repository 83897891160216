import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  showing,

  fetchLeadersByNameStart,
  fetchLeadersByNameSuccess,
  fetchLeadersByNameError,
  leadersByNameReset,

  fetchLeadersByDateStart,
  fetchLeadersByDateSuccess,
  fetchLeadersByDateError,
  leadersByDateReset,

  fetchLeadersStart,
  fetchLeadersSuccess,
  fetchLeadersError,
  leadersReset,

  modifyLeaderStart,
  modifyLeaderSuccess,
  modifyLeaderError,
  modifyLeaderReset,

  addLeaderStart,
  addLeaderSuccess,
  addLeaderError,
  addLeaderReset,

  deleteLeaderStart,
  deleteLeaderSuccess,
  deleteLeaderError,
  deleteLeaderErrorReset,
} = createActions({
  SHOWING: (data) => ({ data }),

  FETCH_LEADERS_BY_NAME_START: () => {},
  FETCH_LEADERS_BY_NAME_SUCCESS: (data) => ({ data }),
  FETCH_LEADERS_BY_NAME_ERROR: (error) => ({ error }),
  LEADERS_BY_NAME_RESET: () => {},

  FETCH_LEADERS_BY_DATE_START: () => {},
  FETCH_LEADERS_BY_DATE_SUCCESS: (data) => ({ data }),
  FETCH_LEADERS_BY_DATE_ERROR: (error) => ({ error }),
  LEADERS_BY_DATE_RESET: () => {},

  FETCH_LEADERS_START: () => {},
  FETCH_LEADERS_SUCCESS: (data) => ({ data }),
  FETCH_LEADERS_ERROR: (error) => ({ error }),
  LEADERS_RESET: () => {},

  MODIFY_LEADER_START: () => {},
  MODIFY_LEADER_SUCCESS: () => {},
  MODIFY_LEADER_ERROR: (error) => ({ error }),
  MODIFY_LEADER_RESET: () => {},

  ADD_LEADER_START: () => {},
  ADD_LEADER_SUCCESS: () => {},
  ADD_LEADER_ERROR: (error) => ({ error }),
  ADD_LEADER_RESET: () => {},

  DELETE_LEADER_START: () => {},
  DELETE_LEADER_SUCCESS: () => {},
  DELETE_LEADER_ERROR: (error) => ({ error }),
  DELETE_LEADER_ERROR_RESET: () => {},
})

const setShowingLeader = (status) => (dispatch) => dispatch(showing({ status }))

const fetchLeadersByName = (name) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchLeadersByNameStart())
    let urlFinal = `${API_ROUTES.GET_LEADERS_BY_NAME}/${name}`
    const {
      selectedFilter: { selectedBusiness },
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)
      dispatch(fetchLeadersByNameSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchLeadersByNameError(error))
    }
  }
}

const resetLeadersByName = () => (dispatch) => dispatch(leadersByNameReset())

const fetchLeadersByDate = (from, to) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchLeadersByDateStart())
    let urlFinal = `${API_ROUTES.PUT_LEADER_NAME}/${API_ROUTES.BY_DATE}/${from}/${to}`
    const {
      selectedFilter: { selectedBusiness },
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)

      dispatch(fetchLeadersByDateSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchLeadersByDateError(error))
    }
  }
}

const resetLeadersByDate = () => (dispatch) => dispatch(leadersByDateReset())

const fetchLeaders = (type = null) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchLeadersStart())
    let urlFinal = `${API_ROUTES.PUT_LEADER_NAME}`
    const {
      selectedFilter: { selectedBusiness },
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`
    urlFinal = type ? `${urlFinal}?leaderType=${type}` : urlFinal

    try {
      const response = await api.get(urlFinal)
      dispatch(fetchLeadersSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchLeadersError(error))
    }
  }
}

const resetLeaders = () => (dispatch) => dispatch(leadersReset())

const modifyLeader = (item, data) => {
  return async (dispatch) => {
    const api = await getApi()
    dispatch(modifyLeaderStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      FileNumber: data.fileNumber,
      CostCenter: data.costCenter,
      Category: data.category,
      DistributionCenter: {
        Id: data.distributionCenterId.value,
      },
      WorkSector: {
        Id: data.workSector.value,
      },
      WorkPosition: {
        Id: data.workPosition.value,
      },
      Leader: {
        Id: data.leader.value,
      },
    }
    try {
      const response = await api.put(`${API_ROUTES.PUT_LEADER_NAME}/${item.id}`, body)
      dispatch(modifyLeaderSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyLeaderError(error))
    }
  }
}

const resetModifyLeader = () => (dispatch) => dispatch(modifyLeaderReset())

const addLeader = (data) => {
  return async (dispatch) => {
    const api = await getApi()
    dispatch(addLeaderStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      FileNumber: data.fileNumber,
      CostCenter: data.costCenter,
      Category: data.category,
      Type: data.leaderType.value,
      DistributionCenter: {
        Id: data.distributionCenterId.value,
      },
      WorkSector: {
        Id: data.workSector.value,
      },
      WorkPosition: {
        Id: data.workPosition.value,
      },
      Leader: data.leader
        ? {
            Id: data.leader.value,
          }
        : null,
    }
    try {
      const response = await api.post(`${API_ROUTES.PUT_LEADER_NAME}`, body)
      dispatch(addLeaderSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addLeaderError(error.data))
    }
  }
}

const resetAddLeader = () => (dispatch) => dispatch(addLeaderReset())

const deleteLeader = (id) => {
  return async (dispatch) => {
    const api = await getApi()
    dispatch(deleteLeaderStart())
    try {
      const response = await api.delete(`${API_ROUTES.PUT_LEADER_NAME}/${id}`)
      dispatch(deleteLeaderSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteLeaderError(error.data))
    }
  }
}

const resetDeleteLeaderError = () => (dispatch) => dispatch(deleteLeaderErrorReset())

export {
  setShowingLeader,
  showing,
  fetchLeadersByName,
  fetchLeadersByNameStart,
  fetchLeadersByNameSuccess,
  fetchLeadersByNameError,
  resetLeadersByName,
  leadersByNameReset,
  fetchLeadersByDate,
  fetchLeadersByDateStart,
  fetchLeadersByDateSuccess,
  fetchLeadersByDateError,
  leadersByDateReset,
  resetLeadersByDate,
  fetchLeaders,
  fetchLeadersStart,
  fetchLeadersSuccess,
  fetchLeadersError,
  leadersReset,
  resetLeaders,
  modifyLeader,
  modifyLeaderStart,
  modifyLeaderSuccess,
  modifyLeaderError,
  resetModifyLeader,
  modifyLeaderReset,
  addLeader,
  addLeaderStart,
  addLeaderSuccess,
  addLeaderError,
  resetAddLeader,
  addLeaderReset,
  deleteLeader,
  deleteLeaderStart,
  deleteLeaderSuccess,
  deleteLeaderError,
  resetDeleteLeaderError,
  deleteLeaderErrorReset,
}

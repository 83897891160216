import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchWorkSectorStart,
  fetchWorkSectorSuccess,
  fetchWorkSectorError,
  workSectorReset
} = createActions({
  FETCH_WORK_SECTOR_START: () => {},
  FETCH_WORK_SECTOR_SUCCESS: data => ({ data }),
  FETCH_WORK_SECTOR_ERROR: error => ({ error }),
  WORK_SECTOR_RESET: () => {}
})

const fetchWorkSector = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchWorkSectorStart())
    try {
      const response = await api.get(`${API_ROUTES.WORK_SECTOR}`)
      dispatch(fetchWorkSectorSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchWorkSectorError(error))
    }
  }
}

const resetWorkSector = () => dispatch => dispatch(workSectorReset())

export {
  fetchWorkSectorStart,
  fetchWorkSectorSuccess,
  fetchWorkSectorError,
  workSectorReset,
  fetchWorkSector,
  resetWorkSector
}

import i18n from 'services/i18n'

export const capitalizeFirstLetter = string =>
  string ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : noString()

export const lowerFirsLetter = string =>
  string ? `${string.charAt(0).toLowerCase()}${string.slice(1)}` : noString()

export const allToCaps = string => (string ? `${string.toUpperCase()}` : noString())

export const allToLower = string => (string ? `${string.toLowerCase()}` : noString())

const noString = () => capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`)

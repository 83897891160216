import filtersDefaultWithState from './filtersDefaultWithState'

export default [
  {
    name: 'DistributionCenters',
    label: 'Centro',
    type: 'select',
    collection: 'centers',
    isMulti: true,
  },
  {
    name: 'Leaders',
    label: 'Jefe',
    type: 'select',
    collection: 'employees',
    dependency: 'DistributionCenters',
    isMulti: true,
  },
  ...filtersDefaultWithState,
]

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setSelectedPage, setSelectedCapacitationPage } from 'actions/page'

import { DivWrapper, PaginationButton } from './styled'

import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'

const Paginator = props => (
  <DivWrapper onClick={e => e.stopPropagation()}>
    <Pagination
      onChange={
        props.isLocal
          ? props.onChangeLocal
          : props.isCapacitation
          ? props.setSelectedCapacitationPage
          : props.setSelectedPage
      }
      current={
        props.isLocal
          ? props.selectedPageLocal
          : props.isCapacitation
          ? props.selectedPage.capacitationPage
          : props.selectedPage.page
      }
      total={props.total}
      showTitle={false}
      nextIcon={<PaginationButton>{`›`}</PaginationButton>}
      prevIcon={<PaginationButton> {`‹`} </PaginationButton>}
    />
  </DivWrapper>
)

const mapStateToProps = ({ selectedPage }) => ({
  selectedPage
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedPage,
      setSelectedCapacitationPage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Paginator)

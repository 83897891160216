import React from 'react'

import ArrowBack from '@material-ui/icons/ArrowBack'

import theme from 'config/theme'
import { Button, ButtonBackWrapper, arrowBack } from './styled'

const ButtonBack = props => (
  <ButtonBackWrapper>
    <Button>
      <ArrowBack onClick={props.onClose} style={arrowBack(theme)} />
    </Button>
  </ButtonBackWrapper>
)
export default ButtonBack

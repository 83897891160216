import React from 'react'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Divider } from '@material-ui/core'

import CustomRadioMaterials from 'components/CustomRadio'

import { capitalizeFirstLetter } from 'utils/strings'
import isDanone from 'utils/isDanone'

import { RadioGroupStyle, FormControlLabelStyle, StyledDivWrapper, DividerStyle } from './styled'

import i18n from 'services/i18n'

import filterKeys from 'constants/filterKeys'

const isDirectEmployee = selectedEmployee => selectedEmployee === filterKeys.EMPLOYEE_DIRECT.DIRECT

const isCarrierEmployee = selectedEmployee =>
  selectedEmployee === filterKeys.EMPLOYEE_CARRIER.CARRIER

const isIndirectEmployee = selectedEmployee =>
  selectedEmployee === filterKeys.EMPLOYEE_INDIRECT.INDIRECT

const isCourse = selectedField => selectedField === filterKeys.COURSE.COURSE_ID

const isCenter = selectedField => selectedField === filterKeys.CENTER.CENTER_ID

const isLeader = selectedField => selectedField === filterKeys.LEADER.LEADER_ID

const isSomeItemClicked = selectedField =>
  isDirectEmployee(selectedField) ||
  isCarrierEmployee(selectedField) ||
  isIndirectEmployee(selectedField) ||
  isCourse(selectedField) ||
  isCenter(selectedField) ||
  isLeader(selectedField)

const SearchFilters = ({ handleChangeFilter, selectedField, selectedItem, isDanoneSelected }) => (
  <StyledDivWrapper>
    <div>
      <FormLabel component="legend">
        {capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_FILTERS_HINT}`)}
      </FormLabel>

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="DirectEmployee"
          name={filterKeys.EMPLOYEE_DIRECT.DIRECT}
          value={selectedItem}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value={filterKeys.EMPLOYEE_DIRECT.DIRECT}
            control={<CustomRadioMaterials checked={isDirectEmployee(selectedField)} />}
            label={capitalizeFirstLetter(
              `${i18n('CAPACITATION').CAPACITATION_TABLE_DIRECT_EMPLOYEE}`
            )}
          />
        </RadioGroup>
      </FormControl>
      {!isDanoneSelected && (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="CarrierEmployee"
            name={filterKeys.EMPLOYEE_CARRIER.CARRIER}
            value={selectedItem}
            onChange={handleChangeFilter}
          >
            <FormControlLabel
              value={filterKeys.EMPLOYEE_CARRIER.CARRIER}
              control={<CustomRadioMaterials checked={isCarrierEmployee(selectedField)} />}
              label={capitalizeFirstLetter(
                `${i18n('CAPACITATION').CAPACITATION_TABLE_CARRIER_EMPLOYEE}`
              )}
            />
          </RadioGroup>
        </FormControl>
      )}

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="IndirectEmployee"
          name={filterKeys.EMPLOYEE_INDIRECT.INDIRECT}
          value={selectedItem}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value={filterKeys.EMPLOYEE_INDIRECT.INDIRECT}
            control={<CustomRadioMaterials checked={isIndirectEmployee(selectedField)} />}
            label={capitalizeFirstLetter(
              `${i18n('CAPACITATION').CAPACITATION_TABLE_INDIRECT_EMPLOYEE}`
            )}
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Course"
          name={filterKeys.COURSE.COURSE_ID}
          value={selectedItem}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value={filterKeys.COURSE.COURSE_ID}
            control={<CustomRadioMaterials checked={isCourse(selectedField)} />}
            label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_COURSE}`)}
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Center"
          name={filterKeys.CENTER.CENTER_ID}
          value={selectedItem}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value={filterKeys.CENTER.CENTER_ID}
            control={<CustomRadioMaterials checked={isCenter(selectedField)} />}
            label={capitalizeFirstLetter(
              `${
                isDanone()
                  ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
                  : i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_PLACE
              }`
            )}
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Leader"
          name={filterKeys.LEADER.LEADER_ID}
          value={selectedItem}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value={filterKeys.LEADER.LEADER_ID}
            control={<CustomRadioMaterials checked={isLeader(selectedField)} />}
            label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`)}
          />
        </RadioGroup>
      </FormControl>
    </div>
    <Divider style={DividerStyle(isSomeItemClicked(selectedField))} />
    <FormControl>
      {isDirectEmployee(selectedField) ? (
        <div>
          <RadioGroup
            aria-label="Items"
            style={RadioGroupStyle}
            value={selectedItem}
            onChange={handleChangeFilter}
          >
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_DIRECT.ALL}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
            />
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_DIRECT.NAME}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(
                `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`
              )}
            />
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_DIRECT.FILE}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(
                `${i18n('CAPACITATION').CAPACITATION_TABLE_FILE_NUMBER}`
              )}
            />
          </RadioGroup>
        </div>
      ) : (
        ''
      )}

      {isCarrierEmployee(selectedField) ? (
        <div>
          <RadioGroup
            aria-label="Items"
            style={RadioGroupStyle}
            value={selectedItem}
            onChange={handleChangeFilter}
          >
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_CARRIER.ALL}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
            />
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_CARRIER.NAME}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(
                `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`
              )}
            />
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_CARRIER.FILE}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(
                `${i18n('CAPACITATION').CAPACITATION_TABLE_FILE_NUMBER}`
              )}
            />
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_CARRIER.DOCUMENT}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_ID}`)}
            />
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_CARRIER.DISTRIBUCION}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(
                `${i18n('CAPACITATION').CAPACITATION_TABLE_DISTRIBUTION_NUMBER}`
              )}
            />
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_CARRIER.ACCOUNT}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(
                `${i18n('CAPACITATION').CAPACITATION_TABLE_ACCOUNT_NUMBER}`
              )}
            />
          </RadioGroup>
        </div>
      ) : (
        ''
      )}

      {isIndirectEmployee(selectedField) ? (
        <div>
          <RadioGroup
            aria-label="Items"
            style={RadioGroupStyle}
            value={selectedItem}
            onChange={handleChangeFilter}
          >
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_INDIRECT.ALL}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
            />
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_INDIRECT.NAME}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(
                `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`
              )}
            />
            <FormControlLabel
              style={FormControlLabelStyle}
              value={filterKeys.EMPLOYEE_INDIRECT.DOCUMENT}
              control={<CustomRadioMaterials small />}
              label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_ID}`)}
            />
          </RadioGroup>
        </div>
      ) : (
        ''
      )}

      {isCourse(selectedField) ? (
        <RadioGroup
          aria-label="Items"
          style={RadioGroupStyle}
          value={selectedItem}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            style={FormControlLabelStyle}
            value={filterKeys.COURSE.ALL}
            control={<CustomRadioMaterials small />}
            label={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
          />
          <FormControlLabel
            style={FormControlLabelStyle}
            value={filterKeys.COURSE.NAME}
            control={<CustomRadioMaterials small />}
            label={capitalizeFirstLetter(
              `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`
            )}
          />
        </RadioGroup>
      ) : (
        ''
      )}

      {isCenter(selectedField) ? (
        <RadioGroup
          aria-label="Items"
          style={RadioGroupStyle}
          value={selectedItem}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            style={FormControlLabelStyle}
            value={filterKeys.CENTER.ALL}
            control={<CustomRadioMaterials small />}
            label={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
          />
          <FormControlLabel
            style={FormControlLabelStyle}
            value={filterKeys.CENTER.NAME}
            control={<CustomRadioMaterials small />}
            label={capitalizeFirstLetter(
              `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`
            )}
          />
        </RadioGroup>
      ) : (
        ''
      )}

      {isLeader(selectedField) ? (
        <RadioGroup
          aria-label="Items"
          style={RadioGroupStyle}
          value={selectedItem}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            style={FormControlLabelStyle}
            value={filterKeys.LEADER.ALL}
            control={<CustomRadioMaterials small />}
            label={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
          />
          <FormControlLabel
            style={FormControlLabelStyle}
            value={filterKeys.LEADER.NAME}
            control={<CustomRadioMaterials small />}
            label={capitalizeFirstLetter(
              `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`
            )}
          />
        </RadioGroup>
      ) : (
        ''
      )}
    </FormControl>
  </StyledDivWrapper>
)

export default SearchFilters

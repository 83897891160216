import { reestructureDataArray } from 'utils/selectorParser'

import { defaulBusiness } from 'constants/defaultBusiness'

export const setValuesAllCenters = (setValues, values, selectorCenters) => {
  setValues({
    ...values,
    ['allCenters']: values.allCenters ? false : true,
    ['distributionCenters']: values.allCenters
      ? null
      : selectorCenters.valuesAll
      ? reestructureDataArray(selectorCenters.valuesAll)
      : [],
  })
}

export const setValuesAllCategories = (setValues, values, selectorCategories) => {
  setValues({
    ...values,
    ['allCategories']: values.allCategories ? false : true,
    ['availableCategories']: values.allCategories
      ? null
      : selectorCategories.values
      ? reestructureDataArray(selectorCategories.values)
      : [],
    ['availableCourses']: null,
    ['allCourses']: false,
  })
}

export const setValuesAllCourses = (setValues, values, selectorCourses) => {
  setValues({
    ...values,
    ['allCourses']: values.allCourses ? false : true,
    ['availableCourses']: values.allCourses
      ? null
      : selectorCourses
      ? reestructureDataArray(selectorCourses)
      : [],
  })
}

export const setValuesBusinessIndirectEmployee = (target, setValues, values, inputName) => {
  switch (inputName) {
    case 'business':
      setValues({
        ...values,
        ['business']: target.value,
        ['businessInput']: target.value !== defaulBusiness.OTHER ? target.value : '',
      })
      break
    case 'companies':
      setValues({
        ...values,
        ['companies']: target,
      })
      break
  }
}

export const setValuesCategory = (setValues, values, target) => {
  setValues({
    ...values,
    ['availableCategories']: target,
    ['availableCourses']: null,
    ['allCourses']: false,
  })
}

export const setValuesLeaderType = (setValues, values, target) => {
  setValues({
    ...values,
    ['leaderType']: target,
  })
}

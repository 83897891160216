import React from 'react'

import { capitalizeFirstLetter } from 'utils/strings'

import i18n from 'services/i18n'

import {
  CourseWrapper,
  PreviewWrapper,
  DivWrapper,
  DivItemWrapper,
  DivHeaderItemWrapper,
  DivItem
} from './styled'

const CapacitationPreview = ({ data }) => (
  <DivWrapper>
    <DivHeaderItemWrapper>
      <DivItem>
        {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_COURSE}`)}
      </DivItem>
      <DivItem>
        {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY}`)}
      </DivItem>
    </DivHeaderItemWrapper>

    {data.map(course => (
      <PreviewWrapper key={`${course.id}-course`}>
        <CourseWrapper>
          <DivItemWrapper>
            <DivItem isTableItem>{course.name}</DivItem>
            <DivItem isTableItem>{course.category.name}</DivItem>
          </DivItemWrapper>
        </CourseWrapper>
      </PreviewWrapper>
    ))}
  </DivWrapper>
)

export default CapacitationPreview

import React, { Fragment } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { createBrowserHistory } from 'history'
import GlobalStyle from 'config/globalStyle'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import theme from 'config/theme'
import muiTheme from 'config/muiTheme'

import store from './store'
import i18n from 'services/i18n'

import Login from 'pages/Login'
import Home from 'pages/Home'
import Users from 'pages/Users'
import NotFound from 'pages/NotFound'
import Dashboard from 'pages/Dashboard'
import Policy from 'pages/Policy'
import PrivateRoute from 'components/PrivateRoute'
import { AdminRoute, InstructorRoute, LeaderUserRoute } from 'components/PrivateRoute/RoleRoute'

const browserHistory = createBrowserHistory()

const App = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <Fragment>
          <Router history={browserHistory}>
            <Switch>
              <PrivateRoute exact path={`/`} component={Home} />
              <InstructorRoute exact path={`/${i18n('PAGE_COURSES')}`} component={Home} />
              <AdminRoute exact path={`/${i18n('PAGE_USERS')}`} component={Users} />
              <LeaderUserRoute path={`/${i18n('PAGE_DASHBOARD')}`} component={Dashboard} />

              <Route exact path={`/login`} component={Login} />
              <Route exact path={`/404`} component={NotFound} />
              <Route exact path={`/${i18n('PAGE_POLICY')}`} component={Policy} />

              <Redirect to="/404" />
            </Switch>
          </Router>
          <GlobalStyle />
        </Fragment>
      </ThemeProvider>
    </MuiThemeProvider>
  </Provider>
)

export default App

import { handleActions } from 'redux-actions'
import { deletePopupStatus } from 'actions/deletePopup'

const defaultState = {
  open: false,
  itemToDelete: null,
  type: null
}

const reducer = handleActions(
  {
    [deletePopupStatus]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => {
      return status
    }
  },
  defaultState
)

export default reducer

import { createActions } from 'redux-actions'

const { selectedSearchedItem, resetSearchedItem } = createActions({
  RESET_SEARCHED_ITEM: () => {},
  SELECTED_SEARCHED_ITEM: data => ({ data })
})

const setSelectedSearchedItem = status => dispatch => dispatch(selectedSearchedItem({ status }))
const resetSelectedSearchedItem = () => dispatch => dispatch(resetSearchedItem())

export {
  setSelectedSearchedItem,
  selectedSearchedItem,
  resetSelectedSearchedItem,
  resetSearchedItem
}

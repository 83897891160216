import { handleActions } from 'redux-actions'
import {
  fetchCapacitationsByIdStart,
  fetchCapacitationsByIdSuccess,
  fetchCapacitationsByIdError,
  capacitationsByIdReset,
  fetchCapacitationsByFilterStart,
  fetchCapacitationsByFilterSuccess,
  fetchCapacitationsByFilterError,
  capacitationsByFilterReset,
  modifyCapacitationStart,
  modifyCapacitationSuccess,
  modifyCapacitationError,
  modifyCapacitationReset,
  deleteCapacitationStart,
  deleteCapacitationSuccess,
  deleteCapacitationError,
  deleteCapacitationErrorReset
} from 'actions/capacitations'

const defaultState = {
  valuesById: null,
  errorById: null,
  isFetchingById: false,

  values: null,
  error: null,
  isFetching: false,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false
}

const reducer = handleActions(
  {
    [fetchCapacitationsByIdStart]: state => ({
      ...state,
      isFetchingById: true,
      errorById: null
    }),
    [fetchCapacitationsByIdSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesById: values,
      isFetchingById: false,
      errorById: null
    }),
    [fetchCapacitationsByIdError]: (state, { error }) => ({
      ...state,
      isFetchingById: false,
      errorById: error
    }),
    [capacitationsByIdReset]: () => defaultState,

    [fetchCapacitationsByFilterStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchCapacitationsByFilterSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchCapacitationsByFilterError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [capacitationsByFilterReset]: () => defaultState,

    [modifyCapacitationStart]: state => ({
      ...state,
      isSubmiting: true,
      successEdit: false,
      errorEdit: false
    }),
    [modifyCapacitationSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyCapacitationError]: (state, error) => ({
      ...state,
      isSubmiting: false,
      successEdit: false,
      errorEdit: error
    }),
    [modifyCapacitationReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false,
      successEdit: false
    }),

    [deleteCapacitationStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteCapacitationSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteCapacitationError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),
    [deleteCapacitationErrorReset]: state => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: false
    })
  },
  defaultState
)

export default reducer

import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchTrainingSummaryStart,
  fetchTrainingSummarySuccess,
  fetchTrainingSummaryError,
  trainingSummaryReset
} = createActions({
  FETCH_TRAINING_SUMMARY_START: () => {},
  FETCH_TRAINING_SUMMARY_SUCCESS: data => ({ data }),
  FETCH_TRAINING_SUMMARY_ERROR: error => ({ error }),
  TRAINING_SUMMARY_RESET: () => {}
})

const fetchTrainingSummary = (centersIds, categoryIds, coursesIds, mode, year) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchTrainingSummaryStart())
    const body = {
      Mode: mode,
      Year: year,
      CourseCategories: categoryIds,
      Courses: coursesIds,
      DistributionCenters: centersIds,
      Leaders: [],
      WorkSectors: []
    }
    try {
      const response = await api.post(`${API_ROUTES.TRAINING_SUMMARY}`, body)
      dispatch(fetchTrainingSummarySuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchTrainingSummaryError(error))
    }
  }
}

const resetTrainingSummary = () => dispatch => dispatch(trainingSummaryReset())

export {
  fetchTrainingSummary,
  fetchTrainingSummaryStart,
  fetchTrainingSummarySuccess,
  fetchTrainingSummaryError,
  trainingSummaryReset,
  resetTrainingSummary
}

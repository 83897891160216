import { handleActions } from 'redux-actions'
import { selector, reset } from 'actions/selector'

const defaultState = {
  selectedOption: {}
}

const reducer = handleActions(
  {
    [selector]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,
    [reset]: () => defaultState
  },
  defaultState
)

export default reducer

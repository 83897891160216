import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchExcel } from 'actions/excelDownload'
import { fetchCapacitationsByFilter, resetCapacitationsByFilter } from 'actions/capacitations'
import { setSelectedCapacitation } from 'actions/selectedCapacitation'
import { setCapacitationDetailStatus } from 'actions/capacitationDetailStatus'
import { setSelectedSearchedItem, resetSelectedSearchedItem } from 'actions/selectedSearchedItem'
import { setEditPopupStatus } from 'actions/editPopup'
import { setDeletePopupStatus } from 'actions/deletePopup'
import { setSelectedCapacitationPage } from 'actions/page'

import { capitalizeFirstLetter, allToLower } from 'utils/strings'
import isDanone from 'utils/isDanone'

import {
  DivWrapperEmployeeData,
  DivWrapper,
  StyledText,
  DivWrapperItems,
  DivWrapperError,
  DivWrapperLoader
} from './styled'

import i18n from 'services/i18n'

import Loader from 'components/Loaders/propagate'
import MaterialExpansionPanel from 'components/ExpansionPanel'
import CapacitationPreview from 'components/CapacitationPreview'
import Pagination from 'components/Pagination'

import filterKeys from 'constants/filterKeys'
import popupKeys from 'constants/popupKeys'

import { paginate } from 'utils/paginator'

class SearchDisplayCourse extends Component {
  state = {
    current: 1
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.selectedPage.capacitationPage !== this.props.selectedPage.capacitationPage &&
      this.props.selectedFilter.filterCategory === filterKeys.LEADER.LEADER_ID
    ) {
      this.getCapacitationsByLeaderId(this.props.selectedSearchedItem.expandedItem)
    }
  }

  isFetching = () =>
    this.props.leaders.isFetching ||
    this.props.leaders.isFetchingByDate ||
    this.props.leaders.isFetchingAll

  isLeadersExpanded = id => this.props.selectedSearchedItem.expandedItem === id

  thereAreValues = () =>
    this.props.leaders.values || this.props.leaders.valuesByDate || this.props.leaders.valuesAll

  getCapacitationsByLeaderId = id => {
    if (this.isLeadersExpanded(id) && id) {
      this.props.fetchCapacitationsByFilter(filterKeys.LEADER.LEADER_ID, id)
    }
  }

  setSelectedLeader = id => {
    if (this.isLeadersExpanded(id)) {
      this.props.resetSelectedSearchedItem()
    } else {
      this.props.setSelectedSearchedItem({ id })
    }
  }

  wichLeaderWasSelected = id => this.state.selectedLeaderDownload === id

  handleLeader = id => {
    this.props.resetSelectedSearchedItem()
    this.props.resetCapacitationsByFilter()
    this.props.setSelectedCapacitationPage(1)
    this.setSelectedLeader(id)
    setTimeout(() => {
      this.getCapacitationsByLeaderId(id)
    }, 40)
  }

  fetchExcel = (e, id, type, fileName) => {
    this.setState({ selectedLeaderDownload: id })
    this.props.fetchExcel(id, type, fileName)
    e.stopPropagation()
  }

  handleCapacitationDetail = (e, capacitation) => {
    e.stopPropagation()
    this.props.setSelectedCapacitation(capacitation)
    this.props.setCapacitationDetailStatus(
      this.props.capacitationDetailStatus.status ? false : true
    )
  }

  onChangePagination = page => {
    this.setState({
      current: page
    })
  }

  handleEditPopup = (e, item, type) => {
    this.props.setEditPopupStatus({ open: true, itemToEdit: item, type: type })
    e.stopPropagation()
  }

  handleDelete = (e, item, type) => {
    this.props.setDeletePopupStatus({ open: true, itemToDelete: item, type: type })
    e.stopPropagation()
  }

  wichValues = () =>
    this.props.leaders.values ? this.props.leaders.values : this.hasCapacitations()

  hasCapacitations = () => {
    if (
      this.props.selectedDateFilter.filterDateFrom &&
      this.props.selectedDateFilter.filterDateTo &&
      this.props.leaders.valuesByDate
    ) {
      return this.props.leaders.valuesByDate.filter(e => e.hasTrainings)
    } else {
      return this.props.leaders.valuesAll
    }
  }

  render() {
    if (this.isFetching()) {
      return <Loader />
    } else {
      return this.thereAreValues() ? (
        this.wichValues().length ? (
          <DivWrapperItems>
            {paginate(this.state.current, this.wichValues()).map(item => (
              <MaterialExpansionPanel
                key={item.id}
                name={item.name}
                id={item.id}
                expanded={this.isLeadersExpanded(item.id)}
                onClick={() => this.handleLeader(item.id)}
                withDownload={true}
                onButtonDownloadClick={e =>
                  this.fetchExcel(e, item.id, filterKeys.LEADER.LEADER_ID, item.name)
                }
                buttonDownloadLoading={
                  this.props.excelDownload.isFetching && this.wichLeaderWasSelected(item.id)
                }
                withEdit
                onButtonEditClick={e => this.handleEditPopup(e, item, popupKeys.LEADER)}
                withDelete
                onButtonDeleteClick={e => this.handleDelete(e, item, popupKeys.LEADER)}
              >
                <div>
                  <DivWrapper>
                    <DivWrapperEmployeeData>
                      <StyledText>
                        {item.fileNumber
                          ? `${capitalizeFirstLetter(
                              `${i18n('CAPACITATION').CAPACITATION_TABLE_FILE_NUMBER}`
                            )}: ${item.fileNumber}`
                          : ''}
                      </StyledText>
                      <StyledText>
                        {item.category
                          ? `${capitalizeFirstLetter(
                              `${i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY}`
                            )}: ${capitalizeFirstLetter(allToLower(item.category))}`
                          : ''}
                      </StyledText>
                      <StyledText>
                        {item.leader
                          ? `${capitalizeFirstLetter(
                              `${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`
                            )}: ${capitalizeFirstLetter(allToLower(item.leader.name))}`
                          : ''}
                      </StyledText>
                      <StyledText>
                        {item.documentNumber
                          ? `${capitalizeFirstLetter(
                              `${i18n('CAPACITATION').CAPACITATION_TABLE_ID}`
                            )}: ${item.documentNumber}`
                          : ''}
                      </StyledText>
                      <StyledText>
                        {item.effectiveStaffId
                          ? `${capitalizeFirstLetter(
                              `${i18n('EDIT_VIEW').EDIT_VIEW_EMPLOYEE_ID}`
                            )}: ${item.effectiveStaffId}`
                          : ''}
                      </StyledText>
                    </DivWrapperEmployeeData>
                    <DivWrapperEmployeeData>
                      <StyledText>
                        {item.distributionCenter
                          ? `${capitalizeFirstLetter(
                              `${
                                isDanone()
                                  ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
                                  : i18n('CAPACITATION').CAPACITATION_TABLE_DISTRIBUTION_CENTER
                              }`
                            )}: ${capitalizeFirstLetter(allToLower(item.distributionCenter.name))}`
                          : ''}
                      </StyledText>
                      <StyledText>
                        {item.workSector
                          ? `${capitalizeFirstLetter(
                              `${i18n('CAPACITATION').CAPACITATION_TABLE_SECTOR}`
                            )}: ${capitalizeFirstLetter(allToLower(item.workSector.name))}`
                          : ''}
                      </StyledText>
                      <StyledText>
                        {item.workPosition
                          ? `${capitalizeFirstLetter(
                              `${i18n('CAPACITATION').CAPACITATION_TABLE_RANK}`
                            )}: ${capitalizeFirstLetter(allToLower(item.workPosition.name))}`
                          : ''}
                      </StyledText>

                      <StyledText>
                        {item.accountNumber
                          ? `${capitalizeFirstLetter(
                              `${i18n('CAPACITATION').CAPACITATION_TABLE_ACCOUNT_NUMBER}`
                            )}: ${item.accountNumber}`
                          : ''}
                      </StyledText>
                      <StyledText>
                        {item.distributionNumber
                          ? `${capitalizeFirstLetter(
                              `${i18n('CAPACITATION').CAPACITATION_TABLE_DISTRIBUTION_NUMBER}`
                            )}: ${item.distributionNumber}`
                          : ''}
                      </StyledText>
                    </DivWrapperEmployeeData>
                  </DivWrapper>
                  {this.props.capacitations.isFetching ? (
                    <DivWrapperLoader>
                      <Loader />
                    </DivWrapperLoader>
                  ) : this.props.capacitations.values &&
                    this.props.capacitations.values.items.length ? (
                    <CapacitationPreview
                      onClick={(e, capacitation) => this.handleCapacitationDetail(e, capacitation)}
                      data={this.props.capacitations.values ? this.props.capacitations.values : ''}
                      onDeleteClick={(e, capacitation) =>
                        this.handleDelete(e, capacitation, popupKeys.CAPACITATION)
                      }
                    />
                  ) : (
                    <DivWrapperError>
                      {capitalizeFirstLetter(`${i18n('HOME').HOME_PAGE_NO_CAPACITATIONS}`)}
                    </DivWrapperError>
                  )}
                </div>
              </MaterialExpansionPanel>
            ))}
            <Pagination
              total={this.wichValues().length}
              isLocal={true}
              onChangeLocal={this.onChangePagination}
              selectedPageLocal={this.state.current}
            />
          </DivWrapperItems>
        ) : (
          <DivWrapperError>
            {capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_NO_DATA}`)}
          </DivWrapperError>
        )
      ) : (
        <DivWrapperError>{capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_ERROR}`)}</DivWrapperError>
      )
    }
  }
}

const mapStateToProps = ({
  leaders,
  capacitations,
  excelDownload,
  capacitationDetailStatus,
  selectedSearchedItem,
  selectedDateFilter,
  selectedPage,
  selectedFilter
}) => ({
  leaders,
  capacitations,
  excelDownload,
  capacitationDetailStatus,
  selectedSearchedItem,
  selectedDateFilter,
  selectedPage,
  selectedFilter
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCapacitationsByFilter,
      fetchExcel,
      setSelectedCapacitation,
      setCapacitationDetailStatus,
      setSelectedSearchedItem,
      resetSelectedSearchedItem,
      resetCapacitationsByFilter,
      setEditPopupStatus,
      setDeletePopupStatus,
      setSelectedCapacitationPage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDisplayCourse)

import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  MENU_HEADER_LINKS,
  ADMINISTRATOR_MENU_HEADER_LINKS,
  INSTRUCTOR_MENU_HEADER_LINKS
} from 'config/headerMenuLinks'
import { HeaderMenuWrapper, MenuItem, MenuLabel } from './styled'

import { isGeneralAdmin, isAdmin, isInstructor } from 'utils/session'

const HeaderMenu = ({ active, history }) => {
  const onLinkClick = link => history.push(`/${link}`)

  const isActive = link => link === active

  const getHeaderLinks = () => {
    if (isInstructor()) {
      return INSTRUCTOR_MENU_HEADER_LINKS
    } else if (isAdmin()) {
      return ADMINISTRATOR_MENU_HEADER_LINKS
    } else if (isGeneralAdmin()) {
      return ADMINISTRATOR_MENU_HEADER_LINKS
    } else {
      return MENU_HEADER_LINKS
    }
  }

  return (
    <HeaderMenuWrapper>
      {getHeaderLinks().map((item, index) => (
        <MenuItem active={isActive(item.link)} key={index} onClick={() => onLinkClick(item.link)}>
          <MenuLabel active={isActive(item.link)}>{item.label}</MenuLabel>
        </MenuItem>
      ))}
    </HeaderMenuWrapper>
  )
}

export default withRouter(HeaderMenu)

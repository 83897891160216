import React from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import i18n from 'services/i18n'
import Tabs from 'components/Tabs'
import Legal from './Legal'
import Optional from './Optional'

export default function TrainingDetail(props) {
  const location = useLocation()
  let urlSearch = location.search
  if (!location.search) {
    urlSearch = `?PageNumber=1&Year=${new Date().getFullYear()}`
  }

  const urlLegal = props.match.path + `/` + i18n('PAGES_DASHBOARD').LEGAL
  const urlOptional = props.match.path + `/` + i18n('PAGES_DASHBOARD').OPTIONAL

  const tabItems = [
    { label: i18n('DASHBOARD').LEGAL, url: `${urlLegal}${urlSearch}` },
    { label: i18n('DASHBOARD').OPTIONAL, url: `${urlOptional}${urlSearch}` },
  ]

  return (
    <>
      <Tabs isSubTab="true" items={tabItems} />

      <Switch>
        <Route path={urlLegal} component={Legal} />
        <Route path={urlOptional} component={Optional} />
        <Redirect exact path={props.match.path + `/`} to={`${urlLegal}${urlSearch}`}></Redirect>
      </Switch>
    </>
  )
}

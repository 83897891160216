import { createActions } from 'redux-actions'

import { excelImportErrorsReset } from 'actions/excelImport'

const { importPopupCoursesStatus } = createActions({
  IMPORT_POPUP_COURSES_STATUS: data => ({ data })
})

const setImportPopupCoursesStatus = status => dispatch => 
            dispatch(importPopupCoursesStatus({ status }))

const resetImportPopupCoursesErrors = () => dispatch => {
  dispatch(excelImportErrorsReset())
}

export { setImportPopupCoursesStatus, resetImportPopupCoursesErrors, importPopupCoursesStatus }

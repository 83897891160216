import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  showing,

  fetchCentersByNameStart,
  fetchCentersByNameSuccess,
  fetchCentersByNameError,
  centersByNameReset,

  fetchCentersByDateStart,
  fetchCentersByDateSuccess,
  fetchCentersByDateError,
  centersByDateReset,

  fetchCentersStart,
  fetchCentersSuccess,
  fetchCentersError,
  centersReset,

  modifyCenterStart,
  modifyCenterSuccess,
  modifyCenterError,
  modifyCenterReset,

  addCenterStart,
  addCenterSuccess,
  addCenterError,
  addCenterReset,

  deleteCenterStart,
  deleteCenterSuccess,
  deleteCenterError,
  deleteCenterErrorReset
} = createActions({
  SHOWING: data => ({ data }),

  FETCH_CENTERS_BY_NAME_START: () => {},
  FETCH_CENTERS_BY_NAME_SUCCESS: data => ({ data }),
  FETCH_CENTERS_BY_NAME_ERROR: error => ({ error }),
  CENTERS_BY_NAME_RESET: () => {},

  FETCH_CENTERS_BY_DATE_START: () => {},
  FETCH_CENTERS_BY_DATE_SUCCESS: data => ({ data }),
  FETCH_CENTERS_BY_DATE_ERROR: error => ({ error }),
  CENTERS_BY_DATE_RESET: () => {},

  FETCH_CENTERS_START: () => {},
  FETCH_CENTERS_SUCCESS: data => ({ data }),
  FETCH_CENTERS_ERROR: error => ({ error }),
  CENTERS_RESET: () => {},

  MODIFY_CENTER_START: () => {},
  MODIFY_CENTER_SUCCESS: () => {},
  MODIFY_CENTER_ERROR: error => ({ error }),
  MODIFY_CENTER_RESET: () => {},

  ADD_CENTER_START: () => {},
  ADD_CENTER_SUCCESS: () => {},
  ADD_CENTER_ERROR: error => ({ error }),
  ADD_CENTER_RESET: () => {},

  DELETE_CENTER_START: () => {},
  DELETE_CENTER_SUCCESS: () => {},
  DELETE_CENTER_ERROR: error => ({ error }),
  DELETE_CENTER_ERROR_RESET: () => {}
})

const setShowingCenter = status => dispatch => dispatch(showing({ status }))

const fetchCentersByName = name => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCentersByNameStart())
    let urlFinal = `${API_ROUTES.GET_DISTRIBUTION_CENTER_BY_NAME}/${name}`
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)
      dispatch(fetchCentersByNameSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCentersByNameError(error))
    }
  }
}

const resetCentersByName = () => dispatch => dispatch(centersByNameReset())

const fetchCentersByDate = (from, to) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCentersByDateStart())
    let urlFinal = `${API_ROUTES.PUT_CENTER_NAME}/${API_ROUTES.BY_DATE}/${from}/${to}`
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)

      dispatch(fetchCentersByDateSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCentersByDateError(error))
    }
  }
}

const resetCentersByDate = () => dispatch => dispatch(centersByDateReset())

const fetchCenters = () => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCentersStart())
    let urlFinal = `${API_ROUTES.PUT_CENTER_NAME}`
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)
      dispatch(fetchCentersSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCentersError(error))
    }
  }
}

const resetCenters = () => dispatch => dispatch(centersReset())

const modifyCenter = (item, data) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyCenterStart())
    const body = {
      Name: data.name
    }
    try {
      await api.put(`${API_ROUTES.PUT_CENTER_NAME}/${item.id}`, body)
      dispatch(modifyCenterSuccess())
    } catch (error) {
      dispatch(modifyCenterError(error.data))
    }
  }
}

const resetModifyCenter = () => dispatch => dispatch(modifyCenterReset())

const addCenter = data => {
  return async dispatch => {
    const api = await getApi()
    dispatch(addCenterStart())
    const body = {
      Name: data.name
    }
    try {
      const response = await api.post(`${API_ROUTES.PUT_CENTER_NAME}`, body)
      dispatch(addCenterSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addCenterError(error.data))
    }
  }
}

const resetAddCenter = () => dispatch => dispatch(addCenterReset())

const deleteCenter = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteCenterStart())
    try {
      const response = await api.delete(`${API_ROUTES.PUT_CENTER_NAME}/${id}`)
      dispatch(deleteCenterSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteCenterError(error.data))
    }
  }
}

const resetDeleteCenterError = () => dispatch => dispatch(deleteCenterErrorReset())

export {
  setShowingCenter,
  showing,
  fetchCentersByName,
  fetchCentersByNameStart,
  fetchCentersByNameSuccess,
  fetchCentersByNameError,
  resetCentersByName,
  centersByNameReset,
  fetchCentersByDate,
  fetchCentersByDateStart,
  fetchCentersByDateSuccess,
  fetchCentersByDateError,
  centersByDateReset,
  resetCentersByDate,
  fetchCenters,
  fetchCentersStart,
  fetchCentersSuccess,
  fetchCentersError,
  centersReset,
  resetCenters,
  modifyCenter,
  modifyCenterStart,
  modifyCenterSuccess,
  modifyCenterError,
  resetModifyCenter,
  modifyCenterReset,
  addCenter,
  addCenterStart,
  addCenterSuccess,
  addCenterError,
  resetAddCenter,
  addCenterReset,
  deleteCenter,
  deleteCenterStart,
  deleteCenterSuccess,
  deleteCenterError,
  resetDeleteCenterError,
  deleteCenterErrorReset
}

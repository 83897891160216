import styled from 'styled-components'
import Selector from 'components/Selector'

export const StyledWrapper = styled.div`
  flex-direction: column;
  width: 50%;
  margin: 0px 10px 0px 10px;
`

export const StyledWrapperTwo = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    width: calc(50% - 20px);
    margin: 5px 10px !important;
  }
`

export const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  ${({ error }) =>
    error
      ? `> :first-child:not(.error) > * {
    color: ${({ theme }) => theme.colors.error} !important;
    border-color: ${({ theme }) => theme.colors.error} !important;
    border-width: 2px;
  }`
      : ''}
  > .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 0.75rem;
    margin-top: 6px;
  }
`

export const StyledSelector = styled(Selector)`
  > * {
    margin-top: 10px;
  }
`

export const StyledSelectorWrapper = styled.div`
  margin-top: 10px;
`

import { handleActions } from 'redux-actions'
import {
  fetchWorkSectorStart,
  fetchWorkSectorSuccess,
  fetchWorkSectorError,
  workSectorReset
} from 'actions/workSector'

const defaultState = {
  valuesAll: null,
  isFetchingAll: false,
  errorAll: null
}

const reducer = handleActions(
  {
    [fetchWorkSectorStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchWorkSectorSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchWorkSectorError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [workSectorReset]: () => defaultState
  },
  defaultState
)

export default reducer

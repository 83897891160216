import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ToastContainer, toast } from 'react-toastify'

import Page from 'components/Page'
import MaterialExpansionPanel from 'components/ExpansionPanel'
import Loader from 'components/Loaders/propagate'
import Pagination from 'components/Pagination'
import FloatingButton from 'components/FloatingButton'
import AddPopup from 'components/AddPopup'
import DeletePopup from 'components/DeletePopup'
import EditPopup from 'components/EditPopup'
import ImportPopup from 'components/ImportPopup'
import ImportPopUpCourses from 'components/ImportPopUpCourses'
import { MessageToast } from 'components/Toast'
import { AdminUserData, InstructorData, LeaderUserData } from 'components/UsersExpansionPanelInfo'
import UserFilterModule from 'components/UserFilterModule'

import usersTypes from 'constants/usersTypes'
import userKeys from 'constants/userKeys'
import toastTypes from 'constants/toastKeys'

import { setExpandedUserType, resetExpandedUserType } from 'actions/expandedUserType'
import { fetchAdministrators, resetAdministrators } from 'actions/administrators'
import { fetchInstructors, resetInstructors } from 'actions/instructors'
import { fetchLeaderUsers, resetLeaderUsers } from 'actions/leaderUsers'
import { resetDisplayToast } from 'actions/toast'
import { setDeletePopupStatus } from 'actions/deletePopup'
import { setEditPopupStatus } from 'actions/editPopup'
import { fetchGeneralAdministrators, resetGeneralAdministrators } from 'actions/generalAdmin'

import { capitalizeFirstLetter } from 'utils/strings'
import { paginate } from 'utils/paginator'
import { isGeneralAdmin } from 'utils/session'

import i18n from 'services/i18n'

import { WrapperLoader, WrapperError, WrapperItems, WrapperUsers } from './styled'
import theme from 'config/theme'
class Users extends Component {
  state = {
    expandedUser: 0,
    current: 1,
  }

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.toastMsj.isShowing && this.props.toastMsj.isShowing) {
      toast(
        <MessageToast
          boldContent={
            this.props.toastMsj.type === toastTypes.TOAST_TYPE_DELETE
              ? capitalizeFirstLetter(`${i18n('TOAST').TOAST_DELETED}!`)
              : capitalizeFirstLetter(`${i18n('TOAST').TOAST_SAVED}!`)
          }
          content={
            this.props.toastMsj.type === toastTypes.TOAST_TYPE_DELETE
              ? capitalizeFirstLetter(`${i18n('TOAST').TOAST_DELETED_LORE}`)
              : capitalizeFirstLetter(`${i18n('TOAST').TOAST_SAVED_LORE}`)
          }
        />
      )
      this.props.resetDisplayToast()
    }

    if (
      (prevProps.addPopup.open && !this.props.addPopup.open && this.successAdd()) ||
      (prevProps.deletePopup.open && !this.props.deletePopup.open && this.successDelete()) ||
      (prevProps.editPopup.open && !this.props.editPopup.open && this.successEdit())
    ) {
      this.props.resetExpandedUserType()
    }

    if (prevProps.selectedUserStatus.selected !== this.props.selectedUserStatus.selected) {
      this.props.resetExpandedUserType()
    }
  }

  successEdit = () =>
    this.props.generalAdministrators.successEdit ||
    this.props.administrators.successEdit ||
    this.props.instructors.successEdit ||
    this.props.leaderUsers.successEdit

  successDelete = () =>
    this.props.generalAdministrators.successDelete ||
    this.props.administrators.successDelete ||
    this.props.instructors.successDelete ||
    this.props.leaderUsers.successDelete

  successAdd = () =>
    this.props.generalAdministrators.successAdd ||
    this.props.administrators.successAdd ||
    this.props.instructors.successAdd ||
    this.props.leaderUsers.successAdd

  handleTypeClick = async (id) => {
    this.setState({ current: 1, expandedUser: 0 })
    this.props.resetGeneralAdministrators()
    this.props.resetAdministrators()
    this.props.resetInstructors()
    this.props.resetLeaderUsers()
    switch (id) {
      case userKeys.GENERAL_ADMIN:
        await this.handleExpansionUserType(id)
        if (this.isTypeExpanded(id)) {
          setTimeout(() => {
            this.props.fetchGeneralAdministrators()
          }, 20)
        }
        break
      case userKeys.ADMINISTRATOR:
        await this.handleExpansionUserType(id)
        if (this.isTypeExpanded(id)) {
          setTimeout(() => {
            this.props.fetchAdministrators()
          }, 20)
        }
        break
      case userKeys.INSTRUCTOR:
        await this.handleExpansionUserType(id)
        if (this.isTypeExpanded(id)) {
          setTimeout(() => {
            this.props.fetchInstructors()
          }, 20)
        }
        break
      case userKeys.LEADER_USER:
        await this.handleExpansionUserType(id)
        if (this.isTypeExpanded(id)) {
          setTimeout(() => {
            this.props.fetchLeaderUsers()
          }, 20)
        }
        break
    }
  }
  handleExpansionUserType = (id) => this.props.setExpandedUserType(this.isTypeExpanded(id) ? 0 : id)

  isTypeExpanded = (id) => {
    return this.props.expandedUserType === id
  }

  handleUserClick = (id, event) => {
    this.handleExpansionUser(id)
    event.stopPropagation()
  }

  handleExpansionUser = (id) => this.setState({ expandedUser: this.isUserExpanded(id) ? 0 : id })

  isUserExpanded = (id) => this.state.expandedUser === id

  wichUserType = (id) => {
    switch (id) {
      case userKeys.GENERAL_ADMIN:
        return this.props.generalAdministrators
      case userKeys.ADMINISTRATOR:
        return this.props.administrators
      case userKeys.INSTRUCTOR:
        return this.props.instructors
      case userKeys.LEADER_USER:
        return this.props.leaderUsers
    }
  }

  onChangePagination = (page) => {
    this.setState({
      current: page,
    })
  }

  renderUserInfo = (type, user) => {
    switch (type) {
      case userKeys.GENERAL_ADMIN:
        return AdminUserData(user)
      case userKeys.ADMINISTRATOR:
        return AdminUserData(user)
      case userKeys.INSTRUCTOR:
        return InstructorData(user)
      case userKeys.LEADER_USER:
        return LeaderUserData(user)
    }
  }

  handleDelete = (e, item, type) => {
    this.props.setDeletePopupStatus({ open: true, itemToDelete: item, type: type })
    e.stopPropagation()
  }

  handleEditPopup = (e, item, type) => {
    this.props.setEditPopupStatus({ open: true, itemToEdit: item, type: type })
    e.stopPropagation()
  }

  isUserActive = (user) =>
    user.generalAdministratorActive ||
    user.administratorActive ||
    user.instructorActive ||
    user.leaderUserActive

  isShowingSearch = () => {
    const { generalAdministrators, administrators, instructors, leaderUsers } = this.props

    return (
      generalAdministrators.isShowing ||
      administrators.isShowing ||
      instructors.isShowing ||
      leaderUsers.isShowing
    )
  }

  renderUsers = () =>
    usersTypes.map(({ id, name }) => (
      <MaterialExpansionPanel
        key={id}
        name={name}
        id={id}
        expanded={this.isTypeExpanded(id)}
        onClick={() => this.handleTypeClick(id)}
        withOutline
        doNotDisplay={id === userKeys.GENERAL_ADMIN && !isGeneralAdmin()}
      >
        {this.wichUserType(this.props.expandedUserType) &&
        this.wichUserType(this.props.expandedUserType).valuesAll &&
        this.wichUserType(this.props.expandedUserType).valuesAll.length &&
        this.props.expandedUserType ? (
          <WrapperItems>
            {paginate(
              this.state.current,
              this.wichUserType(this.props.expandedUserType).valuesAll
            ).map((user) => (
              <MaterialExpansionPanel
                key={`${user.id}-users`}
                name={user.firstName ? user.firstName : user.userName}
                surname={user.lastName}
                username={user.userName}
                id={user.id}
                generalAdminShouldInteract={
                  id === userKeys.GENERAL_ADMIN || id === userKeys.ADMINISTRATOR
                }
                businessAdminShouldInteract={
                  id === userKeys.INSTRUCTOR || id === userKeys.LEADER_USER
                }
                expanded={this.isUserExpanded(user.id)}
                onClick={(e) => this.handleUserClick(user.id, e)}
                withDelete
                onButtonDeleteClick={(e) => this.handleDelete(e, user, this.props.expandedUserType)}
                withEdit
                onButtonEditClick={(e) =>
                  this.handleEditPopup(e, user, this.props.expandedUserType)
                }
                backgroundColor={!this.isUserActive(user) && theme.colors.disabledUserGrey}
                backgroundColorExpanded={
                  !this.isUserActive(user) && theme.colors.disabledUserExpandedGrey
                }
              >
                {this.renderUserInfo(this.props.expandedUserType, user)}
              </MaterialExpansionPanel>
            ))}
            <Pagination
              total={this.wichUserType(this.props.expandedUserType).valuesAll.length}
              isLocal={true}
              onChangeLocal={this.onChangePagination}
              selectedPageLocal={this.state.current}
            />
          </WrapperItems>
        ) : this.wichUserType(this.props.expandedUserType) &&
          this.wichUserType(this.props.expandedUserType).isFetchingAll ? (
          <WrapperLoader>
            <Loader />
          </WrapperLoader>
        ) : (
          <WrapperError>{capitalizeFirstLetter(`${i18n('HOME').NO_USERS}`)}</WrapperError>
        )}
      </MaterialExpansionPanel>
    ))

  renderSearchedUsers = () => {
    const { userType } = this.props.selectedUsersFilter
    let user = this.wichUserType(userType)
    return user.isFetchingAll ? (
      <Loader />
    ) : user.valuesAll && user.valuesAll.length ? (
      <WrapperItems>
        {paginate(this.state.current, user.valuesAll).map((user) => (
          <MaterialExpansionPanel
            key={`${user.id}-searchedUsers`}
            name={user.firstName ? user.firstName : user.userName}
            surname={user.lastName}
            username={user.userName}
            id={user.id}
            generalAdminShouldInteract={
              userType === userKeys.GENERAL_ADMIN || userType === userKeys.ADMINISTRATOR
            }
            businessAdminShouldInteract={
              userType === userKeys.INSTRUCTOR || userType === userKeys.LEADER_USER
            }
            expanded={this.isUserExpanded(user.id)}
            onClick={(e) => this.handleUserClick(user.id, e)}
            withDelete
            onButtonDeleteClick={(e) => this.handleDelete(e, user, userType)}
            withEdit
            onButtonEditClick={(e) => this.handleEditPopup(e, user, userType)}
            backgroundColor={!this.isUserActive(user) && theme.colors.disabledUserGrey}
            backgroundColorExpanded={
              !this.isUserActive(user) && theme.colors.disabledUserExpandedGrey
            }
          >
            {this.renderUserInfo(userType, user)}
          </MaterialExpansionPanel>
        ))}
        <Pagination
          total={user.valuesAll.length}
          isLocal={true}
          onChangeLocal={this.onChangePagination}
          selectedPageLocal={this.state.current}
        />
      </WrapperItems>
    ) : (
      <WrapperError>{capitalizeFirstLetter(`${i18n('HOME').NO_USERS}`)}</WrapperError>
    )
  }

  renderContent = () => {
    if (this.isShowingSearch()) {
      return <WrapperUsers>{this.renderSearchedUsers()}</WrapperUsers>
    } else {
      return (
        <WrapperUsers>
          {this.renderUsers()}
          {isGeneralAdmin() ? (
            <FloatingButton type={userKeys.GENERAL_ADMIN} />
          ) : (
            <FloatingButton type={i18n('PAGE_USERS')} />
          )}
        </WrapperUsers>
      )
    }
  }

  render() {
    return (
      <Page withHeader>
        <UserFilterModule />
        {this.renderContent()}
        {this.props.addPopup.open && <AddPopup />}
        {this.props.deletePopup.open && <DeletePopup />}
        {this.props.editPopup.open && <EditPopup />}
        {this.props.importPopup.open && <ImportPopup />}
        {this.props.importPopupCourses.open && <ImportPopUpCourses />}
        <ToastContainer
          style={{
            minWidth: 600,
            marginLeft: '-280px',
          }}
          closeButton={false}
          position={toast.POSITION.BOTTOM_CENTER}
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable={false}
          pauseOnHover
          className={'toast'}
          bodyClassName={'toastBody'}
        />
      </Page>
    )
  }
}

const mapStateToProps = ({
  expandedUserType,
  administrators,
  instructors,
  leaderUsers,
  addPopup,
  deletePopup,
  editPopup,
  importPopup,
  toastMsj,
  selectedUserStatus,
  generalAdministrators,
  selectedUsersFilter,
  importPopupCourses,
}) => ({
  expandedUserType,
  administrators,
  instructors,
  leaderUsers,
  addPopup,
  deletePopup,
  editPopup,
  importPopup,
  toastMsj,
  selectedUserStatus,
  generalAdministrators,
  selectedUsersFilter,
  importPopupCourses,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setExpandedUserType,
      resetExpandedUserType,
      fetchAdministrators,
      resetAdministrators,
      fetchInstructors,
      resetInstructors,
      fetchLeaderUsers,
      resetLeaderUsers,
      resetDisplayToast,
      setDeletePopupStatus,
      setEditPopupStatus,
      fetchGeneralAdministrators,
      resetGeneralAdministrators,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Users)

import { handleActions } from 'redux-actions'
import {
  showing,
  fetchCoursesStart,
  fetchCoursesSuccess,
  fetchCoursesError,
  coursesReset,
  fetchCoursesByDateStart,
  fetchCoursesByDateSuccess,
  fetchCoursesByDateError,
  coursesByDateReset,
  fetchCoursesByCategoryIdStart,
  fetchCoursesByCategoryIdSuccess,
  fetchCoursesByCategoryIdError,
  coursesByCategoryIdReset,
  fetchCoursesByEmployeeIdStart,
  fetchCoursesByEmployeeIdSuccess,
  fetchCoursesByEmployeeIdError,
  coursesByEmployeeIdReset,
  fetchCoursesByNameStart,
  fetchCoursesByNameSuccess,
  fetchCoursesByNameError,
  coursesByNameReset,
  modifyCourseStart,
  modifyCourseSuccess,
  modifyCourseError,
  modifyCourseReset,
  addCourseStart,
  addCourseSuccess,
  addCourseError,
  addCourseReset,
  deleteCourseStart,
  deleteCourseSuccess,
  deleteCourseError,
  deleteCourseErrorReset,
  enableOrDisableCourseStart,
  enableOrDisableCourseSuccess,
  enableOrDisableCourseError,
  enableOrDisableCourseReset
} from 'actions/courses'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,

  valuesByDate: null,
  errorByDate: null,
  isFetchingByDate: false,

  isShowing: false,

  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false,

  successEnablingOrDisabling: false,
  isEnablingOrDisabling: false,
  errorEnablingOrDisabling: null
}

const reducer = handleActions(
  {
    [showing]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [fetchCoursesStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchCoursesSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchCoursesError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [coursesReset]: () => defaultState,

    [fetchCoursesByDateStart]: state => ({
      ...state,
      isFetchingByDate: true,
      errorByDate: null
    }),
    [fetchCoursesByDateSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesByDate: values,
      isFetchingByDate: false,
      errorByDate: null
    }),
    [fetchCoursesByDateError]: (state, { error }) => ({
      ...state,
      isFetchingByDate: false,
      errorByDate: error
    }),
    [coursesByDateReset]: () => defaultState,

    [fetchCoursesByCategoryIdStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchCoursesByCategoryIdSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchCoursesByCategoryIdError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [coursesByCategoryIdReset]: () => defaultState,

    [fetchCoursesByEmployeeIdStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchCoursesByEmployeeIdSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchCoursesByEmployeeIdError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [coursesByEmployeeIdReset]: () => defaultState,

    [fetchCoursesByNameStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchCoursesByNameSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchCoursesByNameError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [coursesByNameReset]: () => defaultState,

    [modifyCourseStart]: state => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false
    }),
    [modifyCourseSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyCourseError]: (state, error) => ({
      ...state,
      isSubmiting: false,
      successEdit: false,
      errorEdit: error
    }),
    [modifyCourseReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false
    }),

    [addCourseStart]: state => ({
      ...state,
      isAdding: true,
      successAdd: false,
      errorAdd: false
    }),
    [addCourseSuccess]: state => ({
      ...state,
      isAdding: false,
      successAdd: true,
      errorAdd: false
    }),
    [addCourseError]: (state, error) => ({
      ...state,
      isAdding: false,
      successAdd: false,
      errorAdd: error
    }),
    [addCourseReset]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false
    }),

    [deleteCourseStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteCourseSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteCourseError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),
    [deleteCourseErrorReset]: state => ({
      ...state,
      isDeleting: false,
      errorDelete: false
    }),

    [enableOrDisableCourseStart]: state => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: true,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableCourseSuccess]: state => ({
      ...state,
      successEnablingOrDisabling: true,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableCourseError]: (state, error) => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: error
    }),
    [enableOrDisableCourseReset]: () => defaultState
  },
  defaultState
)

export default reducer

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.lightBlue};
`

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  overflow: auto;
`
export const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 25px 0px 0px 0px;
  max-width: 1000px;
`

export const StyledImgDanone = styled.img`
  width: 28%;
  max-width: 450px;
  min-width: 300px;
`

export const StyledImgLaserenisima = styled.img`
  width: 22%;
  max-width: 350px;
  min-width: 240px;
`

export const PolicyWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 70%;
  margin: 25px 0px;
  box-shadow: 0px 0px 17px 3px rgba(0, 0, 0, 0.19);
  border-radius: 12px;
  max-width: 700px;
  padding: 25px;
  display: flex;
  flex-direction: column;
`

export const PolicyTitle = styled.label`
  color: ${({ theme }) => theme.colors.purple};
  font-size: 23px;
  margin: 0px 0px 20px 0px;
`

export const PolicyHint = styled.label`
  color: ${({ theme }) => theme.colors.purple};
  font-size: 14px;
  margin: 20px 0px 0px 0px;
  font-weight: bold;
`

export const PolicyText = styled.label`
  font-size: 14px;
`

export default [
  {
    label: 'Curso',
    name: 'course',
    subProp: 'name',
  },
  {
    label: 'Personas capacitadas',
    name: 'trainedEmployees',
  },
  {
    label: 'Horas reales',
    name: 'trainedHours',
  },
]

import { getUserInfo } from 'utils/session'

export const getCompanyId = () => {
  const { companies } = getUserInfo()
  return companies && { Id: companies[0].id }
}

export const getCompanyObject = () => {
  const { companies } = getUserInfo()
  return companies && { Id: companies[0].id, name: companies[0].name }
}

export const getCompanyById = id => {
  const { companies } = getUserInfo()
  return companies && companies.find(x => x.id === id)
}

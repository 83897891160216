import { handleActions } from 'redux-actions'
import {
  showing,
  fetchLeadersByNameStart,
  fetchLeadersByNameSuccess,
  fetchLeadersByNameError,
  leadersByNameReset,
  fetchLeadersByDateStart,
  fetchLeadersByDateSuccess,
  fetchLeadersByDateError,
  leadersByDateReset,
  fetchLeadersStart,
  fetchLeadersSuccess,
  fetchLeadersError,
  leadersReset,
  modifyLeaderStart,
  modifyLeaderSuccess,
  modifyLeaderError,
  modifyLeaderReset,
  addLeaderStart,
  addLeaderSuccess,
  addLeaderError,
  addLeaderReset,
  deleteLeaderStart,
  deleteLeaderSuccess,
  deleteLeaderError,
  deleteLeaderErrorReset,
} from 'actions/leaders'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,

  valuesByDate: null,
  errorByDate: null,
  isFetchingByDate: false,

  isShowing: false,

  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false,
}

const reducer = handleActions(
  {
    [showing]: (
      state,
      {
        payload: {
          data: { status },
        },
      }
    ) => status,

    [fetchLeadersByNameStart]: (state) => ({
      ...state,
      isFetching: true,
      error: null,
    }),
    [fetchLeadersByNameSuccess]: (
      state,
      {
        payload: {
          data: { values },
        },
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null,
    }),
    [fetchLeadersByNameError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error,
    }),
    [leadersByNameReset]: () => defaultState,

    [fetchLeadersByDateStart]: (state) => ({
      ...state,
      isFetchingByDate: true,
      errorByDate: null,
    }),
    [fetchLeadersByDateSuccess]: (
      state,
      {
        payload: {
          data: { values },
        },
      }
    ) => ({
      ...state,
      valuesByDate: values,
      isFetchingByDate: false,
      errorByDate: null,
    }),
    [fetchLeadersByDateError]: (state, { error }) => ({
      ...state,
      isFetchingByDate: false,
      errorByDate: error,
    }),
    [leadersByDateReset]: () => defaultState,

    [fetchLeadersStart]: (state) => ({
      ...state,
      isFetchingAll: true,
      errorAll: null,
    }),
    [fetchLeadersSuccess]: (
      state,
      {
        payload: {
          data: { values },
        },
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null,
    }),
    [fetchLeadersError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error,
    }),
    [leadersReset]: () => defaultState,

    [modifyLeaderStart]: (state) => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false,
    }),
    [modifyLeaderSuccess]: (state) => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true,
    }),
    [modifyLeaderError]: (state, { error }) => ({
      ...state,
      isSubmiting: false,
      errorEdit: error,
      successEdit: false,
    }),
    [modifyLeaderReset]: (state) => ({
      ...state,
      errorEdit: false,
      isSubmiting: false,
    }),

    [addLeaderStart]: (state) => ({
      ...state,
      isAdding: true,
      successAdd: false,
      errorAdd: false,
    }),
    [addLeaderSuccess]: (state) => ({
      ...state,
      isAdding: false,
      successAdd: true,
      errorAdd: false,
    }),
    [addLeaderError]: (state, error) => ({
      ...state,
      isAdding: false,
      successAdd: false,
      errorAdd: error,
    }),
    [addLeaderReset]: (state) => ({
      ...state,
      isAdding: false,
      errorAdd: false,
    }),
    [deleteLeaderStart]: (state) => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false,
    }),
    [deleteLeaderSuccess]: (state) => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false,
    }),
    [deleteLeaderError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error,
    }),
    [deleteLeaderErrorReset]: (state) => ({
      ...state,
      isDeleting: false,
      errorDelete: false,
    }),
  },
  defaultState
)

export default reducer

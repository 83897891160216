import styled from 'styled-components'

export const HeaderMenuWrapper = styled.div`
  height: 100%;
  width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  margin-right: auto;
`

export const MenuItem = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 30px;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  background-color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.purple)};
  transition: all 0.15s ease;
  margin-right: 25px;
  &:hover > label {
    color: ${({ active, theme }) => !active && theme.colors.blueRibbon};
  }
`

export const MenuLabel = styled.label`
  font-size: 15px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ active, theme }) => (active ? theme.colors.black : theme.colors.white)};

  &:hover {
    color: ${({ active, theme }) => !active && theme.colors.blueRibbon};
  }
`

import { createActions } from 'redux-actions'

import getApi from 'services/api'

import API_ROUTES from 'config/apiRoutes'
import pagerConfig from 'config/pagerConfig'

const {
  showingDirect,

  fetchDirectEmployeeByFilterStart,
  fetchDirectEmployeeByFilterSuccess,
  fetchDirectEmployeeByFilterError,
  directEmployeeByFilterReset,

  fetchDirectEmployeeByDateStart,
  fetchDirectEmployeeByDateSuccess,
  fetchDirectEmployeeByDateError,
  directEmployeeByDateReset,

  fetchDirectEmployeeStart,
  fetchDirectEmployeeSuccess,
  fetchDirectEmployeeError,
  directEmployeeReset,

  addDirectEmployeeStart,
  addDirectEmployeeSuccess,
  addDirectEmployeeError,
  addDirectEmployeeReset,

  modifyDirectEmployeeStart,
  modifyDirectEmployeeSuccess,
  modifyDirectEmployeeError,
  modifyDirectEmployeeReset,

  deleteDirectEmployeeStart,
  deleteDirectEmployeeSuccess,
  deleteDirectEmployeeError,
  deleteDirectEmployeeErrorReset
} = createActions({
  SHOWING_DIRECT: data => ({ data }),

  FETCH_DIRECT_EMPLOYEE_BY_FILTER_START: () => {},
  FETCH_DIRECT_EMPLOYEE_BY_FILTER_SUCCESS: data => ({ data }),
  FETCH_DIRECT_EMPLOYEE_BY_FILTER_ERROR: error => ({ error }),
  DIRECT_EMPLOYEE_BY_FILTER_RESET: () => {},

  FETCH_DIRECT_EMPLOYEE_BY_DATE_START: () => {},
  FETCH_DIRECT_EMPLOYEE_BY_DATE_SUCCESS: data => ({ data }),
  FETCH_DIRECT_EMPLOYEE_BY_DATE_ERROR: error => ({ error }),
  DIRECT_EMPLOYEE_BY_DATE_RESET: () => {},

  FETCH_DIRECT_EMPLOYEE_START: () => {},
  FETCH_DIRECT_EMPLOYEE_SUCCESS: data => ({ data }),
  FETCH_DIRECT_EMPLOYEE_ERROR: error => ({ error }),
  DIRECT_EMPLOYEE_RESET: () => {},

  ADD_DIRECT_EMPLOYEE_START: () => {},
  ADD_DIRECT_EMPLOYEE_SUCCESS: () => {},
  ADD_DIRECT_EMPLOYEE_ERROR: error => ({ error }),
  ADD_DIRECT_EMPLOYEE_RESET: () => {},

  MODIFY_DIRECT_EMPLOYEE_START: () => {},
  MODIFY_DIRECT_EMPLOYEE_SUCCESS: () => {},
  MODIFY_DIRECT_EMPLOYEE_ERROR: error => ({ error }),
  MODIFY_DIRECT_EMPLOYEE_RESET: () => {},

  DELETE_DIRECT_EMPLOYEE_START: () => {},
  DELETE_DIRECT_EMPLOYEE_SUCCESS: () => {},
  DELETE_DIRECT_EMPLOYEE_ERROR: error => ({ error }),
  DELETE_DIRECT_EMPLOYEE_ERROR_RESET: () => {}
})
const setShowingDirect = status => dispatch => dispatch(showingDirect({ status }))

const fetchDirectEmployee = filter => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchDirectEmployeeStart())
    const {
      selectedPage: { page },
      selectedFilter: { selectedBusiness }
    } = getState()
    filter.CompanyId = selectedBusiness.value ? selectedBusiness.value : 0
    filter.Pager.PageSize = pagerConfig.PAGE_SIZE
    filter.Pager.PageNumber = page
    try {
      const response = await api.post(API_ROUTES.GET_DIRECT_EMPLOYEE_BY_FILTER, filter)
      dispatch(fetchDirectEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchDirectEmployeeError(error))
    }
  }
}

const resetDirectEmployee = () => dispatch => dispatch(directEmployeeReset())

const fetchDirectEmployeesByDate = (from, to) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchDirectEmployeeByDateStart())
    let urlFinal = `${API_ROUTES.PUT_DIRECT_EMPLOYEE}/${API_ROUTES.BY_DATE}/${from}/${to}`
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)

      dispatch(fetchDirectEmployeeByDateSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchDirectEmployeeByDateError(error))
    }
  }
}

const resetDirectEmployeesByDate = () => dispatch => dispatch(directEmployeeByDateReset())

const fetchDirectEmployeeByFilter = filter => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchDirectEmployeeByFilterStart())
    const {
      selectedPage: { page },
      selectedFilter: { selectedBusiness }
    } = getState()
    filter.CompanyId = selectedBusiness.value ? selectedBusiness.value : 0
    filter.Pager.PageSize = pagerConfig.PAGE_SIZE
    filter.Pager.PageNumber = page
    try {
      const response = await api.post(API_ROUTES.GET_DIRECT_EMPLOYEE_BY_FILTER, filter)
      dispatch(fetchDirectEmployeeByFilterSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchDirectEmployeeByFilterError(error))
    }
  }
}

const resetDirectEmployeeByFilter = () => dispatch => dispatch(directEmployeeByFilterReset())

const addDirectEmployee = data => {
  return async dispatch => {
    const api = await getApi()
    dispatch(addDirectEmployeeStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      FileNumber: data.fileNumber,
      CostCenter: data.costCenter,
      Category: data.category,
      DistributionCenter: {
        Id: data.distributionCenterId.value
      },
      WorkSector: {
        Id: data.workSector.value
      },
      WorkPosition: {
        Id: data.workPosition.value
      },
      Leader: {
        Id: data.leader.value
      }
    }
    try {
      const response = await api.post(`${API_ROUTES.PUT_DIRECT_EMPLOYEE}`, body)
      dispatch(addDirectEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addDirectEmployeeError(error.data))
    }
  }
}

const resetAddDirectEmployee = () => dispatch => dispatch(addDirectEmployeeReset())

const modifyDirectEmployee = (item, data) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyDirectEmployeeStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      FileNumber: data.fileNumber,
      CostCenter: data.costCenter,
      Category: data.category,
      DistributionCenter: {
        Id: data.distributionCenterId.value
      },
      WorkSector: {
        Id: data.workSector.value
      },
      WorkPosition: {
        Id: data.workPosition.value
      },
      Leader: {
        Id: data.leader.value
      }
    }

    try {
      const response = await api.put(`${API_ROUTES.PUT_DIRECT_EMPLOYEE}/${item.id}`, body)
      dispatch(modifyDirectEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyDirectEmployeeError(error.data))
    }
  }
}

const resetModifyDirectEmployee = () => dispatch => dispatch(modifyDirectEmployeeReset())

const deleteDirectEmployee = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteDirectEmployeeStart())
    try {
      const response = await api.delete(`${API_ROUTES.PUT_DIRECT_EMPLOYEE}/${id}`)
      dispatch(deleteDirectEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteDirectEmployeeError(error.data))
    }
  }
}

const resetDeleteDirectEmployeeError = () => dispatch => dispatch(deleteDirectEmployeeErrorReset())

export {
  showingDirect,
  setShowingDirect,
  fetchDirectEmployeeByFilter,
  fetchDirectEmployeeByFilterStart,
  fetchDirectEmployeeByFilterSuccess,
  fetchDirectEmployeeByFilterError,
  resetDirectEmployeeByFilter,
  directEmployeeByFilterReset,
  fetchDirectEmployeesByDate,
  fetchDirectEmployeeByDateStart,
  fetchDirectEmployeeByDateSuccess,
  fetchDirectEmployeeByDateError,
  directEmployeeByDateReset,
  resetDirectEmployeesByDate,
  fetchDirectEmployee,
  fetchDirectEmployeeStart,
  fetchDirectEmployeeSuccess,
  fetchDirectEmployeeError,
  directEmployeeReset,
  resetDirectEmployee,
  addDirectEmployee,
  addDirectEmployeeStart,
  addDirectEmployeeSuccess,
  addDirectEmployeeError,
  resetAddDirectEmployee,
  addDirectEmployeeReset,
  modifyDirectEmployee,
  modifyDirectEmployeeStart,
  modifyDirectEmployeeSuccess,
  modifyDirectEmployeeError,
  resetModifyDirectEmployee,
  modifyDirectEmployeeReset,
  deleteDirectEmployee,
  deleteDirectEmployeeStart,
  deleteDirectEmployeeSuccess,
  deleteDirectEmployeeError,
  resetDeleteDirectEmployeeError,
  deleteDirectEmployeeErrorReset
}

import React from 'react'

import ButtonMaterial from 'components/ButtonMaterial'

import popupKeys from 'constants/popupKeys'

import theme from 'config/theme'

import { capitalizeFirstLetter } from 'utils/strings'
import { isGeneralAdmin } from 'utils/session'

import i18n from 'services/i18n'

import { Wrapper } from './styles'

import { getCompanyById } from 'utils/getCompany'

export const HeaderButtons = ({
  data,
  employeesData,
  loadingEmployeesData,
  hasImages,
  setEditPopupStatus,
  handleImgDownload,
  capacitationImagesDownload,
  handlePdfDownload,
  capInDetailPdf
}) => (
  <Wrapper>
    {!isGeneralAdmin() && getCompanyById(data.companyId) && (
      <ButtonMaterial
        style={{
          maxWidth: '85px',
          margin: '0px 20px 0px 0px',
          height: '30px'
        }}
        background={loadingEmployeesData ? theme.colors.dustyGray : theme.colors.purple}
        color={theme.colors.white}
        fontSize={'14'}
        display={true}
        small
        onClick={() =>
          setEditPopupStatus({
            open: true,
            itemToEdit: employeesData,
            type: popupKeys.CAPACITATION
          })
        }
        disabled={loadingEmployeesData}
      >
        {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_EDIT}`)}
      </ButtonMaterial>
    )}

    <ButtonMaterial
      style={{
        maxWidth: '85px',
        margin: '0px 20px 0px 0px',
        height: '30px'
      }}
      background={
        !hasImages(employeesData) || loadingEmployeesData
          ? theme.colors.dustyGray
          : theme.colors.purple
      }
      color={theme.colors.white}
      fontSize={'14'}
      display={true}
      small
      onClick={() => handleImgDownload(data.id)}
      loading={capacitationImagesDownload.isFetching || loadingEmployeesData}
      disabled={!hasImages(employeesData) || loadingEmployeesData}
    >
      {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_PICTURES}`)}
    </ButtonMaterial>
    <ButtonMaterial
      style={{
        maxWidth: '85px',
        height: '30px'
      }}
      background={
        capInDetailPdf.isFetching || loadingEmployeesData
          ? theme.colors.dustyGray
          : theme.colors.purple
      }
      color={theme.colors.white}
      fontSize={'14'}
      display={true}
      small
      onClick={() => handlePdfDownload(data.id, data.course.name, data.realizationDate)}
      loading={capInDetailPdf.isFetching || loadingEmployeesData}
      disabled={capInDetailPdf.isFetching || loadingEmployeesData}
    >
      PDF
    </ButtonMaterial>
  </Wrapper>
)

export default HeaderButtons

import styled from 'styled-components'

export const ExpansionPanelStyles = (theme, withOutline, expanded, doNotDisplay) => ({
  borderBottom: 'none',
  width: '100%',
  transition: 'all 0.5s ease',
  boxShadow: 'none',
  border: !withOutline ? (expanded ? '1px solid' : '') : '',
  borderColor: expanded ? theme.colors.blue : 'none',
  margin: '1px 0px',
  display: doNotDisplay && 'none'
})

export const ExpansionPanelSummaryStyles = (
  theme,
  expanded,
  backgroundColor,
  backgroundColorExpanded
) => ({
  boxShadow: 'none',
  background: expanded
    ? backgroundColorExpanded
      ? backgroundColorExpanded
      : theme.colors.blue
    : backgroundColor
    ? backgroundColor
    : theme.colors.darkBlue,
  transition: 'all 0.5s ease',
  height: '40px',
  minHeight: 'unset'
})

export const ExpansionPanelDetailsStyles = theme => ({
  flexDirection: 'column',
  background: theme.colors.white,
  padding: '1px 24px 24px'
})

export const ExpansionPanelSummaryDivWrapperStyles = (withDownload, withEdit, withDelete) => ({
  width: '100%',
  display: 'flex',
  justifyContent: withDownload || withEdit || withDelete ? 'space-between' : '',
  padding: '0px'
})

export const StyledText = styled.text`
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const StyledImg = styled.img`
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 7px;
`

export const StyledButton = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  width: 40px;
  height: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
`

import React from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import i18n from 'services/i18n'
import Tabs from 'components/Tabs'
import ByCenters from './ByCenters'
import ByCategories from './ByCategories'

export default function SummaryTraining(props) {
  const location = useLocation()
  let urlSearch = location.search
  if (!location.search) {
    urlSearch = `?PageNumber=1&Year=${new Date().getFullYear()}`
  }
  const urlByCenters = props.match.path + `/` + i18n('PAGES_DASHBOARD').BY_CENTERS
  const urlByCategories = props.match.path + `/` + i18n('PAGES_DASHBOARD').BY_CATEGORIES

  const tabItems = [
    { label: i18n('DASHBOARD').CENTER, url: `${urlByCenters}${urlSearch}` },
    { label: i18n('DASHBOARD').CATEGORIES, url: `${urlByCategories}${urlSearch}` },
  ]

  return (
    <>
      <Tabs isSubTab="true" items={tabItems} />

      <Switch>
        <Route path={urlByCenters} component={ByCenters} />
        <Route path={urlByCategories} component={ByCategories} />
        <Redirect exact path={props.match.path + `/`} to={`${urlByCenters}${urlSearch}`}></Redirect>
      </Switch>
    </>
  )
}

import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

import pagerConfig from 'config/pagerConfig'

const {
  showingIndirect,

  fetchIndirectEmployeeByFilterStart,
  fetchIndirectEmployeeByFilterSuccess,
  fetchIndirectEmployeeByFilterError,
  indirectEmployeeByFilterReset,

  fetchIndirectEmployeeByDateStart,
  fetchIndirectEmployeeByDateSuccess,
  fetchIndirectEmployeeByDateError,
  indirectEmployeeByDateReset,

  fetchIndirectEmployeeStart,
  fetchIndirectEmployeeSuccess,
  fetchIndirectEmployeeError,
  indirectEmployeeReset,

  addIndirectEmployeeStart,
  addIndirectEmployeeSuccess,
  addIndirectEmployeeError,
  addIndirectEmployeeReset,

  modifyIndirectEmployeeStart,
  modifyIndirectEmployeeSuccess,
  modifyIndirectEmployeeError,
  modifyIndirectEmployeeReset,

  deleteIndirectEmployeeStart,
  deleteIndirectEmployeeSuccess,
  deleteIndirectEmployeeError,
  deleteIndirectEmployeeErrorReset
} = createActions({
  SHOWING_INDIRECT: data => ({ data }),

  FETCH_INDIRECT_EMPLOYEE_BY_FILTER_START: () => {},
  FETCH_INDIRECT_EMPLOYEE_BY_FILTER_SUCCESS: data => ({ data }),
  FETCH_INDIRECT_EMPLOYEE_BY_FILTER_ERROR: error => ({ error }),
  INDIRECT_EMPLOYEE_BY_FILTER_RESET: () => {},

  FETCH_INDIRECT_EMPLOYEE_BY_DATE_START: () => {},
  FETCH_INDIRECT_EMPLOYEE_BY_DATE_SUCCESS: data => ({ data }),
  FETCH_INDIRECT_EMPLOYEE_BY_DATE_ERROR: error => ({ error }),
  INDIRECT_EMPLOYEE_BY_DATE_RESET: () => {},

  FETCH_INDIRECT_EMPLOYEE_START: () => {},
  FETCH_INDIRECT_EMPLOYEE_SUCCESS: data => ({ data }),
  FETCH_INDIRECT_EMPLOYEE_ERROR: error => ({ error }),
  INDIRECT_EMPLOYEE_RESET: () => {},

  ADD_INDIRECT_EMPLOYEE_START: () => {},
  ADD_INDIRECT_EMPLOYEE_SUCCESS: () => {},
  ADD_INDIRECT_EMPLOYEE_ERROR: error => ({ error }),
  ADD_INDIRECT_EMPLOYEE_RESET: () => {},

  MODIFY_INDIRECT_EMPLOYEE_START: () => {},
  MODIFY_INDIRECT_EMPLOYEE_SUCCESS: () => {},
  MODIFY_INDIRECT_EMPLOYEE_ERROR: error => ({ error }),
  MODIFY_INDIRECT_EMPLOYEE_RESET: () => {},

  DELETE_INDIRECT_EMPLOYEE_START: () => {},
  DELETE_INDIRECT_EMPLOYEE_SUCCESS: () => {},
  DELETE_INDIRECT_EMPLOYEE_ERROR: error => ({ error }),
  DELETE_INDIRECT_EMPLOYEE_ERROR_RESET: () => {}
})
const setShowingIndirect = status => dispatch => dispatch(showingIndirect({ status }))

const fetchIndirectEmployee = filter => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchIndirectEmployeeStart())
    const {
      selectedPage: { page },
      selectedFilter: { selectedBusiness }
    } = getState()
    filter.CompanyId = selectedBusiness.value ? selectedBusiness.value : 0
    filter.Pager.PageSize = pagerConfig.PAGE_SIZE
    filter.Pager.PageNumber = page
    try {
      const response = await api.post(API_ROUTES.GET_INDIRECT_EMPLOYEE_BY_FILTER, filter)
      dispatch(fetchIndirectEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchIndirectEmployeeError(error))
    }
  }
}

const resetIndirectEmployee = () => dispatch => dispatch(indirectEmployeeReset())

const fetchIndirectEmployeesByDate = (from, to) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchIndirectEmployeeByDateStart())
    let urlFinal = `${API_ROUTES.PUT_INDIRECT_EMPLOYEE}/${API_ROUTES.BY_DATE}/${from}/${to}`
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)

      dispatch(fetchIndirectEmployeeByDateSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchIndirectEmployeeByDateError(error))
    }
  }
}

const resetIndirectEmployeesByDate = () => dispatch => dispatch(indirectEmployeeByDateReset())

const fetchIndirectEmployeeByFilter = filter => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchIndirectEmployeeByFilterStart())
    const {
      selectedPage: { page },
      selectedFilter: { selectedBusiness }
    } = getState()
    filter.CompanyId = selectedBusiness.value ? selectedBusiness.value : 0
    filter.Pager.PageSize = pagerConfig.PAGE_SIZE
    filter.Pager.PageNumber = page
    try {
      const response = await api.post(API_ROUTES.GET_INDIRECT_EMPLOYEE_BY_FILTER, filter)
      dispatch(fetchIndirectEmployeeByFilterSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchIndirectEmployeeByFilterError(error))
    }
  }
}

const resetIndirectEmployeeByFilter = () => dispatch => dispatch(indirectEmployeeByFilterReset())

const addIndirectEmployee = values => {
  return async dispatch => {
    const api = await getApi()
    dispatch(addIndirectEmployeeStart())
    const body = {
      firstName: values.name,
      lastName: values.lastName,
      documentNumber: values.documentNumber,
      externalCompanyName: values.businessInput,
      companies: values.companies
    }
    try {
      const response = await api.post(`${API_ROUTES.PUT_INDIRECT_EMPLOYEE}`, body)
      dispatch(addIndirectEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addIndirectEmployeeError(error))
    }
  }
}

const resetAddIndirectEmployee = () => dispatch => dispatch(addIndirectEmployeeReset())

const modifyIndirectEmployee = (item, values) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyIndirectEmployeeStart())
    const body = {
      firstName: values.name,
      lastName: values.lastName,
      documentNumber: values.documentNumber,
      externalCompanyName: values.businessInput,
      companies: values.companies
    }
    try {
      const response = await api.put(`${API_ROUTES.PUT_INDIRECT_EMPLOYEE}/${item.id}`, body)
      dispatch(modifyIndirectEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyIndirectEmployeeError(error))
    }
  }
}

const resetModifyIndirectEmployee = () => dispatch => dispatch(modifyIndirectEmployeeReset())

const deleteIndirectEmployee = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteIndirectEmployeeStart())
    try {
      const response = await api.delete(`${API_ROUTES.PUT_INDIRECT_EMPLOYEE}/${id}`)
      dispatch(deleteIndirectEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteIndirectEmployeeError(error.data))
    }
  }
}

const resetDeleteIndirectEmployeeError = () => dispatch =>
  dispatch(deleteIndirectEmployeeErrorReset())

export {
  showingIndirect,
  setShowingIndirect,
  fetchIndirectEmployeeByFilter,
  fetchIndirectEmployeeByFilterStart,
  fetchIndirectEmployeeByFilterSuccess,
  fetchIndirectEmployeeByFilterError,
  indirectEmployeeByFilterReset,
  fetchIndirectEmployeesByDate,
  fetchIndirectEmployeeByDateStart,
  fetchIndirectEmployeeByDateSuccess,
  fetchIndirectEmployeeByDateError,
  indirectEmployeeByDateReset,
  resetIndirectEmployeesByDate,
  resetIndirectEmployeeByFilter,
  fetchIndirectEmployeeStart,
  fetchIndirectEmployeeSuccess,
  fetchIndirectEmployeeError,
  fetchIndirectEmployee,
  resetIndirectEmployee,
  indirectEmployeeReset,
  addIndirectEmployee,
  addIndirectEmployeeStart,
  addIndirectEmployeeSuccess,
  addIndirectEmployeeError,
  resetAddIndirectEmployee,
  addIndirectEmployeeReset,
  modifyIndirectEmployee,
  modifyIndirectEmployeeStart,
  modifyIndirectEmployeeSuccess,
  modifyIndirectEmployeeError,
  resetModifyIndirectEmployee,
  modifyIndirectEmployeeReset,
  deleteIndirectEmployee,
  deleteIndirectEmployeeStart,
  deleteIndirectEmployeeSuccess,
  deleteIndirectEmployeeError,
  resetDeleteIndirectEmployeeError,
  deleteIndirectEmployeeErrorReset
}

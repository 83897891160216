import { createActions } from 'redux-actions'

const { displayToast, resetToast } = createActions({
  RESET_TOAST: () => {},
  DISPLAY_TOAST: data => ({ data })
})

const setDisplayToast = status => dispatch => dispatch(displayToast({ status }))
const resetDisplayToast = () => dispatch => dispatch(resetToast())

export { setDisplayToast, displayToast, resetDisplayToast, resetToast }

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { getByCategoriesRequest } from 'actions/Dashboard/SummaryTraining/byCategories'
import { loadingStart, loadingEnd } from 'actions/loading'
import FiltersUrl from 'components/FiltersUrl'
import PaginationUrl from 'components/PaginationUrl'
import Table from 'components/Table'
import filtersByCategories from 'config/Dashboard/Filters/filtersByCategories'
import columnsByCategories from 'config/Dashboard/Columns/columnsByCategories'

export default function ByCategories() {
  const dispatch = useDispatch()
  const dataByCategories = useSelector((state) => state.byCategories)
  const location = useLocation()
  const query = queryString.parse(location.search)
  useEffect(() => {
    filtersByCategories
      .filter((x) => x.isMulti)
      .forEach((filterMulti) => {
        if (query[filterMulti.name] && !(query[filterMulti.name] instanceof Array)) {
          query[filterMulti.name] = [query[filterMulti.name]]
        }
      })
    dispatch(loadingStart())
    dispatch(getByCategoriesRequest(query)).then(() => {
      dispatch(loadingEnd())
    })
  }, [location.search])

  return (
    <>
      <FiltersUrl filters={filtersByCategories} isLoading={dataByCategories.isLoading} />
      <Table columns={columnsByCategories} data={dataByCategories.values} />
      {dataByCategories.values.length ? (
        <PaginationUrl total={dataByCategories.total} size={10}></PaginationUrl>
      ) : (
        ''
      )}
    </>
  )
}

import styled from 'styled-components'

export const WrapperLoader = styled.div`
  margin-top: 5px;
`

export const WrapperError = styled.div`
  margin-top: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`
export const WrapperItems = styled.div`
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
`

export const WrapperEmployeeData = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

export const Text = styled.text`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`

export const WrapperUsers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

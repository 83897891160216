import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { DivSearchWrapper, DivError } from './styled'

import {
  fetchDirectEmployeeByFilter,
  resetDirectEmployeeByFilter,
  fetchDirectEmployee,
  fetchDirectEmployeesByDate,
  setShowingDirect
} from 'actions/directEmployee'
import {
  fetchCarrierEmployeeByFilter,
  resetCarrierEmployeeByFilter,
  fetchCarrierEmployee,
  fetchCarrierEmployeesByDate,
  setShowingCarrier
} from 'actions/carrierEmployee'
import {
  fetchIndirectEmployeeByFilter,
  resetIndirectEmployeeByFilter,
  fetchIndirectEmployee,
  fetchIndirectEmployeesByDate,
  setShowingIndirect
} from 'actions/indirectEmployee'
import {
  resetCoursesByCategoryId,
  fetchCoursesByName,
  resetCoursesByName,
  fetchCourses,
  setShowingCourse,
  fetchCoursesByDate
} from 'actions/courses'
import {
  fetchCentersByName,
  resetCentersByName,
  fetchCenters,
  fetchCentersByDate,
  setShowingCenter
} from 'actions/centers'
import {
  fetchLeadersByName,
  resetLeadersByName,
  fetchLeaders,
  fetchLeadersByDate,
  setShowingLeader
} from 'actions/leaders'
import { setSelectedFilter } from 'actions/selectedFilter'
import { resetSelectedDateFilter } from 'actions/selectedDateFilter'
import { resetExpandedCategory } from 'actions/expandedCategory'
import { resetSelectedSearchedItem } from 'actions/selectedSearchedItem'
import { resetSelectedPage } from 'actions/page'

import { capitalizeFirstLetter } from 'utils/strings'
import SearchInput from 'components/SearchInput'
import SearchFilters from 'components/SearchFilters'

import filterKeys from 'constants/filterKeys'

import { getInlineDate } from 'utils/date/dateParser'
import isDanone from 'utils/isDanone'

import {
  filtersDirectEmployee,
  filtersCarrierEmployee,
  filtersIndirectEmployee
} from 'utils/searchFilters'
import i18n from 'services/i18n'

class SearchModule extends Component {
  state = { error: '' }

  componentDidUpdate = async prevProps => {
    if (prevProps.selectedPage.page !== this.props.selectedPage.page) {
      this.handleSearch()
    }

    if (prevProps.selectedFilter.filterItem !== this.props.selectedFilter.filterItem) {
      this.handleClear()
    }

    if (prevProps.selectedFilter.filterInput !== this.props.selectedFilter.filterInput) {
      this.props.resetSelectedPage()
    }

    if (
      (prevProps.addPopup.open && !this.props.addPopup.open && this.successAdd()) ||
      (prevProps.deletePopup.open && !this.props.deletePopup.open && this.successDelete()) ||
      (prevProps.editPopup.open && !this.props.editPopup.open && this.successEdit())
    ) {
      this.handleSearch(true)
    }
  }

  successAdd = () =>
    this.props.leaders.successAdd ||
    this.props.centers.successAdd ||
    this.props.courses.successAdd ||
    this.props.directEmployee.successAdd ||
    this.props.indirectEmployee.successAdd ||
    this.props.carrierEmployee.successAdd

  successDelete = () =>
    this.props.courses.successDelete ||
    this.props.centers.successDelete ||
    this.props.leaders.successDelete ||
    this.props.directEmployee.successDelete ||
    this.props.indirectEmployee.successDelete ||
    this.props.carrierEmployee.successDelete ||
    this.props.capacitations.successDelete

  successEdit = () =>
    this.props.leaders.successEdit ||
    this.props.centers.successEdit ||
    this.props.courses.successEdit ||
    this.props.directEmployee.successEdit ||
    this.props.indirectEmployee.successEdit ||
    this.props.carrierEmployee.successEdit

  handleChangeSelectedField = event => {
    this.props.resetDirectEmployeeByFilter()
    this.props.resetCarrierEmployeeByFilter()
    this.props.resetIndirectEmployeeByFilter()
    this.props.resetCoursesByName()
    this.props.resetCentersByName()
    this.props.resetLeadersByName()
    this.props.resetExpandedCategory()
    this.props.setSelectedFilter({ filterCategory: event.target.value, filterItem: '' })
  }

  handleChangeFilter = event => {
    event.target.value === filterKeys.EMPLOYEE_DIRECT.DIRECT ||
    event.target.value === filterKeys.EMPLOYEE_CARRIER.CARRIER ||
    event.target.value === filterKeys.EMPLOYEE_INDIRECT.INDIRECT ||
    event.target.value === filterKeys.COURSE.COURSE_ID ||
    event.target.value === filterKeys.CENTER.CENTER_ID ||
    event.target.value === filterKeys.LEADER.LEADER_ID
      ? this.handleChangeSelectedField(event)
      : this.props.setSelectedFilter({ filterItem: event.target.value })
  }

  handleInput = event => {
    if (event) {
      this.props.setSelectedFilter({ filterInput: event.target.value })
    } else {
      this.props.setSelectedFilter({ filterInput: '' })
    }
  }

  isAllFilterSelected = filterItem =>
    filterItem === filterKeys.EMPLOYEE_DIRECT.ALL ||
    filterItem === filterKeys.EMPLOYEE_CARRIER.ALL ||
    filterItem === filterKeys.EMPLOYEE_INDIRECT.ALL ||
    filterItem === filterKeys.COURSE.ALL ||
    filterItem === filterKeys.CENTER.ALL ||
    filterItem === filterKeys.LEADER.ALL

  handleSearch = withoutError => {
    this.props.resetSelectedSearchedItem()
    if (
      this.isAllFilterSelected(this.props.selectedFilter.filterItem) ||
      this.props.selectedFilter.filterInput
    ) {
      this.props.resetCoursesByCategoryId()
      switch (this.props.selectedFilter.filterCategory) {
        case filterKeys.EMPLOYEE_DIRECT.DIRECT:
          this.resetError()
          this.handleSelectedItemDirect(this.props.selectedFilter.filterInput)
          break
        case filterKeys.EMPLOYEE_CARRIER.CARRIER:
          this.resetError()
          this.handleSelectedItemCarrier(this.props.selectedFilter.filterInput)
          break
        case filterKeys.EMPLOYEE_INDIRECT.INDIRECT:
          this.resetError()
          this.handleSelectedItemIndirect(this.props.selectedFilter.filterInput)
          break
        case filterKeys.COURSE.COURSE_ID:
          this.resetError()
          this.handleSelectedCourse(this.props.selectedFilter.filterInput)
          break
        case filterKeys.CENTER.CENTER_ID:
          this.resetError()
          this.handleSelectedCenter(this.props.selectedFilter.filterInput)
          break
        case filterKeys.LEADER.LEADER_ID:
          this.resetError()
          this.handleSelectedLeader(this.props.selectedFilter.filterInput)
          break
        default:
          this.setState({
            error: capitalizeFirstLetter(i18n('SEARCH').SEARCH_ERROR_NO_FILTER_CATEGORY)
          })
      }
    } else {
      withoutError
        ? this.resetError()
        : this.setState({ error: capitalizeFirstLetter(i18n('SEARCH').SEARCH_ERROR_NO_SEARCH) })
    }
  }

  resetError = () => {
    this.setState({ error: '' })
  }

  getDate = date => {
    return date ? getInlineDate(date) : null
  }

  hasDateFilter = () =>
    this.props.selectedDateFilter.filterDateFrom && this.props.selectedDateFilter.filterDateTo

  handleSelectedItemDirect = input => {
    switch (this.props.selectedFilter.filterItem) {
      case filterKeys.EMPLOYEE_DIRECT.NAME:
        this.props.setShowingDirect({ isShowing: true })
        this.props.fetchDirectEmployeeByFilter({ ...filtersDirectEmployee, Name: input })
        break
      case filterKeys.EMPLOYEE_DIRECT.FILE:
        this.props.setShowingDirect({ isShowing: true })
        this.props.fetchDirectEmployeeByFilter({ ...filtersDirectEmployee, FileNumber: input })
        break
      case filterKeys.EMPLOYEE_DIRECT.ALL:
        this.props.setShowingDirect({ isShowing: true })
        if (this.hasDateFilter()) {
          this.props.fetchDirectEmployeesByDate(
            this.getDate(this.props.selectedDateFilter.filterDateFrom),
            this.getDate(this.props.selectedDateFilter.filterDateTo)
          )
        } else {
          this.props.fetchDirectEmployee(filtersDirectEmployee)
        }
        break
      default:
        this.setState({ error: capitalizeFirstLetter(i18n('SEARCH').SEARCH_ERROR_NO_FILTER_ITEM) })
    }
  }
  handleSelectedItemCarrier = input => {
    switch (this.props.selectedFilter.filterItem) {
      case filterKeys.EMPLOYEE_CARRIER.NAME:
        this.props.setShowingCarrier({ isShowing: true })
        this.props.fetchCarrierEmployeeByFilter({ ...filtersCarrierEmployee, Name: input })
        break
      case filterKeys.EMPLOYEE_CARRIER.FILE:
        this.props.setShowingCarrier({ isShowing: true })
        this.props.fetchCarrierEmployeeByFilter({ ...filtersCarrierEmployee, FileNumber: input })
        break
      case filterKeys.EMPLOYEE_CARRIER.DOCUMENT:
        this.props.setShowingCarrier({ isShowing: true })
        this.props.fetchCarrierEmployeeByFilter({
          ...filtersCarrierEmployee,
          DocumentNumber: input
        })
        break
      case filterKeys.EMPLOYEE_CARRIER.DISTRIBUCION:
        this.props.setShowingCarrier({ isShowing: true })
        this.props.fetchCarrierEmployeeByFilter({
          ...filtersCarrierEmployee,
          DistributionNumber: input
        })
        break
      case filterKeys.EMPLOYEE_CARRIER.ACCOUNT:
        this.props.setShowingCarrier({ isShowing: true })
        this.props.fetchCarrierEmployeeByFilter({ ...filtersCarrierEmployee, AccountNumber: input })
        break
      case filterKeys.EMPLOYEE_CARRIER.ALL:
        this.props.setShowingCarrier({ isShowing: true })
        if (this.hasDateFilter()) {
          this.props.fetchCarrierEmployeesByDate(
            this.getDate(this.props.selectedDateFilter.filterDateFrom),
            this.getDate(this.props.selectedDateFilter.filterDateTo)
          )
        } else {
          this.props.fetchCarrierEmployee(filtersCarrierEmployee)
        }
        break
      default:
        this.setState({ error: capitalizeFirstLetter(i18n('SEARCH').SEARCH_ERROR_NO_FILTER_ITEM) })
    }
  }
  handleSelectedItemIndirect = input => {
    switch (this.props.selectedFilter.filterItem) {
      case filterKeys.EMPLOYEE_INDIRECT.NAME:
        this.props.setShowingIndirect({ isShowing: true })
        this.props.fetchIndirectEmployeeByFilter({ ...filtersIndirectEmployee, Name: input })
        break
      case filterKeys.EMPLOYEE_INDIRECT.DOCUMENT:
        this.props.setShowingIndirect({ isShowing: true })
        this.props.fetchIndirectEmployeeByFilter({
          ...filtersIndirectEmployee,
          DocumentNumber: input
        })
        break
      case filterKeys.EMPLOYEE_INDIRECT.ALL:
        this.props.setShowingIndirect({ isShowing: true })
        if (this.hasDateFilter()) {
          this.props.fetchIndirectEmployeesByDate(
            this.getDate(this.props.selectedDateFilter.filterDateFrom),
            this.getDate(this.props.selectedDateFilter.filterDateTo)
          )
        } else {
          this.props.fetchIndirectEmployee(filtersIndirectEmployee)
        }
        break
      default:
        this.setState({ error: capitalizeFirstLetter(i18n('SEARCH').SEARCH_ERROR_NO_FILTER_ITEM) })
    }
  }

  handleSelectedCourse = input => {
    switch (this.props.selectedFilter.filterItem) {
      case filterKeys.COURSE.NAME:
        this.props.setShowingCourse({ isShowing: true })
        this.props.fetchCoursesByName(input)
        break
      case filterKeys.COURSE.ALL:
        this.props.setShowingCourse({ isShowing: true })
        if (this.hasDateFilter()) {
          this.props.fetchCoursesByDate(
            this.getDate(this.props.selectedDateFilter.filterDateFrom),
            this.getDate(this.props.selectedDateFilter.filterDateTo)
          )
        } else {
          this.props.fetchCourses()
        }
        break
      default:
        this.setState({ error: capitalizeFirstLetter(i18n('SEARCH').SEARCH_ERROR_NO_FILTER_ITEM) })
    }
  }

  handleSelectedCenter = input => {
    switch (this.props.selectedFilter.filterItem) {
      case filterKeys.CENTER.NAME:
        this.props.setShowingCenter({ isShowing: true })
        this.props.fetchCentersByName(input)
        break
      case filterKeys.CENTER.ALL:
        this.props.setShowingCenter({ isShowing: true })
        if (this.hasDateFilter()) {
          this.props.fetchCentersByDate(
            this.getDate(this.props.selectedDateFilter.filterDateFrom),
            this.getDate(this.props.selectedDateFilter.filterDateTo)
          )
        } else {
          this.props.fetchCenters({ isShowing: true })
        }
        break
      default:
        this.setState({ error: capitalizeFirstLetter(i18n('SEARCH').SEARCH_ERROR_NO_FILTER_ITEM) })
    }
  }

  handleSelectedLeader = async input => {
    switch (this.props.selectedFilter.filterItem) {
      case filterKeys.LEADER.NAME:
        this.props.setShowingLeader({ isShowing: true })
        this.props.fetchLeadersByName(input)
        break
      case filterKeys.LEADER.ALL:
        this.props.setShowingLeader({ isShowing: true })
        if (this.hasDateFilter()) {
          this.props.fetchLeadersByDate(
            this.getDate(this.props.selectedDateFilter.filterDateFrom),
            this.getDate(this.props.selectedDateFilter.filterDateTo)
          )
        } else {
          this.props.fetchLeaders()
        }

        break
      default:
        this.setState({ error: capitalizeFirstLetter(i18n('SEARCH').SEARCH_ERROR_NO_FILTER_ITEM) })
    }
  }

  handleClearSearch = () => {
    this.props.setSelectedFilter({ filterCategory: '', filterItem: '' })
    this.handleClear()
    this.handleInput()
  }

  handleClear = () => {
    this.props.resetSelectedDateFilter()
    this.props.resetDirectEmployeeByFilter()
    this.props.resetCarrierEmployeeByFilter()
    this.props.resetIndirectEmployeeByFilter()
    this.props.resetCoursesByName()
    this.props.resetCentersByName()
    this.props.resetLeadersByName()
    this.props.resetExpandedCategory()
    this.props.resetSelectedPage()
  }

  render() {
    return (
      <DivSearchWrapper>
        <SearchFilters
          handleChangeFilter={this.handleChangeFilter}
          selectedField={this.props.selectedFilter.filterCategory}
          selectedItem={this.props.selectedFilter.filterItem}
          isDanoneSelected={isDanone()}
        />
        <SearchInput
          value={this.props.selectedFilter.filterInput}
          valueDate={this.props.selectedDateFilter}
          handleInput={this.handleInput}
          handleClickSearch={this.handleSearch}
          handleClearSearch={this.handleClearSearch}
          isSearchBlocked={this.isAllFilterSelected(this.props.selectedFilter.filterItem)}
        />
        <DivError>{this.state.error}</DivError>
      </DivSearchWrapper>
    )
  }
}

const mapStateToProps = ({
  selectedFilter,
  selectedDateFilter,
  selectedPage,
  editPopup,
  addPopup,
  deletePopup,
  leaders,
  centers,
  courses,
  directEmployee,
  indirectEmployee,
  carrierEmployee,
  capacitations
}) => ({
  selectedFilter,
  selectedDateFilter,
  selectedPage,
  editPopup,
  addPopup,
  deletePopup,
  leaders,
  centers,
  courses,
  directEmployee,
  indirectEmployee,
  carrierEmployee,
  capacitations
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDirectEmployeeByFilter,
      fetchCarrierEmployeeByFilter,
      fetchIndirectEmployeeByFilter,
      fetchCoursesByName,
      fetchCentersByName,
      fetchLeadersByName,
      setSelectedFilter,
      resetDirectEmployeeByFilter,
      resetCarrierEmployeeByFilter,
      resetIndirectEmployeeByFilter,
      resetCoursesByName,
      resetCentersByName,
      resetLeadersByName,
      resetCoursesByCategoryId,
      resetExpandedCategory,
      resetSelectedDateFilter,
      resetSelectedSearchedItem,
      resetSelectedPage,
      fetchLeaders,
      fetchLeadersByDate,
      setShowingLeader,
      fetchCenters,
      fetchCentersByDate,
      setShowingCenter,
      fetchCourses,
      fetchCoursesByDate,
      setShowingCourse,
      fetchIndirectEmployee,
      setShowingIndirect,
      fetchCarrierEmployee,
      setShowingCarrier,
      fetchDirectEmployee,
      setShowingDirect,
      fetchDirectEmployeesByDate,
      fetchCarrierEmployeesByDate,
      fetchIndirectEmployeesByDate
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchModule)

import { handleActions } from 'redux-actions'
import {
  capacitationImagesStart,
  capacitationImagesSuccess,
  capacitationImagesError,
  capacitationImagesReset
} from 'actions/capacitationImagesDownload'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,
  isShowing: false
}

const reducer = handleActions(
  {
    [capacitationImagesStart]: state => ({
      ...state,
      isFetching: true,
      error: null,
      isShowing: true
    }),
    [capacitationImagesSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null,
      isShowing: true
    }),
    [capacitationImagesError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error,
      isShowing: true
    }),
    [capacitationImagesReset]: () => defaultState
  },
  defaultState
)

export default reducer

import React from 'react'
import { useSelector } from 'react-redux'
import { Tab, TabContainer } from './styles'

export default function Tabs({ items, isSubTab }) {
  const disabled = useSelector((state) => state.loading)
  return (
    <TabContainer isSubTab={isSubTab}>
      {items.map((item, index) => (
        <Tab
          key={index}
          activeClassName="active"
          isSubTab={isSubTab}
          to={item.url}
          onClick={(e) => {
            if (disabled) e.preventDefault()
          }}
        >
          {item.label}
        </Tab>
      ))}
    </TabContainer>
  )
}

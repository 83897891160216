import { handleActions } from 'redux-actions'
import {
  showingInstructors,
  fetchInstructorsStart,
  fetchInstructorsSuccess,
  fetchInstructorsError,
  instructorsReset,
  fetchInstructorByIdStart,
  fetchInstructorByIdSuccess,
  fetchInstructorByIdError,
  instructorByIdReset,
  enableOrDisableInstructorsStart,
  enableOrDisableInstructorsSuccess,
  enableOrDisableInstructorsError,
  enableOrDisableInstructorsReset,
  addInstructorStart,
  addInstructorSuccess,
  addInstructorError,
  addInstructorReset,
  deleteInstructorStart,
  deleteInstructorSuccess,
  deleteInstructorError,
  deleteInstructorErrorReset,
  modifyInstructorStart,
  modifyInstructorSuccess,
  modifyInstructorError,
  modifyInstructorReset
} from 'actions/instructors'

const defaultState = {
  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  valuesByid: null,
  isFetchingByid: false,
  errorByid: null,

  successEnablingOrDisabling: false,
  isEnablingOrDisabling: false,
  errorEnablingOrDisabling: null,

  isShowing: false,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false
}

const reducer = handleActions(
  {
    [showingInstructors]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [fetchInstructorsStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchInstructorsSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchInstructorsError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [instructorsReset]: () => defaultState,

    [fetchInstructorByIdStart]: state => ({
      ...state,
      isFetchingByid: true,
      errorByid: null
    }),
    [fetchInstructorByIdSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesByid: values,
      isFetchingByid: false,
      errorByid: null
    }),
    [fetchInstructorByIdError]: (state, { payload: { error } }) => ({
      ...state,
      isFetchingByid: false,
      errorByid: error
    }),
    [instructorByIdReset]: () => defaultState,

    [enableOrDisableInstructorsStart]: state => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: true,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableInstructorsSuccess]: state => ({
      ...state,
      successEnablingOrDisabling: true,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableInstructorsError]: (state, error) => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: error
    }),
    [enableOrDisableInstructorsReset]: () => defaultState,

    [addInstructorStart]: state => ({
      ...state,
      isAdding: true,
      successAdd: false,
      errorAdd: false
    }),
    [addInstructorSuccess]: state => ({
      ...state,
      isAdding: false,
      successAdd: true,
      errorAdd: false
    }),
    [addInstructorError]: (state, error) => ({
      ...state,
      isAdding: false,
      successAdd: false,
      errorAdd: error
    }),
    [addInstructorReset]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false,
      successAdd: false
    }),

    [deleteInstructorStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteInstructorSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteInstructorError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),

    [modifyInstructorStart]: state => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false
    }),
    [modifyInstructorSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyInstructorError]: (state, error) => ({
      ...state,
      isSubmiting: false,
      successEdit: false,
      errorEdit: error
    }),
    [modifyInstructorReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false,
      successEdit: false
    }),
    [deleteInstructorErrorReset]: state => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: false
    })
  },
  defaultState
)

export default reducer

import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px 0;
`

const getDarkGrey = ({ theme: { colors } }) => colors.darkGrey
const getLightGrey = ({ theme: { colors } }) => colors.lightGrey
const getBrandColor = ({ theme: { colors } }) => colors.purple

export const PaginationItem = styled(NavLink)`
  color: ${getDarkGrey};
  font-size: 10px;
  border-radius: 6px;
  min-width: 28px;
  height: 28px;
  line-height: 26px;
  text-align: center;
  border: 1px solid ${getLightGrey};
  text-decoration: none;
  &:not(:first-child) {
    margin-left: 10px;
  }
  &.icon {
    font-size: 16px;
  }
  &.active {
    background-color: ${getBrandColor};
    border-color: ${getBrandColor};
    color: #fff;
  }
`

import { handleActions } from 'redux-actions'
import { loadingStart, loadingEnd } from 'actions/loading'

const reducer = handleActions(
  {
    [loadingStart]: () => true,
    [loadingEnd]: () => false,
  },
  false
)

export default reducer

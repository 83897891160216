import { handleActions } from 'redux-actions'
import {
  showingLeaderUsers,
  fetchLeaderUsersStart,
  fetchLeaderUsersSuccess,
  fetchLeaderUsersError,
  leaderUsersReset,
  enableOrDisableLeaderUsersStart,
  enableOrDisableLeaderUsersSuccess,
  enableOrDisableLeaderUsersError,
  enableOrDisableLeaderUsersReset,
  fetchLeaderUsersByIdStart,
  fetchLeaderUsersByIdSuccess,
  fetchLeaderUsersByIdError,
  leaderUsersByIdReset,
  fetchLeaderUsersByCenterIdStart,
  fetchLeaderUsersByCenterIdSuccess,
  fetchLeaderUsersByCenterIdError,
  leaderUsersByCenterIdReset,
  addLeaderUserStart,
  addLeaderUserSuccess,
  addLeaderUserError,
  addLeaderUserReset,
  deleteLeaderUserStart,
  deleteLeaderUserSuccess,
  deleteLeaderUserError,
  modifyLeaderUserStart,
  modifyLeaderUserSuccess,
  modifyLeaderUserError,
  modifyLeaderUserReset,
  deleteLeaderUserErrorReset
} from 'actions/leaderUsers'

const defaultState = {
  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  successEnablingOrDisabling: false,
  isEnablingOrDisabling: false,
  errorEnablingOrDisabling: null,

  isShowing: false,

  valuesByid: null,
  isFetchingByid: false,
  errorByid: null,

  valuesByCenterid: null,
  isFetchingByCenterid: false,
  errorByCenterid: null,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false
}

const reducer = handleActions(
  {
    [showingLeaderUsers]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [fetchLeaderUsersStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchLeaderUsersSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchLeaderUsersError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [leaderUsersReset]: () => defaultState,

    [enableOrDisableLeaderUsersStart]: state => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: true,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableLeaderUsersSuccess]: state => ({
      ...state,
      successEnablingOrDisabling: true,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableLeaderUsersError]: (state, error) => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: error
    }),
    [enableOrDisableLeaderUsersReset]: () => defaultState,

    [fetchLeaderUsersByIdStart]: state => ({
      ...state,
      isFetchingByid: true,
      errorByid: null
    }),
    [fetchLeaderUsersByIdSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesByid: values,
      isFetchingByid: false,
      errorByid: null
    }),
    [fetchLeaderUsersByIdError]: (state, { payload: { error } }) => ({
      ...state,
      isFetchingByid: false,
      errorByid: error
    }),
    [leaderUsersByIdReset]: () => defaultState,

    [fetchLeaderUsersByCenterIdStart]: state => ({
      ...state,
      isFetchingByCenterid: true,
      errorByCenterid: null
    }),
    [fetchLeaderUsersByCenterIdSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesByCenterid: values,
      isFetchingByCenterid: false,
      errorByCenterid: null
    }),
    [fetchLeaderUsersByCenterIdError]: (state, { error }) => ({
      ...state,
      isFetchingByCenterid: false,
      errorByCenterid: error
    }),
    [leaderUsersByCenterIdReset]: () => defaultState,

    [addLeaderUserStart]: state => ({
      ...state,
      isAdding: true,
      successAdd: false,
      errorAdd: false
    }),
    [addLeaderUserSuccess]: state => ({
      ...state,
      isAdding: false,
      successAdd: true,
      errorAdd: false
    }),
    [addLeaderUserError]: (state, error) => ({
      ...state,
      isAdding: false,
      successAdd: false,
      errorAdd: error
    }),
    [addLeaderUserReset]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false
    }),

    [deleteLeaderUserStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteLeaderUserSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteLeaderUserError]: (state, error) => ({
      ...state,
      isDeleting: false,
      errorDelete: error
    }),

    [modifyLeaderUserStart]: state => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false
    }),
    [modifyLeaderUserSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyLeaderUserError]: (state, error) => ({
      ...state,
      isSubmiting: false,
      successEdit: false,
      errorEdit: error
    }),
    [modifyLeaderUserReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false,
      successEdit: false
    }),
    [deleteLeaderUserErrorReset]: state => ({
      ...state,
      isDeleting: false,
      errorDelete: false
    })
  },
  defaultState
)

export default reducer

import { handleActions } from 'redux-actions'
import {
  importExcelCoursesStart,
  importExcelCoursesSuccess,
  importExcelCoursesError,
  importExcelCapacitationsStart,
  importExcelCapacitationsSuccess,
  importExcelCapacitationsError,
  importExcelDirectEmployeesStart,
  importExcelDirectEmployeesSuccess,
  importExcelDirectEmployeesError,
  importExcelCarrierEmployeesStart,
  importExcelCarrierEmployeesSuccess,
  importExcelCarrierEmployeesError,
  importExcelFullTrainingsStart,
  importExcelFullTrainingsSuccess,
  importExcelFullTrainingsError,
  excelImportErrorsReset,
  excelImportSaveErrors,
} from 'actions/excelImport'

const defaultState = {
  courseSuccess: null,
  courseLoading: false,
  courseError: null,

  capacitationsSuccess: null,
  capacitationsLoading: false,
  capacitationsError: null,

  directEmployeesSuccess: null,
  directEmployeesLoading: false,
  directEmployeesError: null,

  carrierEmployeesSuccess: null,
  carrierEmployeesLoading: false,
  carrierEmployeesError: null,

  fullTrainingSuccess: null,
  fullTrainingLoading: false,
  fullTrainingError: null,

  handleErrors: null,
}

const reducer = handleActions(
  {
    [importExcelCoursesStart]: (state) => ({
      ...state,
      courseLoading: true,
      courseSuccess: null,
      courseError: false,
    }),
    [importExcelCoursesSuccess]: (
      state,
      {
        payload: {
          data: { values },
        },
      }
    ) => ({
      ...state,
      courseSuccess: values,
      courseLoading: false,
      courseError: false,
    }),
    [importExcelCoursesError]: (state, { error }) => ({
      ...state,
      courseLoading: false,
      courseSuccess: false,
      courseError: error,
    }),
    [importExcelCapacitationsStart]: (state) => ({
      ...state,
      capacitationsLoading: true,
      capacitationsSuccess: false,
      capacitationsError: false,
    }),
    [importExcelCapacitationsSuccess]: (state) => ({
      ...state,
      capacitationsLoading: false,
      capacitationsSuccess: true,
      capacitationsError: false,
    }),
    [importExcelCapacitationsError]: (state, { error }) => ({
      ...state,
      capacitationsLoading: false,
      capacitationsSuccess: false,
      capacitationsError: error,
    }),

    [importExcelDirectEmployeesStart]: (state) => ({
      ...state,
      directEmployeesLoading: true,
      directEmployeesSuccess: false,
      directEmployeesError: false,
    }),
    [importExcelDirectEmployeesSuccess]: (
      state,
      {
        payload: {
          data: { values },
        },
      }
    ) => ({
      ...state,
      directEmployeesSuccess: values,
      directEmployeesLoading: false,
      directEmployeesError: false,
    }),
    [importExcelDirectEmployeesError]: (state, { error }) => ({
      ...state,
      directEmployeesLoading: false,
      directEmployeesSuccess: false,
      directEmployeesError: error,
    }),

    [importExcelCarrierEmployeesStart]: (state) => ({
      ...state,
      carrierEmployeesLoading: true,
      carrierEmployeesSuccess: false,
      carrierEmployeesError: false,
    }),
    [importExcelCarrierEmployeesSuccess]: (
      state,
      {
        payload: {
          data: { values },
        },
      }
    ) => ({
      ...state,
      carrierEmployeesSuccess: values,
      carrierEmployeesLoading: false,
      carrierEmployeesError: false,
    }),
    [importExcelCarrierEmployeesError]: (state, { error }) => ({
      ...state,
      carrierEmployeesLoading: false,
      carrierEmployeesSuccess: false,
      carrierEmployeesError: error,
    }),
    [importExcelFullTrainingsStart]: (state) => ({
      ...state,
      fullTrainingLoading: true,
      fullTrainingSuccess: false,
      fullTrainingError: false,
    }),
    [importExcelFullTrainingsSuccess]: (
      state,
      {
        payload: {
          data: { values },
        },
      }
    ) => ({
      ...state,
      fullTrainingSuccess: values,
      fullTrainingLoading: false,
      fullTrainingError: false,
    }),
    [importExcelFullTrainingsError]: (state, { error }) => ({
      ...state,
      fullTrainingLoading: false,
      fullTrainingSuccess: false,
      fullTrainingError: error,
    }),
    [excelImportErrorsReset]: (state) => ({
      ...state,
      courseError: null,
      capacitationsError: null,
      directEmployeesError: null,
      carrierEmployeesError: null,
      handleErrors: null,
    }),
    [excelImportSaveErrors]: (
      state,
      {
        payload: {
          data: { values },
        },
      }
    ) => ({
      ...state,
      handleErrors: values,
    }),
  },
  defaultState
)

export default reducer

import React from 'react'

import { getDate } from 'utils/date/dateParser'
import { capitalizeFirstLetter, allToLower } from 'utils/strings'
import isDanone from 'utils/isDanone'

import DeleteIcon from '@material-ui/icons/DeleteOutline'

import i18n from 'services/i18n'

import Pagination from 'components/Pagination'

import {
  ButtonCapacitation,
  PreviewWrapper,
  ButtonDelete,
  DivWrapper,
  DivItemWrapper,
  DivHeaderItemWrapper,
  DivItem,
  ButtonEmptyDelete
} from './styled'

import { getCompanyById } from 'utils/getCompany'

const evalData = data => {
  if (data.items) {
    return data.items
  } else {
    return data.values.items
  }
}
const getTotal = data => {
  if (data.items) {
    return data.total
  } else {
    return data.values.total
  }
}

const CapacitationPreview = ({ data, onClick, onDeleteClick }) => (
  <DivWrapper>
    <DivHeaderItemWrapper>
      <DivItem>
        {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_DURATIONS}`)}
      </DivItem>
      <DivItem>
        {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MEMBERS}`)}
      </DivItem>
      <DivItem>
        {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_INTERN}`)}
      </DivItem>
      <DivItem>
        {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_INSTRUCTOR}`)}
      </DivItem>
      <DivItem>
        {capitalizeFirstLetter(
          `${
            isDanone()
              ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
              : i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_PLACE
          }`
        )}
      </DivItem>
      <DivItem>
        {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_DATE}`)}
      </DivItem>
      <div style={{ width: '35%' }} />
    </DivHeaderItemWrapper>
    {evalData(data).map(capacitation => (
      <PreviewWrapper key={`${capacitation.id}-capacitation`}>
        <ButtonCapacitation
          pointer={onClick ? true : false}
          onClick={e => (onClick ? onClick(e, capacitation) : '')}
        >
          <DivItemWrapper>
            <DivItem isTableItem>{capacitation.realDuration}Hs</DivItem>
            <DivItem isTableItem>{capacitation.quantityOfEmployees}</DivItem>
            <DivItem isTableItem>
              {capacitation.isInternal
                ? capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_AFFIRMATIVE}`
                  )
                : capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_NEGATIVE}`
                  )}
            </DivItem>
            <DivItem isTableItem>
              {capitalizeFirstLetter(allToLower(capacitation.instructor.lastName))}
            </DivItem>
            <DivItem isTableItem>
              {capitalizeFirstLetter(allToLower(capacitation.distributionCenter.name))}
            </DivItem>
            <DivItem isTableItem>{getDate(capacitation.realizationDate)}</DivItem>
          </DivItemWrapper>
        </ButtonCapacitation>
        {getCompanyById(capacitation.companyId) ? (
          <ButtonDelete onClick={e => onDeleteClick(e, capacitation)}>
            <DeleteIcon />
          </ButtonDelete>
        ) : (
          <ButtonEmptyDelete />
        )}
      </PreviewWrapper>
    ))}
    <Pagination total={getTotal(data)} isCapacitation={true} />
  </DivWrapper>
)

export default CapacitationPreview

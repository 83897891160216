import i18n from 'services/i18n'
import { capitalizeFirstLetter } from 'utils/strings'

export const MENU_HEADER_LINKS = [
  {
    label: capitalizeFirstLetter(i18n('PAGE_COURSES')),
    link: i18n('PAGE_COURSES')
  },
  {
    label: capitalizeFirstLetter(i18n('PAGE_USERS')),
    link: i18n('PAGE_USERS')
  },
  {
    label: capitalizeFirstLetter(i18n('PAGE_DASHBOARD')),
    link: `${i18n('PAGE_DASHBOARD')}`
  }
]

export const ADMINISTRATOR_MENU_HEADER_LINKS = [
  {
    label: capitalizeFirstLetter(i18n('PAGE_COURSES')),
    link: i18n('PAGE_COURSES')
  },
  {
    label: capitalizeFirstLetter(i18n('PAGE_USERS')),
    link: i18n('PAGE_USERS')
  }
]

export const INSTRUCTOR_MENU_HEADER_LINKS = [
  {
    label: capitalizeFirstLetter(i18n('PAGE_COURSES')),
    link: i18n('PAGE_COURSES')
  },
  {
    label: capitalizeFirstLetter(i18n('PAGE_DASHBOARD')),
    link: `${i18n('PAGE_DASHBOARD')}`
  }
]

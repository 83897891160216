import { handleActions } from 'redux-actions'
import { picturesPopupStatus } from 'actions/picturesPopup'

const defaultState = {
  open: false,
  item: null
}

const reducer = handleActions(
  {
    [picturesPopupStatus]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => {
      return status
    }
  },
  defaultState
)

export default reducer

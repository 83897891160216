import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'

const getColorDarkGrey = ({ theme: { colors } }) => colors.darkGrey
const borderLightGrey = ({ theme }) => `1px solid  ${theme.colors.lightGrey}`
const backgroundColor = ({ theme, status }) =>
  status
    ? status === 'Success'
      ? theme.colors.complianceGreen
      : status === 'Warning'
      ? theme.colors.complianceYellow
      : theme.colors.complianceRed
    : ''

export const TableContainer = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  color: ${getColorDarkGrey}
  height: auto;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border: ${borderLightGrey};
  font-size:12.8px;
`

export const TableRow = styled.tr`
  :nth-child(2n + 0) {
    background-color: ${({ theme }) => transparentize(0.5, theme.colors.grey)};
  }
  > td {
    ${({ theme, status }) => {
      let result = ''
      const color = backgroundColor({ theme, status })
      if (color) {
        result = transparentize(0.9, color)
      }
      return result ? `background-color: ${result}` : ''
    }};
  }
`

export const TableCell = styled(({ isHeader, children, ...props }) => {
  if (isHeader) {
    return React.createElement('th', props, children)
  } else {
    return React.createElement('td', props, children)
  }
})`
  padding: 5px;
  border: ${borderLightGrey};
  ${({ theme, isHeader }) => (isHeader ? `background: ${theme.colors.grey};` : '')}
  // :not(:first-child) {
  //   text-align: center;
  // }
  // :not(:first-child):not(:last-child) {
  //   width: 160px;
  // }
  :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  :last-child {
    width: 100px;
  }
`

export const ComplianceWrapper = styled.div`
  position: relative;
  height: 13px;
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 2px;
  text-align: center;
`

export const ComplianceGraph = styled.div`
  width: ${({ row }) => row}%;
  height: 100%;
  background-color: ${backgroundColor}
  border-radius: 2px;
  transition: all 0.5s ease-out;
`

export const PercentageLabel = styled.label`
  position: absolute;
  right: 0;
  left: 0;
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  color: ${({ theme, row }) => (row > 50 ? theme.colors.white : theme.colors.black)};
`

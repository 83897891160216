import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import theme from 'config/theme'
import {
  DivWrapper,
  DivWrapperData,
  DivWrapperEdit,
  DivHeaderItemWrapper,
  DivItem,
  NoItemsText,
  EmployeeTypeText,
  StyledImgIcon,
  StyledButton,
  DivWrapperError,
  DivWrapperSaveButton
} from './styled'
import i18n from 'services/i18n'
import { capitalizeFirstLetter } from 'utils/strings'
import isDanone from 'utils/isDanone'
import { isGeneralAdmin } from 'utils/session'

import RenderCapacitationTable from 'components/CapacitationTable'
import CapacitationTableHeader from 'components/CapacitationTableHeader'
import ButtonBack from 'components/ButtonBack'
import ButtonMaterial from 'components/ButtonMaterial'
import Loader from 'components/Loaders/propagate'

import iconPersonBlue from 'assets/icons/iconPersonBlue.png'
import iconFileBlue from 'assets/icons/iconFileBlue.png'
import iconTruckBlue from 'assets/icons/iconTruckBlue.png'

import { modifyCapacitation } from 'actions/capacitations'
import { setPicturesPopupStatus } from 'actions/picturesPopup'
import { setDisplayToast } from 'actions/toast'
import { fetchCapacitationsById } from 'actions/capacitations'

import EditIcon from '@material-ui/icons/Edit'

import popupKeys from 'constants/popupKeys'
import toastTypes from 'constants/toastKeys'

class CapacitationTable extends Component {
  state = {
    isEditing: false,
    noteEditValues: {},
    noteHasEmptyValues: false
  }

  componentDidMount = () => {
    this.props.fetchCapacitationsById(this.props.selectedCapacitation.id)
  }

  handleEditNote = () => {
    this.setState({ isEditing: this.state.isEditing ? false : true })
  }

  handleNote = (id, value) => {
    this.setState({ noteEditValues: { ...this.state.noteEditValues, [id]: value } }, () =>
      this.setState({ noteHasEmptyValues: this.hasEmptyValues() })
    )
  }

  handleSave = async (e, selectedCapacitation) => {
    await this.props.modifyCapacitation(
      selectedCapacitation,
      this.state.noteEditValues,
      popupKeys.CAPACITATION_NOTE
    )
    !this.props.capacitations.error && this.props.onClose(e, selectedCapacitation)
    this.props.setDisplayToast({ isShowing: true, type: toastTypes.TOAST_TYPE_ADD })
  }

  valuesHasChanged = () => (Object.keys(this.state.noteEditValues).length ? false : true)

  hasEmptyValues = () => {
    return Object.values(this.state.noteEditValues).reduce(
      (accum, actual) => accum && actual !== '',
      true
    )
  }

  hasEmployees = selectedCapacitation =>
    !selectedCapacitation.directEmployees.length &&
    !selectedCapacitation.indirectEmployees.length &&
    !selectedCapacitation.carrierEmployees.length

  handlePics = item => {
    this.props.setPicturesPopupStatus({ open: true, item: item })
  }

  render() {
    const { onClose, selectedCapacitation } = this.props
    const { valuesById, isFetchingById, errorById } = this.props.capacitations
    return (
      <Fragment>
        <ButtonBack onClose={onClose} />
        <CapacitationTableHeader
          data={selectedCapacitation}
          employeesData={valuesById}
          loadingEmployeesData={isFetchingById}
        />
        {isFetchingById ? (
          <Loader />
        ) : (
          <DivWrapper>
            <DivHeaderItemWrapper>
              <DivItem>
                {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_PICTURES}`)}
              </DivItem>
              <DivItem>
                {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_EMPLOYEE}`)}
              </DivItem>
              <DivItem>
                {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_ID}`)}
              </DivItem>
              <DivItem>
                {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_FILE_NUMBER}`)}
              </DivItem>
              <DivItem>
                {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_ACCOUNT_NUMBER}`)}
              </DivItem>
              <DivItem>
                {capitalizeFirstLetter(
                  `${i18n('CAPACITATION').CAPACITATION_TABLE_DISTRIBUTION_NUMBER}`
                )}
              </DivItem>
              <DivItem>
                {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_SECTOR}`)}
              </DivItem>
              <DivItem>
                {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_RANK}`)}
              </DivItem>
              <DivItem>{capitalizeFirstLetter(`${i18n('CAPACITATION').APPROVED}`)}</DivItem>
            </DivHeaderItemWrapper>
            <DivWrapperData>
              <EmployeeTypeText>
                <StyledImgIcon src={iconPersonBlue} />
                <DivWrapperEdit>
                  {capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_TABLE_DIRECT_EMPLOYEE}`
                  )}
                  {valuesById && !this.hasEmployees(valuesById) && !isGeneralAdmin() ? (
                    <StyledButton
                      isEditing={this.state.isEditing}
                      onClick={() => this.handleEditNote()}
                    >
                      <EditIcon />
                    </StyledButton>
                  ) : (
                    ''
                  )}
                </DivWrapperEdit>
              </EmployeeTypeText>
              {valuesById && valuesById.directEmployees.length ? (
                valuesById.directEmployees.map((dEmployee, index) => (
                  <RenderCapacitationTable
                    items={dEmployee}
                    cantItems={valuesById.directEmployees.length}
                    index={index}
                    key={dEmployee.id}
                    isEditing={this.state.isEditing}
                    handleNote={(id, value) => this.handleNote(id, value)}
                    noteEditValues={this.state.noteEditValues}
                    handlePics={item => this.handlePics(item)}
                  />
                ))
              ) : (
                <NoItemsText>
                  {capitalizeFirstLetter(
                    `${i18n('CAPACITATION').NO_EMPLOYEES.NO_DIRECT_EMPLOYEES}`
                  )}
                </NoItemsText>
              )}
              {!isDanone() && (
                <>
                  <EmployeeTypeText>
                    <StyledImgIcon src={iconFileBlue} />
                    {capitalizeFirstLetter(
                      `${i18n('CAPACITATION').CAPACITATION_TABLE_CARRIER_EMPLOYEE}`
                    )}
                  </EmployeeTypeText>
                  {valuesById && valuesById.carrierEmployees.length ? (
                    valuesById.carrierEmployees.map((cEmployee, index) => (
                      <RenderCapacitationTable
                        items={cEmployee}
                        cantItems={valuesById.carrierEmployees.length}
                        index={index}
                        key={cEmployee.id}
                        isEditing={this.state.isEditing}
                        handleNote={(id, value) => this.handleNote(id, value)}
                        noteEditValues={this.state.noteEditValues}
                        handlePics={item => this.handlePics(item)}
                      />
                    ))
                  ) : (
                    <NoItemsText>
                      {capitalizeFirstLetter(
                        `${i18n('CAPACITATION').NO_EMPLOYEES.NO_CARRIER_EMPLOYEES}`
                      )}
                    </NoItemsText>
                  )}
                </>
              )}

              <EmployeeTypeText>
                <StyledImgIcon src={iconTruckBlue} bigg={true} />
                {capitalizeFirstLetter(
                  `${i18n('CAPACITATION').CAPACITATION_TABLE_INDIRECT_EMPLOYEE}`
                )}
              </EmployeeTypeText>
              {valuesById && valuesById.indirectEmployees.length ? (
                valuesById.indirectEmployees.map((iEmployee, index) => (
                  <RenderCapacitationTable
                    items={iEmployee}
                    cantItems={valuesById.indirectEmployees.length}
                    index={index}
                    key={iEmployee.id}
                    isEditing={this.state.isEditing}
                    handleNote={(id, value) => this.handleNote(id, value)}
                    noteEditValues={this.state.noteEditValues}
                    handlePics={item => this.handlePics(item)}
                  />
                ))
              ) : (
                <NoItemsText>
                  {capitalizeFirstLetter(
                    `${i18n('CAPACITATION').NO_EMPLOYEES.NO_INDIRECT_EMPLOYEES}`
                  )}
                </NoItemsText>
              )}
            </DivWrapperData>
            {errorById && (
              <DivWrapperError>
                {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR}`)}
              </DivWrapperError>
            )}
            <DivWrapperSaveButton>
              <ButtonMaterial
                background={
                  this.valuesHasChanged() || !this.state.noteHasEmptyValues
                    ? theme.colors.darkGrey
                    : theme.colors.purple
                }
                color={theme.colors.white}
                fontSize={'16'}
                display={this.state.isEditing}
                loading={this.props.capacitations.isSubmiting}
                small
                onClick={e => this.handleSave(e, valuesById)}
                disabled={this.valuesHasChanged() || !this.state.noteHasEmptyValues}
              >
                {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_SAVE}`)}
              </ButtonMaterial>
            </DivWrapperSaveButton>
          </DivWrapper>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = ({ capacitations, selectedFilter }) => {
  return {
    capacitations,
    selectedFilter
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      modifyCapacitation,
      setPicturesPopupStatus,
      setDisplayToast,
      fetchCapacitationsById
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CapacitationTable)

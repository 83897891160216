import { createActions } from 'redux-actions'

import { modifyCategoriesReset } from 'actions/categories'
import { modifyCourseReset } from 'actions/courses'
import { modifyCapacitationReset } from 'actions/capacitations'
import { modifyDirectEmployeeReset } from 'actions/directEmployee'
import { modifyCarrierEmployeeReset } from 'actions/carrierEmployee'
import { modifyIndirectEmployeeReset } from 'actions/indirectEmployee'
import { modifyCenterReset } from 'actions/centers'
import { modifyLeaderReset } from 'actions/leaders'
import { modifyLeaderUserReset } from 'actions/leaderUsers'
import { modifyInstructorReset } from 'actions/instructors'
import { modifyAdministratorReset } from 'actions/administrators'
import { setStaffIdReset } from 'actions/directEmployeeStaffId'

const { editPopupStatus } = createActions({
  EDIT_POPUP_STATUS: data => ({ data })
})

const setEditPopupStatus = status => dispatch => dispatch(editPopupStatus({ status }))

const resetEditPopupErrors = () => dispatch => {
  dispatch(modifyCategoriesReset())
  dispatch(modifyCourseReset())
  dispatch(modifyCapacitationReset())
  dispatch(modifyDirectEmployeeReset())
  dispatch(modifyCarrierEmployeeReset())
  dispatch(modifyIndirectEmployeeReset())
  dispatch(modifyCenterReset())
  dispatch(modifyLeaderReset())
  dispatch(modifyLeaderUserReset())
  dispatch(modifyInstructorReset())
  dispatch(modifyAdministratorReset())
  dispatch(setStaffIdReset())
}

export { setEditPopupStatus, resetEditPopupErrors, editPopupStatus }

import { createActions } from 'redux-actions'

const { selectedUserStatus, resetUserStatus } = createActions({
  RESET_USER_STATUS: () => {},
  SELECTED_USER_STATUS: data => ({ data })
})

const setSelectedUserStatus = status => dispatch => dispatch(selectedUserStatus({ status }))
const resetSelectedUserStatus = () => dispatch => dispatch(resetUserStatus())

export { setSelectedUserStatus, selectedUserStatus, resetSelectedUserStatus, resetUserStatus }

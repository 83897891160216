import { createActions } from 'redux-actions'

const { expandedUserType, resetExpanded } = createActions({
  RESET_EXPANDED: () => {},
  EXPANDED_USER_TYPE: data => ({ data })
})

const setExpandedUserType = status => dispatch => dispatch(expandedUserType({ status }))
const resetExpandedUserType = () => dispatch => dispatch(resetExpanded())

export { setExpandedUserType, expandedUserType, resetExpandedUserType, resetExpanded }

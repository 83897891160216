import popupKeys from 'constants/popupKeys'
import i18n from 'services/i18n'

export default [
  {
    id: popupKeys.EMPLOYEE.DIRECT,
    name: `${i18n('CAPACITATION').CAPACITATION_TABLE_DIRECT_EMPLOYEE}`
  },
  {
    id: popupKeys.EMPLOYEE.INDIRECT,
    name: `${i18n('CAPACITATION').CAPACITATION_TABLE_INDIRECT_EMPLOYEE}`
  },
  {
    id: popupKeys.EMPLOYEE.CARRIER,
    name: `${i18n('CAPACITATION').CAPACITATION_TABLE_CARRIER_EMPLOYEE}`
  }
]

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchCoursesByEmployeeId, resetCoursesByEmployeeId } from 'actions/courses'
import { fetchExcel } from 'actions/excelDownload'
import { setSelectedCapacitation } from 'actions/selectedCapacitation'
import { setCapacitationDetailStatus } from 'actions/capacitationDetailStatus'
import { setSelectedSearchedItem, resetSelectedSearchedItem } from 'actions/selectedSearchedItem'
import { setEditPopupStatus } from 'actions/editPopup'
import { setDeletePopupStatus } from 'actions/deletePopup'

import { fetchCapacitationsByFilter, resetCapacitationsByFilter } from 'actions/capacitations'

import { capitalizeFirstLetter, allToLower } from 'utils/strings'
import isDanone from 'utils/isDanone'

import i18n from 'services/i18n'
import {
  DivWrapperEmployeeData,
  DivWrapper,
  DivWrapperItems,
  StyledText,
  DivWrapperError,
  DivWrapperLoader
} from './styled'

import Loader from 'components/Loaders/propagate'
import MaterialExpansionPanel from 'components/ExpansionPanel'
import CapacitationPreview from 'components/CapacitationPreview'
import Pagination from 'components/Pagination'

import { paginate } from 'utils/paginator'

import filterKeys from 'constants/filterKeys'
import popupKeys from 'constants/popupKeys'

class SearchDisplayEmployee extends Component {
  state = {
    currentCapacitation: 1,
    currentPage: 1
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.selectedPage.capacitationPage !== this.props.selectedPage.capacitationPage &&
      this.checkFilterCategory(this.props.selectedFilter.filterCategory)
    ) {
      this.getCapacitationsByEmployeeId(this.props.selectedSearchedItem.expandedItem)
    }
  }

  checkFilterCategory = filter =>
    filter === filterKeys.EMPLOYEE_DIRECT.DIRECT ||
    filter === filterKeys.EMPLOYEE_CARRIER.CARRIER ||
    filter === filterKeys.EMPLOYEE_INDIRECT.INDIRECT

  whichIsShowing = () => {
    if (this.props.directEmployee.isShowing) {
      return { values: this.wichValues(this.props.directEmployee), type: popupKeys.EMPLOYEE.DIRECT }
    } else if (this.props.carrierEmployee.isShowing) {
      return {
        values: this.wichValues(this.props.carrierEmployee),
        type: popupKeys.EMPLOYEE.CARRIER
      }
    } else {
      return {
        values: this.wichValues(this.props.indirectEmployee),
        type: popupKeys.EMPLOYEE.INDIRECT
      }
    }
  }

  wichValues = employee => (employee.values ? employee.values : this.hasCapacitations(employee))

  hasCapacitations = employee => {
    if (
      this.props.selectedDateFilter.filterDateFrom &&
      this.props.selectedDateFilter.filterDateTo &&
      employee.valuesByDate
    ) {
      const test = employee.valuesByDate.filter(e => e.hasTrainings)
      return { items: test, total: test.length }
    } else {
      return employee.valuesAll
    }
  }

  isFetching = () =>
    this.props.directEmployee.isFetching ||
    this.props.carrierEmployee.isFetching ||
    this.props.indirectEmployee.isFetching ||
    this.props.directEmployee.isFetchingByDate ||
    this.props.directEmployee.isFetchingAll ||
    this.props.carrierEmployee.isFetchingByDate ||
    this.props.carrierEmployee.isFetchingAll ||
    this.props.indirectEmployee.isFetchingByDate ||
    this.props.indirectEmployee.isFetchingAll

  isCourseExpanded = id => this.props.selectedSearchedItem.expandedItem === id

  thereAreValues = () =>
    this.props.directEmployee.values ||
    this.props.carrierEmployee.values ||
    this.props.indirectEmployee.values ||
    this.props.directEmployee.valuesByDate ||
    this.props.directEmployee.valuesAll ||
    this.props.carrierEmployee.valuesByDate ||
    this.props.carrierEmployee.valuesAll ||
    this.props.indirectEmployee.valuesByDate ||
    this.props.indirectEmployee.valuesAll

  getCapacitationsByEmployeeId = id => {
    if (this.isCourseExpanded(id)) {
      this.props.fetchCapacitationsByFilter(filterKeys.EMPLOYEE, id)
    }
  }

  setSelectedEmployee = id => {
    if (this.isCourseExpanded(id)) {
      this.props.resetSelectedSearchedItem()
    } else {
      this.props.setSelectedSearchedItem({ id })
    }
  }

  wichEmployeeWasSelected = id => this.state.selectedEmployeeDownload === id

  handleEmployee = id => {
    this.props.resetSelectedSearchedItem()
    this.props.resetCoursesByEmployeeId()
    this.props.resetCapacitationsByFilter(1)
    this.setSelectedEmployee(id)
    setTimeout(() => {
      this.getCapacitationsByEmployeeId(id)
    }, 80)
  }

  fetchExcel = (e, id, type, fileName) => {
    this.setState({ selectedEmployeeDownload: id })
    this.props.fetchExcel(id, type, fileName)
    e.stopPropagation()
  }

  handleCapacitationDetail = (e, capacitation) => {
    e.stopPropagation()
    this.props.setSelectedCapacitation(capacitation)
    this.props.setCapacitationDetailStatus(
      this.props.capacitationDetailStatus.status ? false : true
    )
  }

  handleEditPopup = (e, item, type) => {
    this.props.setEditPopupStatus({ open: true, itemToEdit: item, type: type })
    e.stopPropagation()
  }

  handleDelete = (e, item, type) => {
    this.props.setDeletePopupStatus({ open: true, itemToDelete: item, type: type })
    e.stopPropagation()
  }

  onChangePaginationCapacitation = page => {
    this.setState({
      currentCapacitation: page
    })
  }

  onChangePagination = page => {
    this.setState({
      currentPage: page
    })
  }

  hasDateFilter = () =>
    this.props.selectedDateFilter.filterDateFrom && this.props.selectedDateFilter.filterDateTo

  searchedEmployeesDisplay = item => (
    <MaterialExpansionPanel
      key={item.id}
      name={item.firstName}
      surname={item.lastName}
      id={item.id}
      expanded={this.isCourseExpanded(item.id)}
      onClick={() => this.handleEmployee(item.id)}
      withDownload={true}
      onButtonDownloadClick={e => this.fetchExcel(e, item.id, filterKeys.EMPLOYEE, item.lastName)}
      buttonDownloadLoading={
        this.props.excelDownload.isFetching && this.wichEmployeeWasSelected(item.id)
      }
      withEdit
      onButtonEditClick={e => this.handleEditPopup(e, item, this.whichIsShowing().type)}
      withDelete
      onButtonDeleteClick={e => this.handleDelete(e, item, this.whichIsShowing().type)}
    >
      <div>
        <DivWrapper>
          <DivWrapperEmployeeData>
            <StyledText>
              {item.fileNumber
                ? `${capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_TABLE_FILE_NUMBER}`
                  )}: ${item.fileNumber}`
                : ''}
            </StyledText>
            <StyledText>
              {item.category
                ? `${capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY}`
                  )}: ${capitalizeFirstLetter(allToLower(item.category))}`
                : ''}
            </StyledText>
            <StyledText>
              {item.leader
                ? `${capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`
                  )}: ${capitalizeFirstLetter(allToLower(item.leader.name))}`
                : ''}
            </StyledText>
            <StyledText>
              {item.documentNumber
                ? `${capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_ID}`)}: ${
                    item.documentNumber
                  }`
                : ''}
            </StyledText>
            <StyledText>
              {item.effectiveStaffId
                ? `${capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_EMPLOYEE_ID}`)}: ${
                    item.effectiveStaffId
                  }`
                : ''}
            </StyledText>
          </DivWrapperEmployeeData>
          <DivWrapperEmployeeData>
            <StyledText>
              {item.distributionCenter
                ? `${capitalizeFirstLetter(
                    `${
                      isDanone()
                        ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
                        : i18n('CAPACITATION').CAPACITATION_TABLE_DISTRIBUTION_CENTER
                    }`
                  )}: ${capitalizeFirstLetter(allToLower(item.distributionCenter.name))}`
                : ''}
            </StyledText>
            <StyledText>
              {item.workSector
                ? `${capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_TABLE_SECTOR}`
                  )}: ${capitalizeFirstLetter(allToLower(item.workSector.name))}`
                : ''}
            </StyledText>
            <StyledText>
              {item.workPosition
                ? `${capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_TABLE_RANK}`
                  )}: ${capitalizeFirstLetter(allToLower(item.workPosition.name))}`
                : ''}
            </StyledText>

            <StyledText>
              {item.accountNumber
                ? `${capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_TABLE_ACCOUNT_NUMBER}`
                  )}: ${item.accountNumber}`
                : ''}
            </StyledText>
            <StyledText>
              {item.distributionNumber
                ? `${capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_TABLE_DISTRIBUTION_NUMBER}`
                  )}: ${item.distributionNumber}`
                : ''}
            </StyledText>
            <StyledText>
              {item.companyName
                ? `${capitalizeFirstLetter(`${i18n('SELECTOR').BUSINESS_INPUT}`)}: ${
                    item.companyName
                  }`
                : ''}
            </StyledText>
          </DivWrapperEmployeeData>
        </DivWrapper>

        {this.props.capacitations.isFetching ? (
          <DivWrapperLoader>
            <Loader />
          </DivWrapperLoader>
        ) : this.props.capacitations.values && this.props.capacitations.values.items.length ? (
          <CapacitationPreview
            onClick={(e, capacitation) => this.handleCapacitationDetail(e, capacitation)}
            data={this.props.capacitations.values ? this.props.capacitations : ''}
            onDeleteClick={(e, capacitation) =>
              this.handleDelete(e, capacitation, popupKeys.CAPACITATION)
            }
            onChangePaginationCapacitation={this.onChangePaginationCapacitation}
            currentCapacitation={this.state.currentCapacitation}
          />
        ) : (
          <DivWrapperError>
            {capitalizeFirstLetter(`${i18n('HOME').HOME_PAGE_NO_CAPACITATIONS}`)}
          </DivWrapperError>
        )}
      </div>
    </MaterialExpansionPanel>
  )

  render() {
    if (this.isFetching()) {
      return <Loader />
    } else {
      return this.thereAreValues() ? (
        this.whichIsShowing().values.items.length ? (
          <DivWrapperItems>
            {this.hasDateFilter() ? (
              <>
                {paginate(this.state.currentPage, this.whichIsShowing().values.items).map(item =>
                  this.searchedEmployeesDisplay(item)
                )}
                <Pagination
                  total={this.whichIsShowing().values.total}
                  isLocal={true}
                  onChangeLocal={this.onChangePagination}
                  selectedPageLocal={this.state.currentPage}
                />
              </>
            ) : (
              <>
                {this.whichIsShowing().values.items.map(item =>
                  this.searchedEmployeesDisplay(item)
                )}
                <Pagination total={this.whichIsShowing().values.total} />
              </>
            )}
          </DivWrapperItems>
        ) : (
          <DivWrapperError>
            {capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_NO_DATA}`)}
          </DivWrapperError>
        )
      ) : (
        <DivWrapperError>{capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_ERROR}`)}</DivWrapperError>
      )
    }
  }
}

const mapStateToProps = ({
  directEmployee,
  carrierEmployee,
  indirectEmployee,
  courses,
  excelDownload,
  capacitationDetailStatus,
  selectedSearchedItem,
  selectedPage,
  selectedDateFilter,
  capacitations,
  selectedFilter
}) => ({
  directEmployee,
  carrierEmployee,
  indirectEmployee,
  courses,
  excelDownload,
  capacitationDetailStatus,
  selectedSearchedItem,
  selectedPage,
  selectedDateFilter,
  capacitations,
  selectedFilter
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCoursesByEmployeeId,
      resetCoursesByEmployeeId,
      fetchExcel,
      setSelectedCapacitation,
      setCapacitationDetailStatus,
      setSelectedSearchedItem,
      resetSelectedSearchedItem,
      setEditPopupStatus,
      setDeletePopupStatus,
      fetchCapacitationsByFilter,
      resetCapacitationsByFilter
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDisplayEmployee)

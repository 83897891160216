import React from 'react'

import i18n from 'services/i18n'
import { allToCaps, allToLower, capitalizeFirstLetter } from 'utils/strings'

import {
  StyledSelect,
  StyledDivItemWrapper,
  StyledDivItem,
  StyledImagesButton,
  StyledImg,
  NoImage
} from './styled'

import { base64ToFile } from 'utils/imageParser'

const Na = allToCaps(`${i18n('CAPACITATION').CAPACITATION_TABLE_NOT_APPLAY}`)

const stylizeText = text => capitalizeFirstLetter(allToLower(text))

const RenderCapacitationTable = ({
  items,
  cantItems,
  index,
  isEditing,
  handleNote,
  noteEditValues,
  handlePics
}) => (
  <StyledDivItemWrapper cantItems={cantItems} index={index}>
    <StyledDivItem>
      <StyledImagesButton
        clickable={items.employeePhoto}
        onClick={() =>
          items.employeePhoto
            ? handlePics({ employeeImg: items.employeePhoto, employeeName: items.employee.name })
            : ''
        }
      >
        {items.employeePhoto ? (
          <StyledImg src={base64ToFile(items.employeePhoto)} />
        ) : (
          <NoImage>{capitalizeFirstLetter(`${i18n('PIC_POPUP').NO_IMG}`)}</NoImage>
        )}
      </StyledImagesButton>
    </StyledDivItem>
    <StyledDivItem>
      {`${items.employee.firstName ? stylizeText(items.employee.firstName) : Na}
      ${items.employee.lastName ? stylizeText(items.employee.lastName) : Na}`}
    </StyledDivItem>
    <StyledDivItem>
      {items.employee.documentNumber ? items.employee.documentNumber : Na}
    </StyledDivItem>
    <StyledDivItem>{items.employee.fileNumber ? items.employee.fileNumber : Na}</StyledDivItem>
    <StyledDivItem>
      {items.employee.accountNumber ? items.employee.accountNumber : Na}
    </StyledDivItem>
    <StyledDivItem>
      {items.employee.distributionNumber ? items.employee.distributionNumber : Na}
    </StyledDivItem>
    <StyledDivItem>
      {items.employee.workSector ? stylizeText(items.employee.workSector.name) : Na}
    </StyledDivItem>
    <StyledDivItem>
      <StyledImagesButton
        clickable={items.employeeSignature}
        onClick={() =>
          items.employeeSignature
            ? handlePics({
                employeeImg: items.employeeSignature,
                employeeName: items.employee.name
              })
            : ''
        }
      >
        {items.employeeSignature ? (
          <StyledImg src={base64ToFile(items.employeeSignature)} />
        ) : (
          <NoImage>{capitalizeFirstLetter(`${i18n('PIC_POPUP').NO_IMG}`)}</NoImage>
        )}
      </StyledImagesButton>
    </StyledDivItem>
    <StyledDivItem>
      <StyledSelect
        isEditing={isEditing}
        disabled={!isEditing}
        onChange={({ target: value }) => {
          handleNote(items.employee.id, value.value)
        }}
        value={
          noteEditValues[items.employee.id] !== undefined
            ? noteEditValues[items.employee.id]
            : items.note
        }
      >
        <option value="" style={{ display: 'none' }} />
        <option value="A">{i18n('CAPACITATION').APPROVED}</option>
        <option value="D">{i18n('CAPACITATION').DISAPPROVED}</option>
      </StyledSelect>
    </StyledDivItem>
  </StyledDivItemWrapper>
)

export default RenderCapacitationTable

import { createActions } from 'redux-actions'
import dayjs from 'dayjs'

import getApi from 'services/api'

import API_ROUTES from 'config/apiRoutes'
import pagerConfig from 'config/pagerConfig'

import filterKeys from 'constants/filterKeys'
import popupKeys from 'constants/popupKeys'

import { getSimpleDate } from 'utils/date/dateParser'

import { isInstructor, getUserId } from 'utils/session'

const {
  fetchCapacitationsByIdStart,
  fetchCapacitationsByIdSuccess,
  fetchCapacitationsByIdError,
  capacitationsByIdReset,

  fetchCapacitationsByFilterStart,
  fetchCapacitationsByFilterSuccess,
  fetchCapacitationsByFilterError,
  capacitationsByFilterReset,

  modifyCapacitationStart,
  modifyCapacitationSuccess,
  modifyCapacitationError,
  modifyCapacitationReset,

  deleteCapacitationStart,
  deleteCapacitationSuccess,
  deleteCapacitationError,
  deleteCapacitationErrorReset
} = createActions({
  FETCH_CAPACITATIONS_BY_ID_START: () => {},
  FETCH_CAPACITATIONS_BY_ID_SUCCESS: data => ({ data }),
  FETCH_CAPACITATIONS_BY_ID_ERROR: error => ({ error }),
  CAPACITATIONS_BY_ID_RESET: () => {},

  FETCH_CAPACITATIONS_BY_FILTER_START: () => {},
  FETCH_CAPACITATIONS_BY_FILTER_SUCCESS: data => ({ data }),
  FETCH_CAPACITATIONS_BY_FILTER_ERROR: error => ({ error }),
  CAPACITATIONS_BY_FILTER_RESET: () => {},

  MODIFY_CAPACITATION_START: () => {},
  MODIFY_CAPACITATION_SUCCESS: () => {},
  MODIFY_CAPACITATION_ERROR: error => ({ error }),
  MODIFY_CAPACITATION_RESET: () => {},

  DELETE_CAPACITATION_START: () => {},
  DELETE_CAPACITATION_SUCCESS: () => {},
  DELETE_CAPACITATION_ERROR: error => ({ error }),
  DELETE_CAPACITATION_ERROR_RESET: () => {}
})

const fetchCapacitationsById = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchCapacitationsByIdStart())
    try {
      const response = await api.get(`${API_ROUTES.PUT_CAPACITATION}/${id}`)
      dispatch(fetchCapacitationsByIdSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCapacitationsByIdError(error))
    }
  }
}

const resetCapacitationsById = () => dispatch => dispatch(capacitationsByIdReset())

const fetchCapacitationsByFilter = (type, id) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCapacitationsByFilterStart())

    let { selectedDateFilter, selectedFilter, selectedPage } = getState()
    const filterDateFrom =
      selectedDateFilter.filterDateFrom &&
      selectedFilter.filterCategory &&
      selectedFilter.filterItem
        ? dayjs(selectedDateFilter.filterDateFrom)
        : null
    const filterDateTo =
      selectedDateFilter.filterDateTo && selectedFilter.filterCategory && selectedFilter.filterItem
        ? dayjs(selectedDateFilter.filterDateTo)
        : null
    const body = {
      Filters: [],
      Sort: null,
      Pager: { PageSize: pagerConfig.PAGE_SIZE, PageNumber: selectedPage.capacitationPage },
      DateFrom: filterDateFrom ? getSimpleDate(filterDateFrom) : null,
      DateTo: filterDateTo ? getSimpleDate(filterDateTo) : null,
      Courses: type === filterKeys.COURSE.COURSE_ID ? [id] : [],
      Instructors: isInstructor() ? [getUserId()] : [],
      DistributionCenters: type === filterKeys.CENTER.CENTER_ID ? [id] : [],
      Employees: type === filterKeys.EMPLOYEE ? [id] : [],
      Leaders: type === filterKeys.LEADER.LEADER_ID ? [id] : []
    }
    try {
      const response = await api.post(API_ROUTES.GET_CAPACITATIONS_BY_COURSE_ID, body)
      dispatch(fetchCapacitationsByFilterSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCapacitationsByFilterError(error))
    }
  }
}

const resetCapacitationsByFilter = () => dispatch => dispatch(capacitationsByFilterReset())

const retrieveEmployee = (employeeArray, editValues) =>
  employeeArray.map(employee => {
    employee.note = editValues[employee.employee.id] || employee.note
    return employee
  })
const hasData = type => type === popupKeys.CAPACITATION_DATA
const modifyCapacitation = (selectedCapacitation, editValues, type) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyCapacitationStart())

    const body = {
      course: hasData(type)
        ? { category: editValues.category, id: editValues.course.id, name: editValues.course.name }
        : selectedCapacitation.course,
      distributionCenter: selectedCapacitation.distributionCenter,
      instructor: selectedCapacitation.instructor,
      code: selectedCapacitation.code,
      realDuration: hasData(type) ? editValues.duration : selectedCapacitation.realDuration,
      realizationDate: selectedCapacitation.realizationDate,
      isInternal: selectedCapacitation.isInternal,
      observations: hasData(type) ? editValues.observation : selectedCapacitation.observations,

      directEmployees: retrieveEmployee(selectedCapacitation.directEmployees, editValues),
      indirectEmployees: retrieveEmployee(selectedCapacitation.indirectEmployees, editValues),
      carrierEmployees: retrieveEmployee(selectedCapacitation.carrierEmployees, editValues)
    }

    try {
      const response = await api.put(
        `${API_ROUTES.PUT_CAPACITATION}/${selectedCapacitation.id}`,
        body
      )
      dispatch(modifyCapacitationSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyCapacitationError(error.data))
    }
  }
}

const resetModifyCapacitation = () => dispatch => dispatch(modifyCapacitationReset())

const deleteCapacitation = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteCapacitationStart())
    try {
      const response = await api.delete(`${API_ROUTES.PUT_CAPACITATION}/${id}`)
      dispatch(deleteCapacitationSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteCapacitationError(error.data))
    }
  }
}

const resetDeleteCapacitationError = () => dispatch => dispatch(deleteCapacitationErrorReset())

export {
  fetchCapacitationsById,
  fetchCapacitationsByIdStart,
  fetchCapacitationsByIdSuccess,
  fetchCapacitationsByIdError,
  capacitationsByIdReset,
  resetCapacitationsById,
  fetchCapacitationsByFilter,
  fetchCapacitationsByFilterStart,
  fetchCapacitationsByFilterSuccess,
  fetchCapacitationsByFilterError,
  resetCapacitationsByFilter,
  capacitationsByFilterReset,
  modifyCapacitation,
  modifyCapacitationStart,
  modifyCapacitationSuccess,
  modifyCapacitationError,
  modifyCapacitationReset,
  resetModifyCapacitation,
  deleteCapacitation,
  deleteCapacitationStart,
  deleteCapacitationSuccess,
  deleteCapacitationError,
  resetDeleteCapacitationError,
  deleteCapacitationErrorReset
}

import styled from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress'

export const PageWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
`

export const Loading = styled(LinearProgress)`
  opacity: ${({ loading }) => (loading ? 1 : 0)};
`

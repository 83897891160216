import { handleActions } from 'redux-actions'
import {
  legalStart,
  legalSuccess,
  legalError,
  legalReset,
} from 'actions/Dashboard/TrainingDetail/legal'

const defaultState = {
  success: false,
  values: [],
  total: 0,
  error: null,
  isLoading: false,
}

const reducer = handleActions(
  {
    [legalStart]: (state) => ({
      ...state,
      success: false,
      isLoading: true,
      error: [],
    }),
    [legalSuccess]: (state, { payload: { values, total } }) => ({
      ...state,
      success: true,
      values,
      total,
      isLoading: false,
    }),
    [legalError]: (state, { payload: { error } }) => ({
      ...state,
      success: false,
      isLoading: false,
      error,
    }),
    [legalReset]: () => ({ ...defaultState }),
  },
  defaultState
)

export default reducer

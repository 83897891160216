import { handleActions } from 'redux-actions'
import {
  fetchBusinessStart,
  fetchBusinessSuccess,
  fetchBusinessError,
  businessReset
} from 'actions/business'

const defaultState = {
  valuesAll: null,
  isFetchingAll: false,
  errorAll: null
}

const reducer = handleActions(
  {
    [fetchBusinessStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchBusinessSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchBusinessError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [businessReset]: () => defaultState
  },
  defaultState
)

export default reducer

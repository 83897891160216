import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

import { getUserInfo } from 'utils/session'

const {
  showingBusinessAdmin,

  fetchAdministratorsStart,
  fetchAdministratorsSuccess,
  fetchAdministratorsError,
  administratorsReset,

  enableOrDisableAdministratorsStart,
  enableOrDisableAdministratorsSuccess,
  enableOrDisableAdministratorsError,
  enableOrDisableAdministratorsReset,

  addAdministratorStart,
  addAdministratorSuccess,
  addAdministratorError,
  addAdministratorReset,

  deleteAdministratorStart,
  deleteAdministratorSuccess,
  deleteAdministratorError,
  deleteAdministratorErrorReset,

  modifyAdministratorStart,
  modifyAdministratorSuccess,
  modifyAdministratorError,
  modifyAdministratorReset
} = createActions({
  SHOWING_BUSINESS_ADMIN: data => ({ data }),

  FETCH_ADMINISTRATORS_START: () => {},
  FETCH_ADMINISTRATORS_SUCCESS: data => ({ data }),
  FETCH_ADMINISTRATORS_ERROR: error => ({ error }),
  ADMINISTRATORS_RESET: () => {},

  ENABLE_OR_DISABLE_ADMINISTRATORS_START: () => {},
  ENABLE_OR_DISABLE_ADMINISTRATORS_SUCCESS: () => {},
  ENABLE_OR_DISABLE_ADMINISTRATORS_ERROR: error => ({ error }),
  ENABLE_OR_DISABLE_ADMINISTRATORS_RESET: () => {},

  ADD_ADMINISTRATOR_START: () => {},
  ADD_ADMINISTRATOR_SUCCESS: data => ({ data }),
  ADD_ADMINISTRATOR_ERROR: error => ({ error }),
  ADD_ADMINISTRATOR_RESET: () => {},

  DELETE_ADMINISTRATOR_START: () => {},
  DELETE_ADMINISTRATOR_SUCCESS: () => {},
  DELETE_ADMINISTRATOR_ERROR: error => ({ error }),
  DELETE_ADMINISTRATOR_ERROR_RESET: () => {},

  MODIFY_ADMINISTRATOR_START: () => {},
  MODIFY_ADMINISTRATOR_SUCCESS: () => {},
  MODIFY_ADMINISTRATOR_ERROR: error => ({ error }),
  MODIFY_ADMINISTRATOR_RESET: () => {}
})
const setShowingBusinessAdmin = status => dispatch => dispatch(showingBusinessAdmin({ status }))

const fetchAdministrators = () => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchAdministratorsStart())
    const {
      selectedUserStatus,
      selectedUsersFilter: { inputNameValue, category },
      selectedFilter: { selectedBusiness }
    } = getState()

    const companyId = getUserInfo().companies
      ? getUserInfo().companies[0].id
      : selectedBusiness.value

    const body = {
      CompanyId: companyId,
      Name: inputNameValue ? inputNameValue : '',
      CategoryId: category ? category.id : 0
    }
    try {
      const response = await api.post(
        `${API_ROUTES.ADMINISTRATORS}/${selectedUserStatus.selected}`,
        body
      )
      dispatch(fetchAdministratorsSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchAdministratorsError(error))
    }
  }
}

const resetAdministrators = () => dispatch => dispatch(administratorsReset())

const enableOrDisableAdministrators = user => {
  return async dispatch => {
    const api = await getApi()
    dispatch(enableOrDisableAdministratorsStart())
    const enableOrDisable = user.Enable ? `enableAdministrator` : `disableAdministrator`
    try {
      await api.patch(`${API_ROUTES.ADMINISTRATORS}/${enableOrDisable}/${user.id}`)
      dispatch(enableOrDisableAdministratorsSuccess())
    } catch (error) {
      dispatch(enableOrDisableAdministratorsError(error.data))
    }
  }
}

const resetEnableOrDisableAdministrators = () => dispatch =>
  dispatch(enableOrDisableAdministratorsReset())

const addAdministrator = data => {
  return async dispatch => {
    const api = await getApi()
    dispatch(addAdministratorStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      UserName: data.userName,
      Password: data.passwordConfirmation,
      CompanyId: data.selectedBusiness.id,
      Mail: data.email
    }
    try {
      const response = await api.post(`${API_ROUTES.ADMINISTRATORS}`, body)
      dispatch(addAdministratorSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addAdministratorError(error.data))
    }
  }
}

const resetAddAdministrator = () => dispatch => dispatch(addAdministratorReset())

const deleteAdministrator = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteAdministratorStart())
    try {
      const response = await api.delete(`${API_ROUTES.ADMINISTRATORS}/${id}`)
      dispatch(deleteAdministratorSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteAdministratorError(error.data))
    }
  }
}

const resetDeleteAdministratorError = () => dispatch => dispatch(deleteAdministratorErrorReset())

const modifyAdministrator = (item, data) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyAdministratorStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      UserName: data.userName,
      CompanyId: data.selectedBusiness.value,
      Mail: data.email
    }
    try {
      const response = await api.put(`${API_ROUTES.ADMINISTRATORS}/${item.id}`, body)
      dispatch(modifyAdministratorSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyAdministratorError(error.data))
    }
  }
}

const resetModifyAdministrator = () => dispatch => dispatch(modifyAdministratorReset())

export {
  setShowingBusinessAdmin,
  showingBusinessAdmin,
  fetchAdministrators,
  fetchAdministratorsStart,
  fetchAdministratorsSuccess,
  fetchAdministratorsError,
  administratorsReset,
  resetAdministrators,
  enableOrDisableAdministrators,
  enableOrDisableAdministratorsStart,
  enableOrDisableAdministratorsSuccess,
  enableOrDisableAdministratorsError,
  enableOrDisableAdministratorsReset,
  resetEnableOrDisableAdministrators,
  addAdministrator,
  addAdministratorStart,
  addAdministratorSuccess,
  addAdministratorError,
  resetAddAdministrator,
  addAdministratorReset,
  deleteAdministrator,
  deleteAdministratorStart,
  deleteAdministratorSuccess,
  deleteAdministratorError,
  resetDeleteAdministratorError,
  deleteAdministratorErrorReset,
  modifyAdministrator,
  modifyAdministratorStart,
  modifyAdministratorSuccess,
  modifyAdministratorError,
  resetModifyAdministrator,
  modifyAdministratorReset
}

import React from 'react'
import Select from 'react-select'
import i18n from 'services/i18n'

import { capitalizeFirstLetter } from 'utils/strings'

const Selector = (props) => (
  <Select
    isLoading
    isDisabled={props.disabled}
    {...props}
    noOptionsMessage={() => capitalizeFirstLetter(i18n('SEARCH').SEARCH_NO_DATA)}
  />
)

export default Selector

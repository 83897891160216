import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px
  margin: 15px 0px 0px;
`

export const DividerStyle = isShowing => ({
  display: isShowing ? 'inherit' : 'none'
})

export const InputsWrapper = styled.div`
  display: flex;
  margin: 10px 0px;
`

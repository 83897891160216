import React from 'react'

import { Track, TransitionElement } from './styles'

export const MySwitch = ({ checked, onClick, trackColor, buttonColor, disabled }) => (
  <Track onClick={() => !disabled && onClick()} trackColor={trackColor} checked={checked}>
    <TransitionElement checked={checked} buttonColor={buttonColor} disabled={disabled} />
  </Track>
)

export default MySwitch

import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

import { capitalizeFirstLetter } from 'utils/strings'
import i18n from 'services/i18n'

const {
  capacitationImagesStart,
  capacitationImagesSuccess,
  capacitationImagesError,
  capacitationImagesReset
} = createActions({
  CAPACITATION_IMAGES_START: () => {},
  CAPACITATION_IMAGES_SUCCESS: data => ({ data }),
  CAPACITATION_IMAGES_ERROR: error => ({ error }),
  CAPACITATION_IMAGES_RESET: () => ({})
})

const fetchCapacitationImages = id => {
  return async dispatch => {
    const api = await getApi(true)
    dispatch(capacitationImagesStart())
    try {
      const response = await api.get(`${API_ROUTES.PUT_CAPACITATION}/${id}/${API_ROUTES.IMAGES}`)
      var fileBlob = new Blob([response.data], {
        type: 'octet/stream'
      })
      var objectURL = URL.createObjectURL(fileBlob)
      const link = document.createElement('a')
      link.href = objectURL
      link.setAttribute(
        'download',
        capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_PICTURES}.zip`)
      )
      document.body.appendChild(link)
      link.click()

      dispatch(capacitationImagesSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(capacitationImagesError(error))
    }
  }
}

const resetCapacitationImages = () => dispatch => dispatch(capacitationImagesReset())

export {
  fetchCapacitationImages,
  capacitationImagesStart,
  capacitationImagesSuccess,
  capacitationImagesError,
  resetCapacitationImages,
  capacitationImagesReset
}

import { handleActions } from 'redux-actions'
import {
  showingGeneralAdmin,
  fetchGeneralAdministratorsStart,
  fetchGeneralAdministratorsSuccess,
  fetchGeneralAdministratorsError,
  generalAdministratorsReset,
  addGeneralAdministratorsStart,
  addGeneralAdministratorsSuccess,
  addGeneralAdministratorsError,
  addGeneralAdministratorsReset,
  deleteGeneralAdministratorStart,
  deleteGeneralAdministratorSuccess,
  deleteGeneralAdministratorError,
  deleteGeneralAdministratorErrorReset,
  modifyGeneralAdministratorStart,
  modifyGeneralAdministratorSuccess,
  modifyGeneralAdministratorError,
  modifyGeneralAdministratorReset,
  enableOrDisableGeneralAdministratorsStart,
  enableOrDisableGeneralAdministratorsSuccess,
  enableOrDisableGeneralAdministratorsError,
  enableOrDisableGeneralAdministratorsReset
} from 'actions/generalAdmin'

const defaultState = {
  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isShowing: false,

  successEnablingOrDisabling: false,
  isEnablingOrDisabling: false,
  errorEnablingOrDisabling: null,

  isDeleting: false,
  successDelete: false,
  errorDelete: false,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false
}

const reducer = handleActions(
  {
    [showingGeneralAdmin]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [fetchGeneralAdministratorsStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchGeneralAdministratorsSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchGeneralAdministratorsError]: (state, error) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [generalAdministratorsReset]: () => defaultState,

    [addGeneralAdministratorsStart]: state => ({
      ...state,
      successAdd: false,
      isAdding: true,
      errorAdd: null
    }),
    [addGeneralAdministratorsSuccess]: state => ({
      ...state,
      successAdd: true,
      isAdding: false,
      errorAdd: null
    }),
    [addGeneralAdministratorsError]: (state, error) => ({
      ...state,
      successAdd: false,
      isAdding: false,
      errorAdd: error
    }),
    [addGeneralAdministratorsReset]: () => defaultState,

    [enableOrDisableGeneralAdministratorsStart]: state => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: true,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableGeneralAdministratorsSuccess]: state => ({
      ...state,
      successEnablingOrDisabling: true,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableGeneralAdministratorsError]: (state, error) => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: error
    }),
    [enableOrDisableGeneralAdministratorsReset]: () => defaultState,

    [deleteGeneralAdministratorStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteGeneralAdministratorSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteGeneralAdministratorError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),
    [deleteGeneralAdministratorErrorReset]: () => defaultState,

    [modifyGeneralAdministratorStart]: state => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false
    }),
    [modifyGeneralAdministratorSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyGeneralAdministratorError]: (state, error) => ({
      ...state,
      isSubmiting: false,
      successEdit: false,
      errorEdit: error
    }),
    [modifyGeneralAdministratorReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false,
      successEdit: false
    })
  },
  defaultState
)

export default reducer

import { createActions } from 'redux-actions'

const { selector, reset } = createActions({
  RESET: () => {},
  SELECTOR: data => ({ data })
})

const setSelector = status => dispatch => dispatch(selector({ status }))
const resetSelector = () => dispatch => dispatch(reset())

export { selector, setSelector, reset, resetSelector }

import React from 'react'

import styled from 'styled-components'
import PulseLoader from 'react-spinners/PulseLoader'

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loader = ({ color }) => (
  <LoaderWrapper>
    <PulseLoader sizeUnit={'px'} size={6} color={color} />
  </LoaderWrapper>
)

export default Loader

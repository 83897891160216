const flatFileKey = '6481af9b-4c79-4d13-84f9-17b13c2406bc'

const flatFileConfig = (type, fields) => {
  return {
    type,
    fields,
  }
}

const coursesConfig = [
  {
    label: 'Curso',
    key: 'CourseName',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Categoria',
    key: 'CategoryName',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Empresa',
    key: 'CompanyName',
    validators: [{ validate: 'required' }],
  },
]

const coursesTrainingsConfig = [
  {
    label: 'Curso',
    key: 'Course',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Categoria',
    key: 'Category',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Duracion',
    key: 'Duration',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Fecha',
    key: 'DateToConvert',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Instructor',
    key: 'Instructor',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Negocio',
    key: 'Company',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Centro de Dist',
    key: 'DistributionCenter',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Observaciones',
    key: 'Observations',
  },
  {
    label: 'Es Interno',
    key: 'IsInternal',
    type: 'checkbox',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Cant Alumnos',
    key: 'QtyEmployees',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Lleva Nota',
    type: 'checkbox',
    key: 'HasCalification',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Legajo o Dni',
    key: 'FileNumberOrDni',
    validators: [{ validate: 'required' }, { validate: 'unique' }],
  },
  {
    label: 'Nombres',
    key: 'FirstName',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Apellido',
    key: 'LastName',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Tipo Empleado',
    key: 'EmployeeType',
    validators: [{ validate: 'required' }],
    type: 'select',
    options: [
      { value: 'EFECTIVO', label: 'Efectivo' },
      { value: 'AGENCIA/CONTRATISTA', label: 'Agencia/Contratista' },
      { value: 'TRANSPORTISTA', label: 'Transportista' },
    ],
  },
  {
    label: 'Nota',
    key: 'Calification',
  },
  {
    label: 'Tipo Lider',
    key: 'LeaderType',
    type: 'select',
    options: [
      { value: 'Boss', label: 'Jefe' },
      { value: 'Manager', label: 'Manager' },
      { value: 'Director', label: 'Director' },
      { value: '', label: 'Ninguno' },
    ],
  },
]

const coursesDirectEmployeesConfig = [
  {
    label: 'Legajo',
    key: 'FileNumber',
    validators: [{ validate: 'required' }, { validate: 'unique' }],
  },
  {
    label: 'Apellido',
    key: 'LastName',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Nombre',
    key: 'FirstName',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Centro de Costo',
    key: 'CostCenter',
  },
  {
    label: 'Categoría',
    key: 'Category',
  },
  {
    label: 'Nombre Lider',
    key: 'LeaderName',
  },
  {
    label: 'Legajo Lider',
    key: 'LeaderFileNumber',
  },
  {
    label: 'Sector de Trabajo',
    key: 'WorkSector',
  },
  {
    label: 'Centro de distribución',
    key: 'DistributionCenter',
  },
  {
    label: 'Posición de Trabajo',
    key: 'WorkPosition',
  },
  {
    label: 'Staff Efectivo Id',
    key: 'EffectiveStaffId',
  },
  {
    label: 'Compañía',
    key: 'CompanyName',
    validators: [{ validate: 'required' }],
  },
]

const coursesCarrierEmployeesConfig = [
  {
    label: 'Legajo',
    key: 'FileNumber',
    validators: [{ validate: 'required' }, { validate: 'unique' }],
  },
  {
    label: 'Documento',
    key: 'DocumentNumber',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Cuenta',
    key: 'AccountNumber',
  },
  {
    label: 'Nombre',
    key: 'FirstName',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Apellido',
    key: 'LastName',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Compania',
    key: 'CompanyName',
    validators: [{ validate: 'required' }],
  },
]

const flatFileCustomerSettings = {
  companyId: 'Terrand',
  companyName: 'Danone',
  email: 'capadmin',
  name: 'admin',
  userId: '12345',
}

export {
  flatFileKey,
  flatFileConfig,
  coursesConfig,
  coursesTrainingsConfig,
  coursesDirectEmployeesConfig,
  coursesCarrierEmployeesConfig,
  flatFileCustomerSettings,
}

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { fetchCategories, resetCategories, resetModifyCategories } from 'actions/categories'
import { fetchCoursesByCategoryId, resetCoursesByCategoryId } from 'actions/courses'
import { fetchCapacitationsByFilter, resetCapacitationsByFilter } from 'actions/capacitations'
import { fetchExcel } from 'actions/excelDownload'
import { setExpandedCategory, resetExpandedCategory } from 'actions/expandedCategory'
import { setSelectedCapacitation } from 'actions/selectedCapacitation'
import { setCapacitationDetailStatus } from 'actions/capacitationDetailStatus'
import { setEditPopupStatus, resetEditPopupErrors } from 'actions/editPopup'
import { resetDisplayToast } from 'actions/toast'
import { setDeletePopupStatus } from 'actions/deletePopup'

import { capitalizeFirstLetter } from 'utils/strings'
import { isGeneralAdmin, isInstructor } from 'utils/session'

import i18n from 'services/i18n'

import { DivWrapperItems, DivWrapperLoader, DivWrapperError, DivWrapperHome } from './styled'

import Page from 'components/Page'
import MaterialExpansionPanel from 'components/ExpansionPanel'
import CapacitationTable from 'pages/CapacitationTable'
import Loader from 'components/Loaders/propagate'
import CapacitationPreview from 'components/CapacitationPreview'
import SearchModule from 'components/SearchModule'
import SearchDisplayEmployee from 'components/SearchDisplayEmployee'
import SearchDisplayCourses from 'components/SearchDisplayCourses'
import SearchDisplayCenters from 'components/SearchDisplayCenters'
import SearchDisplayLeaders from 'components/SearchDisplayLeaders'
import Pagination from 'components/Pagination'
import { MessageToast } from 'components/Toast'
import FloatingButton from 'components/FloatingButton'
import { fetchWorkSector } from 'actions/workSector'
import { fetchWorkPosition } from 'actions/workPosition'
import { fetchLeaders } from 'actions/leaders'
import { fetchCenters } from 'actions/centers'
import { resetCapacitationImages } from 'actions/capacitationImagesDownload'
import { setSelectedCapacitationPage } from 'actions/page'
import { resetCapacitationDetailPdfDownload } from 'actions/capInDetailPdf'

import filterKeys from 'constants/filterKeys'
import popupKeys from 'constants/popupKeys'
import toastTypes from 'constants/toastKeys'

import { paginate } from 'utils/paginator'
import EditPopup from 'components/EditPopup'
import AddPopup from 'components/AddPopup'
import DeletePopup from 'components/DeletePopup'
import PicturesPopup from 'components/PicturesPopup'

import theme from 'config/theme'

class Home extends React.Component {
  state = {
    keepCategoryOpen: true,
    expandedCourse: 0,
    selectedCourseDownload: 0,
    current: 1,
  }
  componentDidMount() {
    this.props.fetchCategories()
  }

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.toastMsj.isShowing && this.props.toastMsj.isShowing) {
      toast(
        <MessageToast
          boldContent={
            this.props.toastMsj.type === toastTypes.TOAST_TYPE_DELETE
              ? capitalizeFirstLetter(`${i18n('TOAST').TOAST_DELETED}!`)
              : capitalizeFirstLetter(`${i18n('TOAST').TOAST_SAVED}!`)
          }
          content={
            this.props.toastMsj.type === toastTypes.TOAST_TYPE_DELETE
              ? capitalizeFirstLetter(`${i18n('TOAST').TOAST_DELETED_LORE}`)
              : capitalizeFirstLetter(`${i18n('TOAST').TOAST_SAVED_LORE}`)
          }
        />
      )
      this.props.resetDisplayToast()
    }

    if (
      (prevProps.addPopup.open && !this.props.addPopup.open && this.successAdd()) ||
      (prevProps.deletePopup.open && !this.props.deletePopup.open && this.successDelete()) ||
      (prevProps.editPopup.open && !this.props.editPopup.open && this.successEdit())
    ) {
      this.props.resetExpandedCategory()
      await this.props.resetCategories()
      this.setState({ expandedCourse: 0 })
      this.props.fetchCategories()
    }

    if (
      prevProps.deletePopup.open &&
      !this.props.deletePopup.open &&
      this.props.capacitations.successDelete
    ) {
      this.setState({ expandedCourse: 0 })
    }

    if (
      prevProps.addPopup.open !== this.props.addPopup.open &&
      this.props.addPopup.type === popupKeys.LEADER
    ) {
      this.getSelectorContent()
    }

    if (
      prevProps.editPopup.open !== this.props.editPopup.open &&
      (this.props.editPopup.type === popupKeys.EMPLOYEE.DIRECT ||
        this.props.editPopup.type === popupKeys.LEADER)
    ) {
      this.getSelectorContent()
    }

    if (
      prevProps.selectedPage.capacitationPage !== this.props.selectedPage.capacitationPage &&
      this.props.selectedFilter.filterCategory === '' &&
      this.state.expandedCourse
    ) {
      this.getCapacitationByCourseId(this.state.expandedCourse)
    }

    if (
      prevProps.selectedFilter.selectedBusiness.value !==
      this.props.selectedFilter.selectedBusiness.value
    ) {
      this.setState({ expandedCourse: 0, current: 1 })
      this.props.resetExpandedCategory()
      this.props.resetCategories()
      this.props.fetchCategories()
    }
  }

  getSelectorContent = () => {
    this.props.fetchCenters()
    this.props.fetchWorkSector()
    this.props.fetchWorkPosition()
    this.props.fetchLeaders()
  }

  successAdd = () => this.props.categories.successAdd || this.props.courses.successAdd

  successDelete = () => this.props.categories.successDelete || this.props.courses.successDelete

  successEdit = () =>
    this.props.categories.successEdit ||
    this.props.courses.successEdit ||
    this.props.capacitations.successEdit

  getCoursesByCategoryId = (id) => {
    if (this.isCategoryExpanded(id)) {
      this.props.fetchCoursesByCategoryId(id, this.props.selectedFilter.selectedBusiness.value)
    }
  }

  getCapacitationByCourseId = (id) => {
    if (this.isCourseExpanded(id)) {
      this.props.fetchCapacitationsByFilter(filterKeys.COURSE.COURSE_ID, id)
    }
  }

  handleCategories = async (id) => {
    this.setState({ current: 1 })
    this.props.resetExpandedCategory()
    this.props.resetCoursesByCategoryId()
    await this.setSelectedCategory(id)
    setTimeout(() => {
      this.getCoursesByCategoryId(id)
    }, 20)
  }

  handleCourses = async (event, id) => {
    this.setState({ expandedCourse: 0 })
    this.props.resetCapacitationsByFilter()
    this.props.setSelectedCapacitationPage(1)
    await this.setSelectedCourse(event, id)

    this.getCapacitationByCourseId(id)
  }

  setSelectedCategory = (id) => {
    this.props.setExpandedCategory(this.isCategoryExpanded(id) ? 0 : id)
  }

  setSelectedCourse = (e, id) => {
    e.stopPropagation()
    this.setState({
      expandedCourse: this.isCourseExpanded(id) ? 0 : id,
    })
  }

  isCategoryExpanded = (id) => {
    return this.props.expandedCategory === id
  }

  isCourseExpanded = (id) => this.state.expandedCourse === id

  handleCapacitationDetail = (e, capacitation) => {
    e.stopPropagation()
    this.props.resetCapacitationImages()
    this.props.resetCapacitationDetailPdfDownload()
    this.props.setSelectedCapacitation(capacitation)
    this.props.setCapacitationDetailStatus(
      this.props.capacitationDetailStatus.status ? false : true
    )
  }

  isLoading = () => {
    return <Loader />
  }

  wichCourseWasSelected = (id) => this.state.selectedCourseDownload === id

  fetchExcel = (e, id, type, fileName) => {
    this.setState({ selectedCourseDownload: id })
    this.props.fetchExcel(id, type, fileName)
    e.stopPropagation()
  }

  onChangePagination = (page) => {
    this.setState({
      current: page,
    })
  }

  handleEditPopup = (e, item, type) => {
    this.props.setEditPopupStatus({ open: true, itemToEdit: item, type: type })
    e.stopPropagation()
  }

  handleDelete = (e, item, type) => {
    this.props.setDeletePopupStatus({ open: true, itemToDelete: item, type: type })
    e.stopPropagation()
  }

  renderCategories = (categories, courses, capacitations) => {
    if (categories.values && categories.values.length) {
      return categories.values.map((category) => (
        <MaterialExpansionPanel
          key={category.id}
          name={category.name}
          id={category.id}
          expanded={this.isCategoryExpanded(category.id)}
          getCoursesByCategoryId={this.getCoursesByCategoryId}
          onClick={() => this.handleCategories(category.id)}
          withEdit
          withOutline
          onButtonEditClick={(e) => this.handleEditPopup(e, category, popupKeys.CATEGORY)}
          withDelete
          onButtonDeleteClick={(e) => this.handleDelete(e, category, popupKeys.CATEGORY)}
          businessAdminShouldInteract={true}
        >
          {courses.values && courses.values.length ? (
            <DivWrapperItems>
              {paginate(this.state.current, courses.values).map((courses) => (
                <MaterialExpansionPanel
                  key={`${courses.id}-courses`}
                  name={courses.name}
                  id={courses.id}
                  expanded={this.isCourseExpanded(courses.id, filterKeys.COURSE.COURSE_ID)}
                  getCapacitationByCourseId={this.getCapacitationByCourseId}
                  onClick={(e) => this.handleCourses(e, courses.id)}
                  withDownload
                  onButtonDownloadClick={(e) =>
                    this.fetchExcel(e, courses.id, filterKeys.COURSE.COURSE_ID, courses.name)
                  }
                  buttonDownloadLoading={
                    this.props.excelDownload.isFetching && this.wichCourseWasSelected(courses.id)
                  }
                  withEdit
                  onButtonEditClick={(e) => this.handleEditPopup(e, courses, popupKeys.COURSE)}
                  withDelete
                  onButtonDeleteClick={(e) => this.handleDelete(e, courses, popupKeys.COURSE)}
                  backgroundColor={!courses.courseActive && theme.colors.disabledUserGrey}
                  backgroundColorExpanded={
                    !courses.courseActive && theme.colors.disabledUserExpandedGrey
                  }
                  businessAdminShouldInteract={true}
                >
                  {this.props.capacitations.isFetching ? (
                    <DivWrapperLoader>
                      <Loader />
                    </DivWrapperLoader>
                  ) : capacitations.values && capacitations.values.items.length ? (
                    <CapacitationPreview
                      onClick={(e, capacitation) => this.handleCapacitationDetail(e, capacitation)}
                      data={capacitations}
                      onDeleteClick={(e, capacitation) =>
                        this.handleDelete(e, capacitation, popupKeys.CAPACITATION)
                      }
                    />
                  ) : (
                    <DivWrapperError>
                      {capitalizeFirstLetter(`${i18n('HOME').HOME_PAGE_NO_CAPACITATIONS}`)}
                    </DivWrapperError>
                  )}
                </MaterialExpansionPanel>
              ))}
              <Pagination
                total={courses.values.length}
                isLocal={true}
                onChangeLocal={this.onChangePagination}
                selectedPageLocal={this.state.current}
              />
            </DivWrapperItems>
          ) : this.props.courses.isFetching ? (
            <DivWrapperLoader>
              <Loader />
            </DivWrapperLoader>
          ) : (
            <DivWrapperError>
              {capitalizeFirstLetter(`${i18n('HOME').HOME_PAGE_NO_COURSES}`)}
            </DivWrapperError>
          )}
        </MaterialExpansionPanel>
      ))
    } else {
      return this.props.categories.isFetching ? (
        <Loader />
      ) : (
        <DivWrapperError>
          {capitalizeFirstLetter(`${i18n('HOME').HOME_PAGE_NO_CATEGORIES}`)}
        </DivWrapperError>
      )
    }
  }

  renderSearch = () => {
    switch (this.props.selectedFilter.filterCategory) {
      case filterKeys.COURSE.COURSE_ID:
        return <SearchDisplayCourses />
      case filterKeys.CENTER.CENTER_ID:
        return <SearchDisplayCenters />
      case filterKeys.LEADER.LEADER_ID:
        return <SearchDisplayLeaders />
      default:
        return <SearchDisplayEmployee />
    }
  }

  renderCapacitationDetail = () => {
    return (
      <CapacitationTable
        onClose={this.handleCapacitationDetail}
        selectedCapacitation={this.props.selectedCapacitation}
      />
    )
  }

  renderHome = () => (
    <DivWrapperHome>
      <SearchModule />
      {this.props.directEmployee.isShowing ||
      this.props.carrierEmployee.isShowing ||
      this.props.indirectEmployee.isShowing ||
      this.props.courses.isShowing ||
      this.props.centers.isShowing ||
      this.props.leaders.isShowing
        ? this.renderSearch()
        : this.renderCategories(
            this.props.categories,
            this.props.courses,
            this.props.capacitations
          )}
      {!isInstructor() && !isGeneralAdmin() ? <FloatingButton type={i18n('PAGE_COURSES')} /> : ''}
    </DivWrapperHome>
  )

  render() {
    return (
      <Page withHeader>
        {this.props.capacitationDetailStatus.status
          ? this.renderCapacitationDetail()
          : this.renderHome()}
        {this.props.editPopup.open && <EditPopup />}
        {this.props.addPopup.open && <AddPopup />}
        {this.props.deletePopup.open && <DeletePopup />}
        {this.props.picturesPopup.open && <PicturesPopup />}
        <ToastContainer
          style={{
            minWidth: 600,
            marginLeft: '-280px',
          }}
          closeButton={false}
          position={toast.POSITION.BOTTOM_CENTER}
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable={false}
          pauseOnHover
          className={'toast'}
          bodyClassName={'toastBody'}
        />
      </Page>
    )
  }
}

const mapStateToProps = ({
  categories,
  courses,
  centers,
  leaders,
  capacitations,
  directEmployee,
  carrierEmployee,
  indirectEmployee,
  excelDownload,
  excelImport,
  selectedFilter,
  expandedCategory,
  selectedCapacitation,
  capacitationDetailStatus,
  editPopup,
  addPopup,
  deletePopup,
  picturesPopup,
  toastMsj,
  selectedPage,
}) => ({
  categories,
  courses,
  centers,
  leaders,
  capacitations,
  directEmployee,
  carrierEmployee,
  indirectEmployee,
  excelDownload,
  excelImport,
  selectedFilter,
  expandedCategory,
  selectedCapacitation,
  capacitationDetailStatus,
  editPopup,
  addPopup,
  deletePopup,
  picturesPopup,
  toastMsj,
  selectedPage,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCategories,
      resetCategories,
      fetchCoursesByCategoryId,
      resetCoursesByCategoryId,
      fetchCapacitationsByFilter,
      resetCapacitationsByFilter,
      fetchExcel,
      setExpandedCategory,
      resetExpandedCategory,
      setSelectedCapacitation,
      setCapacitationDetailStatus,
      setEditPopupStatus,
      resetDisplayToast,
      setDeletePopupStatus,
      fetchCenters,
      fetchWorkSector,
      fetchWorkPosition,
      fetchLeaders,
      resetCapacitationImages,
      resetEditPopupErrors,
      resetModifyCategories,
      setSelectedCapacitationPage,
      resetCapacitationDetailPdfDownload,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Home)

import roles from 'constants/roles'

export const isUserLoggedIn = () => getCacheString('@logistica/token')

export const getUserInfo = () => {
  const result = JSON.parse(getCacheString('@logistica/user'))
  return result ? result : {}
}

export const getUserId = () => getCacheString('@logistica/userId')

export const isGeneralAdmin = () => getCacheString('@logistica/role') === roles.GENERAL_ADMIN

export const isAdmin = () => getCacheString('@logistica/role') === roles.ADMINISTRATOR

export const isInstructor = () => getCacheString('@logistica/role') === roles.INSTRUCTOR

export const isLeaderUser = () => getCacheString('@logistica/role') === roles.LEADER_USER

export const getUserRole = () => getCacheString('@logistica/role')

const removeMarks = string => {
  return string ? string.slice(1, string.length - 1) : ''
}

export const saveLocalStatus = (token, userInfo) => {
  localStorage.setItem('@logistica/token', token)
  localStorage.setItem('@logistica/user', JSON.stringify(userInfo))
  localStorage.setItem('@logistica/userId', JSON.stringify(userInfo.id))
  localStorage.setItem('@logistica/role', removeMarks(JSON.stringify(userInfo.rol.name)))
}

export const getCacheString = key => localStorage.getItem(key)

import { handleActions } from 'redux-actions'
import {
  fetchTrainingDetailStart,
  fetchTrainingDetailSuccess,
  fetchTrainingDetailError,
  trainingDetailReset
} from 'actions/trainingDetail'

const defaultState = {
  values: null,
  isFetching: false,
  error: null
}

const reducer = handleActions(
  {
    [fetchTrainingDetailStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchTrainingDetailSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values: values,
      isFetching: false,
      error: null
    }),
    [fetchTrainingDetailError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error: error
    }),
    [trainingDetailReset]: () => defaultState
  },
  defaultState
)

export default reducer

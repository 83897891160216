export default {
  HOME: 'home',
  CATEGORY: 'category',
  EMPLOYEE: {
    DEFAULT: 'employee',
    DIRECT: 'direct',
    INDIRECT: 'indirect',
    CARRIER: 'carrier'
  },
  COURSE: 'course',
  CENTER: 'center',
  LEADER: 'leader',
  CAPACITATION: 'capacitation',
  CAPACITATION_NOTE: 'note',
  CAPACITATION_DATA: 'data'
}

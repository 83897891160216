import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Modal from 'components/Modal'
import ButtonMaterial from 'components/ButtonMaterial'

import { setDeletePopupStatus, resetDeletePopupErrors } from 'actions/deletePopup'
import { setDisplayToast } from 'actions/toast'
import { deleteCategory } from 'actions/categories'
import { deleteCourse } from 'actions/courses'
import { deleteCenter } from 'actions/centers'
import { deleteLeader } from 'actions/leaders'
import { deleteDirectEmployee } from 'actions/directEmployee'
import { deleteIndirectEmployee } from 'actions/indirectEmployee'
import { deleteCarrierEmployee } from 'actions/carrierEmployee'
import { deleteCapacitation } from 'actions/capacitations'
import { deleteAdministrator } from 'actions/administrators'
import { deleteInstructor } from 'actions/instructors'
import { deleteLeaderUser } from 'actions/leaderUsers'
import { setCapacitationDetailStatus } from 'actions/capacitationDetailStatus'
import { resetSelectedSearchedItem } from 'actions/selectedSearchedItem'
import { deleteGeneralAdministrator } from 'actions/generalAdmin'

import theme from 'config/theme'
import {
  StyledContainer,
  StyledDivHeaderWrapper,
  CrossImage,
  StyledTextHeader,
  ButtonWrapper,
  StyledDivFooterWrapper,
  StyledLore,
  ErrorLabel,
  StyledDivWrapper
} from './styled'

import CrossIcon from 'assets/icons/cross.png'

import { capitalizeFirstLetter, allToLower } from 'utils/strings'
import { getDate } from 'utils/date/dateParser'
import isDanone from 'utils/isDanone'

import i18n from 'services/i18n'

import popupKeys from 'constants/popupKeys'
import toastTypes from 'constants/toastKeys'
import userKeys from 'constants/userKeys'

class DeletePopup extends Component {
  componentWillUnmount = () => {
    this.props.resetDeletePopupErrors()
  }

  onClose = soft => {
    this.props.setDeletePopupStatus({ open: false, type: null })
    if (!soft) {
      this.props.setDisplayToast({ isShowing: true, type: toastTypes.TOAST_TYPE_DELETE })
    }
  }

  getHeaderName = () => {
    switch (this.props.deletePopup.type) {
      case popupKeys.CATEGORY:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY}`
      case popupKeys.LEADER:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`
      case popupKeys.CENTER:
        return `${
          isDanone()
            ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
            : i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_PLACE
        }`
      case popupKeys.COURSE:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_COURSE}`
      case popupKeys.EMPLOYEE.DIRECT:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_DIRECT_EMPLOYEE}`
      case popupKeys.EMPLOYEE.INDIRECT:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_INDIRECT_EMPLOYEE}`
      case popupKeys.EMPLOYEE.CARRIER:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_CARRIER_EMPLOYEE}`
      case popupKeys.CAPACITATION:
        return `${i18n('CAPACITATION').CAPACITATION}`
      case userKeys.GENERAL_ADMIN:
        return `${i18n('USER_TYPES').GENERAL_ADMIN}`
      case userKeys.ADMINISTRATOR:
        return `${i18n('USER_TYPES').ADMINISTRATOR}`
      case userKeys.INSTRUCTOR:
        return `${i18n('USER_TYPES').INSTRUCTOR}`
      case userKeys.LEADER_USER:
        return `${i18n('USER_TYPES').LEADER_USER}`
    }
  }

  handleDelete = async id => {
    switch (this.props.deletePopup.type) {
      case popupKeys.CATEGORY:
        await this.props.deleteCategory(id)
        break
      case popupKeys.LEADER:
        await this.props.deleteLeader(id)
        break
      case popupKeys.CENTER:
        await this.props.deleteCenter(id)
        break
      case popupKeys.COURSE:
        await this.props.deleteCourse(id)
        break
      case popupKeys.EMPLOYEE.DIRECT:
        await this.props.deleteDirectEmployee(id)
        break
      case popupKeys.EMPLOYEE.INDIRECT:
        await this.props.deleteIndirectEmployee(id)
        break
      case popupKeys.EMPLOYEE.CARRIER:
        await this.props.deleteCarrierEmployee(id)
        break
      case popupKeys.CAPACITATION:
        await this.props.deleteCapacitation(id)
        break
      case userKeys.GENERAL_ADMIN:
        await this.props.deleteGeneralAdministrator(id)
        break
      case userKeys.ADMINISTRATOR:
        await this.props.deleteAdministrator(id)
        break
      case userKeys.INSTRUCTOR:
        await this.props.deleteInstructor(id)
        break
      case userKeys.LEADER_USER:
        await this.props.deleteLeaderUser(id)
        break
    }
    if (!this.hasError()) {
      this.onClose()
    }
  }

  isLoading = () =>
    this.props.categories.isDeleting ||
    this.props.courses.isDeleting ||
    this.props.centers.isDeleting ||
    this.props.leaders.isDeleting ||
    this.props.directEmployee.isDeleting ||
    this.props.indirectEmployee.isDeleting ||
    this.props.carrierEmployee.isDeleting ||
    this.props.capacitations.isDeleting ||
    this.props.generalAdministrators.isDeleting ||
    this.props.administrators.isDeleting ||
    this.props.instructors.isDeleting ||
    this.props.leaderUsers.isDeleting

  hasError = () =>
    this.props.categories.errorDelete ||
    this.props.courses.errorDelete ||
    this.props.centers.errorDelete ||
    this.props.leaders.errorDelete ||
    this.props.directEmployee.errorDelete ||
    this.props.indirectEmployee.errorDelete ||
    this.props.carrierEmployee.errorDelete ||
    this.props.capacitations.errorDelete ||
    this.props.generalAdministrators.errorDelete ||
    this.props.administrators.errorDelete ||
    this.props.instructors.errorDelete ||
    this.props.leaderUsers.errorDelete

  render() {
    return (
      <Modal>
        <StyledContainer>
          <StyledDivHeaderWrapper>
            <StyledTextHeader>
              {capitalizeFirstLetter(`${i18n('DELETE_VIEW').DELETE}`)} {this.getHeaderName()}
            </StyledTextHeader>
            <CrossImage onClick={() => this.onClose(true)} src={CrossIcon} />
          </StyledDivHeaderWrapper>
          <StyledLore>
            {capitalizeFirstLetter(`${i18n('DELETE_VIEW').DELETE_VIEW_LORE}`)}:{' '}
            {this.props.deletePopup.type === popupKeys.CAPACITATION
              ? `${capitalizeFirstLetter(
                  `${i18n('DELETE_VIEW').DELETE_VIEW_LORE_CAPACITATION}`
                )} ${getDate(this.props.deletePopup.itemToDelete.realizationDate)}`
              : this.props.deletePopup.itemToDelete.name
              ? capitalizeFirstLetter(allToLower(this.props.deletePopup.itemToDelete.name))
              : this.props.deletePopup.itemToDelete.firstName
              ? `${capitalizeFirstLetter(
                  allToLower(this.props.deletePopup.itemToDelete.firstName)
                )}, ${capitalizeFirstLetter(
                  allToLower(this.props.deletePopup.itemToDelete.lastName)
                )}`
              : capitalizeFirstLetter(allToLower(this.props.deletePopup.itemToDelete.userName))}
          </StyledLore>
          <StyledDivFooterWrapper>
            <ButtonWrapper>
              <ButtonMaterial
                type={'submit'}
                color={theme.colors.white}
                background={this.isLoading() ? theme.colors.dustyGray : theme.colors.purple}
                borderRadius={'10'}
                fontSize={'18'}
                small
                disabled={this.isLoading() ? true : false}
                loading={this.isLoading()}
                display={true}
                onClick={() => this.handleDelete(this.props.deletePopup.itemToDelete.id)}
              >
                {capitalizeFirstLetter(`${i18n('DELETE_VIEW').DELETE_VIEW_CONFIRM}`)}
              </ButtonMaterial>
            </ButtonWrapper>
            <StyledDivWrapper>
              {this.hasError() && (
                <ErrorLabel>
                  {this.hasError().payload.error && this.hasError().payload.error.errors[0]
                    ? this.hasError().payload.error.errors[0]
                    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR}`)}
                </ErrorLabel>
              )}
            </StyledDivWrapper>
          </StyledDivFooterWrapper>
        </StyledContainer>
      </Modal>
    )
  }
}

const mapStateToProps = ({
  deletePopup,
  categories,
  courses,
  centers,
  leaders,
  directEmployee,
  indirectEmployee,
  carrierEmployee,
  capacitations,
  administrators,
  instructors,
  leaderUsers,
  generalAdministrators
}) => ({
  deletePopup,
  categories,
  courses,
  centers,
  leaders,
  directEmployee,
  indirectEmployee,
  carrierEmployee,
  capacitations,
  administrators,
  instructors,
  leaderUsers,
  generalAdministrators
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDeletePopupStatus,
      setDisplayToast,
      deleteCategory,
      deleteCourse,
      deleteCenter,
      deleteLeader,
      deleteDirectEmployee,
      deleteIndirectEmployee,
      deleteCarrierEmployee,
      deleteCapacitation,
      deleteAdministrator,
      deleteInstructor,
      deleteLeaderUser,
      resetSelectedSearchedItem,
      setCapacitationDetailStatus,
      resetDeletePopupErrors,
      deleteGeneralAdministrator
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletePopup)

import { handleActions } from 'redux-actions'
import { selectedSearchedItem, resetSearchedItem } from 'actions/selectedSearchedItem'

const defaultState = {
  expandedItem: 0
}

const reducer = handleActions(
  {
    [selectedSearchedItem]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => ({ expandedItem: status.id }),
    [resetSearchedItem]: () => defaultState
  },
  defaultState
)

export default reducer

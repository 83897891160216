import { handleActions } from 'redux-actions'
import { selectedDateFilter, resetDateFilter } from 'actions/selectedDateFilter'

const defaultState = {
  filterDateFrom: '',
  filterDateTo: ''
}

const reducer = handleActions(
  {
    [selectedDateFilter]: (state, { payload: { data: status } }) => ({
      ...state,
      ...status.status
    }),

    [resetDateFilter]: () => defaultState
  },
  defaultState
)

export default reducer

import { handleActions } from 'redux-actions'
import { expandedCategory, resetExpanded } from 'actions/expandedCategory'

const defaultState = {
  expandedCategory: ''
}

const reducer = handleActions(
  {
    [expandedCategory]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,
    [resetExpanded]: () => defaultState
  },
  defaultState
)

export default reducer

import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const { fetchYearsStart, fetchYearsSuccess, fetchYearsError } = createActions({
  FETCH_YEARS_START: () => {},
  FETCH_YEARS_SUCCESS: data => ({ data }),
  FETCH_YEARS_ERROR: error => ({ error })
})

const fetchYears = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchYearsStart())
    try {
      const response = await api.get(`${API_ROUTES.ACTIVE_YEARS}`)
      dispatch(fetchYearsSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchYearsError(error))
    }
  }
}

export { fetchYears, fetchYearsStart, fetchYearsSuccess, fetchYearsError }

import { createActions } from 'redux-actions'
import getApi from 'services/api'

const {
  byCategoriesStart,
  byCategoriesSuccess,
  byCategoriesError,
  byCategoriesReset,
} = createActions({
  BY_CATEGORIES_START: () => {},
  BY_CATEGORIES_SUCCESS: (values, total) => ({ values, total }),
  BY_CATEGORIES_ERROR: (error) => ({ error }),
  BY_CATEGORIES_RESET: () => {},
})

const getByCategoriesRequest = (query) => {
  return async (dispatch) => {
    const api = await getApi()

    const body = {
      ...query,
      Sort: {
        Name: '',
        IsAscending: true,
      },
      Pager: {
        PageSize: 10,
        PageNumber: query.PageNumber ? query.PageNumber : 1,
      },
    }

    dispatch(byCategoriesStart())

    return api
      .post('dashboard/summarybycategory', body)
      .then((response) => {
        const dataResponse = response.data.data
        dispatch(byCategoriesSuccess(dataResponse.courseCategories, dataResponse.totalCategories))
      })
      .catch((error) => {
        dispatch(byCategoriesError(error.data ? error.data.message : error.message))
      })
  }
}

export {
  getByCategoriesRequest,
  byCategoriesStart,
  byCategoriesSuccess,
  byCategoriesError,
  byCategoriesReset,
}

import { handleActions } from 'redux-actions'
import {
  showingDirect,
  fetchDirectEmployeeByFilterStart,
  fetchDirectEmployeeByFilterSuccess,
  fetchDirectEmployeeByFilterError,
  fetchDirectEmployeeByDateStart,
  fetchDirectEmployeeByDateSuccess,
  fetchDirectEmployeeByDateError,
  directEmployeeByDateReset,
  directEmployeeByFilterReset,
  fetchDirectEmployeeStart,
  fetchDirectEmployeeSuccess,
  fetchDirectEmployeeError,
  directEmployeeReset,
  modifyDirectEmployeeStart,
  modifyDirectEmployeeSuccess,
  modifyDirectEmployeeError,
  modifyDirectEmployeeReset,
  addDirectEmployeeStart,
  addDirectEmployeeSuccess,
  addDirectEmployeeError,
  addDirectEmployeeReset,
  deleteDirectEmployeeStart,
  deleteDirectEmployeeSuccess,
  deleteDirectEmployeeError,
  deleteDirectEmployeeErrorReset
} from 'actions/directEmployee'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,

  valuesByDate: null,
  errorByDate: null,
  isFetchingByDate: false,

  isShowing: false,

  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false
}

const reducer = handleActions(
  {
    [showingDirect]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [fetchDirectEmployeeStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchDirectEmployeeSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchDirectEmployeeError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [directEmployeeReset]: () => defaultState,

    [fetchDirectEmployeeByDateStart]: state => ({
      ...state,
      isFetchingByDate: true,
      errorByDate: null
    }),
    [fetchDirectEmployeeByDateSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesByDate: values,
      isFetchingByDate: false,
      errorByDate: null
    }),
    [fetchDirectEmployeeByDateError]: (state, { error }) => ({
      ...state,
      isFetchingByDate: false,
      errorByDate: error
    }),
    [directEmployeeByDateReset]: () => defaultState,

    [fetchDirectEmployeeByFilterStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchDirectEmployeeByFilterSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchDirectEmployeeByFilterError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [directEmployeeByFilterReset]: () => defaultState,

    [modifyDirectEmployeeStart]: state => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false
    }),
    [modifyDirectEmployeeSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyDirectEmployeeError]: (state, error) => ({
      ...state,
      isSubmiting: false,
      errorEdit: error,
      successEdit: false
    }),
    [modifyDirectEmployeeReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false
      //  successEdit: false
    }),

    [addDirectEmployeeStart]: state => ({
      ...state,
      isAdding: true,
      errorAdd: false,
      successAdd: false
    }),
    [addDirectEmployeeSuccess]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false,
      successAdd: true
    }),
    [addDirectEmployeeError]: (state, error) => ({
      ...state,
      isAdding: false,
      errorAdd: error,
      successAdd: false
    }),
    [addDirectEmployeeReset]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false
      //  successAdd: false
    }),

    [deleteDirectEmployeeStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteDirectEmployeeSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteDirectEmployeeError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),
    [deleteDirectEmployeeErrorReset]: state => ({
      ...state,
      isDeleting: false,
      //  successDelete: false,
      errorDelete: false
    })
  },
  defaultState
)

export default reducer

import { handleActions } from 'redux-actions'
import {
  showingBusinessAdmin,
  fetchAdministratorsStart,
  fetchAdministratorsSuccess,
  fetchAdministratorsError,
  administratorsReset,
  enableOrDisableAdministratorsStart,
  enableOrDisableAdministratorsSuccess,
  enableOrDisableAdministratorsError,
  enableOrDisableAdministratorsReset,
  addAdministratorStart,
  addAdministratorSuccess,
  addAdministratorError,
  addAdministratorReset,
  deleteAdministratorStart,
  deleteAdministratorSuccess,
  deleteAdministratorError,
  deleteAdministratorErrorReset,
  modifyAdministratorStart,
  modifyAdministratorSuccess,
  modifyAdministratorError,
  modifyAdministratorReset
} from 'actions/administrators'

const defaultState = {
  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  successEnablingOrDisabling: false,
  isEnablingOrDisabling: false,
  errorEnablingOrDisabling: null,

  isShowing: false,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false
}

const reducer = handleActions(
  {
    [showingBusinessAdmin]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [fetchAdministratorsStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchAdministratorsSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchAdministratorsError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [administratorsReset]: () => defaultState,

    [enableOrDisableAdministratorsStart]: state => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: true,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableAdministratorsSuccess]: state => ({
      ...state,
      successEnablingOrDisabling: true,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: null
    }),
    [enableOrDisableAdministratorsError]: (state, error) => ({
      ...state,
      successEnablingOrDisabling: false,
      isEnablingOrDisabling: false,
      errorEnablingOrDisabling: error
    }),
    [enableOrDisableAdministratorsReset]: () => defaultState,

    [addAdministratorStart]: state => ({
      ...state,
      isAdding: true,
      successAdd: false,
      errorAdd: false
    }),
    [addAdministratorSuccess]: state => ({
      ...state,
      isAdding: false,
      successAdd: true,
      errorAdd: false
    }),
    [addAdministratorError]: (state, error) => ({
      ...state,
      isAdding: false,
      successAdd: false,
      errorAdd: error
    }),
    [addAdministratorReset]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false,
      successAdd: false
    }),

    [deleteAdministratorStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteAdministratorSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteAdministratorError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),

    [modifyAdministratorStart]: state => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false
    }),
    [modifyAdministratorSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyAdministratorError]: (state, error) => ({
      ...state,
      isSubmiting: false,
      successEdit: false,
      errorEdit: error
    }),
    [modifyAdministratorReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false,
      successEdit: false
    }),
    [deleteAdministratorErrorReset]: state => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: false
    })
  },
  defaultState
)

export default reducer

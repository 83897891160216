import React, { Component } from 'react'
import Modal from 'components/Modal'
import { Formik } from 'formik'
import * as Yup from 'yup'
import CrossIcon from 'assets/icons/cross.png'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import theme from 'config/theme'
import {
  StyledContainer,
  StyledDivHeaderWrapper,
  CrossImage,
  StyledForm,
  StyledTextHeader,
  ButtonWrapper,
  StyledDivFooterWrapper,
  ErrorLabel,
  StyledDivWrapper,
} from './styled'

import {
  courseInput,
  employeeInput,
  leaderInput,
  administratorInput,
  instructorInput,
  leaderUserInput,
  defaultInput,
} from 'components/AddPopupInputs'

import { capitalizeFirstLetter } from 'utils/strings'
import isDanone from 'utils/isDanone'
import { getCompanyObject } from 'utils/getCompany'
import { reestructureDataSimple } from 'utils/selectorParser'

import ButtonMaterial from 'components/ButtonMaterial'

import i18n from 'services/i18n'

import { setAddPopupStatus, resetAddPopupErrors } from 'actions/addPopup'
import { addCategory } from 'actions/categories'
import { setDisplayToast } from 'actions/toast'
import { setCapacitationDetailStatus } from 'actions/capacitationDetailStatus'
import { resetSelectedSearchedItem } from 'actions/selectedSearchedItem'
import { addLeader, fetchLeaders } from 'actions/leaders'
import { addCenter, fetchCenters } from 'actions/centers'
import { addCourse } from 'actions/courses'
import { resetSelector } from 'actions/selector'
import { fetchWorkSector } from 'actions/workSector'
import { fetchWorkPosition } from 'actions/workPosition'
import { addDirectEmployee } from 'actions/directEmployee'
import { addIndirectEmployee } from 'actions/indirectEmployee'
import { addCarrierEmployee } from 'actions/carrierEmployee'
import { addAdministrator } from 'actions/administrators'
import { addInstructor } from 'actions/instructors'
import { addLeaderUser } from 'actions/leaderUsers'
import { fetchCourses } from 'actions/courses'
import { fetchCategories } from 'actions/categories'
import { addGeneralAdministrators } from 'actions/generalAdmin'
import { fetchBusiness } from 'actions/business'

import popupKeys from 'constants/popupKeys'
import userKeys from 'constants/userKeys'
import employeeTypes from 'constants/employeeTypes'
import toastTypes from 'constants/toastKeys'

import { getCompanyById } from 'utils/getCompany'

import bossTypes from 'constants/bossTypeKeys'

class AddPopup extends Component {
  state = {
    samePasswordError: false,
    leaderError: false,
    distributionCentersError: false,
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.selector.value !== this.props.selector.value && !this.hasError()) {
      this.props.resetAddPopupErrors()
    }
    if (
      prevProps.selector.value !== this.props.selector.value &&
      this.props.selector.value === popupKeys.EMPLOYEE.DIRECT
    ) {
      this.props.fetchCenters()
      this.props.fetchWorkSector()
      this.props.fetchWorkPosition()
      this.props.fetchLeaders(bossTypes.BOSS)
    }
  }

  componentWillUnmount = () => {
    this.props.resetAddPopupErrors()
  }

  componentDidMount = () => {
    if (this.props.addPopup.type === userKeys.EMPLOYEE) {
      this.props.fetchBusiness()
    }

    if (this.props.addPopup.type === userKeys.LEADER_USER) {
      this.props.fetchLeaders(bossTypes.BOSS)
      this.props.fetchCenters()
    }

    if (this.props.addPopup.type === userKeys.INSTRUCTOR) {
      this.props.fetchCategories()
      this.props.fetchCourses()
      this.props.fetchBusiness()
    }

    if (this.props.addPopup.type === userKeys.ADMINISTRATOR) {
      this.props.fetchBusiness()
    }
  }

  onClose = (soft) => {
    this.props.setAddPopupStatus({ open: false, type: null })
    this.props.resetSelector()
    if (!soft) {
      this.props.setDisplayToast({ isShowing: true, type: toastTypes.TOAST_TYPE_ADD })
      if (this.props.addPopup.type === popupKeys.CAPACITATION) {
        this.props.setCapacitationDetailStatus(false)
        this.props.resetSelectedSearchedItem()
      }
    }
  }

  getDirectOrLeaderInicialValue = () => ({
    name: '',
    lastName: '',
    fileNumber: '',
    costCenter: '',
    category: '',
    distributionCenterId: null,
    workSector: null,
    workPosition: null,
    leader: null,
    leaderType: null,
  })

  employeeInicialValue = async () => {
    switch (this.props.selector.value) {
      case popupKeys.EMPLOYEE.DIRECT:
        return await this.getDirectOrLeaderInicialValue()
      case popupKeys.EMPLOYEE.INDIRECT:
        return {
          name: '',
          lastName: '',
          documentNumber: '',
          business: null,
          businessInput: '',
          companies: [],
        }
      case popupKeys.EMPLOYEE.CARRIER:
        return {
          name: '',
          lastName: '',
          documentNumber: '',
          fileNumber: '',
          distributionNumber: '',
          accountNumber: '',
        }
    }
  }

  basicAddUserFormInicialValues = () => {
    return {
      userName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    }
  }

  complexAddUserFormInicialValues = () => {
    return { ...this.basicAddUserFormInicialValues(), name: '', lastName: '' }
  }

  getInicialValues = () => {
    switch (this.props.addPopup.type) {
      case popupKeys.EMPLOYEE.DEFAULT:
        return this.employeeInicialValue()
      case popupKeys.LEADER:
        return this.getDirectOrLeaderInicialValue()
      case userKeys.GENERAL_ADMIN:
        return this.complexAddUserFormInicialValues()
      case userKeys.ADMINISTRATOR:
        return this.complexAddUserFormInicialValues()
      case userKeys.INSTRUCTOR:
        return {
          ...this.complexAddUserFormInicialValues(),
          allCourses: false,
          allCategories: false,
          isInternal: false,
          canAddCourses: false,
          availableCourses: null,
          availableCategories: null,
          selectedBusiness: [
            reestructureDataSimple(getCompanyObject().Id, getCompanyObject().name),
          ],
        }
      case userKeys.LEADER_USER:
        return {
          ...this.basicAddUserFormInicialValues(),
          allCenters: false,
          leaderLevel: null,
          leader: null,
          distributionCenters: null,
        }
      default:
        return {
          name: '',
        }
    }
  }

  getDirectOrLeaderValidations = () => ({
    name: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
    lastName: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_LASTNAME}`)),
    fileNumber: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_FILE}`)),
    costCenter: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)),
    category: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_CATEGORY}`)),
    distributionCenterId: Yup.string().required(),
    workSector: Yup.string().required(),
    workPosition: Yup.string().required(),
  })

  employeeValidations = () => {
    switch (this.props.selector.value) {
      case popupKeys.EMPLOYEE.DIRECT:
        return this.getDirectOrLeaderValidations()
      case popupKeys.EMPLOYEE.INDIRECT:
        return {
          name: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
          ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
          lastName: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
          ).required(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_LASTNAME}`)
          ),
          documentNumber: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
          ).required(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_DOCUMENT}`)
          ),
          companies: Yup.string().required(),
          business: Yup.string().required(),
          businessInput: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
          ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
        }
      case popupKeys.EMPLOYEE.CARRIER:
        return {
          name: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
          ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
          lastName: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
          ).required(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_LASTNAME}`)
          ),
          documentNumber: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
          ).required(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_DOCUMENT}`)
          ),
          fileNumber: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
          ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_FILE}`)),

          distributionNumber: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
          ).required(
            capitalizeFirstLetter(
              `${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_DISTRIBUTION_NUMBER}`
            )
          ),
          accountNumber: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
          ).required(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_ACCOUNT}`)
          ),
        }
    }
  }

  basicAddUserFormValidations = () => ({
    userName: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_USERNAME}`)),
    email: Yup.string(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`))
      .email(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_EMAIL}`))
      .required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_EMAIL}`)),
    password: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_PASSWORD}`)),
    passwordConfirmation: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_PASSWORD}`)),
  })

  complexAddUserFormValidations = () => ({
    ...this.basicAddUserFormValidations(),
    name: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
    lastName: Yup.string(
      capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
    ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_LASTNAME}`)),
  })

  instructorFormValidations = () => ({
    ...this.complexAddUserFormValidations(),
    //availableCourses: Yup.string().required(),
    //availableCategories: Yup.string().required(),
  })

  getValidations = () => {
    switch (this.props.addPopup.type) {
      case popupKeys.EMPLOYEE.DEFAULT:
        return this.employeeValidations()
      case popupKeys.LEADER:
        return this.getDirectOrLeaderValidations()
      case userKeys.GENERAL_ADMIN:
        return this.complexAddUserFormValidations()
      case userKeys.ADMINISTRATOR:
        return this.complexAddUserFormValidations()
      case userKeys.INSTRUCTOR:
        return this.instructorFormValidations()
      case userKeys.LEADER_USER:
        return {
          ...this.basicAddUserFormValidations(),
          leaderLevel: Yup.string().required(),
        }
      default:
        return {
          name: Yup.string(
            capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
          ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
        }
    }
  }

  getDirectOrLeaderEmptyValues = (values) =>
    values.name === this.getInicialValues().name &&
    values.lastName === this.getInicialValues().lastName &&
    values.fileNumber === this.getInicialValues().fileNumber &&
    values.costCenter === this.getInicialValues().costCenter &&
    values.category === this.getInicialValues().category &&
    values.leaderType === this.getInicialValues().leaderType

  employeeEmptyValues = (values) => {
    switch (this.props.selector.value) {
      case popupKeys.EMPLOYEE.DIRECT:
        return this.getDirectOrLeaderEmptyValues(values)
      case popupKeys.EMPLOYEE.INDIRECT:
        return (
          values.name === this.getInicialValues().name &&
          values.lastName === this.getInicialValues().lastName &&
          values.documentNumber === this.getInicialValues().documentNumber &&
          values.businessInput === this.getInicialValues().businessInput
        )
      case popupKeys.EMPLOYEE.CARRIER:
        return (
          values.name === this.getInicialValues().name &&
          values.lastName === this.getInicialValues().lastName &&
          values.documentNumber === this.getInicialValues().documentNumber &&
          values.fileNumber === this.getInicialValues().fileNumber &&
          values.distributionNumber === this.getInicialValues().distributionNumber &&
          values.accountNumber === this.getInicialValues().accountNumber
        )
      default:
        return true
    }
  }

  basicAddUserForm = (values) =>
    values.userName === this.getInicialValues().userName &&
    values.email === this.getInicialValues().email &&
    values.password === this.getInicialValues().password &&
    values.passwordConfirmation === this.getInicialValues().passwordConfirmation

  complexAddUserForm = (values) =>
    this.basicAddUserForm(values) &&
    values.name === this.getInicialValues().name &&
    values.lastName === this.getInicialValues().lastName

  hasEmptyValues = (values) => {
    switch (this.props.addPopup.type) {
      case popupKeys.EMPLOYEE.DEFAULT:
        return this.employeeEmptyValues(values)
      case popupKeys.LEADER:
        return this.getDirectOrLeaderEmptyValues(values)
      case userKeys.GENERAL_ADMIN:
        return this.complexAddUserForm(values)
      case userKeys.ADMINISTRATOR:
        return this.complexAddUserForm(values)
      case userKeys.INSTRUCTOR:
        return this.complexAddUserForm(values)
      case userKeys.LEADER_USER:
        return this.basicAddUserForm(values)
      default:
        return values.name === this.getInicialValues().name
    }
  }

  renderForm = () => {
    const SignUpSchema = Yup.object().shape(this.getValidations())
    return (
      <Formik
        initialValues={this.getInicialValues()}
        validateOnChange={false}
        validationSchema={SignUpSchema}
        onSubmit={this.handleSubmit}
        render={(e) => this.getFormContent(e)}
      />
    )
  }

  handleSubmitEmployee = async (values) => {
    switch (this.props.selector.value) {
      case popupKeys.EMPLOYEE.DIRECT:
        if (this.props.selector.value === popupKeys.EMPLOYEE.DIRECT && !values.leader) {
          this.setState({ leaderError: true })
        } else {
          this.setState({ leaderError: false })
          await this.props.addDirectEmployee(values)
        }

        break
      case popupKeys.EMPLOYEE.INDIRECT:
        await this.props.addIndirectEmployee(values)
        break
      case popupKeys.EMPLOYEE.CARRIER:
        await this.props.addCarrierEmployee(values)
        break
    }
  }

  handleSubmit = async (values) => {
    if (values.password) {
      this.setState({ samePasswordError: !this.samePassword(values) })
    }
    if (values.leaderLevel) {
      if (values.distributionCenters) {
        this.setState({ distributionCentersError: false })
      } else if (values.leaderLevel.value === userKeys.BOSS) {
        this.setState({ distributionCentersError: false })
      } else {
        this.setState({ distributionCentersError: true })
      }
      if (values.leader) {
        this.setState({ leaderError: false })
      } else if (
        values.leaderLevel.value === userKeys.DIRECTOR ||
        values.leaderLevel.value === userKeys.MANAGER
      ) {
        this.setState({ leaderError: false })
      } else {
        this.setState({ leaderError: true })
      }
    }

    switch (this.props.addPopup.type) {
      case popupKeys.CATEGORY:
        await this.props.addCategory(values)
        break
      case popupKeys.LEADER:
        await this.props.addLeader(values)
        break
      case popupKeys.CENTER:
        await this.props.addCenter(values)
        break
      case popupKeys.COURSE:
        await this.props.addCourse(values, this.props.selector)
        this.props.resetSelector()
        break
      case popupKeys.EMPLOYEE.DEFAULT:
        await this.handleSubmitEmployee(values)
        break
      case userKeys.GENERAL_ADMIN:
        if (!this.state.samePasswordError) {
          await this.props.addGeneralAdministrators(values)
        } else {
          break
        }
        break
      case userKeys.ADMINISTRATOR:
        if (!this.state.samePasswordError) {
          await this.props.addAdministrator(values)
        } else {
          break
        }
        break
      case userKeys.INSTRUCTOR:
        console.log(values)
        if (!this.state.samePasswordError) {
          await this.props.addInstructor(values)
        } else {
          break
        }
        break
      case userKeys.LEADER_USER:
        if (
          !this.state.samePasswordError &&
          !this.state.leaderError &&
          !this.state.distributionCentersError
        ) {
          await this.props.addLeaderUser(values)
        } else {
          break
        }
        break
    }

    if (
      !this.hasError() &&
      !this.state.leaderError &&
      !this.state.distributionCentersError &&
      !this.state.samePasswordError
    ) {
      this.onClose()
    }
  }

  samePassword = (values) => values.password === values.passwordConfirmation

  renderInput = (values, setFieldValue, errors, setValues) => {
    switch (this.props.addPopup.type) {
      case popupKeys.COURSE:
        return courseInput(values, setFieldValue, errors, this.props.categories.values)
      case popupKeys.EMPLOYEE.DEFAULT:
        // eslint-disable-next-line no-case-declarations
        const newEmployeeTypes = [...employeeTypes]
        if (getCompanyById(2)) {
          newEmployeeTypes.splice(
            employeeTypes.findIndex((x) => x.id === popupKeys.EMPLOYEE.CARRIER),
            1
          )
        }
        return employeeInput(
          values,
          setFieldValue,
          errors,
          newEmployeeTypes,
          this.props.selector.value,
          this.props.centers,
          this.props.workSector,
          this.props.workPosition,
          this.props.leaders,
          setValues,
          this.props.business ? this.props.business.valuesAll : []
        )
      case popupKeys.LEADER:
        return leaderInput(
          values,
          setFieldValue,
          errors,
          this.props.centers,
          this.props.workSector,
          this.props.workPosition,
          this.props.leaders,
          true,
          this.props.fetchLeaders
        )
      case userKeys.GENERAL_ADMIN:
        return administratorInput(values, setFieldValue, errors)
      case userKeys.ADMINISTRATOR:
        return administratorInput(values, setFieldValue, errors, true, this.props.business)
      case userKeys.INSTRUCTOR:
        console.log(errors)
        return instructorInput(
          values,
          setFieldValue,
          errors,
          this.props.business,
          this.props.categories,
          this.props.courses,
          setValues
        )
      case userKeys.LEADER_USER:
        return leaderUserInput(
          values,
          setFieldValue,
          setValues,
          errors,
          this.props.leaders,
          this.props.centers
        )
      default:
        return defaultInput(values, setFieldValue, errors)
    }
  }

  getHeaderName = () => {
    switch (this.props.addPopup.type) {
      case popupKeys.CATEGORY:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY}`
      case popupKeys.LEADER:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`
      case popupKeys.CENTER:
        return `${
          isDanone()
            ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
            : i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_PLACE
        }`
      case popupKeys.COURSE:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_COURSE}`
      case popupKeys.EMPLOYEE.DEFAULT:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_EMPLOYEE}`
      case userKeys.GENERAL_ADMIN:
        return `${i18n('USER_TYPES').GENERAL_ADMIN}`
      case userKeys.ADMINISTRATOR:
        return `${i18n('USER_TYPES').ADMINISTRATOR}`
      case userKeys.INSTRUCTOR:
        return `${i18n('USER_TYPES').INSTRUCTOR}`
      case userKeys.LEADER_USER:
        return `${i18n('USER_TYPES').LEADER_USER}`
    }
  }

  isFetching = () =>
    this.props.categories.isAdding ||
    this.props.leaders.isAdding ||
    this.props.centers.isAdding ||
    this.props.courses.isAdding ||
    this.props.directEmployee.isAdding ||
    this.props.indirectEmployee.isAdding ||
    this.props.carrierEmployee.isAdding ||
    this.props.generalAdministrators.isAdding ||
    this.props.administrators.isAdding ||
    this.props.instructors.isAdding ||
    this.props.leaderUsers.isAdding

  hasError = () =>
    this.props.categories.errorAdd ||
    this.props.leaders.errorAdd ||
    this.props.centers.errorAdd ||
    this.props.courses.errorAdd ||
    this.props.directEmployee.errorAdd ||
    this.props.indirectEmployee.errorAdd ||
    this.props.carrierEmployee.errorAdd ||
    this.props.generalAdministrators.errorAdd ||
    this.props.administrators.errorAdd ||
    this.props.instructors.errorAdd ||
    this.props.leaderUsers.errorAdd

  getFormContent = ({ handleSubmit, values, setFieldValue, errors, setValues }) => (
    <StyledForm onSubmit={handleSubmit}>
      <StyledDivHeaderWrapper>
        <StyledTextHeader>
          {capitalizeFirstLetter(`${i18n('FLOATING_BUTTON').FLOATING_BUTTON_MAIN}`)}{' '}
          {this.getHeaderName()}
        </StyledTextHeader>
        <CrossImage onClick={() => this.onClose(true)} src={CrossIcon} />
      </StyledDivHeaderWrapper>
      {this.renderInput(values, setFieldValue, errors, setValues)}
      <StyledDivFooterWrapper>
        <ButtonWrapper>
          <ButtonMaterial
            type={'submit'}
            color={theme.colors.white}
            background={this.hasEmptyValues(values) ? theme.colors.dustyGray : theme.colors.purple}
            borderRadius={'10'}
            fontSize={'18'}
            small
            disabled={this.hasEmptyValues(values) ? true : false}
            loading={this.isFetching()}
            display={true}
          >
            {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_SAVE}`)}
          </ButtonMaterial>
        </ButtonWrapper>
        <StyledDivWrapper>
          {this.hasError() && (
            <ErrorLabel>
              {this.hasError().payload.error && this.hasError().payload.error.errors[0]
                ? this.hasError().payload.error.errors[0]
                : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR}`)}
            </ErrorLabel>
          )}
          {errors.distributionCenterId ||
          errors.workSector ||
          errors.workPosition ||
          this.state.leaderError ||
          this.state.distributionCentersError ||
          errors.distributionCenters ||
          errors.business ||
          errors.leader ||
          errors.availableCourses ||
          errors.availableCategories ? (
            <ErrorLabel>{capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ERROR}`)}</ErrorLabel>
          ) : (
            ''
          )}
          {this.state.samePasswordError ? (
            <ErrorLabel>
              {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_NO_MATCH_PASSWORD}`)}
            </ErrorLabel>
          ) : (
            ''
          )}
        </StyledDivWrapper>
      </StyledDivFooterWrapper>
    </StyledForm>
  )

  render() {
    return (
      <Modal>
        <StyledContainer>{this.renderForm()}</StyledContainer>
      </Modal>
    )
  }
}

const mapStateToProps = ({
  addPopup,
  categories,
  leaders,
  centers,
  selector,
  courses,
  workSector,
  workPosition,
  directEmployee,
  indirectEmployee,
  carrierEmployee,
  administrators,
  instructors,
  leaderUsers,
  generalAdministrators,
  business,
}) => ({
  addPopup,
  categories,
  leaders,
  centers,
  selector,
  courses,
  workSector,
  workPosition,
  directEmployee,
  indirectEmployee,
  carrierEmployee,
  administrators,
  instructors,
  leaderUsers,
  generalAdministrators,
  business,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAddPopupStatus,
      addCategory,
      setDisplayToast,
      setCapacitationDetailStatus,
      resetSelectedSearchedItem,
      addLeader,
      addCenter,
      fetchCenters,
      addCourse,
      resetSelector,
      fetchWorkSector,
      fetchWorkPosition,
      fetchLeaders,
      addDirectEmployee,
      addIndirectEmployee,
      addCarrierEmployee,
      addAdministrator,
      addInstructor,
      addLeaderUser,
      fetchCourses,
      resetAddPopupErrors,
      fetchCategories,
      addGeneralAdministrators,
      fetchBusiness,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AddPopup)

import { handleActions } from 'redux-actions'
import {
  byCategoriesStart,
  byCategoriesSuccess,
  byCategoriesError,
  byCategoriesReset,
} from 'actions/Dashboard/SummaryTraining/byCategories'

const defaultState = {
  success: false,
  values: [],
  total: 0,
  error: null,
  isLoading: false,
}

const reducer = handleActions(
  {
    [byCategoriesStart]: (state) => ({
      ...state,
      success: false,
      isLoading: true,
      error: [],
    }),
    [byCategoriesSuccess]: (state, { payload: { values, total } }) => ({
      ...state,
      success: true,
      values,
      total,
      isLoading: false,
    }),
    [byCategoriesError]: (state, { payload: { error } }) => ({
      ...state,
      success: false,
      isLoading: false,
      error,
    }),
    [byCategoriesReset]: () => ({ ...defaultState }),
  },
  defaultState
)

export default reducer

import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchPendingTrainingStart,
  fetchPendingTrainingSuccess,
  fetchPendingTrainingError,
  pendingTrainingReset
} = createActions({
  FETCH_PENDING_TRAINING_START: () => {},
  FETCH_PENDING_TRAINING_SUCCESS: data => ({ data }),
  FETCH_PENDING_TRAINING_ERROR: error => ({ error }),
  PENDING_TRAINING_RESET: () => {}
})

const fetchPendingTraining = (courseIds, mode, year) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchPendingTrainingStart())
    const body = {
      Mode: mode,
      Year: year,
      CourseCategories: [],
      Courses: [courseIds],
      DistributionCenters: [],
      Leaders: [],
      WorkSectors: []
    }
    try {
      const response = await api.post(`${API_ROUTES.TRAINING_PENDING}`, body)
      dispatch(fetchPendingTrainingSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchPendingTrainingError(error))
    }
  }
}

const resetPendingTraining = () => dispatch => dispatch(pendingTrainingReset())

export {
  fetchPendingTraining,
  fetchPendingTrainingStart,
  fetchPendingTrainingSuccess,
  fetchPendingTrainingError,
  pendingTrainingReset,
  resetPendingTraining
}

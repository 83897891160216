import { combineReducers } from 'redux'

import auth from './authentication'
import categories from './categories'
import courses from './courses'
import capacitations from './capacitations'
import directEmployee from './directEmployee'
import carrierEmployee from './carrierEmployee'
import indirectEmployee from './indirectEmployees'
import excelDownload from './excelDownload'
import selectedFilter from './selectedFilter'
import expandedCategory from './expandedCategory'
import selectedCapacitation from './selectedCapacitation'
import capacitationDetailStatus from './capacitationDetailStatus'
import centers from './centers'
import leaders from './leaders'
import selectedDateFilter from './selectedDateFilter'
import selectedSearchedItem from './selectedSearchedItem'
import selectedPage from './page'
import editPopup from './editPopup'
import addPopup from './addPopup'
import toastMsj from './toast'
import selector from './selector'
import workSector from './workSector'
import workPosition from './workPosition'
import deletePopup from './deletePopup'
import expandedUserType from './expandedUserType'
import administrators from './administrators'
import instructors from './instructors'
import leaderUsers from './leaderUsers'
import picturesPopup from './picturesPopup'
import trainingSummary from './trainingSummary'
import trainingDetail from './trainingDetail'
import trainingPending from './trainingPending'
import importPopup from './importPopup'
import importPopupCourses from './importPopupCourses';
import excelImport from './excelImport'
import activeYears from './activeYears'
import capacitationImagesDownload from './capacitationImagesDownload'
import selectedUserStatus from './selectedUserStatus'
import directEmployeeStaffId from './directEmployeeStaffId'
import capInDetailPdf from './capInDetailPdf'
import generalAdministrators from './generalAdmin'
import business from './business'
import selectedUsersFilter from './selectedUsersFilter'
import byCenters from './Dashboard/SummaryTraining/byCenters'
import byCategories from './Dashboard/SummaryTraining/byCategories'
import legal from './Dashboard/TrainingDetail/legal'
import leaderUsersByCenterIds from './leaderUsersByCenterIds'
import loading from './loading'
import coursesByCategoriesIds from './coursesByCategoriesIds'
import optional from './Dashboard/TrainingDetail/optional'
import pendingTraining from './Dashboard/pendingTraining'

const rootReducer = combineReducers({
  auth,
  categories,
  courses,
  capacitations,
  directEmployee,
  carrierEmployee,
  indirectEmployee,
  excelDownload,
  selectedFilter,
  expandedCategory,
  selectedCapacitation,
  capacitationDetailStatus,
  centers,
  leaders,
  selectedDateFilter,
  selectedSearchedItem,
  selectedPage,
  editPopup,
  addPopup,
  toastMsj,
  selector,
  workSector,
  workPosition,
  deletePopup,
  expandedUserType,
  administrators,
  instructors,
  leaderUsers,
  picturesPopup,
  trainingSummary,
  trainingDetail,
  trainingPending,
  importPopup,
  importPopupCourses,
  excelImport,
  activeYears,
  capacitationImagesDownload,
  selectedUserStatus,
  directEmployeeStaffId,
  capInDetailPdf,
  generalAdministrators,
  business,
  selectedUsersFilter,
  byCenters,
  byCategories,
  leaderUsersByCenterIds,
  loading,
  legal,
  coursesByCategoriesIds,
  optional,
  pendingTraining,
})

export default rootReducer

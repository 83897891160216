import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import FiltersUrl from 'components/FiltersUrl'
import filtersByCenters from 'config/Dashboard/Filters/filtersByCenters'
import Table from 'components/Table'
import columnsByCenters from 'config/Dashboard/Columns/columnsByCenters'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { getByCentersRequest } from 'actions/Dashboard/SummaryTraining/byCenters'
import { loadingStart, loadingEnd } from 'actions/loading'
import PaginationUrl from 'components/PaginationUrl'

export default function ByCenters() {
  const dispatch = useDispatch()
  const dataByCenters = useSelector((state) => state.byCenters)
  const location = useLocation()
  const query = queryString.parse(location.search)
  useEffect(() => {
    filtersByCenters
      .filter((x) => x.isMulti)
      .forEach((filterMulti) => {
        if (query[filterMulti.name] && !(query[filterMulti.name] instanceof Array)) {
          query[filterMulti.name] = [query[filterMulti.name]]
        }
      })
    dispatch(loadingStart())
    dispatch(getByCentersRequest(query)).then(() => {
      dispatch(loadingEnd())
    })
  }, [location.search])

  return (
    <>
      <FiltersUrl filters={filtersByCenters} isLoading={dataByCenters.isLoading} />
      <Table
        columns={columnsByCenters}
        data={dataByCenters.values}
        getCell={(data, column, getCellDefault) =>
          column.name === 'distributionCenter'
            ? data[column.name].name
            : getCellDefault(data, column)
        }
      />
      {dataByCenters.values.length ? (
        <PaginationUrl total={dataByCenters.total} size={10}></PaginationUrl>
      ) : (
        ''
      )}
    </>
  )
}

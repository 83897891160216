export default [
  {
    label: 'Personas a capacitar',
    name: 'trainedEmployees',
  },
  {
    label: 'Personas capacitadas',
    name: 'totalEmployees',
  },
  {
    label: 'Horas planificadas',
    name: 'plannedHours',
  },
  {
    label: 'Horas reales',
    name: 'trainedHours',
  },
  {
    label: 'Horas totales',
    name: 'totalHours',
  },
  {
    label: 'Cumplimiento',
    name: 'compliancePercentage',
  },
  {
    label: 'Estado',
    name: 'complianceStatus',
  },
]

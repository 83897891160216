import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'

import { setSelector } from 'actions/selector'

import { reestructureDataArray } from 'utils/selectorParser'

class Selector extends Component {
  state = {}

  handleChange = selectedOption => {
    this.props.setSelector(selectedOption)
  }

  render() {
    const { selectorValues, placeholder } = this.props

    return (
      <Select
        placeholder={placeholder}
        onChange={this.handleChange}
        options={reestructureDataArray(selectorValues)}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = ({ selector }) => ({
  selector
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelector
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Selector)

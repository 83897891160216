import columnsByGroup from './columnsByGroup'

export default [
  {
    label: 'Curso',
    name: 'course',
    subProp: 'name',
  },
  ...columnsByGroup,
]

import { createActions } from 'redux-actions'
import getApi from 'services/api'
import { saveLocalStatus } from 'utils/session'
import API_ROUTES from 'config/apiRoutes'

const { loginStart, loginSuccess, loginError, loginResetState } = createActions({
  LOGIN_START: () => {},
  LOGIN_SUCCESS: (data) => ({ data }),
  LOGIN_ERROR: (error) => ({ error }),
  LOGIN_RESET_STATE: () => {},
})

const login = (username, password) => {
  return async (dispatch) => {
    const api = await getApi()
    dispatch(loginStart())
    const body = {
      username,
      password,
    }
    try {
      const url = API_ROUTES.LOGIN + '?isMobile=false'

      const { data } = await api.post(url, body)
      saveLocalStatus(data.data.token, data.data.userInfo)
      dispatch(loginSuccess())
      return data.data.userInfo.rol.name
    } catch (error) {
      dispatch(loginError(error.data))
    }
  }
}

const resetLoginReducer = () => (dispatch) => dispatch(loginResetState())

export { login, loginStart, loginSuccess, loginError, loginResetState, resetLoginReducer }

export default {
  EMPLOYEE: 'employee',
  EMPLOYEE_DIRECT: {
    DIRECT: 'direct',
    ALL: 'allDirect',
    NAME: 'nameDirect',
    FILE: 'fileDirect'
  },
  EMPLOYEE_CARRIER: {
    CARRIER: 'carrier',
    ALL: 'allCarrier',
    NAME: 'nameCarrier',
    FILE: 'fileCarrier',
    DOCUMENT: 'documentCarrier',
    DISTRIBUCION: 'distibucionCarrier',
    ACCOUNT: 'accountCarrier'
  },
  EMPLOYEE_INDIRECT: {
    INDIRECT: 'indirect',
    ALL: 'allIndirect',
    NAME: 'nameIndirect',
    DOCUMENT: 'documentIndirect'
  },
  COURSE: {
    COURSE_ID: 'course',
    ALL: 'allCourse',
    NAME: 'nameCourse'
  },
  CENTER: {
    CENTER_ID: 'center',
    ALL: 'allCenter',
    NAME: 'nameCenter'
  },
  LEADER: {
    LEADER_ID: 'leader',
    ALL: 'allLeader',
    NAME: 'nameLeader'
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'

import { setSelectedDateFilter } from 'actions/selectedDateFilter'

import { DateInputWrapper } from './styled'

import { capitalizeFirstLetter } from 'utils/strings'

import i18n from 'services/i18n'

import 'moment/locale/es.js'

class DateInput extends Component {
  handleFromChange = from => {
    this.props.setSelectedDateFilter({ filterDateFrom: from })
  }
  handleToChange = to => {
    this.props.setSelectedDateFilter({ filterDateTo: to })
  }

  render() {
    const { filterDateFrom, filterDateTo } = this.props.selectedDateFilter
    const modifiers = { start: filterDateFrom, end: filterDateTo }
    return (
      <DateInputWrapper>
        <DayPickerInput
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
          value={this.props.selectedDateFilter.filterDateFrom}
          placeholder={capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_DATE_FILTER_FROM}...`)}
          format="DD/MM/YYYY"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            locale: 'es',
            localeUtils: MomentLocaleUtils,
            selectedDays: [
              this.props.selectedDateFilter.filterDateFrom,
              { filterDateFrom, filterDateTo }
            ],
            disabledDays: { after: this.props.selectedDateFilter.filterDateTo },
            toMonth: this.props.selectedDateFilter.filterDateTo,
            modifiers,
            numberOfMonths: 1,
            onDayClick: () => this.dateTo.getInput().focus()
          }}
          onDayChange={this.handleFromChange}
        />

        <DayPickerInput
          ref={el => (this.dateTo = el)}
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
          value={this.props.selectedDateFilter.filterDateTo}
          placeholder={capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_DATE_FILTER_TO}...`)}
          format="DD/MM/YYYY"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            locale: 'es',
            localeUtils: MomentLocaleUtils,
            selectedDays: [
              this.props.selectedDateFilter.filterDateFrom,
              { filterDateFrom, filterDateTo }
            ],
            disabledDays: { before: this.props.selectedDateFilter.filterDateFrom },
            modifiers,
            month: this.props.selectedDateFilter.filterDateFrom,
            fromMonth: this.props.selectedDateFilter.filterDateFrom,
            numberOfMonths: 1
          }}
          onDayChange={this.handleToChange}
        />
        <style>{`
        
  .DayPickerInput > input {
    border-radius:4px;
    height: 20px;
    padding: 2px 4px;
    display: flex;
    align-items: center;
    max-height: 50px;
    box-shadow: none;
    border: none;
    width: 100%;
    font-size:16px;
    text-overflow: ellipsis;
  }
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):
  not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 550px;
   
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`}</style>
      </DateInputWrapper>
    )
  }
}

const mapStateToProps = ({ selectedDateFilter }) => ({
  selectedDateFilter
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedDateFilter
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateInput)

import styled from 'styled-components'

import { Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import theme from 'config/theme'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const InputWrapper = styled.div`
  width: 45%;
`

export const Title = styled.label`
  color: ${({ theme }) => theme.colors.disabledUserGrey};
  font-size: 13px;
  display: flex;
  margin-bottom: 5px;
`

export const StyledWrapper = styled.div`
  flex-direction: column;
  width: 45%;
`

export const SwitchWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`

export const Text = styled.text`
  margin: auto auto;
`

export const WrapperCheckbox = styled.div`
  margin: 0px 0px 10px 0px;
`

export const WrapperSelect = styled.div`
  flex-direction: column;
  margin: 20px 0px 0px;
`

const checkBoxStyles = {
  root: {
    '&$checked': {
      color: theme.colors.purple
    }
  },
  checked: {}
}

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox)

import React from 'react'
import { Container, Button } from 'react-floating-action-button'

import AddIcon from '@material-ui/icons/Add'
import IconCategory from 'assets/icons/iconCategory.png'
import IconCourse from 'assets/icons/iconCourse.png'
import IconCenter from 'assets/icons/iconCenter.png'
import IconLeader from 'assets/icons/iconLeader.png'
import IconEmployees from 'assets/icons/iconEmployees.png'
import IconAdmin from 'assets/icons/iconAdmin.png'
import IconFileFull from 'assets/icons/iconFileFull.png'
import IconInstructor from 'assets/icons/iconInstructor.png'
import { ContainerStyles, LinkStyles, ButtonStyles, IconButtonStyles, StyledIcon } from './styled'

import { capitalizeFirstLetter } from 'utils/strings'
import isDanone from 'utils/isDanone'

import i18n from 'services/i18n'

import popupKeys from 'constants/popupKeys'
import userKeys from 'constants/userKeys'

const container = (children) => (
  <Container styles={ContainerStyles}>
    {children}
    <Button
      styles={ButtonStyles}
      tooltip={capitalizeFirstLetter(`${i18n('FLOATING_BUTTON').FLOATING_BUTTON_MAIN}`)}
      rotate={true}
    >
      <AddIcon styles={IconButtonStyles} />
    </Button>
  </Container>
)

export const coursesButtons = (props) =>
  container(
    <>
      <Button
        tooltip={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_EMPLOYEE}`)}
        styles={LinkStyles}
        onClick={() => props.setAddPopupStatus({ open: true, type: popupKeys.EMPLOYEE.DEFAULT })}
      >
        <StyledIcon src={IconEmployees} />
      </Button>
      <Button
        tooltip={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`)}
        styles={LinkStyles}
        onClick={() => props.setAddPopupStatus({ open: true, type: popupKeys.LEADER })}
      >
        <StyledIcon src={IconLeader} />
      </Button>
      <Button
        tooltip={capitalizeFirstLetter(
          `${
            isDanone()
              ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
              : i18n('CAPACITATION').CAPACITATION_TABLE_DISTRIBUTION_CENTER
          }`
        )}
        styles={LinkStyles}
        onClick={() => props.setAddPopupStatus({ open: true, type: popupKeys.CENTER })}
      >
        <StyledIcon src={IconCenter} />
      </Button>
      <Button
        tooltip={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_COURSE}`)}
        styles={LinkStyles}
        onClick={() => props.setAddPopupStatus({ open: true, type: popupKeys.COURSE })}
      >
        <StyledIcon src={IconCourse} />
      </Button>
      <Button
        tooltip={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY}`)}
        styles={LinkStyles}
        onClick={() => props.setAddPopupStatus({ open: true, type: popupKeys.CATEGORY })}
      >
        <StyledIcon src={IconCategory} />
      </Button>
    </>
  )

export const usersButtons = (props) =>
  container(
    <>
      <Button
        tooltip={capitalizeFirstLetter(`Importar Planes`)}
        styles={LinkStyles}
        onClick={() => props.setImportPopupCoursesStatus({ open: true })}
      >
        <StyledIcon src={IconFileFull} />
      </Button>
      <Button
        tooltip={capitalizeFirstLetter(`${i18n('IMPORT_POPUP').IMPORT}`)}
        styles={LinkStyles}
        onClick={() => props.setImportPopupStatus({ open: true })}
      >
        <StyledIcon src={IconFileFull} />
      </Button>
      <Button
        tooltip={capitalizeFirstLetter(`${i18n('USER_TYPES').INSTRUCTOR}`)}
        styles={LinkStyles}
        onClick={() => props.setAddPopupStatus({ open: true, type: userKeys.INSTRUCTOR })}
      >
        <StyledIcon src={IconInstructor} />
      </Button>
      <Button
        tooltip={capitalizeFirstLetter(`${i18n('USER_TYPES').LEADER_USER}`)}
        styles={LinkStyles}
        onClick={() => props.setAddPopupStatus({ open: true, type: userKeys.LEADER_USER })}
      >
        <StyledIcon src={IconLeader} />
      </Button>{' '}
    </>
  )

export const generalAdminButtons = (props) =>
  container(
    <>
      <Button
        tooltip={capitalizeFirstLetter(`${i18n('USER_TYPES').GENERAL_ADMIN}`)}
        styles={LinkStyles}
        onClick={() => props.setAddPopupStatus({ open: true, type: userKeys.GENERAL_ADMIN })}
      >
        <StyledIcon src={IconAdmin} />
      </Button>
      <Button
        tooltip={capitalizeFirstLetter(`${i18n('USER_TYPES').ADMINISTRATOR}`)}
        styles={LinkStyles}
        onClick={() => props.setAddPopupStatus({ open: true, type: userKeys.ADMINISTRATOR })}
      >
        <StyledIcon src={IconAdmin} />
      </Button>
    </>
  )

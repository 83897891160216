export const filtersDirectEmployee = {
  Filters: [],
  Sort: null,
  Pager: {
    PageSize: null,
    PageNumber: null
  },
  Name: null,
  FileNumber: null,
  DistributionCenters: [],
  WorkSectors: [],
  WorkPositions: [],
  Leaders: []
}

export const filtersCarrierEmployee = {
  Filters: [],
  Sort: null,
  Pager: {
    PageSize: null,
    PageNumber: null
  },
  Name: null,
  FileNumber: null,
  DocumentNumber: null,
  DistributionNumber: null,
  AccountNumber: null
}

export const filtersIndirectEmployee = {
  Filters: [],
  Sort: null,
  Pager: {
    PageSize: null,
    PageNumber: null
  },
  Name: null,
  DocumentNumber: null
}

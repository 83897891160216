import { handleActions } from 'redux-actions'
import { fetchExcelStart, fetchExcelSuccess, fetchExcelError } from 'actions/excelDownload'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,
  isShowing: false
}

const reducer = handleActions(
  {
    [fetchExcelStart]: state => ({
      ...state,
      isFetching: true,
      error: null,
      isShowing: true
    }),
    [fetchExcelSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null,
      isShowing: true
    }),
    [fetchExcelError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error,
      isShowing: true
    })
  },
  defaultState
)

export default reducer

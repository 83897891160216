import userKeys from 'constants/userKeys'
import i18n from 'services/i18n'

export default [
  {
    id: userKeys.GENERAL_ADMIN,
    name: `${i18n('USER_TYPES').GENERAL_ADMIN}`
  },
  {
    id: userKeys.ADMINISTRATOR,
    name: `${i18n('USER_TYPES').ADMINISTRATOR}`
  },
  {
    id: userKeys.INSTRUCTOR,
    name: `${i18n('USER_TYPES').INSTRUCTOR}`
  },
  {
    id: userKeys.LEADER_USER,
    name: `${i18n('USER_TYPES').LEADER_USER}`
  }
]

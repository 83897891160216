export default {
  PAGE_COURSES: 'cursos',
  PAGE_USERS: 'usuarios',
  PAGE_DASHBOARD: 'dashboard',
  PAGES_DASHBOARD: {
    TRAINING_SUMMARY: 'resumen-capacitaciones',
    TRAINING_DETAIL: 'detalles-capacitaciones',
    TRAINING_PENDING: 'capacitaciones-pendientes',
    BY_CENTERS: 'por-centros',
    BY_CATEGORIES: 'por-categorias',
    LEGAL: 'obligatorios',
    OPTIONAL: 'opcionales',
  },
  PAGE_POLICY: 'politicas',
  LOGIN: {
    LOGIN_PAGE: 'login',
    LOGIN_FORM_INPUT_USERNAME: 'usuario',
    LOGIN_FORM_INPUT_PASSWORD: 'contraseña',
    LOGIN_FORM_INPUT_CONFIRM_PASSWORD: 'confirme contraseña',
    LOGIN_FORM_BUTTON: 'ingresar',
    LOGIN_FORM_USERNAME_ERROR: 'usuario requerido',
    LOGIN_FORM_PASSWORD_ERROR: 'contraseña requerida',
    LOGIN_ERROR_MESSAGE: 'no fue posible logearse',
  },
  HOME: {
    HOME_PAGE_NO_CATEGORIES: 'no hay categorías disponibles',
    HOME_PAGE_NO_COURSES: 'no hay cursos disponibles',
    HOME_PAGE_NO_CAPACITATIONS: 'no hay capacitationes disponibles',
    HOME_PAGE_LOGOUT: 'cerrar sesión',
    NO_USERS: 'no hay usuarios disponibles',
  },
  CAPACITATION: {
    CAPACITATION: 'capacitación',
    CAPACITATION_TITLE: 'rrh03',
    CAPACITATION_PREVIEW_HEADER_NAME: 'nombre',
    CAPACITATION_PREVIEW_HEADER_DURATIONS: 'duración',
    CAPACITATION_HEADER_ESTIMATED_DURATION: 'duración estimada',
    CAPACITATION_HEADER_REAL_DURATION: 'duración',
    CAPACITATION_PREVIEW_HEADER_MEMBERS: 'integrantes',
    CAPACITATION_PREVIEW_HEADER_INTERN: 'interno',
    CAPACITATION_PREVIEW_HEADER_INSTRUCTOR: 'instructor',
    CAPACITATION_PREVIEW_HEADER_PLACE: 'centro',
    CAPACITATION_PREVIEW_HEADER_MANAGEMENT: 'gerencia',
    CAPACITATION_PREVIEW_HEADER_OBSERVATIONS: 'observación',
    CAPACITATION_PREVIEW_HEADER_DATE: 'fecha',
    CAPACITATION_PREVIEW_INTERN_AFFIRMATIVE: 'si',
    CAPACITATION_PREVIEW_INTERN_NEGATIVE: 'no',
    CAPACITATION_TABLE_NOT_APPLAY: 'n/a',
    CAPACITATION_TABLE_PICTURES: 'imagenes',
    CAPACITATION_TABLE_EMPLOYEE: 'empleado',
    CAPACITATION_TABLE_ID: 'documento',
    CAPACITATION_TABLE_FILE_NUMBER: 'legajo',
    CAPACITATION_TABLE_ACCOUNT_NUMBER: 'n.º cuenta',
    CAPACITATION_TABLE_DISTRIBUTION_NUMBER: 'n.º distribución',
    CAPACITATION_TABLE_COURSE: 'curso',
    CAPACITATION_TABLE_CATEGORY: 'categoría',
    CAPACITATION_TABLE_COST_CENTER: 'costo de centro',
    CAPACITATION_TABLE_COST_MANAGEMENT: 'costo de gerencia',
    CAPACITATION_TABLE_LEADER: 'jefe',
    CAPACITATION_TABLE_DISTRIBUTION_CENTER: 'centro de distribución',
    CAPACITATION_TABLE_SECTOR: 'sector',
    CAPACITATION_TABLE_RANK: 'posición',
    CAPACITATION_TABLE_NOTE: 'nota',
    CAPACITATION_TABLE_LASTNAME: 'apellido',
    CAPACITATION_TABLE_DIRECT_EMPLOYEE: 'personal efectivo',
    CAPACITATION_TABLE_CARRIER_EMPLOYEE: 'personal transportista',
    CAPACITATION_TABLE_INDIRECT_EMPLOYEE: 'personal de agencia o contratista',
    CAPACITATION_TABLE_GROUPER: 'agrupador',
    EMAIL: 'email',
    COMPANY: 'empresa',
    NO_EMPLOYEES: {
      NO_DIRECT_EMPLOYEES: 'no hay personal efectivo en esta capacitación',
      NO_CARRIER_EMPLOYEES: 'no hay personal transportista en esta capacitación',
      NO_INDIRECT_EMPLOYEES: 'no hay personal de agencia o contratista en esta capacitación',
    },
    APPROVED: 'Aprobado',
    DISAPPROVED: 'Desaprobado',
  },
  SEARCH: {
    SEARCH_FILTERS_HINT: 'seleccione los filtos de búsqueda',
    SEARCH_INPUT_HINT: 'buscar...',
    SEARCH_ERROR_NO_SEARCH: 'por favor ingrese su búsqueda',
    SEARCH_ERROR_NO_FILTER_CATEGORY: 'por favor seleccione una categoria de filtro',
    SEARCH_ERROR_NO_FILTER_ITEM: 'por favor seleccione un filtro',
    SEARCH_DELETE_BUTTON: 'limpiar',
    SEARCH_NO_DATA: 'no hay información para mostrar',
    SEARCH_ERROR: 'hubo un error en la busqueda',
    SEARCH_DATE_FILTER_FROM: 'desde',
    SEARCH_DATE_FILTER_TO: 'hasta',
  },
  EDIT_VIEW: {
    EDIT_VIEW_SAVE: 'guardar',
    EDIT_VIEW_EDIT: 'editar',
    EDIT_VIEW_ERROR_REQUIRED_TEXT: 'por favor ingrese un texto válido',
    EDIT_VIEW_ERROR_REQUIRED_EMAIL: 'por favor ingrese un email válido',
    EDIT_VIEW_ERROR_REQUIRED_NUMBER: 'por favor ingrese un número válido',
    EDIT_VIEW_ERROR_REQUIRED_NAME: 'por favor ingrese un nombre válido',
    EDIT_VIEW_ERROR_REQUIRED_LASTNAME: 'por favor ingrese un apellido válido',
    EDIT_VIEW_ERROR_REQUIRED_FILE: 'por favor ingrese un legajo válido',
    EDIT_VIEW_ERROR_REQUIRED_CATEGORY: 'por favor ingrese una categoría válida',
    EDIT_VIEW_ERROR_REQUIRED_LEADER: 'por favor ingrese un líder válido',
    EDIT_VIEW_ERROR_REQUIRED_CENTER: 'por favor ingrese un centro de distribución válido',
    EDIT_VIEW_ERROR_REQUIRED_MANAGEMENT: 'por favor ingrese una gerencia válida',
    EDIT_VIEW_ERROR_REQUIRED_SECTOR: 'por favor ingrese un sector válido',
    EDIT_VIEW_ERROR_REQUIRED_POSITION: 'por favor ingrese una posición válida',
    EDIT_VIEW_ERROR_REQUIRED_DOCUMENT: 'por favor ingrese un documento válido',
    EDIT_VIEW_ERROR_REQUIRED_ACCOUNT: 'por favor ingrese una cuenta válido',
    EDIT_VIEW_ERROR_REQUIRED_DISTRIBUTION_NUMBER: 'por favor ingrese una distribución válida',
    EDIT_VIEW_ERROR_REQUIRED_OBSERVATION: 'por favor ingrese una observación válida',
    EDIT_VIEW_ERROR_REQUIRED_DURATION: 'por favor ingrese una duración válida',
    EDIT_VIEW_ERROR_REQUIRED_USERNAME: 'por favor ingrese un usuario válido',
    EDIT_VIEW_ERROR_REQUIRED_PASSWORD: 'por favor ingrese una contraseña válida',
    EDIT_VIEW_NULL_ERROR: 'no disponible',
    EDIT_VIEW_ERROR: 'hubo un problema',
    EDIT_VIEW_ERROR_NO_MATCH_PASSWORD: 'las contraseñas no son iguales',
    EDIT_VIEW_ERROR_REQUIRED_STAFF_ID: 'por favor ingrese un id válido',
    EDIT_VIEW_EMPLOYEE_ID: 'id de empleado',
    EDIT_VIEW_DISABLE: 'habilitar',
  },
  TOAST: {
    TOAST_SAVED: 'guardado',
    TOAST_SAVED_LORE: 'se guardó correctamente',
    TOAST_DELETED: 'eliminado',
    TOAST_DELETED_LORE: 'se eliminó correctamente',
  },
  FLOATING_BUTTON: {
    FLOATING_BUTTON_MAIN: 'añadir',
  },
  SELECTOR: {
    SELECTOR_CATEGORY: 'seleccione una categoría',
    SELECTOR_EMPLOYEE: 'seleccione un tipo de empleado',
    SELECTOR_CENTER: 'seleccione un centro',
    SELECTOR_MANAGEMENT: 'seleccione gerencia',
    SELECTOR_WORK_POS: 'seleccione una posición',
    SELECTOR_LEADER: 'seleccione un jefe',
    SELECTOR_LEADERTYPE: 'seleccione un tipo de jefe',
    SELECTOR_WORK_SECTOR: 'seleccione un sector',
    SELECTOR_ERROR: 'complete los selectores',
    SELECTOR_ROLE: 'seleccione un rol',
    SELECTOR_COURSE: 'seleccione un curso',
    SELECTOR_ALL: 'todos',
    SELECTOR_OTHER: 'otro',
    SELECTOR_BUSINESS: 'seleccione una empresa',
    SELECTOR_COMPANY: 'seleccione un negocio',
    BUSINESS_INPUT: 'nombre de la empresa',
    ACTIVE: 'activos',
    INACTIVE: 'inactivos',
    SELECTOR_USERS_STATE: 'seleccione el estado de los usuarios',
    BUSINESS: 'negocio',
  },
  DELETE_VIEW: {
    DELETE: 'eliminar',
    DELETE_VIEW_CONFIRM: 'confirmar',
    DELETE_VIEW_LORE: 'se eliminara',
    DELETE_VIEW_LORE_CAPACITATION: 'capacitacion realizada el',
  },
  USER_TYPES: {
    GENERAL_ADMIN: 'administrador general',
    ADMINISTRATOR: 'administrador',
    INSTRUCTOR: 'instructor',
    LEADER_USER: 'usuarios dashboard',
    PREVIEW_CAN_ADD_COURSES: 'puede agregar cursos',
    DIRECTOR: 'director',
    MANAGER: 'gerente',
    ROLE: 'rol',
  },
  PIC_POPUP: {
    NO_IMG: 'sin imagen',
    PIC: 'foto',
    SIGNATURE: 'firma',
  },
  404: {
    ROUTE_NOT_FOUND: 'no hemos encontrado la ruta, ',
    REDIRECT_MESSAGE: 'redirigir a la página principal',
  },
  DASHBOARD: {
    RESUME_TAB: 'Resumen de capacitaciones',
    DETAIL_TAB: 'Detalle de capacitaciones',
    PENDING_TAB: 'Capacitaciones pendientes',
    CENTER: 'Centros',
    CATEGORIES: 'Categorías',
    NEXT_TEXT: 'siguiente',
    PREVIOUS_TEXT: 'previo',
    NO_DATA_TEXT: 'No se econtraron filas',
    LOADING_TEXT: 'cargando',
    PAGE_TEXT: 'página',
    OF_TEXT: 'de',
    ROWS_TEXT: 'filas',
    SUCCESS_TEXT: 'éxito',
    FAILURE_TEXT: 'falló',
    ANNUAL: 'anual',
    YEAR_TODAY: 'ytd',
    INFORMATION: 'información',
    IS_BOSS: 'es jefe',
    NO_EMPLOYEES: 'no hay empleados para capacitar',
    PENDING_EMPLOYEES_HINT: 'pendientes a capacitar',
    LEADERS_HINT: 'jefes asociados',
    HEADER: {
      STATUS: 'estado',
      HOURS: 'horas',
    },
    COLUMN_HEADER: {
      TRAINED: 'entrenados',
      TOTAL: 'total',
      COMPLIANCE: 'cumplimiento',
      SUMMARY: 'resumen',
      PLANNED: 'planeadas',
      COURSED: 'cursadas',
      TOTALHOURS: 'horas totales',
    },
    SUB_COMPONENT: {
      MANDATORY: 'legales',
      OPTIONAL: 'genéricos',
    },
    RESULTS: {
      PENDING: 'Pendiente',
    },
    LEGAL: 'Obligatorios',
    OPTIONAL: 'Opcionales',
  },
  IMPORT_POPUP: {
    IMPORT: 'importar',
    HINT: 'clickea o arrastra un archivo de excel para importar. (Maximo 2MB)',
    HINT_DROP: 'suelta para importar',
    HINT_INCORRECT_FILE: 'solo archivos de excel',
    HINT_NO_TYPE: 'seleccione el tipo a importar',
    CAPACITATIONS: 'capacitaciones',
    FULLTRAININGS: 'capacitaciones y empleados',
  },
  ACTIVATE_POPUP: {
    ACTIVATE: 'activar',
    DESACTIVATE: 'desactivar',
    HINT_DESACTIVATE: '¿Desea desactivar al usuario',
    HINT_ACTIVATE: '¿Desea activar al usuario',
  },
  POLICY: {
    POLICY_TITLE: 'Política de privacidad',
    BODY_1:
      'La presente Política de Privacidad establece los términos en que ORTUSTEN S.A.C.I.F usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.',
    POLICY_HINT_1: 'Información que es recogida',
    BODY_2:
      'Nuestro sitio web podrá recoger información personal y del cliente por ejemplo: Nombre, información de contacto como su dirección de correo electrónica e información operativa del negocio.',
    POLICY_HINT_2: 'Uso de la información recogida',
    BODY_3:
      'ORTUSTEN S.A.C.I.F está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.',
    POLICY_HINT_3: 'Cookies',
    BODY_4:
      'Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web. Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente, visitas a una web. Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.',
    POLICY_HINT_4: 'Control de su información personal',
    BODY_5:
      'Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial. ORTUSTEN S.A.C.I.F Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.',
  },
}

import { handleActions } from 'redux-actions'
import {
  fetchCapacitationDetailPdfStart,
  fetchCapacitationDetailPdfSuccess,
  fetchCapacitationDetailPdfError,
  capacitationDetailPdfDownloadReset
} from 'actions/capInDetailPdf.js'

const defaultState = {
  values: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchCapacitationDetailPdfStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchCapacitationDetailPdfSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchCapacitationDetailPdfError]: (state, error) => ({
      ...state,
      isFetching: false,
      error
    }),
    [capacitationDetailPdfDownloadReset]: () => defaultState
  },
  defaultState
)

export default reducer

import styled from 'styled-components'

export const CustomInputWrapper = styled.div`
  padding: 6px 10px;
  display: flex;
  align-items: center;
  max-height: 35px;
  box-shadow: none;
  border: 1px solid;
  width: 100%;
  border-radius: 30px;
  max-width: 190px;
  min-width: 130px;
`

export const Input = styled.input`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.1875em;
  padding: 2px;
  font-size: 16px;
  border: none;
  width: inherit;
`

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getExtendedDate, getPdfDate } from 'utils/date/dateParser'
import { capitalizeFirstLetter, allToLower, allToCaps } from 'utils/strings'
import isDanone from 'utils/isDanone'

import { setEditPopupStatus } from 'actions/editPopup'
import { fetchCapacitationImages } from 'actions/capacitationImagesDownload'
import { fetchCapacitationDetailPdf } from 'actions/capInDetailPdf'

import i18n from 'services/i18n'

import {
  DivHeaderWrapper,
  StyledText,
  DivHeaderItemsWrapper,
  CapacitationTitleWrapper,
  StyledTitle,
  StyledError,
  SignatureWrapper,
  Signature,
  Separator,
  NoSignatureWrapper
} from './styled'

import HeaderButtons from './Buttons'

import Loader from 'components/Loaders/pulse'

import theme from 'config/theme'

class CapacitationTableHeader extends Component {
  handleImgDownload = capacitationId => this.props.fetchCapacitationImages(capacitationId)

  hasEmployees = (selectedCapacitation, type) => selectedCapacitation && selectedCapacitation[type]

  employeeTypeHasPicture = (selectedCapacitation, type) =>
    selectedCapacitation[type].some(i => i.employeePhoto)

  hasImages = selectedCapacitation => {
    const types = {
      DIRECT: 'directEmployees',
      INDIRECT: 'indirectEmployees',
      CARRIER: 'carrierEmployees'
    }
    const directHasImages =
      this.hasEmployees(selectedCapacitation, types.DIRECT) &&
      this.employeeTypeHasPicture(selectedCapacitation, types.DIRECT)

    const indirectHasImages =
      this.hasEmployees(selectedCapacitation, types.INDIRECT) &&
      this.employeeTypeHasPicture(selectedCapacitation, types.INDIRECT)

    const carrierHasImages =
      this.hasEmployees(selectedCapacitation, types.CARRIER) &&
      this.employeeTypeHasPicture(selectedCapacitation, types.CARRIER)

    return directHasImages || indirectHasImages || carrierHasImages
  }

  handlePdfDownload = (capId, courseName, date) => {
    const archiveName = capitalizeFirstLetter(allToLower(courseName)) + getPdfDate(date)

    this.props.fetchCapacitationDetailPdf(capId, archiveName)
  }

  noSignature = () => (
    <NoSignatureWrapper>
      <StyledText>{capitalizeFirstLetter(i18n('PIC_POPUP').NO_IMG)}</StyledText>
    </NoSignatureWrapper>
  )

  render() {
    const {
      data,
      employeesData,
      loadingEmployeesData,
      setEditPopupStatus,
      capacitationImagesDownload,
      capInDetailPdf
    } = this.props

    return (
      <>
        <DivHeaderWrapper>
          <SignatureWrapper>
            {loadingEmployeesData ? (
              <Loader color={theme.colors.blue} />
            ) : employeesData ? (
              employeesData.instructorSignature ? (
                <Signature src={`data:image/png;base64,${employeesData.instructorSignature}`} />
              ) : (
                this.noSignature()
              )
            ) : (
              this.noSignature()
            )}
            <StyledText style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
              {capitalizeFirstLetter(allToLower(data.instructor.lastName))},{' '}
              {capitalizeFirstLetter(allToLower(data.instructor.firstName))}
            </StyledText>
          </SignatureWrapper>
          <Separator />
          <DivHeaderItemsWrapper>
            <StyledText>
              {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_COURSE}`)}:{' '}
              {capitalizeFirstLetter(allToLower(data.course.name))}
            </StyledText>
            <StyledText>
              {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_INTERN}`)}:{' '}
              {data.isInternal
                ? capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_AFFIRMATIVE}`
                  )
                : capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_NEGATIVE}`
                  )}
            </StyledText>
            <StyledText>
              {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_HEADER_REAL_DURATION}`)}:{' '}
              {data.realDuration} Hs
            </StyledText>
            <StyledText>
              {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_DATE}`)}:{' '}
              {getExtendedDate(data.realizationDate)}
            </StyledText>
            <StyledText>
              {capitalizeFirstLetter(
                `${
                  isDanone()
                    ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
                    : i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_PLACE
                }`
              )}
              : {capitalizeFirstLetter(allToLower(data.distributionCenter.name))}
            </StyledText>
            <StyledText>
              {data.observations
                ? capitalizeFirstLetter(
                    `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_OBSERVATIONS}`
                  ) +
                  ': ' +
                  capitalizeFirstLetter(allToLower(data.observations))
                : ''}
            </StyledText>
          </DivHeaderItemsWrapper>

          <CapacitationTitleWrapper>
            <StyledTitle>{allToCaps(`${i18n('CAPACITATION').CAPACITATION_TITLE}`)}</StyledTitle>
          </CapacitationTitleWrapper>
        </DivHeaderWrapper>
        <HeaderButtons
          data={data}
          employeesData={employeesData}
          loadingEmployeesData={loadingEmployeesData}
          hasImages={this.hasImages}
          setEditPopupStatus={setEditPopupStatus}
          handleImgDownload={this.handleImgDownload}
          capacitationImagesDownload={capacitationImagesDownload}
          handlePdfDownload={this.handlePdfDownload}
          capInDetailPdf={capInDetailPdf}
        />
        {capacitationImagesDownload.error || capInDetailPdf.error ? (
          <StyledError>{capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR}`)}</StyledError>
        ) : (
          ''
        )}
      </>
    )
  }
}

const mapStateToProps = ({ editPopup, capacitationImagesDownload, capInDetailPdf }) => ({
  editPopup,
  capacitationImagesDownload,
  capInDetailPdf
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEditPopupStatus,
      fetchCapacitationImages,
      fetchCapacitationDetailPdf
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CapacitationTableHeader)

import { createActions } from 'redux-actions'

const { expandedCategory, resetExpanded } = createActions({
  RESET_EXPANDED: () => {},
  EXPANDED_CATEGORY: data => ({ data })
})

const setExpandedCategory = status => dispatch => dispatch(expandedCategory({ status }))
const resetExpandedCategory = () => dispatch => dispatch(resetExpanded())

export { setExpandedCategory, expandedCategory, resetExpandedCategory, resetExpanded }

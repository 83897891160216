import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-top: 8px !important;
`

const TextFieldMaterial = props => <StyledTextField {...props} />

export default TextFieldMaterial

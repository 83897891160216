import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setAddPopupStatus } from 'actions/addPopup'
import { setImportPopupStatus } from 'actions/importPopup'
import { setImportPopupCoursesStatus } from 'actions/importPopupCourses'

import { coursesButtons, usersButtons, generalAdminButtons } from 'components/FloatingButtonButtons'

import userKeys from 'constants/userKeys'

import i18n from 'services/i18n'

const FloatingButton = (props) => {
  switch (props.type) {
    case i18n('PAGE_COURSES'):
      return coursesButtons(props)
    case i18n('PAGE_USERS'):
      return usersButtons(props)
    case userKeys.GENERAL_ADMIN:
      return generalAdminButtons(props)
    default:
      return null
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAddPopupStatus,
      setImportPopupStatus,
      setImportPopupCoursesStatus,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(FloatingButton)

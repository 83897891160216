import styled from 'styled-components'
import mediaQueries from 'config/media-queries'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue};
`

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
`

export const InfoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries.tablet`
    justify-content: flex-end;
  `};
`

export const HeaderTitle = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 500;
  font-size: 30px;
  padding: 9px 23px;
`

export const HeaderIcon = styled.img`
  display: none;
  width: 25px;
  height: 20px;
  margin-left: 16px;
  cursor: pointer;

  ${mediaQueries.tablet`
    display: block;
  `};
`
export const StyledExitButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
`

export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
`

export const StyledImg = styled.img`
  height: 45px;
`

export const StyledImgLogout = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  width: 20px;
`

export const StyledText = styled.text`
  font-size: 11px;
`

import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

import { getCompanyId } from 'utils/getCompany'

const {
  showing,

  fetchCoursesStart,
  fetchCoursesSuccess,
  fetchCoursesError,
  coursesReset,

  fetchCoursesByDateStart,
  fetchCoursesByDateSuccess,
  fetchCoursesByDateError,
  coursesByDateReset,

  fetchCoursesByCategoryIdStart,
  fetchCoursesByCategoryIdSuccess,
  fetchCoursesByCategoryIdError,
  coursesByCategoryIdReset,

  fetchCoursesByEmployeeIdStart,
  fetchCoursesByEmployeeIdSuccess,
  fetchCoursesByEmployeeIdError,
  coursesByEmployeeIdReset,

  fetchCoursesByNameStart,
  fetchCoursesByNameSuccess,
  fetchCoursesByNameError,
  coursesByNameReset,

  modifyCourseStart,
  modifyCourseSuccess,
  modifyCourseError,
  modifyCourseReset,

  addCourseStart,
  addCourseSuccess,
  addCourseError,
  addCourseReset,

  deleteCourseStart,
  deleteCourseSuccess,
  deleteCourseError,
  deleteCourseErrorReset,

  enableOrDisableCourseStart,
  enableOrDisableCourseSuccess,
  enableOrDisableCourseError,
  enableOrDisableCourseReset
} = createActions({
  SHOWING: data => ({ data }),

  FETCH_COURSES_START: () => {},
  FETCH_COURSES_SUCCESS: data => ({ data }),
  FETCH_COURSES_ERROR: error => ({ error }),
  COURSES_RESET: () => {},

  FETCH_COURSES_BY_DATE_START: () => {},
  FETCH_COURSES_BY_DATE_SUCCESS: data => ({ data }),
  FETCH_COURSES_BY_DATE_ERROR: error => ({ error }),
  COURSES_BY_DATE_RESET: () => {},

  FETCH_COURSES_BY_CATEGORY_ID_START: () => {},
  FETCH_COURSES_BY_CATEGORY_ID_SUCCESS: data => ({ data }),
  FETCH_COURSES_BY_CATEGORY_ID_ERROR: error => ({ error }),
  COURSES_BY_CATEGORY_ID_RESET: () => {},

  FETCH_COURSES_BY_EMPLOYEE_ID_START: () => {},
  FETCH_COURSES_BY_EMPLOYEE_ID_SUCCESS: data => ({ data }),
  FETCH_COURSES_BY_EMPLOYEE_ID_ERROR: error => ({ error }),
  COURSES_BY_EMPLOYEE_ID_RESET: () => {},

  FETCH_COURSES_BY_NAME_START: () => {},
  FETCH_COURSES_BY_NAME_SUCCESS: data => ({ data }),
  FETCH_COURSES_BY_NAME_ERROR: error => ({ error }),
  COURSES_BY_NAME_RESET: () => {},

  MODIFY_COURSE_START: () => {},
  MODIFY_COURSE_SUCCESS: () => {},
  MODIFY_COURSE_ERROR: error => ({ error }),
  MODIFY_COURSE_RESET: () => {},

  ADD_COURSE_START: () => {},
  ADD_COURSE_SUCCESS: () => {},
  ADD_COURSE_ERROR: error => ({ error }),
  ADD_COURSE_RESET: () => {},

  DELETE_COURSE_START: () => {},
  DELETE_COURSE_SUCCESS: () => {},
  DELETE_COURSE_ERROR: error => ({ error }),
  DELETE_COURSE_ERROR_RESET: () => {},

  ENABLE_OR_DISABLE_COURSE_START: () => {},
  ENABLE_OR_DISABLE_COURSE_SUCCESS: () => {},
  ENABLE_OR_DISABLE_COURSE_ERROR: error => ({ error }),
  ENABLE_OR_DISABLE_COURSE_RESET: () => {}
})

const setShowingCourse = status => dispatch => dispatch(showing({ status }))

const fetchCourses = () => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCoursesStart())
    let urlFinal = `${API_ROUTES.PUT_COURSE_NAME}`
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)
      dispatch(fetchCoursesSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCoursesError(error))
    }
  }
}

const resetCourses = () => dispatch => dispatch(coursesReset())

const fetchCoursesByDate = (from, to) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCoursesByDateStart())
    let urlFinal = `${API_ROUTES.PUT_COURSE_NAME}/${API_ROUTES.BY_DATE}/${from}/${to}`
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)

      dispatch(fetchCoursesByDateSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCoursesByDateError(error))
    }
  }
}

const resetCoursesByDate = () => dispatch => dispatch(coursesByDateReset())

const fetchCoursesByCategoryId = (id, companyId) => {
  return async dispatch => {
    const api = await getApi()
    const company = getCompanyId()
    const companyIdParam = companyId ? companyId : company ? company.Id : 0
    dispatch(fetchCoursesByCategoryIdStart())

    try {
      const response = await api.get(
        `${API_ROUTES.PUT_COURSE_NAME}/${
          API_ROUTES.GET_COURSES_BY_CATEGORY_ID
        }${id}/company/${companyIdParam}`
      )
      dispatch(fetchCoursesByCategoryIdSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCoursesByCategoryIdError(error))
    }
  }
}

const resetCoursesByCategoryId = () => dispatch => dispatch(coursesByCategoryIdReset())

const fetchCoursesByEmployeeId = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchCoursesByEmployeeIdStart())

    const body = {
      Filters: [],
      Sort: null,
      Pager: null,
      DateFrom: null,
      DateTo: null,
      Courses: [],
      Instructors: [],
      DistributionCenters: [],
      Employees: [id]
    }

    try {
      const response = await api.post(API_ROUTES.GET_COURSES_BY_EMPLOYEE_ID, body)
      dispatch(fetchCoursesByEmployeeIdSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCoursesByEmployeeIdError(error))
    }
  }
}

const resetCoursesByEmployeeId = () => dispatch => dispatch(coursesByEmployeeIdReset())

const fetchCoursesByName = name => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCoursesByNameStart())
    let urlFinal = `${API_ROUTES.GET_COURSES_BY_NAME}/${name}`
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`
    try {
      const response = await api.get(urlFinal)
      dispatch(fetchCoursesByNameSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCoursesByNameError(error))
    }
  }
}

const resetCoursesByName = () => dispatch => dispatch(coursesByNameReset())

const modifyCourse = (item, name) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyCourseStart())
    const body = {
      Name: name,
      Category: {
        Id: item.category.id
      },
      Companies: [getCompanyId()]
    }
    try {
      await api.put(`${API_ROUTES.PUT_COURSE_NAME}/${item.id}`, body)
      dispatch(modifyCourseSuccess())
    } catch (error) {
      dispatch(modifyCourseError(error.data))
    }
  }
}

const resetModifyCourse = () => dispatch => dispatch(modifyCourseReset())

const addCourse = (data, category) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(addCourseStart())
    const body = {
      Name: data.name,
      Category: {
        Id: category.value
      },
      Companies: [getCompanyId()]
    }
    try {
      const response = await api.post(`${API_ROUTES.PUT_COURSE_NAME}`, body)
      dispatch(addCourseSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addCourseError(error.data))
    }
  }
}

const resetAddCourse = () => dispatch => dispatch(addCourseReset())

const deleteCourse = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteCourseStart())
    try {
      const response = await api.delete(`${API_ROUTES.PUT_COURSE_NAME}/${id}`)
      dispatch(deleteCourseSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteCourseError(error.data))
    }
  }
}

const resetDeleteCourseError = () => dispatch => dispatch(deleteCourseErrorReset())

const enableOrDisableCourse = course => {
  return async dispatch => {
    const api = await getApi()
    dispatch(enableOrDisableCourseStart())
    const enableOrDisable = course.Enable ? `enableCourse` : `disableCourse`
    try {
      await api.patch(`${API_ROUTES.PUT_COURSE_NAME}/${enableOrDisable}/${course.id}`)
      dispatch(enableOrDisableCourseSuccess())
    } catch (error) {
      dispatch(enableOrDisableCourseError(error.data))
    }
  }
}

const resetEnableOrDisableCourse = () => dispatch => dispatch(enableOrDisableCourseReset())

export {
  showing,
  setShowingCourse,
  fetchCoursesByCategoryId,
  fetchCoursesByCategoryIdStart,
  fetchCoursesByCategoryIdSuccess,
  fetchCoursesByCategoryIdError,
  resetCoursesByCategoryId,
  coursesByCategoryIdReset,
  fetchCoursesByDate,
  fetchCoursesByDateStart,
  fetchCoursesByDateSuccess,
  fetchCoursesByDateError,
  coursesByDateReset,
  resetCoursesByDate,
  fetchCoursesByEmployeeId,
  fetchCoursesByEmployeeIdStart,
  fetchCoursesByEmployeeIdSuccess,
  fetchCoursesByEmployeeIdError,
  coursesByEmployeeIdReset,
  resetCoursesByEmployeeId,
  fetchCoursesByNameStart,
  fetchCoursesByNameSuccess,
  fetchCoursesByNameError,
  fetchCoursesByName,
  coursesByNameReset,
  resetCoursesByName,
  modifyCourse,
  modifyCourseStart,
  modifyCourseSuccess,
  modifyCourseError,
  resetModifyCourse,
  modifyCourseReset,
  addCourse,
  addCourseStart,
  addCourseSuccess,
  addCourseError,
  resetAddCourse,
  addCourseReset,
  deleteCourse,
  deleteCourseStart,
  deleteCourseSuccess,
  deleteCourseError,
  resetDeleteCourseError,
  deleteCourseErrorReset,
  fetchCourses,
  fetchCoursesStart,
  fetchCoursesSuccess,
  fetchCoursesError,
  coursesReset,
  resetCourses,
  enableOrDisableCourse,
  enableOrDisableCourseStart,
  enableOrDisableCourseSuccess,
  enableOrDisableCourseError,
  enableOrDisableCourseReset,
  resetEnableOrDisableCourse
}

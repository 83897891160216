import { handleActions } from 'redux-actions'
import {
  fetchWorkPositionStart,
  fetchWorkPositionSuccess,
  fetchWorkPositionError,
  workPositionReset
} from 'actions/workPosition'

const defaultState = {
  valuesAll: null,
  isFetchingAll: false,
  errorAll: null
}

const reducer = handleActions(
  {
    [fetchWorkPositionStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchWorkPositionSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchWorkPositionError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [workPositionReset]: () => defaultState
  },
  defaultState
)

export default reducer

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Modal from 'components/Modal'
import ButtonMaterial from 'components/ButtonMaterial'
import {
  directEmployeeInputs,
  indirectEmployeeInputs,
  carrierEmployeeInputs,
  capacitationInputs,
  leaderInput,
  generalAdminInputs,
  administratorInputs,
  instructorInputs,
  leaderUserInputs,
  courseInputs,
  defaultInput
} from 'components/EditPopupInputs'
import {
  InicialValuesIndirectEmployee,
  InicialValuesCarrierEmployee,
  InicialValuesCapacitation,
  InicialValuesDirectOrLeader,
  InicialValuesSimpleName,
  InicialValuesSimpleFirstName,
  InicialValuesGeneralAdministrator,
  InicialValuesAdministrator,
  InicialValuesInstructor,
  InicialValuesLeaderUser,
  InicialValuesCourse
} from './InicialValues'
import {
  ValidationsDirectOrLeader,
  ValidationsIndirectEmployee,
  ValidationsCarrierEmployee,
  ValidationsCapacitation,
  ValidationsSimpleUser,
  validationUserName,
  ValidationsDefault,
  ValidationBusinessAdmin,
  ValidationInstructor
} from './Validations'

import theme from 'config/theme'

import { setEditPopupStatus, resetEditPopupErrors } from 'actions/editPopup'
import { modifyCategory } from 'actions/categories'
import { modifyCourse } from 'actions/courses'
import { modifyLeader } from 'actions/leaders'
import { modifyCenter } from 'actions/centers'
import { modifyDirectEmployee } from 'actions/directEmployee'
import { modifyIndirectEmployee } from 'actions/indirectEmployee'
import { modifyCarrierEmployee } from 'actions/carrierEmployee'
import { modifyCapacitation } from 'actions/capacitations'
import { modifyAdministrator, enableOrDisableAdministrators } from 'actions/administrators'
import { modifyInstructor, enableOrDisableInstructors } from 'actions/instructors'
import { modifyLeaderUser, enableOrDisableLeaderUsers } from 'actions/leaderUsers'
import { setCapacitationDetailStatus } from 'actions/capacitationDetailStatus'
import { resetSelectedSearchedItem } from 'actions/selectedSearchedItem'
import { setDisplayToast } from 'actions/toast'
import { fetchLeaders } from 'actions/leaders'
import { fetchCenters } from 'actions/centers'
import { fetchCourses, fetchCoursesByCategoryId, enableOrDisableCourse } from 'actions/courses'
import { setEmployeeDirectStaffId } from 'actions/directEmployeeStaffId'
import { fetchCategories } from 'actions/categories'
import { fetchBusiness } from 'actions/business'
import {
  modifyGeneralAdministrator,
  enableOrDisableGeneralAdministrators
} from 'actions/generalAdmin'

import CrossIcon from 'assets/icons/cross.png'

import popupKeys from 'constants/popupKeys'
import toastTypes from 'constants/toastKeys'
import userKeys from 'constants/userKeys'

import { capitalizeFirstLetter } from 'utils/strings'
import isDanone from 'utils/isDanone'

import i18n from 'services/i18n'

import {
  StyledContainer,
  StyledDivHeaderWrapper,
  CrossImage,
  StyledForm,
  ButtonWrapper,
  StyledDivFooterWrapper,
  ErrorLabel,
  StyledDivWrapper,
  StyledTextHeader
} from './styled'

class EditPopup extends Component {
  state = {
    itemToEdit: this.props.editPopup.itemToEdit,
    categorySelectedValue: this.props.editPopup.itemToEdit.course
      ? this.props.editPopup.itemToEdit.course.category
      : null
  }

  componentDidMount = () => {
    if (this.props.editPopup.type === userKeys.LEADER_USER) {
      this.props.fetchLeaders()
      this.props.fetchCenters()
    }
    if (this.props.editPopup.type === userKeys.INSTRUCTOR) {
      this.props.fetchCategories()
      this.props.fetchCourses()
      this.props.fetchBusiness()
    }
    if (this.props.editPopup.type === popupKeys.CAPACITATION) {
      this.props.fetchCoursesByCategoryId(this.props.editPopup.itemToEdit.course.category.id)
    }

    if (this.props.editPopup.type === userKeys.ADMINISTRATOR) {
      this.props.fetchBusiness()
    }
  }

  componentWillUnmount = () => {
    this.props.resetEditPopupErrors()
  }

  onClose = soft => {
    this.props.setEditPopupStatus({ open: false, itemToEdit: null })
    if (!soft) {
      this.props.setDisplayToast({ isShowing: true, type: toastTypes.TOAST_TYPE_ADD })
      if (this.props.editPopup.type === popupKeys.CAPACITATION) {
        this.props.resetSelectedSearchedItem()
        this.props.setCapacitationDetailStatus(false)
      }
    }
  }

  getInicialValuesSimple = () => {
    if (this.props.editPopup.itemToEdit) {
      if (this.props.editPopup.itemToEdit.name) {
        return InicialValuesSimpleName(this.props.editPopup.itemToEdit)
      } else {
        return InicialValuesSimpleFirstName(this.props.editPopup.itemToEdit)
      }
    }
  }

  getInicialValues = () => {
    switch (this.props.editPopup.type) {
      case popupKeys.EMPLOYEE.DIRECT:
        return InicialValuesDirectOrLeader(this.props.editPopup.itemToEdit)
      case popupKeys.EMPLOYEE.INDIRECT:
        return InicialValuesIndirectEmployee(this.props.editPopup.itemToEdit)
      case popupKeys.EMPLOYEE.CARRIER:
        return InicialValuesCarrierEmployee(this.props.editPopup.itemToEdit)
      case popupKeys.CAPACITATION:
        return InicialValuesCapacitation(this.props.editPopup.itemToEdit)
      case popupKeys.LEADER:
        return InicialValuesDirectOrLeader(this.props.editPopup.itemToEdit)
      case userKeys.GENERAL_ADMIN:
        return InicialValuesGeneralAdministrator(this.props.editPopup.itemToEdit)
      case userKeys.ADMINISTRATOR:
        return InicialValuesAdministrator(this.props.editPopup.itemToEdit)
      case userKeys.INSTRUCTOR:
        return InicialValuesInstructor(this.props.editPopup.itemToEdit)
      case userKeys.LEADER_USER:
        return InicialValuesLeaderUser(this.props.editPopup.itemToEdit)
      case popupKeys.COURSE:
        return InicialValuesCourse(this.props.editPopup.itemToEdit)
      default:
        return this.getInicialValuesSimple()
    }
  }

  getDirectOrLeaderValues = values =>
    values.name === this.getInicialValues().name &&
    values.lastName === this.getInicialValues().lastName &&
    values.fileNumber === this.getInicialValues().fileNumber &&
    values.costCenter === this.getInicialValues().costCenter &&
    values.category === this.getInicialValues().category &&
    values.leader.value === this.getInicialValues().leader.value &&
    values.distributionCenterId.value === this.getInicialValues().distributionCenterId.value &&
    values.workPosition.value === this.getInicialValues().workPosition.value &&
    values.workSector.value === this.getInicialValues().workSector.value &&
    values.effectiveStaffId === this.getInicialValues().effectiveStaffId

  handleChangeCategory = selectedValue => {
    if (!this.sameObject(selectedValue, this.state.categorySelectedValue)) {
      this.setState({ categorySelectedValue: selectedValue.category })
      this.props.fetchCoursesByCategoryId(selectedValue.id)
    }
  }

  hasSameValues = values => {
    switch (this.props.editPopup.type) {
      case popupKeys.EMPLOYEE.DIRECT:
        return this.getDirectOrLeaderValues(values)
      case popupKeys.EMPLOYEE.INDIRECT:
        return (
          values.name === this.getInicialValues().name &&
          values.lastName === this.getInicialValues().lastName &&
          values.documentNumber === this.getInicialValues().documentNumber &&
          values.business === this.getInicialValues().business &&
          values.businessInput === this.getInicialValues().businessInput
        )
      case popupKeys.EMPLOYEE.CARRIER:
        return (
          values.name === this.getInicialValues().name &&
          values.lastName === this.getInicialValues().lastName &&
          values.documentNumber === this.getInicialValues().documentNumber &&
          values.fileNumber === this.getInicialValues().fileNumber &&
          values.distributionNumber === this.getInicialValues().distributionNumber &&
          values.accountNumber === this.getInicialValues().accountNumber
        )
      case popupKeys.CAPACITATION: {
        return (
          values.duration === this.getInicialValues().duration &&
          values.observation === this.getInicialValues().observation &&
          this.sameObject(values.course, this.getInicialValues().course) &&
          this.sameObject(values.category, this.getInicialValues().category)
        )
      }

      case popupKeys.LEADER:
        return this.getDirectOrLeaderValues(values)
      case userKeys.GENERAL_ADMIN:
        return (
          values.userName === this.getInicialValues().userName &&
          values.name === this.getInicialValues().name &&
          values.lastName === this.getInicialValues().lastName &&
          values.isUserActive === this.getInicialValues().isUserActive &&
          values.email === this.getInicialValues().email
        )
      case userKeys.ADMINISTRATOR:
        return (
          values.userName === this.getInicialValues().userName &&
          values.name === this.getInicialValues().name &&
          values.lastName === this.getInicialValues().lastName &&
          values.isUserActive === this.getInicialValues().isUserActive &&
          this.sameObject(values.selectedBusiness, this.getInicialValues().selectedBusiness) &&
          values.email === this.getInicialValues().email
        )
      case userKeys.INSTRUCTOR:
        return (
          values.userName === this.getInicialValues().userName &&
          values.name === this.getInicialValues().name &&
          values.lastName === this.getInicialValues().lastName &&
          values.isInternal === this.getInicialValues().isInternal &&
          values.canAddCourses === this.getInicialValues().canAddCourses &&
          this.sameObject(values.availableCourses, this.getInicialValues().availableCourses) &&
          this.sameObject(
            values.availableCategories.map(item => item.category),
            this.getInicialValues().availableCategories
          ) &&
          values.isUserActive === this.getInicialValues().isUserActive &&
          values.email === this.getInicialValues().email
        )
      case userKeys.LEADER_USER:
        return (
          values.userName === this.getInicialValues().userName &&
          values.leader.value === this.getInicialValues().leader.value &&
          values.leaderLevel.value === this.getInicialValues().leaderLevel.value &&
          this.sameObject(
            values.distributionCenters,
            this.getInicialValues().distributionCenters
          ) &&
          values.isUserActive === this.getInicialValues().isUserActive &&
          values.email === this.getInicialValues().email
        )
      case popupKeys.COURSE:
        return (
          values.name === this.getInicialValues().name &&
          values.isCourseActive === this.getInicialValues().isCourseActive
        )

      default:
        return this.isSameName(values)
    }
  }

  sameObject = (newObject, initialObject) =>
    JSON.stringify(newObject) === JSON.stringify(initialObject)

  isSameName = value => value.name === this.getInicialValuesSimple().name

  getValidations = () => {
    switch (this.props.editPopup.type) {
      case popupKeys.EMPLOYEE.DIRECT:
        return ValidationsDirectOrLeader()
      case popupKeys.EMPLOYEE.INDIRECT:
        return ValidationsIndirectEmployee()
      case popupKeys.EMPLOYEE.CARRIER:
        return ValidationsCarrierEmployee()
      case popupKeys.CAPACITATION:
        return ValidationsCapacitation()
      case popupKeys.LEADER:
        return ValidationsDirectOrLeader()
      case userKeys.GENERAL_ADMIN:
        return ValidationsSimpleUser()
      case userKeys.ADMINISTRATOR:
        return ValidationBusinessAdmin()
      case userKeys.INSTRUCTOR:
        return ValidationInstructor()
      case userKeys.LEADER_USER:
        return validationUserName()
      default:
        return ValidationsDefault()
    }
  }

  renderForm = () => {
    const SignUpSchema = Yup.object().shape(this.getValidations())
    return (
      <Formik
        initialValues={this.getInicialValues()}
        validateOnChange={false}
        validationSchema={SignUpSchema}
        onSubmit={this.handleSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }

  handleSubmit = async values => {
    switch (this.props.editPopup.type) {
      case popupKeys.CATEGORY:
        await this.props.modifyCategory(this.props.editPopup.itemToEdit, values.name)
        break
      case popupKeys.COURSE:
        await this.props.enableOrDisableCourse({
          Enable: values.isCourseActive,
          id: this.props.editPopup.itemToEdit.id
        })
        await this.props.modifyCourse(this.props.editPopup.itemToEdit, values.name)
        break
      case popupKeys.LEADER:
        await this.props.modifyLeader(this.props.editPopup.itemToEdit, values)
        await this.props.setEmployeeDirectStaffId(
          this.props.editPopup.itemToEdit.id,
          parseInt(values.effectiveStaffId)
        )
        break
      case popupKeys.CENTER:
        await this.props.modifyCenter(this.props.editPopup.itemToEdit, values)
        break
      case popupKeys.EMPLOYEE.DIRECT:
        await this.props.modifyDirectEmployee(this.props.editPopup.itemToEdit, values)
        await this.props.setEmployeeDirectStaffId(
          this.props.editPopup.itemToEdit.id,
          parseInt(values.effectiveStaffId)
        )
        break
      case popupKeys.EMPLOYEE.INDIRECT:
        await this.props.modifyIndirectEmployee(this.props.editPopup.itemToEdit, values)
        break
      case popupKeys.EMPLOYEE.CARRIER:
        await this.props.modifyCarrierEmployee(this.props.editPopup.itemToEdit, values)
        break
      case popupKeys.CAPACITATION:
        await this.props.modifyCapacitation(
          this.state.itemToEdit,
          values,
          popupKeys.CAPACITATION_DATA
        )
        break
      case userKeys.GENERAL_ADMIN:
        await this.props.enableOrDisableGeneralAdministrators({
          Enable: values.isUserActive,
          id: this.props.editPopup.itemToEdit.id
        })
        await this.props.modifyGeneralAdministrator(this.props.editPopup.itemToEdit, values)
        break
      case userKeys.ADMINISTRATOR:
        await this.props.enableOrDisableAdministrators({
          Enable: values.isUserActive,
          id: this.props.editPopup.itemToEdit.id
        })
        await this.props.modifyAdministrator(this.props.editPopup.itemToEdit, values)
        break
      case userKeys.INSTRUCTOR:
        await this.props.enableOrDisableInstructors({
          Enable: values.isUserActive,
          id: this.props.editPopup.itemToEdit.id
        })
        await this.props.modifyInstructor(this.props.editPopup.itemToEdit, values)
        break
      case userKeys.LEADER_USER:
        await this.props.enableOrDisableLeaderUsers({
          Enable: values.isUserActive,
          id: this.props.editPopup.itemToEdit.id
        })
        await this.props.modifyLeaderUser(this.props.editPopup.itemToEdit, values)
        break
    }

    if (!this.hasError()) {
      this.onClose()
    }
  }

  isFetching = () =>
    this.props.categories.isSubmiting ||
    this.props.courses.isSubmiting ||
    this.props.courses.isEnablingOrDisabling ||
    this.props.leaders.isSubmiting ||
    this.props.centers.isSubmiting ||
    this.props.directEmployee.isSubmiting ||
    this.props.indirectEmployee.isSubmiting ||
    this.props.carrierEmployee.isSubmiting ||
    this.props.capacitations.isSubmiting ||
    this.props.administrators.isSubmiting ||
    this.props.instructors.isSubmiting ||
    this.props.leaderUsers.isSubmiting ||
    this.props.generalAdministrators.isSubmiting ||
    this.props.directEmployeeStaffId.isSubmiting ||
    this.props.instructors.isEnablingOrDisabling ||
    this.props.leaderUsers.isEnablingOrDisabling ||
    this.props.administrators.isEnablingOrDisabling ||
    this.props.generalAdministrators.isEnablingOrDisabling

  hasError = () =>
    this.props.categories.errorEdit ||
    this.props.courses.errorEdit ||
    this.props.courses.errorEnablingOrDisabling ||
    this.props.leaders.errorEdit ||
    this.props.centers.errorEdit ||
    this.props.directEmployee.errorEdit ||
    this.props.indirectEmployee.errorEdit ||
    this.props.carrierEmployee.errorEdit ||
    this.props.capacitations.errorEdit ||
    this.props.administrators.errorEdit ||
    this.props.instructors.errorEdit ||
    this.props.leaderUsers.errorEdit ||
    this.props.directEmployeeStaffId.error ||
    this.props.generalAdministrators.errorEdit ||
    this.props.instructors.errorEnablingOrDisabling ||
    this.props.leaderUsers.errorEnablingOrDisabling ||
    this.props.administrators.errorEnablingOrDisabling ||
    this.props.generalAdministrators.errorEnablingOrDisabling

  renderInput = (values, setFieldValue, errors, setValues) => {
    switch (this.props.editPopup.type) {
      case popupKeys.EMPLOYEE.DIRECT:
        return directEmployeeInputs(
          values,
          setFieldValue,
          errors,
          this.props.centers,
          this.props.workSector,
          this.props.workPosition,
          this.props.leaders
        )
      case popupKeys.EMPLOYEE.INDIRECT:
        return indirectEmployeeInputs(values, setFieldValue, errors, setValues)
      case popupKeys.EMPLOYEE.CARRIER:
        return carrierEmployeeInputs(values, setFieldValue, errors)
      case popupKeys.CAPACITATION:
        return capacitationInputs(
          values,
          this.props.courses,
          this.props.categories.values,
          setFieldValue,
          errors,
          this.handleChangeCategory
        )
      case popupKeys.LEADER:
        return leaderInput(
          values,
          setFieldValue,
          errors,
          this.props.centers,
          this.props.workSector,
          this.props.workPosition,
          this.props.leaders
        )
      case userKeys.GENERAL_ADMIN:
        return generalAdminInputs(values, setFieldValue, errors)
      case userKeys.ADMINISTRATOR:
        return administratorInputs(values, setFieldValue, errors, false, this.props.business)
      case userKeys.INSTRUCTOR:
        return instructorInputs(
          values,
          setFieldValue,
          setValues,
          errors,
          this.props.business,
          this.props.categories,
          this.props.courses
        )
      case userKeys.LEADER_USER:
        return leaderUserInputs(
          values,
          setFieldValue,
          setValues,
          errors,
          this.props.leaders,
          this.props.centers
        )
      case popupKeys.COURSE:
        return courseInputs(values, setFieldValue, errors)
      default:
        return defaultInput(values, setFieldValue, errors)
    }
  }

  getHeaderName = () => {
    switch (this.props.editPopup.type) {
      case popupKeys.CATEGORY:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY}`
      case popupKeys.COURSE:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_COURSE}`
      case popupKeys.LEADER:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`
      case popupKeys.CENTER:
        return `${
          isDanone()
            ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
            : i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_PLACE
        }`
      case popupKeys.EMPLOYEE.DIRECT:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_DIRECT_EMPLOYEE}`
      case popupKeys.EMPLOYEE.INDIRECT:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_INDIRECT_EMPLOYEE}`
      case popupKeys.EMPLOYEE.CARRIER:
        return `${i18n('CAPACITATION').CAPACITATION_TABLE_CARRIER_EMPLOYEE}`
      case popupKeys.CAPACITATION:
        return `${i18n('CAPACITATION').CAPACITATION}`
    }
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors, setValues }) => (
    <StyledForm onSubmit={handleSubmit}>
      <StyledDivHeaderWrapper>
        <StyledTextHeader>
          {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_EDIT}`)} {this.getHeaderName()}
        </StyledTextHeader>
        <CrossImage onClick={() => this.onClose(true)} src={CrossIcon} />
      </StyledDivHeaderWrapper>
      {this.renderInput(values, setFieldValue, errors, setValues)}
      <StyledDivFooterWrapper>
        <ButtonWrapper>
          <ButtonMaterial
            type={'submit'}
            color={theme.colors.white}
            background={this.hasSameValues(values) ? theme.colors.dustyGray : theme.colors.purple}
            borderRadius={'10'}
            fontSize={'18'}
            small
            disabled={this.hasSameValues(values) || this.isFetching() ? true : false}
            loading={this.isFetching()}
            display={true}
          >
            {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_SAVE}`)}
          </ButtonMaterial>
        </ButtonWrapper>
        <StyledDivWrapper>
          {this.hasError() && (
            <ErrorLabel>
              {this.hasError().payload.error && this.hasError().payload.error.errors
                ? this.hasError().payload.error.errors[0]
                : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR}`)}
            </ErrorLabel>
          )}
          {errors.distributionCenterId ||
          errors.workSector ||
          errors.workPosition ||
          errors.leader ||
          errors.distributionCenters ||
          errors.selectedBusiness ||
          errors.availableCategories ||
          errors.availableCourses ||
          errors.selectedBusiness ? (
            <ErrorLabel>{capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ERROR}`)}</ErrorLabel>
          ) : (
            ''
          )}
        </StyledDivWrapper>
      </StyledDivFooterWrapper>
    </StyledForm>
  )

  render() {
    return (
      <Modal>
        <StyledContainer>{this.renderForm()}</StyledContainer>
      </Modal>
    )
  }
}

const mapStateToProps = ({
  editPopup,
  categories,
  courses,
  leaders,
  centers,
  workSector,
  workPosition,
  directEmployee,
  indirectEmployee,
  carrierEmployee,
  capacitations,
  administrators,
  instructors,
  leaderUsers,
  directEmployeeStaffId,
  business,
  generalAdministrators
}) => ({
  editPopup,
  categories,
  courses,
  leaders,
  centers,
  workSector,
  workPosition,
  directEmployee,
  indirectEmployee,
  carrierEmployee,
  capacitations,
  administrators,
  instructors,
  leaderUsers,
  directEmployeeStaffId,
  business,
  generalAdministrators
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      modifyCategory,
      modifyCourse,
      modifyLeader,
      modifyCenter,
      modifyDirectEmployee,
      modifyIndirectEmployee,
      modifyCarrierEmployee,
      modifyCapacitation,
      modifyAdministrator,
      modifyInstructor,
      modifyLeaderUser,
      resetSelectedSearchedItem,
      setCapacitationDetailStatus,
      setEditPopupStatus,
      setDisplayToast,
      fetchLeaders,
      fetchCenters,
      fetchCourses,
      fetchCoursesByCategoryId,
      resetEditPopupErrors,
      setEmployeeDirectStaffId,
      enableOrDisableInstructors,
      enableOrDisableAdministrators,
      enableOrDisableLeaderUsers,
      fetchCategories,
      fetchBusiness,
      modifyGeneralAdministrator,
      enableOrDisableGeneralAdministrators,
      enableOrDisableCourse
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPopup)

import { createActions } from 'redux-actions'
import getApi from 'services/api'

const { legalStart, legalSuccess, legalError, legalReset } = createActions({
  LEGAL_START: () => {},
  LEGAL_SUCCESS: (values, total) => ({ values, total }),
  LEGAL_ERROR: (error) => ({ error }),
  LEGAL_RESET: () => {},
})

const getLegal = (query) => {
  return async (dispatch) => {
    const api = await getApi()

    const body = {
      ...query,
      Sort: {
        Name: '',
        IsAscending: true,
      },
      Pager: {
        PageSize: 10,
        PageNumber: query.PageNumber ? query.PageNumber : 1,
      },
    }

    dispatch(legalStart())

    return api
      .post('dashboard/legaltrainingdetail', body)
      .then((response) => {
        const dataResponse = response.data.data
        dispatch(legalSuccess(dataResponse.mandatoryCourses, dataResponse.totalCourses))
      })
      .catch((error) => {
        dispatch(legalError(error.data ? error.data.message : error.message))
      })
  }
}

export { getLegal, legalStart, legalSuccess, legalError, legalReset }

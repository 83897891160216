import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  showingGeneralAdmin,

  fetchGeneralAdministratorsStart,
  fetchGeneralAdministratorsSuccess,
  fetchGeneralAdministratorsError,
  generalAdministratorsReset,

  addGeneralAdministratorsStart,
  addGeneralAdministratorsSuccess,
  addGeneralAdministratorsError,
  addGeneralAdministratorsReset,

  enableOrDisableGeneralAdministratorsStart,
  enableOrDisableGeneralAdministratorsSuccess,
  enableOrDisableGeneralAdministratorsError,
  enableOrDisableGeneralAdministratorsReset,

  deleteGeneralAdministratorStart,
  deleteGeneralAdministratorSuccess,
  deleteGeneralAdministratorError,
  deleteGeneralAdministratorErrorReset,

  modifyGeneralAdministratorStart,
  modifyGeneralAdministratorSuccess,
  modifyGeneralAdministratorError,
  modifyGeneralAdministratorReset
} = createActions({
  SHOWING_GENERAL_ADMIN: data => ({ data }),

  FETCH_GENERAL_ADMINISTRATORS_START: () => {},
  FETCH_GENERAL_ADMINISTRATORS_SUCCESS: data => ({ data }),
  FETCH_GENERAL_ADMINISTRATORS_ERROR: error => ({ error }),
  GENERAL_ADMINISTRATORS_RESET: () => {},

  ADD_GENERAL_ADMINISTRATORS_START: () => {},
  ADD_GENERAL_ADMINISTRATORS_SUCCESS: () => {},
  ADD_GENERAL_ADMINISTRATORS_ERROR: error => ({ error }),
  ADD_GENERAL_ADMINISTRATORS_RESET: () => {},

  ENABLE_OR_DISABLE_GENERAL_ADMINISTRATORS_START: () => {},
  ENABLE_OR_DISABLE_GENERAL_ADMINISTRATORS_SUCCESS: () => {},
  ENABLE_OR_DISABLE_GENERAL_ADMINISTRATORS_ERROR: error => ({ error }),
  ENABLE_OR_DISABLE_GENERAL_ADMINISTRATORS_RESET: () => {},

  DELETE_GENERAL_ADMINISTRATOR_START: () => {},
  DELETE_GENERAL_ADMINISTRATOR_SUCCESS: () => {},
  DELETE_GENERAL_ADMINISTRATOR_ERROR: error => ({ error }),
  DELETE_GENERAL_ADMINISTRATOR_ERROR_RESET: () => {},

  MODIFY_GENERAL_ADMINISTRATOR_START: () => {},
  MODIFY_GENERAL_ADMINISTRATOR_SUCCESS: () => {},
  MODIFY_GENERAL_ADMINISTRATOR_ERROR: error => ({ error }),
  MODIFY_GENERAL_ADMINISTRATOR_RESET: () => {}
})

const setShowingGeneralAdmin = status => dispatch => dispatch(showingGeneralAdmin({ status }))

const fetchGeneralAdministrators = () => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchGeneralAdministratorsStart())
    const {
      selectedUserStatus,
      selectedUsersFilter: { inputNameValue, category }
    } = getState()
    const body = {
      CompanyId: 0,
      Name: inputNameValue ? inputNameValue : '',
      CategoryId: category ? category.id : 0
    }
    try {
      const response = await api.post(
        `${API_ROUTES.GENERAL_ADMIN}/${selectedUserStatus.selected}`,
        body
      )
      dispatch(fetchGeneralAdministratorsSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchGeneralAdministratorsError(error.data))
    }
  }
}

const resetGeneralAdministrators = () => dispatch => dispatch(generalAdministratorsReset())

const addGeneralAdministrators = data => {
  return async dispatch => {
    const api = await getApi()
    dispatch(addGeneralAdministratorsStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      UserName: data.userName,
      Password: data.passwordConfirmation,
      Mail: data.email
    }
    try {
      await api.post(API_ROUTES.GENERAL_ADMIN, body)
      dispatch(addGeneralAdministratorsSuccess())
    } catch (error) {
      dispatch(addGeneralAdministratorsError(error.data))
    }
  }
}

const resetAddGeneralAdministrators = () => dispatch => dispatch(addGeneralAdministratorsReset())

const enableOrDisableGeneralAdministrators = user => {
  return async dispatch => {
    const api = await getApi()
    dispatch(enableOrDisableGeneralAdministratorsStart())
    const enableOrDisable = user.Enable
      ? `enablegeneraladministrator`
      : `disablegeneraladministrator`
    try {
      await api.patch(`${API_ROUTES.GENERAL_ADMIN}/${enableOrDisable}/${user.id}`)
      dispatch(enableOrDisableGeneralAdministratorsSuccess())
    } catch (error) {
      dispatch(enableOrDisableGeneralAdministratorsError(error.data))
    }
  }
}

const resetEnableOrDisableGeneralAdministrators = () => dispatch =>
  dispatch(enableOrDisableGeneralAdministratorsReset())

const deleteGeneralAdministrator = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteGeneralAdministratorStart())
    try {
      await api.delete(`${API_ROUTES.GENERAL_ADMIN}/${id}`)
      dispatch(deleteGeneralAdministratorSuccess())
    } catch (error) {
      dispatch(deleteGeneralAdministratorError(error.data))
    }
  }
}

const resetDeleteGeneralAdministratorError = () => dispatch =>
  dispatch(deleteGeneralAdministratorErrorReset())

const modifyGeneralAdministrator = (item, data) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyGeneralAdministratorStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      UserName: data.userName,
      Mail: data.email
    }
    try {
      const response = await api.put(`${API_ROUTES.GENERAL_ADMIN}/${item.id}`, body)
      dispatch(modifyGeneralAdministratorSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyGeneralAdministratorError(error.data))
    }
  }
}

const resetModifyGeneralAdministrator = () => dispatch =>
  dispatch(modifyGeneralAdministratorReset())

export {
  setShowingGeneralAdmin,
  showingGeneralAdmin,
  fetchGeneralAdministrators,
  fetchGeneralAdministratorsStart,
  fetchGeneralAdministratorsSuccess,
  fetchGeneralAdministratorsError,
  generalAdministratorsReset,
  resetGeneralAdministrators,
  addGeneralAdministrators,
  addGeneralAdministratorsStart,
  addGeneralAdministratorsSuccess,
  addGeneralAdministratorsError,
  addGeneralAdministratorsReset,
  resetAddGeneralAdministrators,
  deleteGeneralAdministrator,
  deleteGeneralAdministratorStart,
  deleteGeneralAdministratorSuccess,
  deleteGeneralAdministratorError,
  resetDeleteGeneralAdministratorError,
  deleteGeneralAdministratorErrorReset,
  modifyGeneralAdministrator,
  modifyGeneralAdministratorStart,
  modifyGeneralAdministratorSuccess,
  modifyGeneralAdministratorError,
  modifyGeneralAdministratorReset,
  resetModifyGeneralAdministrator,
  enableOrDisableGeneralAdministrators,
  enableOrDisableGeneralAdministratorsStart,
  enableOrDisableGeneralAdministratorsSuccess,
  enableOrDisableGeneralAdministratorsError,
  enableOrDisableGeneralAdministratorsReset,
  resetEnableOrDisableGeneralAdministrators
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Dropzone from 'react-dropzone'
import DeleteIcon from '@material-ui/icons/DeleteOutline'

import Modal from 'components/Modal'
import Loader from 'components/Loaders/propagate'

import {
  setImportPopupCoursesStatus,
  resetImportPopupCoursesErrors,
} from 'actions/importPopupCourses'
import { setDisplayToast } from 'actions/toast'
import { importExcelCapacitations } from 'actions/excelImport'

import {
  StyledContainer,
  StyledDivHeaderWrapper,
  CrossImage,
  StyledTextHeader,
  StyledDropZone,
  StyledTextDropZone,
  StyledIcon,
  StyledFileNameWrapper,
  StyledDivFooterWrapper,
  StyledButtonsWrapper,
  Button,
  ErrorLabel,
  LoaderWrapper,
} from './styled'

import CrossIcon from 'assets/icons/cross.png'
import IconFileBlue from 'assets/icons/iconFileBlue.png'

import toastTypes from 'constants/toastKeys'

import { capitalizeFirstLetter } from 'utils/strings'

import i18n from 'services/i18n'

class ImportPopupCourses extends Component {
  state = {
    fileToImport: null,
    selectedtype: null,
  }

  componentWillUnmount = () => {
    this.props.resetImportPopupCoursesErrors()
  }

  onClose = (soft) => {
    this.props.setImportPopupCoursesStatus({ open: false })
    if (!soft) {
      this.props.setDisplayToast({ isShowing: true, type: toastTypes.TOAST_TYPE_ADD })
    }
  }

  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      this.setState({ fileToImport: acceptedFiles[0] })
    }
  }

  hasFile = () => (this.state.fileToImport ? true : false)

  fileToBlob = (file) => {
    const data = new FormData()
    data.append(file.name, file)
    return data
  }

  handleDelete = () => this.setState({ fileToImport: null, selectedtype: null })

  handleImport = async () => {
    await this.props.importExcelCapacitations(this.fileToBlob(this.state.fileToImport))

    if (!this.hasError() && this.successImportation()) {
      this.onClose()
    }
  }

  successImportation = () => this.props.excelImport.capacitationsSuccess

  isImporting = () => this.props.excelImport.capacitationsLoading

  hasError = () => this.props.excelImport.capacitationsError

  render() {
    const { fileToImport } = this.state

    return (
      <Modal>
        <StyledContainer>
          <StyledDivHeaderWrapper>
            <StyledTextHeader>
              {capitalizeFirstLetter(`${i18n('IMPORT_POPUP').IMPORT}`) + ' ' + 'Cursos y empleados'}
            </StyledTextHeader>
            <CrossImage onClick={() => this.onClose(true)} src={CrossIcon} />
          </StyledDivHeaderWrapper>

          <Dropzone
            onDrop={this.onDrop}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            multiple={false}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <StyledDropZone {...getRootProps()}>
                <input {...getInputProps()} />
                {this.isImporting() ? (
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                ) : fileToImport ? (
                  <StyledFileNameWrapper>
                    <StyledIcon src={IconFileBlue} />
                    <text>{fileToImport.name}</text>
                  </StyledFileNameWrapper>
                ) : (
                  <StyledTextDropZone>
                    {!isDragActive && capitalizeFirstLetter(`${i18n('IMPORT_POPUP').HINT}`)}
                    {isDragActive &&
                      !isDragReject &&
                      capitalizeFirstLetter(`${i18n('IMPORT_POPUP').HINT_DROP}`)}
                    {isDragReject &&
                      capitalizeFirstLetter(`${i18n('IMPORT_POPUP').HINT_INCORRECT_FILE}`)}
                  </StyledTextDropZone>
                )}
              </StyledDropZone>
            )}
          </Dropzone>
          <StyledDivFooterWrapper>
            <StyledButtonsWrapper>
              <Button
                isDisabled={this.hasFile() && !this.isImporting()}
                onClick={() => (this.hasFile() && !this.isImporting() ? this.handleDelete() : '')}
              >
                <DeleteIcon />
              </Button>
              <Button
                isDisabled={this.hasFile() && !this.isImporting()}
                onClick={() => (this.hasFile() && !this.isImporting() ? this.handleImport() : '')}
              >
                {capitalizeFirstLetter(`${i18n('IMPORT_POPUP').IMPORT}`)}
              </Button>
            </StyledButtonsWrapper>
            {this.hasError() && (
              <ErrorLabel>
                {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR}`)}
              </ErrorLabel>
            )}
          </StyledDivFooterWrapper>
        </StyledContainer>
      </Modal>
    )
  }
}

const mapStateToProps = ({ importPopupCourses, excelImport }) => ({
  importPopupCourses,
  excelImport,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setImportPopupCoursesStatus,
      setDisplayToast,
      resetImportPopupCoursesErrors,
      importExcelCapacitations,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ImportPopupCourses)

import store from 'store'

import { getUserInfo } from 'utils/session'

const isDanone = () => {
  const danone = 'Danone'
  const { selectedBusiness } = store.getState().selectedFilter
  const sessionBusinessName =
    getUserInfo() &&
    getUserInfo().companies &&
    getUserInfo().companies[0] &&
    getUserInfo().companies[0].name

  return selectedBusiness.label === danone || sessionBusinessName === danone
}

export default isDanone

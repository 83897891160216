import { handleActions } from 'redux-actions'
import {
  byCentersStart,
  byCentersSuccess,
  byCentersError,
  byCentersReset,
} from 'actions/Dashboard/SummaryTraining/byCenters'

const defaultState = {
  success: false,
  values: [],
  total: 0,
  error: null,
  isLoading: false,
}

const reducer = handleActions(
  {
    [byCentersStart]: (state) => ({
      ...state,
      success: false,
      isLoading: true,
      error: [],
    }),
    [byCentersSuccess]: (state, { payload: { values, total } }) => ({
      ...state,
      success: true,
      values,
      total,
      isLoading: false,
    }),
    [byCentersError]: (state, { payload: { error } }) => ({
      ...state,
      success: false,
      isLoading: false,
      error,
    }),
    [byCentersReset]: () => ({ ...defaultState }),
  },
  defaultState
)

export default reducer

import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { FiltersForm } from './styled'
import FilterSelect from 'components/FilterSelect'

const FiltersUrl = ({ isLoading, ...props }) => {
  const location = useLocation()
  const history = useHistory()

  const query = queryString.parse(location.search)
  const onChange = (filter, value) => {
    query['PageNumber'] = 1
    query[filter.name] = value
    if (value && filter.isMulti) {
      query[filter.name] = value.map((x) => x.value)
    } else if (value && value.value) {
      query[filter.name] = value.value
    }
    const urlNew = `${location.pathname}?${queryString.stringify(query)}`
    history.push(urlNew)
  }
  return (
    <FiltersForm noValidate>
      {props.filters.map((filter, i) => {
        let result = (
          <input
            key={`${filter.name}${i}`}
            type={filter.subType}
            name={filter.name}
            value={query[filter.name]}
            onChange={(value) => onChange(filter, value)}
            disabled={isLoading}
          />
        )
        switch (filter.type) {
          case 'select': {
            result = (
              <FilterSelect
                {...filter}
                disabled={isLoading}
                key={`${filter.name}${i}`}
                onChange={(value) => onChange(filter, value)}
                value={query[filter.name]}
              />
            )
          }
        }
        return (
          <label key={'filter' + i}>
            <span>{filter.label}</span>
            {result}
          </label>
        )
      })}
    </FiltersForm>
  )
}
export default FiltersUrl

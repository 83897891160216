export default {
  GENERAL_ADMIN: 'general admin',
  ADMINISTRATOR: 'admin',
  INSTRUCTOR: 'instructor',
  LEADER_USER: 'leader user',
  DIRECTOR: 'director',
  MANAGER: 'manager',
  BOSS: 'boss',
  EMPLOYEE: 'employee'
}

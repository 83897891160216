import { handleActions } from 'redux-actions'
import {
  optionalStart,
  optionalSuccess,
  optionalError,
  optionalReset,
} from 'actions/Dashboard/TrainingDetail/optional'

const defaultState = {
  success: false,
  values: [],
  total: 0,
  error: null,
  isLoading: false,
}

const reducer = handleActions(
  {
    [optionalStart]: (state) => ({
      ...state,
      success: false,
      isLoading: true,
      error: [],
    }),
    [optionalSuccess]: (state, { payload: { values, total } }) => ({
      ...state,
      success: true,
      values,
      total,
      isLoading: false,
    }),
    [optionalError]: (state, { payload: { error } }) => ({
      ...state,
      success: false,
      isLoading: false,
      error,
    }),
    [optionalReset]: () => ({ ...defaultState }),
  },
  defaultState
)

export default reducer

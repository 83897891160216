import { createMuiTheme } from '@material-ui/core/styles'

import theme from 'config/theme'

export default createMuiTheme({
  palette: {
    primary: { main: theme.colors.blue },
    secondary: { main: theme.colors.purple },
  },
})

import styled from 'styled-components'

export const Button = styled.button`
  min-height: 40px;
  min-width: 70px;
  background: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.purple : theme.colors.dustyGray};
  border: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'pointer' : 'inherit')};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
`

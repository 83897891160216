import styled from 'styled-components'

export const arrowBack = theme => ({
  color: theme.colors.blue
})

export const Button = styled.button`
cursor: pointer
background: ${({ theme }) => theme.colors.white};
height: 40px;
width: 40px;
border-radius: 20px;
border: none;
`

export const ButtonBackWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`

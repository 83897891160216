import React from 'react'
import styled from 'styled-components'

import SearchIcon from '@material-ui/icons/Search'

const Button = styled.button`
  max-width: 120px;
  min-width: 70px;
  background: ${({ theme }) => theme.colors.blue};
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.white};
  width: 7%;
  border-radius: 30px;
`

export const SearchButton = ({ handleSearch }) => (
  <Button onClick={() => handleSearch()}>
    <SearchIcon />
  </Button>
)

export default SearchButton

import { createActions } from 'redux-actions'

import { excelImportErrorsReset } from 'actions/excelImport'

const { importPopupStatus } = createActions({
  IMPORT_POPUP_STATUS: data => ({ data })
})

const setImportPopupStatus = status => dispatch => dispatch(importPopupStatus({ status }))

const resetImportPopupErrors = () => dispatch => {
  dispatch(excelImportErrorsReset())
}

export { setImportPopupStatus, resetImportPopupErrors, importPopupStatus }

import React from 'react'
import { Redirect } from 'react-router-dom'
import { isGeneralAdmin, isAdmin, isInstructor, isLeaderUser, getUserRole } from 'utils/session'

import BASE_ROUTES from 'utils/baseRoutes'

import PrivateRoute from 'components/PrivateRoute'

const ConditionalRoute = ({ component, condition, path, ...rest }) =>
  condition ? (
    <PrivateRoute path={path} component={component} {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: BASE_ROUTES[getUserRole()]
      }}
    />
  )

export const AdminRoute = props => (
  <ConditionalRoute
    condition={isAdmin() || isGeneralAdmin()}
    path={props.path}
    component={props.component}
  />
)

export const InstructorRoute = props => (
  <ConditionalRoute
    condition={isInstructor() || isAdmin() || isGeneralAdmin()}
    path={props.path}
    component={props.component}
  />
)

export const LeaderUserRoute = props => (
  <ConditionalRoute
    condition={isLeaderUser() || isInstructor()}
    path={props.path}
    component={props.component}
  />
)

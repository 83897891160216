import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const TabContainer = styled.div`
  width: 100%
  margin: 15px 0;
  ${({ isSubTab }) => (isSubTab ? 'margin-top: 0;' : '')}
`

export const Tab = styled(NavLink)`
  max-width: 190px;
  object-fit: contain;
  border-radius: 12.5px;
  transition: ${({ isSubTab }) => (isSubTab ? 'none' : 'all 0.7s ease')};
  background: ${({ theme: { colors }, selected, isSubTab }) =>
    isSubTab ? 'transparent' : selected ? colors.purple : colors.white};
  box-shadow: none;
  border: ${({ theme: { colors }, selected, isSubTab }) =>
    isSubTab && selected ? `2px solid ${colors.purple}` : '2px solid transparent'};
  padding: 3px 11px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme: { colors }, selected, isSubTab }) =>
    isSubTab ? colors.purple : selected ? colors.white : colors.purple};
  margin: 0px 15px 0px 0px;
  text-decoration: none;
  &:hover {
    color: ${({ selected, isSubTab }) => (selected && !isSubTab ? 'withe' : 'black')};
  }
  &.active {
    background: ${({ theme: { colors } }) => colors.purple};
    color: white;
    &:hover {
      color: white;
    }
  }
`

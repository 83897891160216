import React, { useLayoutEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import { PaginationContainer, PaginationItem } from './styled'

const PageItem = ({ page, children }) => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const pageCurrent = query['PageNumber'] ? Number(query['PageNumber']) : 1

  let newQuery = { ...query }
  if (page != query['PageNumber']) {
    newQuery['PageNumber'] = page
  }
  const urlPage = `${location.pathname}?${queryString.stringify(newQuery)}`

  return (
    <PaginationItem
      isActive={() => (children ? false : pageCurrent == page)}
      to={urlPage}
      className={children ? 'icon' : ''}
    >
      {children ? children : page}
    </PaginationItem>
  )
}
const PaginationUrl = (props) => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const pageCurrent = query['PageNumber'] ? Number(query['PageNumber']) : 1

  const { total, size } = props
  const getPages = () => {
    let pagesCurrent = 1
    if (total > size) {
      pagesCurrent = Math.round(total / size)
      if (total % size > 0) {
        pagesCurrent++
      }
    }
    return pagesCurrent
  }
  const [pages, setPages] = useState(getPages())
  useLayoutEffect(() => {
    setPages(getPages())
  }, [total])

  const pagesHtml = []

  let pageFrom = pageCurrent > 9 ? pageCurrent - 4 : 1
  const limit = 5
  let pageTo = 0
  if (pages - pageCurrent < limit) {
    const difference = 10 - (pages - pageCurrent)
    const newFrom = pageCurrent - difference
    pageFrom = newFrom > 0 ? newFrom : 1
    pageTo = pages
  } else {
    const newFrom = pageCurrent - limit
    pageFrom = newFrom > 0 ? newFrom : 1
    pageTo = pageCurrent + limit
  }

  for (let page = pageFrom; page <= pageTo; page++) {
    pagesHtml.push(<PageItem key={'page-item' + page} page={page}></PageItem>)
  }
  const nextPage = pageCurrent == pages ? pageCurrent : pageCurrent + 1
  return (
    <PaginationContainer>
      <PageItem page={1}>‹‹</PageItem>
      <PageItem page={-1}>‹</PageItem>
      {pagesHtml}
      <PageItem page={nextPage}>›</PageItem>
      <PageItem page={pages}>››</PageItem>
    </PaginationContainer>
  )
}

export default PaginationUrl

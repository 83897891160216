import React from 'react'
import { Radio } from '@material-ui/core'
import { RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons'

import { withStyles } from '@material-ui/core/styles'
import theme from 'config/theme'

const CustomRadioPurple = withStyles({
  root: {
    '&$checked': {
      color: theme.colors.purple
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />)

const CustomRadioWhite = withStyles({
  root: {
    color: theme.colors.white,
    '&$checked': {
      color: theme.colors.white
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />)

const customRadioMaterial = ({ checked, small, disabled, inWhite, ...otherProps }) =>
  inWhite ? (
    <CustomRadioWhite
      checked={checked}
      icon={small ? <RadioButtonUnchecked fontSize="small" /> : <RadioButtonUnchecked />}
      checkedIcon={small ? <RadioButtonChecked fontSize="small" /> : <RadioButtonChecked />}
      disabled={disabled}
      {...otherProps}
    />
  ) : (
    <CustomRadioPurple
      checked={checked}
      icon={small ? <RadioButtonUnchecked fontSize="small" /> : <RadioButtonUnchecked />}
      checkedIcon={small ? <RadioButtonChecked fontSize="small" /> : <RadioButtonChecked />}
      disabled={disabled}
      {...otherProps}
    />
  )

export default customRadioMaterial

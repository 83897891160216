import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Modal from 'components/Modal'

import { setPicturesPopupStatus } from 'actions/picturesPopup'

import CrossIcon from 'assets/icons/cross.png'

import {
  StyledContainer,
  StyledDivHeaderWrapper,
  CrossImage,
  StyledImgsWrapper,
  ImageWrapper,
  Image,
  NoImage
} from './styled'

import { capitalizeFirstLetter, allToLower } from 'utils/strings'
import { base64ToFile } from 'utils/imageParser'

import i18n from 'services/i18n'

class PicturesPopup extends Component {
  state = {}

  onClose = () => {
    this.props.setPicturesPopupStatus({ open: false, item: null })
  }

  render() {
    const { picturesPopup } = this.props
    return (
      <Modal>
        <StyledContainer>
          <StyledDivHeaderWrapper>
            <CrossImage onClick={() => this.onClose(true)} src={CrossIcon} />
          </StyledDivHeaderWrapper>
          {picturesPopup.item.employeeName
            ? capitalizeFirstLetter(allToLower(picturesPopup.item.employeeName))
            : ''}{' '}
          <StyledImgsWrapper>
            <ImageWrapper>
              {picturesPopup.item.employeeImg ? (
                <Image src={base64ToFile(picturesPopup.item.employeeImg)} />
              ) : (
                <NoImage>{capitalizeFirstLetter(`${i18n('PIC_POPUP').NO_IMG}`)}</NoImage>
              )}
            </ImageWrapper>
          </StyledImgsWrapper>
        </StyledContainer>
      </Modal>
    )
  }
}

const mapStateToProps = ({ picturesPopup }) => {
  return {
    picturesPopup
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPicturesPopupStatus
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PicturesPopup)

import userKeys from 'constants/userKeys'
import i18n from 'services/i18n'

export default [
  {
    id: userKeys.DIRECTOR,
    name: `${i18n('USER_TYPES').DIRECTOR}`
  },
  {
    id: userKeys.MANAGER,
    name: `${i18n('USER_TYPES').MANAGER}`
  },
  {
    id: userKeys.BOSS,
    name: `${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`
  }
]

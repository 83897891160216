import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Wrapper, DividerStyle, InputsWrapper } from './styled'

import { FormControl, RadioGroup, FormControlLabel, FormLabel, Divider } from '@material-ui/core'

import CustomRadioMaterials from 'components/CustomRadio'
import SearchButton from 'components/SearchButton'
import ClearButton from 'components/ClearButton'
import { nameInput, instructorInputs } from './userTypeInputs.js'

import userFilterKeys from 'constants/usersFilterKeys'

import i18n from 'services/i18n'

import { capitalizeFirstLetter } from 'utils/strings'
import { isGeneralAdmin } from 'utils/session'

import { setSelectedUserStatus } from 'actions/selectedUserStatus'
import { fetchCategories } from 'actions/categories'
import { setSelectedUsersFilter, resetSelectedUsersFilter } from 'actions/selectedUsersFilter'
import { setShowingGeneralAdmin } from 'actions/generalAdmin'
import { setShowingBusinessAdmin } from 'actions/administrators'
import { setShowingInstructors } from 'actions/instructors'
import { setShowingLeaderUsers } from 'actions/leaderUsers'
import { fetchGeneralAdministrators, resetGeneralAdministrators } from 'actions/generalAdmin'
import { fetchAdministrators, resetAdministrators } from 'actions/administrators'
import { fetchInstructors, resetInstructors } from 'actions/instructors'
import { fetchLeaderUsers, resetLeaderUsers } from 'actions/leaderUsers'
import { resetExpandedUserType } from 'actions/expandedUserType'

import userKeys from 'constants/userKeys'

class UserFilterModule extends Component {
  componentDidMount = () => {
    this.props.setSelectedUserStatus({ selected: userFilterKeys.ACTIVE })
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.selectedUsersFilter.userType !== userKeys.INSTRUCTOR &&
      this.props.selectedUsersFilter.userType === userKeys.INSTRUCTOR
    ) {
      this.props.fetchCategories()
    }

    if (prevProps.selectedUsersFilter.userType !== this.props.selectedUsersFilter.userType) {
      this.props.setSelectedUsersFilter({ inputNameValue: '', category: null })
    }

    if (
      (prevProps.deletePopup.open && !this.props.deletePopup.open) ||
      (prevProps.editPopup.open && !this.props.editPopup.open)
    ) {
      this.handleSearch()
    }
  }

  handleChangeUserType = event => {
    this.handleClear()
    this.props.setSelectedUsersFilter({ userType: event.target.value })
  }

  handleChangeFilter = event => {
    if (this.isShowingSearch()) {
      this.handleClear()
    }
    this.props.setSelectedUserStatus({ selected: event.target.value })
  }

  handleCategorySelector = target =>
    this.props.setSelectedUsersFilter({ category: { value: target.value, label: target.label } })

  isSomeUserTypeSelected = () => this.props.selectedUsersFilter.userType

  handleNameInput = event =>
    this.props.setSelectedUsersFilter({ inputNameValue: event.target.value })

  hasSomeFilter = () =>
    this.props.selectedUsersFilter.inputNameValue || this.props.selectedUsersFilter.category

  handleSearch = () => {
    switch (this.props.selectedUsersFilter.userType) {
      case userKeys.GENERAL_ADMIN:
        this.props.setShowingGeneralAdmin({ isShowing: true })
        this.props.fetchGeneralAdministrators()
        break
      case userKeys.ADMINISTRATOR:
        this.props.setShowingBusinessAdmin({ isShowing: true })
        this.props.fetchAdministrators()
        break
      case userKeys.INSTRUCTOR:
        this.props.setShowingInstructors({ isShowing: true })
        this.props.fetchInstructors()
        break
      case userKeys.LEADER_USER:
        this.props.setShowingLeaderUsers({ isShowing: true })
        this.props.fetchLeaderUsers()
        break
    }
  }

  handleClear = () => {
    this.props.resetExpandedUserType()
    this.props.resetSelectedUsersFilter()
    this.props.setShowingGeneralAdmin({ isShowing: false })
    this.props.setShowingBusinessAdmin({ isShowing: false })
    this.props.setShowingInstructors({ isShowing: false })
    this.props.setShowingLeaderUsers({ isShowing: false })
    this.props.resetGeneralAdministrators()
    this.props.resetAdministrators()
    this.props.resetInstructors()
    this.props.resetLeaderUsers()
  }

  isShowingSearch = () => {
    const { generalAdministrators, administrators, instructors, leaderUsers } = this.props
    return (
      generalAdministrators.isShowing ||
      administrators.isShowing ||
      instructors.isShowing ||
      leaderUsers.isShowing
    )
  }

  renderUserTypeFilters = () => {
    const { selectedUsersFilter } = this.props
    let inputs = ''
    switch (selectedUsersFilter.userType) {
      case userKeys.GENERAL_ADMIN:
        inputs = nameInput(selectedUsersFilter.inputNameValue, this.handleNameInput)
        break
      case userKeys.ADMINISTRATOR:
        inputs = nameInput(selectedUsersFilter.inputNameValue, this.handleNameInput)
        break
      case userKeys.INSTRUCTOR:
        inputs = instructorInputs(
          selectedUsersFilter.inputNameValue,
          this.handleNameInput,
          selectedUsersFilter.category,
          this.props.categories,
          this.handleCategorySelector
        )
        break
      case userKeys.LEADER_USER:
        inputs = nameInput(selectedUsersFilter.inputNameValue, this.handleNameInput)
        break
    }

    return (
      this.props.selectedUsersFilter.userType && (
        <InputsWrapper>
          {inputs}
          <SearchButton handleSearch={() => this.hasSomeFilter() && this.handleSearch()} />
          <ClearButton
            handleClear={() => this.handleClear()}
            disabled={this.isShowingSearch() || this.hasSomeFilter()}
          />
        </InputsWrapper>
      )
    )
  }

  render() {
    const {
      selectedUserStatus,
      selectedUsersFilter: { userType }
    } = this.props
    return (
      <Wrapper>
        <FormLabel> {capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_FILTERS_HINT}`)}</FormLabel>
        <FormControl component="fieldset">
          <RadioGroup
            style={{ flexDirection: 'row' }}
            aria-label="userType"
            name={'userType'}
            value={userType}
            onChange={e => this.handleChangeUserType(e)}
          >
            {isGeneralAdmin() && (
              <FormControlLabel
                value={userKeys.GENERAL_ADMIN}
                control={<CustomRadioMaterials checked={userType === userKeys.GENERAL_ADMIN} />}
                label={capitalizeFirstLetter(i18n('USER_TYPES').GENERAL_ADMIN)}
              />
            )}
            <FormControlLabel
              value={userKeys.ADMINISTRATOR}
              control={<CustomRadioMaterials checked={userType === userKeys.ADMINISTRATOR} />}
              label={capitalizeFirstLetter(i18n('USER_TYPES').ADMINISTRATOR)}
            />
            <FormControlLabel
              value={userKeys.INSTRUCTOR}
              control={<CustomRadioMaterials checked={userType === userKeys.INSTRUCTOR} />}
              label={capitalizeFirstLetter(i18n('USER_TYPES').INSTRUCTOR)}
            />
            <FormControlLabel
              value={userKeys.LEADER_USER}
              control={<CustomRadioMaterials checked={userType === userKeys.LEADER_USER} />}
              label={capitalizeFirstLetter(i18n('USER_TYPES').LEADER_USER)}
            />
          </RadioGroup>
        </FormControl>
        <Divider style={DividerStyle(this.isSomeUserTypeSelected())} />
        {this.renderUserTypeFilters()}

        <Divider />
        <FormLabel style={{ marginTop: '15px' }}>
          {capitalizeFirstLetter(i18n('SELECTOR').SELECTOR_USERS_STATE)}
        </FormLabel>
        <FormControl component="fieldset">
          <RadioGroup
            style={{ flexDirection: 'row' }}
            aria-label="userState"
            name={'userState'}
            value={selectedUserStatus.selected}
            onChange={e => this.handleChangeFilter(e)}
          >
            <FormControlLabel
              value={userFilterKeys.ACTIVE}
              control={
                <CustomRadioMaterials
                  checked={selectedUserStatus.selected === userFilterKeys.ACTIVE}
                />
              }
              label={capitalizeFirstLetter(i18n('SELECTOR').ACTIVE)}
            />
            <FormControlLabel
              value={userFilterKeys.INACTIVE}
              control={
                <CustomRadioMaterials
                  checked={selectedUserStatus.selected === userFilterKeys.INACTIVE}
                />
              }
              label={capitalizeFirstLetter(i18n('SELECTOR').INACTIVE)}
            />
            <FormControlLabel
              value={userFilterKeys.ALL}
              control={
                <CustomRadioMaterials
                  checked={selectedUserStatus.selected === userFilterKeys.ALL}
                />
              }
              label={capitalizeFirstLetter(i18n('SELECTOR').SELECTOR_ALL)}
            />
          </RadioGroup>
        </FormControl>
      </Wrapper>
    )
  }
}

const mapStateToProps = ({
  selectedUserStatus,
  categories,
  selectedUsersFilter,
  generalAdministrators,
  administrators,
  instructors,
  leaderUsers,
  deletePopup,
  editPopup
}) => ({
  selectedUserStatus,
  categories,
  selectedUsersFilter,
  generalAdministrators,
  administrators,
  instructors,
  leaderUsers,
  deletePopup,
  editPopup
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedUserStatus,
      fetchCategories,
      setSelectedUsersFilter,
      resetSelectedUsersFilter,
      setShowingGeneralAdmin,
      setShowingBusinessAdmin,
      setShowingInstructors,
      setShowingLeaderUsers,
      fetchGeneralAdministrators,
      fetchAdministrators,
      fetchInstructors,
      fetchLeaderUsers,
      resetGeneralAdministrators,
      resetAdministrators,
      resetInstructors,
      resetLeaderUsers,
      resetExpandedUserType
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFilterModule)

import { handleActions } from 'redux-actions'
import {
  showingCarrier,
  fetchCarrierEmployeeByFilterStart,
  fetchCarrierEmployeeByFilterSuccess,
  fetchCarrierEmployeeByFilterError,
  carrierEmployeeByFilterReset,
  fetchCarrierEmployeeByDateStart,
  fetchCarrierEmployeeByDateSuccess,
  fetchCarrierEmployeeByDateError,
  carrierEmployeeByDateReset,
  fetchCarrierEmployeeStart,
  fetchCarrierEmployeeSuccess,
  fetchCarrierEmployeeError,
  carrierEmployeeReset,
  addCarrierEmployeeStart,
  addCarrierEmployeeSuccess,
  addCarrierEmployeeError,
  addCarrierEmployeeReset,
  modifyCarrierEmployeeStart,
  modifyCarrierEmployeeSuccess,
  modifyCarrierEmployeeError,
  modifyCarrierEmployeeReset,
  deleteCarrierEmployeeStart,
  deleteCarrierEmployeeSuccess,
  deleteCarrierEmployeeError,
  deleteCarrierEmployeeErrorReset
} from 'actions/carrierEmployee'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,

  valuesByDate: null,
  errorByDate: null,
  isFetchingByDate: false,

  isShowing: false,

  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false
}

const reducer = handleActions(
  {
    [showingCarrier]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [fetchCarrierEmployeeStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchCarrierEmployeeSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchCarrierEmployeeError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [carrierEmployeeReset]: () => defaultState,

    [fetchCarrierEmployeeByDateStart]: state => ({
      ...state,
      isFetchingByDate: true,
      errorByDate: null
    }),
    [fetchCarrierEmployeeByDateSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesByDate: values,
      isFetchingByDate: false,
      errorByDate: null
    }),
    [fetchCarrierEmployeeByDateError]: (state, { error }) => ({
      ...state,
      isFetchingByDate: false,
      errorByDate: error
    }),
    [carrierEmployeeByDateReset]: () => defaultState,

    [fetchCarrierEmployeeByFilterStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchCarrierEmployeeByFilterSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchCarrierEmployeeByFilterError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [carrierEmployeeByFilterReset]: () => defaultState,

    [modifyCarrierEmployeeStart]: state => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false
    }),
    [modifyCarrierEmployeeSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyCarrierEmployeeError]: (state, { error }) => ({
      ...state,
      isSubmiting: false,
      errorEdit: error,
      successEdit: false
    }),
    [modifyCarrierEmployeeReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false
    }),

    [addCarrierEmployeeStart]: state => ({
      ...state,
      isAdding: true,
      errorAdd: false,
      successAdd: false
    }),
    [addCarrierEmployeeSuccess]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false,
      successAdd: true
    }),
    [addCarrierEmployeeError]: (state, { error }) => ({
      ...state,
      isAdding: false,
      errorAdd: error,
      successAdd: false
    }),
    [addCarrierEmployeeReset]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false
    }),

    [deleteCarrierEmployeeStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteCarrierEmployeeSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteCarrierEmployeeError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),
    [deleteCarrierEmployeeErrorReset]: state => ({
      ...state,
      isDeleting: false,
      errorDelete: false
    })
  },
  defaultState
)

export default reducer

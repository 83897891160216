import styled from 'styled-components'
import mediaQueries from 'config/media-queries'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.lightBlue};
`

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  max-width: ${({ narrow }) => (narrow ? 1000 : 1200)}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaQueries.laptop`
    max-width: ${({ narrow }) => (narrow ? 900 : 1100)}px;
  `};
`
export const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const StyledImgDanone = styled.img`
  width: 28%;
  max-width: 450px;
  min-width: 300px;
`

export const StyledImgLaserenisima = styled.img`
  width: 22%;
  max-width: 350px;
  min-width: 240px;
`

export const TextWrapper = styled.div`
  display: flex;
`

export const StyledLabel = styled.label`
  font-size: ${({ size }) => size || 30}px;
  color: ${({ theme }) => theme.colors.white};
`

export const Redirect = styled.a`
  font-size: ${({ size }) => size || 30}px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  text-decoration: underline;
`

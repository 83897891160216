import { handleActions } from 'redux-actions'
import {
  coursesByCategoriesIdsStart,
  coursesByCategoriesIdsSuccess,
  coursesByCategoriesIdsError,
} from 'actions/coursesByCategoriesIds'

const defaultState = {
  success: false,
  values: [],
  cache: [],
  error: null,
  isFetching: false,
}

const reducer = handleActions(
  {
    [coursesByCategoriesIdsStart]: (state) => ({
      ...state,
      success: false,
      isFetching: true,
      error: [],
    }),
    [coursesByCategoriesIdsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      success: true,
      values,
      cache: [
        ...state.cache,
        ...values.filter((newValue) => !state.cache.find((x) => x.centerId === newValue.centerId)),
      ],
      isFetching: false,
    }),
    [coursesByCategoriesIdsError]: (state, { payload: { error } }) => ({
      ...state,
      success: false,
      isFetching: false,
      error,
    }),
  },
  defaultState
)

export default reducer

import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchWorkPositionStart,
  fetchWorkPositionSuccess,
  fetchWorkPositionError,
  workPositionReset
} = createActions({
  FETCH_WORK_POSITION_START: () => {},
  FETCH_WORK_POSITION_SUCCESS: data => ({ data }),
  FETCH_WORK_POSITION_ERROR: error => ({ error }),
  WORK_POSITION_RESET: () => {}
})

const fetchWorkPosition = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchWorkPositionStart())
    try {
      const response = await api.get(`${API_ROUTES.WORK_POSITION}`)
      dispatch(fetchWorkPositionSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchWorkPositionError(error))
    }
  }
}

const resetWorkPosition = () => dispatch => dispatch(workPositionReset())

export {
  fetchWorkPositionStart,
  fetchWorkPositionSuccess,
  fetchWorkPositionError,
  workPositionReset,
  fetchWorkPosition,
  resetWorkPosition
}

import { handleActions } from 'redux-actions'
import {
  fetchTrainingSummaryStart,
  fetchTrainingSummarySuccess,
  fetchTrainingSummaryError,
  trainingSummaryReset
} from 'actions/trainingSummary'

const defaultState = {
  values: null,
  isFetching: false,
  error: null
}

const reducer = handleActions(
  {
    [fetchTrainingSummaryStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchTrainingSummarySuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values: values,
      isFetching: false,
      error: null
    }),
    [fetchTrainingSummaryError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error: error
    }),
    [trainingSummaryReset]: () => defaultState
  },
  defaultState
)

export default reducer

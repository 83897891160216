import { handleActions } from 'redux-actions'
import { capacitationDetailStatus } from 'actions/capacitationDetailStatus'

const defaultState = {
  status: false
}

const reducer = handleActions(
  {
    [capacitationDetailStatus]: (state, { payload: { data: status } }) => status
  },
  defaultState
)

export default reducer

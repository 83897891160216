import { capitalizeFirstLetter, allToLower } from 'utils/strings'

export const reestructureDataArray = data =>
  data.map(item => ({
    ...item,
    value: item.id,
    label: capitalizeFirstLetter(allToLower(item.name))
  }))

export const reestructureDataSimple = (id, name) => {
  return { value: id, label: capitalizeFirstLetter(allToLower(name)) }
}

export const getArrayOfId = data =>
  data.map(item => ({
    Id: item.value
  }))

import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  leaderUsersByCenterIdsStart,
  leaderUsersByCenterIdsSuccess,
  leaderUsersByCenterIdsError,
} = createActions({
  LEADER_USERS_BY_CENTER_IDS_START: () => {},
  LEADER_USERS_BY_CENTER_IDS_SUCCESS: (values) => ({ values }),
  LEADER_USERS_BY_CENTER_IDS_ERROR: (error) => ({ error }),
})

const getLeaderUsersByCenterIds = (ids) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    const {
      leaderUsersByCenterIds: { cache },
    } = getState()
    dispatch(leaderUsersByCenterIdsStart())
    try {
      return Promise.all(
        (ids instanceof Array ? ids : [ids]).map((id) => {
          const center = cache.find((item) => item.centerId === id)
          if (center) {
            return new Promise((resolve) => {
              resolve(center)
            })
          } else {
            return api
              .get(`${API_ROUTES.LEADER_USERS}/${API_ROUTES.BY_DISTRIBUTION_CENTER}/${id}`)
              .then((response) => ({ centerId: id, items: response.data.data }))
          }
        })
      ).then((leaders) => {
        dispatch(leaderUsersByCenterIdsSuccess(leaders))
      })
    } catch (error) {
      dispatch(leaderUsersByCenterIdsError(error.data ? error.data.message : error.message))
    }
  }
}

export {
  getLeaderUsersByCenterIds,
  leaderUsersByCenterIdsStart,
  leaderUsersByCenterIdsSuccess,
  leaderUsersByCenterIdsError,
}

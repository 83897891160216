import styled from 'styled-components'

export const RadioGroupStyle = {
  display: 'flex',
  margin: '5px 0px',
  flexDirection: 'row'
}

export const FormControlLabelStyle = {
  height: '30px'
}

export const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DividerStyle = isShowing => ({
  display: isShowing ? 'inherit' : 'none'
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchExcel } from 'actions/excelDownload'
import { fetchCapacitationsByFilter, resetCapacitationsByFilter } from 'actions/capacitations'
import { setSelectedCapacitation } from 'actions/selectedCapacitation'
import { setCapacitationDetailStatus } from 'actions/capacitationDetailStatus'
import { setSelectedSearchedItem, resetSelectedSearchedItem } from 'actions/selectedSearchedItem'
import { setEditPopupStatus } from 'actions/editPopup'
import { setDeletePopupStatus } from 'actions/deletePopup'
import { setSelectedCapacitationPage } from 'actions/page'

import { DivWrapperItems, DivWrapperError, DivWrapperLoader } from './styled'

import { capitalizeFirstLetter } from 'utils/strings'

import i18n from 'services/i18n'

import Loader from 'components/Loaders/propagate'
import MaterialExpansionPanel from 'components/ExpansionPanel'
import CapacitationPreview from 'components/CapacitationPreview'
import Pagination from 'components/Pagination'

import { paginate } from 'utils/paginator'

import filterKeys from 'constants/filterKeys'
import popupKeys from 'constants/popupKeys'

class SearchDisplayCenters extends Component {
  state = {
    current: 1
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.selectedPage.capacitationPage !== this.props.selectedPage.capacitationPage &&
      this.props.selectedFilter.filterCategory === filterKeys.CENTER.CENTER_ID
    ) {
      this.getCapacitationsByCenterId(this.props.selectedSearchedItem.expandedItem)
    }
  }

  isFetching = () =>
    this.props.centers.isFetching ||
    this.props.centers.isFetchingByDate ||
    this.props.centers.isFetchingAll

  isCenterExpanded = id => this.props.selectedSearchedItem.expandedItem === id

  thereAreValues = () =>
    this.props.centers.values || this.props.centers.valuesByDate || this.props.centers.valuesAll

  getCapacitationsByCenterId = id => {
    if (this.isCenterExpanded(id) && id) {
      this.props.fetchCapacitationsByFilter(filterKeys.CENTER.CENTER_ID, id)
    }
  }

  setSelectedCenter = id => {
    if (this.isCenterExpanded(id)) {
      this.props.resetSelectedSearchedItem()
    } else {
      this.props.setSelectedSearchedItem({ id })
    }
  }

  wichCenterWasSelected = id => this.state.selectedCenterDownload === id

  handleCenter = id => {
    this.props.resetSelectedSearchedItem()
    this.props.resetCapacitationsByFilter()
    this.props.setSelectedCapacitationPage(1)
    this.setSelectedCenter(id)
    setTimeout(() => {
      this.getCapacitationsByCenterId(id)
    }, 40)
  }

  fetchExcel = (e, id, type, fileName) => {
    this.setState({ selectedCenterDownload: id })
    this.props.fetchExcel(id, type, fileName)
    e.stopPropagation()
  }

  handleCapacitationDetail = (e, capacitation) => {
    e.stopPropagation()
    this.props.setSelectedCapacitation(capacitation)
    this.props.setCapacitationDetailStatus(
      this.props.capacitationDetailStatus.status ? false : true
    )
  }

  onChangePagination = page => {
    this.setState({
      current: page
    })
  }

  handleEditPopup = (e, item, type) => {
    this.props.setEditPopupStatus({ open: true, itemToEdit: item, type: type })
    e.stopPropagation()
  }

  handleDelete = (e, item, type) => {
    this.props.setDeletePopupStatus({ open: true, itemToDelete: item, type: type })
    e.stopPropagation()
  }

  wichValues = () =>
    this.props.centers.values ? this.props.centers.values : this.hasCapacitations()

  hasCapacitations = () => {
    if (
      this.props.selectedDateFilter.filterDateFrom &&
      this.props.selectedDateFilter.filterDateTo &&
      this.props.centers.valuesByDate
    ) {
      return this.props.centers.valuesByDate.filter(e => e.hasTrainings)
    } else {
      return this.props.centers.valuesAll
    }
  }

  render() {
    if (this.isFetching()) {
      return <Loader />
    } else {
      return this.thereAreValues() ? (
        this.wichValues().length ? (
          <DivWrapperItems>
            {paginate(this.state.current, this.wichValues()).map(item => (
              <MaterialExpansionPanel
                key={item.id}
                name={item.name}
                id={item.id}
                expanded={this.isCenterExpanded(item.id)}
                onClick={() => this.handleCenter(item.id)}
                withDownload={true}
                onButtonDownloadClick={e =>
                  this.fetchExcel(e, item.id, filterKeys.CENTER.CENTER_ID, item.name)
                }
                buttonDownloadLoading={
                  this.props.excelDownload.isFetching && this.wichCenterWasSelected(item.id)
                }
                withEdit
                onButtonEditClick={e => this.handleEditPopup(e, item, popupKeys.CENTER)}
                withDelete
                onButtonDeleteClick={e => this.handleDelete(e, item, popupKeys.CENTER)}
                hasCapacitations={item['hasTrainings']}
              >
                <div>
                  {this.props.capacitations.isFetching ? (
                    <DivWrapperLoader>
                      <Loader />
                    </DivWrapperLoader>
                  ) : this.props.capacitations.values &&
                    this.props.capacitations.values.items.length ? (
                    <CapacitationPreview
                      onClick={(e, capacitation) => this.handleCapacitationDetail(e, capacitation)}
                      data={this.props.capacitations.values ? this.props.capacitations.values : ''}
                      onDeleteClick={(e, capacitation) =>
                        this.handleDelete(e, capacitation, popupKeys.CAPACITATION)
                      }
                    />
                  ) : (
                    <DivWrapperError>
                      {capitalizeFirstLetter(`${i18n('HOME').HOME_PAGE_NO_CAPACITATIONS}`)}
                    </DivWrapperError>
                  )}
                </div>
              </MaterialExpansionPanel>
            ))}
            <Pagination
              total={this.wichValues().length}
              isLocal={true}
              onChangeLocal={this.onChangePagination}
              selectedPageLocal={this.state.current}
            />
          </DivWrapperItems>
        ) : (
          <DivWrapperError>
            {capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_NO_DATA}`)}
          </DivWrapperError>
        )
      ) : (
        <DivWrapperError>{capitalizeFirstLetter(`${i18n('SEARCH').SEARCH_ERROR}`)}</DivWrapperError>
      )
    }
  }
}

const mapStateToProps = ({
  centers,
  capacitations,
  excelDownload,
  capacitationDetailStatus,
  selectedSearchedItem,
  selectedDateFilter,
  selectedPage,
  selectedFilter
}) => ({
  centers,
  capacitations,
  excelDownload,
  capacitationDetailStatus,
  selectedSearchedItem,
  selectedDateFilter,
  selectedPage,
  selectedFilter
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCapacitationsByFilter,
      fetchExcel,
      setSelectedCapacitation,
      setCapacitationDetailStatus,
      setSelectedSearchedItem,
      resetSelectedSearchedItem,
      resetCapacitationsByFilter,
      setEditPopupStatus,
      setDeletePopupStatus,
      setSelectedCapacitationPage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDisplayCenters)

import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { capitalizeFirstLetter } from 'utils/strings'

dayjs.locale('es')

export const getExtendedDate = date => {
  const actualDate = dayjs(date)
  return `${actualDate.format('DD')} de ${capitalizeFirstLetter(
    actualDate.format('MMMM')
  )}, ${actualDate.format('YYYY')}, ${actualDate.format('HH:mm')}`
}

export const getDate = date => {
  const actualDate = dayjs(date)
  return `${actualDate.format('DD')} de ${capitalizeFirstLetter(
    actualDate.format('MMMM')
  )}, ${actualDate.format('YYYY')}`
}

export const getSimpleDate = date => {
  //this date is already formatted with dayjs
  return date.format('YYYY-MM-D')
}

export const getInlineDate = date => {
  const actualDate = dayjs(date)
  return actualDate.format('YYYYMMDD')
}

export const getPdfDate = date => {
  const actualDate = dayjs(date)
  return actualDate.format('DD/MM/YYYY')
}

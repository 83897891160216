import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isUserLoggedIn } from 'utils/session'
import i18n from 'services/i18n'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isUserLoggedIn() ? (
          rest.path === '/' ? (
            <Redirect
              to={{
                pathname: `/${i18n('PAGE_COURSES')}`,
                state: { from: props.location }
              }}
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: `/${i18n('LOGIN').LOGIN_PAGE}`,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute

import React from 'react'
import { useLocation } from 'react-router-dom'
import SummaryTraining from './SummaryTraining'
import i18n from 'services/i18n'
import { Switch, Route, Redirect } from 'react-router-dom'
import Page from 'components/Page'
import Tabs from 'components/Tabs'
import TrainingDetail from './TrainingDetail'
import PendingTraining from './PendingTraining'

export default function Dashboard(props) {
  const location = useLocation()
  let urlSearch = location.search
  if (!location.search) {
    urlSearch = `?PageNumber=1&Year=${new Date().getFullYear()}`
  }

  const urlSummary = props.match.path + `/` + i18n('PAGES_DASHBOARD').TRAINING_SUMMARY
  const urlDetail = props.match.path + `/` + i18n('PAGES_DASHBOARD').TRAINING_DETAIL
  const urlPending = props.match.path + `/` + i18n('PAGES_DASHBOARD').TRAINING_PENDING

  const tabItems = [
    { label: i18n('DASHBOARD').RESUME_TAB, url: `${urlSummary}${urlSearch}` },
    { label: i18n('DASHBOARD').DETAIL_TAB, url: `${urlDetail}${urlSearch}` },
    { label: i18n('DASHBOARD').PENDING_TAB, url: `${urlPending}${urlSearch}` },
  ]

  return (
    <Page withHeader>
      <Tabs items={tabItems} />
      <Switch>
        <Route path={urlSummary} component={SummaryTraining}></Route>
        <Route path={urlDetail} component={TrainingDetail}></Route>
        <Route path={urlPending} component={PendingTraining}></Route>
        <Redirect exact path={props.match.path + `/`} to={urlSummary} />
      </Switch>
    </Page>
  )
}

import styled from 'styled-components'

export const DivHeaderWrapper = styled.div`
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  display: flex;
`

export const StyledText = styled.text`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`

export const StyledTitle = styled.text`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
  flex-direction: column;
  text-align: end;
  font-size: 20px;
  margin-right: 10px;
`

export const DivHeaderItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;
`

export const CapacitationTitleWrapper = styled.div`
  display: flex;
  width: 33%;
  flex-direction: column;
`

export const StyledError = styled.label`
  width: 300px;
  margin-left: auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.red};
  font-weight: 600;
`

export const SignatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 145px;
`

export const Signature = styled.img`
  width: 145px;
  height: 125px;
  margin: auto auto;
`

export const Separator = styled.div`
  height: 120px;
  width: 1px;
  background: ${({ theme }) => theme.colors.darkGrey};
  margin: auto 20px;
`

export const NoSignatureWrapper = styled.div`
  width: 145px;
  height: 125px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

import { handleActions } from 'redux-actions'
import { selectedUserStatus, resetUserStatus } from 'actions/selectedUserStatus'

const defaultState = {
  selected: null
}

const reducer = handleActions(
  {
    [selectedUserStatus]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [resetUserStatus]: () => defaultState
  },
  defaultState
)

export default reducer

import React from 'react'

import { CustomInputWrapper, Input } from './styles'

import i18n from 'services/i18n'

import { capitalizeFirstLetter } from 'utils/strings'
import { reestructureDataArray } from 'utils/selectorParser'

import theme from 'config/theme'

import Selector from 'components/Selector'

const Wrapper = props => (
  <CustomInputWrapper style={props.style}>{props.children}</CustomInputWrapper>
)

const IndicatorSeparator = ({ innerProps }) => {
  return <span {...innerProps} />
}

export const nameInput = (value, handleNameInput) => (
  <Wrapper>
    <Input
      value={value}
      placeholder={capitalizeFirstLetter(i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME)}
      onChange={e => handleNameInput(e)}
    />
  </Wrapper>
)

export const instructorInputs = (
  value,
  handleNameInput,
  category,
  availableCategories,
  handleCategorySelector
) => (
  <>
    {nameInput(value, handleNameInput)}
    <Wrapper style={{ marginLeft: '15px' }}>
      <Selector
        components={{
          IndicatorSeparator
        }}
        placeholder={capitalizeFirstLetter(i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY)}
        value={category}
        noOptionsMessage={() => capitalizeFirstLetter(i18n('SEARCH').SEARCH_NO_DATA)}
        options={
          availableCategories.values ? reestructureDataArray(availableCategories.values) : []
        }
        isLoading={availableCategories.isFetching}
        disabled={availableCategories.isFetching || availableCategories.error}
        onChange={target => handleCategorySelector(target)}
        styles={{
          container: base => ({
            ...base,
            width: 'inherit'
          }),
          control: base => ({
            ...base,
            minHeight: '30px',
            height: '30px',
            width: 'inherit',
            border: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            backgroundColor: 'transparent'
          }),
          valueContainer: base => ({
            ...base,
            padding: '0px 2px',
            overflow: 'auto',
            position: 'initial'
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            padding: state.selectProps.menuIsOpen ? '0px 0px 0px 8px' : '0px 8px 0px 0px',
            color: state.isFocused ? theme.colors.blue : theme.colors.dustyGray,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
            transition: 'all 0.5s ease',
            boxShadow: 'none',
            '&:hover': {
              color: theme.colors.blue
            }
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected && theme.colors.blue,
            fontSize: '15px',
            fontFamily: 'Roboto,sans-serif',
            '&:hover': {
              backgroundColor: !state.isSelected && theme.colors.grey
            }
          }),
          menuList: base => ({
            ...base
          }),
          placeholder: base => ({
            ...base,
            height: '23px',
            fontSize: '16px',
            fontFamily: 'Roboto,sans-serif'
          }),
          singleValue: base => ({
            ...base,
            width: '140px',
            fontSize: '16px',
            fontFamily: 'Roboto,sans-serif'
          })
        }}
      />
    </Wrapper>
  </>
)

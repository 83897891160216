import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from 'utils/strings'
import conformityStatus from 'config/Dashboard/conformityStatus'

import {
  TableContainer,
  TableRow,
  TableCell,
  ComplianceWrapper,
  ComplianceGraph,
  PercentageLabel,
} from './styled'

const Table = ({ columns, data, getCell, ...props }) => {
  const [newData, setNewData] = useState(null)

  useEffect(() => {
    ProcessData(data)
  }, [data])

  const ProcessData = (data) => {
    if (data) {
      data.forEach((element) => {
        if (element.employees) {
          if (element.employees.length === 0) {
            element.employees.push(emptyEmployee())
          }
        }
      })
    }
    setNewData(data)
  }

  const emptyEmployee = () => {
    return {
      name: '',
      fileNumber: '',
      firstName: '',
      lastName: '',
    }
  }

  const getCellDefault = (data, column) => {
    if (column.name === 'compliancePercentage') {
      if (data[column.name] > 100) {
        data[column.name] = 100
      }
    }

    return column.name === 'compliancePercentage' ? (
      <ComplianceWrapper>
        <PercentageLabel row={data[column.name]}>{data[column.name]}%</PercentageLabel>
        <ComplianceGraph row={data[column.name]} status={data.complianceStatus} />
      </ComplianceWrapper>
    ) : column.name === 'complianceStatus' ? (
      conformityStatus.find((x) => x.code == data[column.name]).label
    ) : column.subProp ? (
      data[column.name][column.subProp]
    ) : (
      data[column.name]
    )
  }

  if (!data.length) {
    return (
      <TableContainer {...props}>
        <tbody>
          <TableRow>
            <TableCell isHeader>No hay informacion para mostar</TableCell>
          </TableRow>
        </tbody>
      </TableContainer>
    )
  }

  const childrenCols = columns.filter((x) => x.childrenProp)
  let children = null
  if (childrenCols.length) {
    children = childrenCols[0]
  }
  return (
    <TableContainer {...props}>
      <thead>
        <TableRow>
          {(children ? [...columns, ...children.children] : columns).map((column, clI) => (
            <TableCell isHeader key={`hcellch${clI}`}>
              {capitalizeFirstLetter(column.label)}
            </TableCell>
          ))}
        </TableRow>
      </thead>
      <tbody>
        {newData?.map((group, gI) => {
          let items = [group]
          if (children) {
            const totalItems = group[children.childrenProp].length
            items = items.reduce(
              (a, b) =>
                children ? [...a, ...b[children.childrenProp].filter((x, i) => i < 10)] : [...a, b],
              []
            )
            items[0] = {
              ...items[0],
              rowspan: totalItems > 10 ? 10 : totalItems,
            }
            items[0][children.name] = group[children.name]
          }
          return items.map((item, iR) => (
            <TableRow key={`row${iR}${gI}`} status={item.complianceStatus}>
              {columns
                .reduce((a, b) => (b.children ? [...a, b, ...b.children] : [...a, b]), [])
                .filter((column) => item[column.name] !== undefined)
                .map((column, clI) => (
                  <TableCell
                    key={`cell${iR}${clI}`}
                    rowSpan={
                      item.rowspan && column.name == childrenCols[0].name ? item.rowspan : null
                    }
                  >
                    {getCell ? getCell(item, column, getCellDefault) : getCellDefault(item, column)}
                  </TableCell>
                ))}
            </TableRow>
          ))
        })}
      </tbody>
    </TableContainer>
  )
}

Table.propTypes = {
  collumns: PropTypes.array,
  data: PropTypes.array,
}

export default Table

import { createActions } from 'redux-actions'

const { selectedCapacitation, resetCapacitation } = createActions({
  RESET_CAPACITATION: () => {},
  SELECTED_CAPACITATION: data => ({ data })
})

const setSelectedCapacitation = status => dispatch => dispatch(selectedCapacitation({ status }))
const resetSelectedCapacitation = () => dispatch => dispatch(resetCapacitation())

export {
  setSelectedCapacitation,
  selectedCapacitation,
  resetSelectedCapacitation,
  resetCapacitation
}

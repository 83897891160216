import theme from 'config/theme'
import styled from 'styled-components'

export const ContainerStyles = {
  maxWidth: '1000px',
  right: 'unset',
  margin: 'unset',
  alignSelf: 'flex-end'
}

export const LinkStyles = {
  background: theme.colors.purple
}

export const ButtonStyles = {
  background: theme.colors.purple,
  color: theme.colors.white
}

export const IconButtonStyles = {
  fontSize: '30px'
}

export const StyledIcon = styled.img`
  height: 25px;
  width: 25px;
`

import styled from 'styled-components'

export const StyledContainer = styled.div`
  min-width: 400px;
  width: 90%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  margin-left: auto;
  margin-right: auto;
  padding: 15px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-width: 800px;
`

export const StyledDivHeaderWrapper = styled.div`
  justify-content: space-between;
  display: flex;
`

export const CrossImage = styled.img`
  width: 15px;
  height: 15px;
  top: 15px;
  right: 20px;
  cursor: pointer;
`

export const StyledTextHeader = styled.text`
  font-size: 20px;
  font-weight: bold;
`

export const StyledDropZone = styled.div`
  margin: 15px auto;
  min-height: 200px;
  max-width: 400px;
  border: 4px dashed;
  width: 60%;
  display: flex;
  color: ${({ theme }) => theme.colors.darkGrey};
`
export const StyledTextDropZone = styled.text`
  margin: auto;
  text-align: center;
`

export const StyledIcon = styled.img`
  margin: 0px auto 0px auto;
  height: 55px;
  width: 55px;
`

export const StyledFileNameWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export const StyledDivFooterWrapper = styled.div`
  margin: 0px 100px 0px 100px;
  flex-direction: column;
  display: flex;
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const Button = styled.button`
  min-height: 40px;
  min-width: 70px;
  background: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.purple : theme.colors.dustyGray};
  border: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'pointer' : 'inherit')};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
`

export const ErrorLabel = styled.label`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`

export const LoaderWrapper = styled.div`
  width: 100%;
`

export const RadioGroupStyle = {
  display: 'flex',
  margin: '5px 0px',
  flexDirection: 'row'
}

export const FormControlLabelStyle = {
  height: '30px',
  marginRight: '14px'
}

import { createActions } from 'redux-actions'

import { deleteCategoryErrorReset } from 'actions/categories'
import { deleteCourseErrorReset } from 'actions/courses'
import { deleteDirectEmployeeErrorReset } from 'actions/directEmployee'
import { deleteCarrierEmployeeErrorReset } from 'actions/carrierEmployee'
import { deleteIndirectEmployeeErrorReset } from 'actions/indirectEmployee'
import { deleteCapacitationErrorReset } from 'actions/capacitations'
import { deleteCenterErrorReset } from 'actions/centers'
import { deleteLeaderErrorReset } from 'actions/leaders'
import { deleteLeaderUserErrorReset } from 'actions/leaderUsers'
import { deleteInstructorErrorReset } from 'actions/instructors'
import { deleteAdministratorErrorReset } from 'actions/administrators'

const { deletePopupStatus } = createActions({
  DELETE_POPUP_STATUS: data => ({ data })
})

const setDeletePopupStatus = status => dispatch => dispatch(deletePopupStatus({ status }))

const resetDeletePopupErrors = () => dispatch => {
  dispatch(deleteCategoryErrorReset())
  dispatch(deleteCourseErrorReset())
  dispatch(deleteDirectEmployeeErrorReset())
  dispatch(deleteCarrierEmployeeErrorReset())
  dispatch(deleteIndirectEmployeeErrorReset())
  dispatch(deleteCapacitationErrorReset())
  dispatch(deleteCenterErrorReset())
  dispatch(deleteLeaderErrorReset())
  dispatch(deleteLeaderUserErrorReset())
  dispatch(deleteInstructorErrorReset())
  dispatch(deleteAdministratorErrorReset())
}

export { setDeletePopupStatus, resetDeletePopupErrors, deletePopupStatus }

import { capitalizeFirstLetter, allToLower } from 'utils/strings'
import { reestructureDataArray } from 'utils/selectorParser'
import { reestructureDataSimple } from 'utils/selectorParser'

import i18n from 'services/i18n'

import userKeys from 'constants/userKeys'

export const InicialValuesIndirectEmployee = itemToEdit => ({
  name: itemToEdit.firstName
    ? capitalizeFirstLetter(allToLower(itemToEdit.firstName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  lastName: itemToEdit.lastName
    ? capitalizeFirstLetter(allToLower(itemToEdit.lastName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  documentNumber: itemToEdit.documentNumber
    ? capitalizeFirstLetter(allToLower(itemToEdit.documentNumber))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  business: itemToEdit.companyName
    ? reestructureDataSimple(itemToEdit.companyName, itemToEdit.companyName)
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  businessInput: itemToEdit.companyName ? itemToEdit.companyName : ''
})

export const InicialValuesCarrierEmployee = itemToEdit => ({
  name: itemToEdit.firstName
    ? capitalizeFirstLetter(allToLower(itemToEdit.firstName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  lastName: itemToEdit.lastName
    ? capitalizeFirstLetter(allToLower(itemToEdit.lastName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  documentNumber: itemToEdit.documentNumber
    ? capitalizeFirstLetter(allToLower(itemToEdit.documentNumber))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  fileNumber: itemToEdit.fileNumber
    ? capitalizeFirstLetter(allToLower(itemToEdit.fileNumber))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  distributionNumber: itemToEdit.distributionNumber
    ? capitalizeFirstLetter(allToLower(itemToEdit.distributionNumber))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  accountNumber: itemToEdit.accountNumber
    ? capitalizeFirstLetter(allToLower(itemToEdit.accountNumber))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`)
})

export const InicialValuesCapacitation = itemToEdit => ({
  duration: itemToEdit.realDuration,
  observation: itemToEdit.observations
    ? capitalizeFirstLetter(allToLower(itemToEdit.observations))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  course: itemToEdit.course
    ? itemToEdit.course.name
      ? { name: itemToEdit.course.name, id: itemToEdit.course.id }
      : {}
    : {},
  category: itemToEdit.course
    ? itemToEdit.course.category
      ? { name: itemToEdit.course.category.name, id: itemToEdit.course.category.id }
      : {}
    : {}
})

export const InicialValuesDirectOrLeader = itemToEdit => ({
  name: itemToEdit.firstName
    ? capitalizeFirstLetter(allToLower(itemToEdit.firstName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  lastName: itemToEdit.lastName
    ? capitalizeFirstLetter(allToLower(itemToEdit.lastName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  fileNumber: itemToEdit.fileNumber
    ? capitalizeFirstLetter(allToLower(itemToEdit.fileNumber))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  category: itemToEdit.category
    ? capitalizeFirstLetter(allToLower(itemToEdit.category))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  costCenter: itemToEdit.costCenter
    ? capitalizeFirstLetter(allToLower(itemToEdit.costCenter))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  leader: itemToEdit.leader
    ? reestructureDataSimple(itemToEdit.leader.id, itemToEdit.leader.name)
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  distributionCenterId: itemToEdit.distributionCenter
    ? reestructureDataSimple(itemToEdit.distributionCenter.id, itemToEdit.distributionCenter.name)
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  workSector: itemToEdit.workSector
    ? reestructureDataSimple(itemToEdit.workSector.id, itemToEdit.workSector.name)
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  workPosition: itemToEdit.workPosition
    ? reestructureDataSimple(itemToEdit.workPosition.id, itemToEdit.workPosition.name)
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  effectiveStaffId: itemToEdit.effectiveStaffId
    ? itemToEdit.effectiveStaffId
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`)
})

export const InicialValuesSimpleName = itemToEdit => ({
  name: itemToEdit.name
    ? capitalizeFirstLetter(allToLower(itemToEdit.name))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`)
})

export const InicialValuesSimpleFirstName = itemToEdit => ({
  name: itemToEdit.firstName
    ? capitalizeFirstLetter(allToLower(itemToEdit.firstName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`)
})

export const InicialValuesCourse = itemToEdit => ({
  name: itemToEdit.name
    ? capitalizeFirstLetter(allToLower(itemToEdit.name))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  isCourseActive: itemToEdit.courseActive
})

export const InicialValuesGeneralAdministrator = itemToEdit => ({
  userName: itemToEdit.userName
    ? capitalizeFirstLetter(allToLower(itemToEdit.userName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  name: itemToEdit.firstName
    ? capitalizeFirstLetter(allToLower(itemToEdit.firstName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  lastName: itemToEdit.lastName
    ? capitalizeFirstLetter(allToLower(itemToEdit.lastName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  isUserActive: itemToEdit.generalAdministratorActive,
  email: itemToEdit.mail
    ? capitalizeFirstLetter(allToLower(itemToEdit.mail))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`)
})

export const InicialValuesAdministrator = itemToEdit => ({
  userName: itemToEdit.userName
    ? capitalizeFirstLetter(allToLower(itemToEdit.userName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  name: itemToEdit.firstName
    ? capitalizeFirstLetter(allToLower(itemToEdit.firstName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  lastName: itemToEdit.lastName
    ? capitalizeFirstLetter(allToLower(itemToEdit.lastName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  isUserActive: itemToEdit.administratorActive,
  selectedBusiness: itemToEdit.company
    ? { value: itemToEdit.company.id, label: itemToEdit.company.name }
    : null,
  email: itemToEdit.mail
    ? capitalizeFirstLetter(allToLower(itemToEdit.mail))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`)
})

export const InicialValuesInstructor = itemToEdit => ({
  userName: itemToEdit.userName
    ? capitalizeFirstLetter(allToLower(itemToEdit.userName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  name: itemToEdit.firstName
    ? capitalizeFirstLetter(allToLower(itemToEdit.firstName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  lastName: itemToEdit.lastName
    ? capitalizeFirstLetter(allToLower(itemToEdit.lastName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  availableCourses: itemToEdit.availableCourses
    ? reestructureDataArray(itemToEdit.availableCourses)
    : '',
  availableCategories: itemToEdit.categories ? reestructureDataArray(itemToEdit.categories) : [],
  isInternal: itemToEdit.isInternal,
  canAddCourses: itemToEdit.canAddCourses,
  allCourses: false,
  allCategories: false,
  isUserActive: itemToEdit.instructorActive,
  email: itemToEdit.mail
    ? capitalizeFirstLetter(allToLower(itemToEdit.mail))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  selectedBusiness: itemToEdit.companies ? reestructureDataArray(itemToEdit.companies) : []
})

const leaderToObj = leaderLevel => {
  switch (leaderLevel) {
    case userKeys.DIRECTOR:
      return {
        value: userKeys.DIRECTOR,
        label: `${i18n('USER_TYPES').DIRECTOR}`
      }
    case userKeys.MANAGER:
      return {
        value: userKeys.MANAGER,
        label: `${i18n('USER_TYPES').MANAGER}`
      }
    case userKeys.BOSS:
      return {
        value: userKeys.BOSS,
        label: `${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`
      }
  }
}

export const InicialValuesLeaderUser = itemToEdit => ({
  userName: itemToEdit.userName
    ? capitalizeFirstLetter(allToLower(itemToEdit.userName))
    : capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_NULL_ERROR}`),
  leaderLevel: leaderToObj(itemToEdit.leaderLevel),
  leader: itemToEdit.leader
    ? reestructureDataSimple(itemToEdit.leader.id, itemToEdit.leader.name)
    : '',
  distributionCenters: itemToEdit.distributionCenters
    ? reestructureDataArray(itemToEdit.distributionCenters)
    : '',
  allCenters: false,
  isUserActive: itemToEdit.leaderUserActive,
  email: itemToEdit.mail
})

import { createActions } from 'redux-actions'

import getApi from 'services/api'

import API_ROUTES from 'config/apiRoutes'
import pagerConfig from 'config/pagerConfig'

const {
  showingCarrier,

  fetchCarrierEmployeeByFilterStart,
  fetchCarrierEmployeeByFilterSuccess,
  fetchCarrierEmployeeByFilterError,
  carrierEmployeeByFilterReset,

  fetchCarrierEmployeeByDateStart,
  fetchCarrierEmployeeByDateSuccess,
  fetchCarrierEmployeeByDateError,
  carrierEmployeeByDateReset,

  fetchCarrierEmployeeStart,
  fetchCarrierEmployeeSuccess,
  fetchCarrierEmployeeError,
  carrierEmployeeReset,

  addCarrierEmployeeStart,
  addCarrierEmployeeSuccess,
  addCarrierEmployeeError,
  addCarrierEmployeeReset,

  modifyCarrierEmployeeStart,
  modifyCarrierEmployeeSuccess,
  modifyCarrierEmployeeError,
  modifyCarrierEmployeeReset,

  deleteCarrierEmployeeStart,
  deleteCarrierEmployeeSuccess,
  deleteCarrierEmployeeError,
  deleteCarrierEmployeeErrorReset
} = createActions({
  SHOWING_CARRIER: data => ({ data }),

  FETCH_CARRIER_EMPLOYEE_BY_FILTER_START: () => {},
  FETCH_CARRIER_EMPLOYEE_BY_FILTER_SUCCESS: data => ({ data }),
  FETCH_CARRIER_EMPLOYEE_BY_FILTER_ERROR: error => ({ error }),
  CARRIER_EMPLOYEE_BY_FILTER_RESET: () => {},

  FETCH_CARRIER_EMPLOYEE_BY_DATE_START: () => {},
  FETCH_CARRIER_EMPLOYEE_BY_DATE_SUCCESS: data => ({ data }),
  FETCH_CARRIER_EMPLOYEE_BY_DATE_ERROR: error => ({ error }),
  CARRIER_EMPLOYEE_BY_DATE_RESET: () => {},

  FETCH_CARRIER_EMPLOYEE_START: () => {},
  FETCH_CARRIER_EMPLOYEE_SUCCESS: data => ({ data }),
  FETCH_CARRIER_EMPLOYEE_ERROR: error => ({ error }),
  CARRIER_EMPLOYEE_RESET: () => {},

  ADD_CARRIER_EMPLOYEE_START: () => {},
  ADD_CARRIER_EMPLOYEE_SUCCESS: () => {},
  ADD_CARRIER_EMPLOYEE_ERROR: error => ({ error }),
  ADD_CARRIER_EMPLOYEE_RESET: () => {},

  MODIFY_CARRIER_EMPLOYEE_START: () => {},
  MODIFY_CARRIER_EMPLOYEE_SUCCESS: () => {},
  MODIFY_CARRIER_EMPLOYEE_ERROR: error => ({ error }),
  MODIFY_CARRIER_EMPLOYEE_RESET: () => {},

  DELETE_CARRIER_EMPLOYEE_START: () => {},
  DELETE_CARRIER_EMPLOYEE_SUCCESS: () => {},
  DELETE_CARRIER_EMPLOYEE_ERROR: error => ({ error }),
  DELETE_CARRIER_EMPLOYEE_ERROR_RESET: () => {}
})
const setShowingCarrier = status => dispatch => dispatch(showingCarrier({ status }))

const fetchCarrierEmployee = filter => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCarrierEmployeeStart())
    const {
      selectedPage: { page },
      selectedFilter: { selectedBusiness }
    } = getState()
    filter.CompanyId = selectedBusiness.value ? selectedBusiness.value : 0
    filter.Pager.PageSize = pagerConfig.PAGE_SIZE
    filter.Pager.PageNumber = page
    try {
      const response = await api.post(API_ROUTES.GET_CARRIER_EMPLOYEE_BY_FILTER, filter)
      dispatch(fetchCarrierEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCarrierEmployeeError(error))
    }
  }
}

const resetIndirectEmployee = () => dispatch => dispatch(carrierEmployeeReset())

const fetchCarrierEmployeesByDate = (from, to) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCarrierEmployeeByDateStart())
    let urlFinal = `${API_ROUTES.PUT_CARRIER_EMPLOYEE}/${API_ROUTES.BY_DATE}/${from}/${to}`
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    urlFinal = `${urlFinal}/company/`
    urlFinal = `${urlFinal}${selectedBusiness.value ? selectedBusiness.value : 0}`

    try {
      const response = await api.get(urlFinal)

      dispatch(fetchCarrierEmployeeByDateSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCarrierEmployeeByDateError(error))
    }
  }
}

const resetCarrierEmployeesByDate = () => dispatch => dispatch(carrierEmployeeByDateReset())

const fetchCarrierEmployeeByFilter = filter => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCarrierEmployeeByFilterStart())
    const {
      selectedPage: { page },
      selectedFilter: { selectedBusiness }
    } = getState()
    filter.CompanyId = selectedBusiness.value ? selectedBusiness.value : 0
    filter.Pager.PageSize = pagerConfig.PAGE_SIZE
    filter.Pager.PageNumber = page
    try {
      const response = await api.post(API_ROUTES.GET_CARRIER_EMPLOYEE_BY_FILTER, filter)
      dispatch(fetchCarrierEmployeeByFilterSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCarrierEmployeeByFilterError(error))
    }
  }
}

const resetCarrierEmployeeByFilter = () => dispatch => dispatch(carrierEmployeeByFilterReset())

const addCarrierEmployee = values => {
  return async dispatch => {
    const api = await getApi()
    dispatch(addCarrierEmployeeStart())
    const body = {
      firstName: values.name,
      lastName: values.lastName,
      documentNumber: values.documentNumber,
      fileNumber: values.fileNumber,
      accountNumber: values.accountNumber,
      distributionNumber: values.distributionNumber
    }
    try {
      const response = await api.post(`${API_ROUTES.PUT_CARRIER_EMPLOYEE}`, body)
      dispatch(addCarrierEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addCarrierEmployeeError(error))
    }
  }
}

const resetAddCarrierEmployee = () => dispatch => dispatch(addCarrierEmployeeReset())

const modifyCarrierEmployee = (item, values) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyCarrierEmployeeStart())
    const body = {
      firstName: values.name,
      lastName: values.lastName,
      documentNumber: values.documentNumber,
      fileNumber: values.fileNumber,
      accountNumber: values.accountNumber,
      distributionNumber: values.distributionNumber
    }
    try {
      const response = await api.put(`${API_ROUTES.PUT_CARRIER_EMPLOYEE}/${item.id}`, body)
      dispatch(modifyCarrierEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyCarrierEmployeeError(error))
    }
  }
}

const resetModifyCarrierEmployee = () => dispatch => dispatch(modifyCarrierEmployeeReset())

const deleteCarrierEmployee = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteCarrierEmployeeStart())

    try {
      const response = await api.delete(`${API_ROUTES.PUT_CARRIER_EMPLOYEE}/${id}`)
      dispatch(deleteCarrierEmployeeSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteCarrierEmployeeError(error.data))
    }
  }
}

const resetDeleteCarrierEmployeeError = () => dispatch =>
  dispatch(deleteCarrierEmployeeErrorReset())

export {
  showingCarrier,
  setShowingCarrier,
  fetchCarrierEmployeeByFilter,
  fetchCarrierEmployeeByFilterStart,
  fetchCarrierEmployeeByFilterSuccess,
  fetchCarrierEmployeeByFilterError,
  resetCarrierEmployeeByFilter,
  carrierEmployeeByFilterReset,
  fetchCarrierEmployeesByDate,
  fetchCarrierEmployeeByDateStart,
  fetchCarrierEmployeeByDateSuccess,
  fetchCarrierEmployeeByDateError,
  carrierEmployeeByDateReset,
  resetCarrierEmployeesByDate,
  fetchCarrierEmployee,
  fetchCarrierEmployeeStart,
  fetchCarrierEmployeeSuccess,
  fetchCarrierEmployeeError,
  carrierEmployeeReset,
  resetIndirectEmployee,
  addCarrierEmployee,
  addCarrierEmployeeStart,
  addCarrierEmployeeSuccess,
  addCarrierEmployeeError,
  resetAddCarrierEmployee,
  addCarrierEmployeeReset,
  modifyCarrierEmployee,
  modifyCarrierEmployeeStart,
  modifyCarrierEmployeeSuccess,
  modifyCarrierEmployeeError,
  resetModifyCarrierEmployee,
  modifyCarrierEmployeeReset,
  deleteCarrierEmployee,
  deleteCarrierEmployeeStart,
  deleteCarrierEmployeeSuccess,
  deleteCarrierEmployeeError,
  resetDeleteCarrierEmployeeError,
  deleteCarrierEmployeeErrorReset
}

import styled from 'styled-components'

const PageBlock = styled.div`
  width: 100%;
  max-width: ${({ narrow }) => (narrow ? 700 : 1100)}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export default PageBlock

import { createActions } from 'redux-actions'

import getApi from 'services/api'

import API_ROUTES from 'config/apiRoutes'

const { setStaffIdStart, setStaffIdSuccess, setStaffIdError, setStaffIdReset } = createActions({
  SET_STAFF_ID_START: () => {},
  SET_STAFF_ID_SUCCESS: () => {},
  SET_STAFF_ID_ERROR: error => ({ error }),
  SET_STAFF_ID_RESET: () => {}
})

const setEmployeeDirectStaffId = (id, staffId) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(setStaffIdStart())
    const body = {
      EffectiveStaffId: staffId
    }

    try {
      await api.patch(
        `${API_ROUTES.PUT_DIRECT_EMPLOYEE}/${API_ROUTES.SET_DIRECT_EMPLOYEE}/${id}`,
        body
      )
      dispatch(setStaffIdSuccess())
    } catch (error) {
      dispatch(setStaffIdError(error.data))
    }
  }
}

const resetEmployeeDirectStaffIdStatus = () => dispatch => dispatch(setStaffIdReset())

export {
  setEmployeeDirectStaffId,
  setStaffIdStart,
  setStaffIdSuccess,
  setStaffIdError,
  setStaffIdReset,
  resetEmployeeDirectStaffIdStatus
}

import { createActions } from 'redux-actions'

const { selectedDateFilter, resetDateFilter } = createActions({
  RESET_DATE_FILTER: () => {},
  SELECTED_DATE_FILTER: data => ({ data })
})

const setSelectedDateFilter = status => dispatch => dispatch(selectedDateFilter({ status }))

const resetSelectedDateFilter = () => dispatch => dispatch(resetDateFilter())

export { setSelectedDateFilter, selectedDateFilter, resetSelectedDateFilter, resetDateFilter }

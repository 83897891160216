import React, { Component } from 'react'
import { flatFileKey } from '../../constants/flatFileKey'
import FlatFileImporter from '@flatfile/adapter'
import { Button } from './styled'
import { capitalizeFirstLetter } from 'utils/strings'
import i18n from 'services/i18n'
import {
  importExcelCourses,
  importExcelCapacitations,
  importExcelDirectEmployees,
  importExcelCarrierEmployees,
  importExcelFullTrainings,
} from 'actions/excelImport'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setImportPopupStatus, resetImportPopupErrors } from 'actions/importPopup'
import { setDisplayToast } from 'actions/toast'
import importationKeys from 'constants/importationKeys'
import toastTypes from 'constants/toastKeys'

class FlatFile extends Component {
  state = {
    results: [],
  }

  handleImport = async () => {
    switch (this.props.flatFileObject.type) {
      case importationKeys.COURSES:
        await this.props.importExcelCourses(this.state.results)
        break
      case importationKeys.E_DIRECT:
        await this.props.importExcelDirectEmployees(this.state.results)
        break
      case importationKeys.E_CARRIER:
        await this.props.importExcelCarrierEmployees(this.state.results)
        break
      case importationKeys.FULLTRAININGS:
        await this.props.importExcelFullTrainings(this.state.results)
        break
    }

    if (!this.hasError() && !this.hasBackendError()) {
      this.onClose()
    } else {
      this.props.displayError()
    }
  }

  hasBackendError = () =>
    this.props.excelImport.courseSuccess?.errors?.length > 0 ||
    this.props.excelImport.fullTrainingSuccess?.errors?.length > 0 ||
    this.props.excelImport.directEmployeesSuccess?.errors?.length > 0 ||
    this.props.excelImport.carrierEmployeesSuccess?.errors?.length > 0

  onClose = (soft) => {
    this.props.setImportPopupStatus({ open: false })
    if (!soft) {
      this.props.setDisplayToast({ isShowing: true, type: toastTypes.TOAST_TYPE_ADD })
    }
  }

  hasError = () =>
    this.props.excelImport.courseError ||
    this.props.excelImport.capacitationsError ||
    this.props.excelImport.directEmployeesError ||
    this.props.excelImport.carrierEmployeesError ||
    this.props.excelImport.fullTrainingError

  isImporting = () =>
    this.props.excelImport.courseLoading ||
    this.props.excelImport.directEmployeesLoading ||
    this.props.excelImport.carrierEmployeesLoading ||
    this.props.excelImport.fullTrainingLoading

  render() {
    const config = {
      type: this.props.flatFileObject.type,
      fields: this.props.flatFileObject.fields,
      i18nOverrides: {
        otherLocales: ['es', 'es-ES'],
      },
      devMode: true,
    }

    const importer = new FlatFileImporter(flatFileKey, config)

    importer.setCustomer({ userId: '12345' })

    const launchFlatFile = () => {
      importer.requestDataFromUser().then((results) => {
        importer.displayLoader()
        setTimeout(() => {
          importer.displaySuccess(
            'Archivo Leído Correctamente. Espero el resultado de la importación.'
          )
          this.setState({
            results: results.validData,
          })
          this.handleImport()
        })
      })
    }

    return (
      <Button
        onClick={launchFlatFile}
        isDisabled={this.props.flatFileObject.fields.length > 0 && !this.isImporting()}
      >
        {capitalizeFirstLetter(`${i18n('IMPORT_POPUP').IMPORT}`)}
      </Button>
    )
  }
}

const mapStateToProps = ({ importPopup, excelImport }) => ({
  importPopup,
  excelImport,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setImportPopupStatus,
      setDisplayToast,
      importExcelCourses,
      importExcelCapacitations,
      importExcelDirectEmployees,
      importExcelCarrierEmployees,
      importExcelFullTrainings,
      resetImportPopupErrors,
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(FlatFile)

import { handleActions } from 'redux-actions'
import { selectedPage, selectedCapacitationPage, resetPage } from 'actions/page'

const defaultState = {
  page: 1,
  capacitationPage: 1
}

const reducer = handleActions(
  {
    [selectedPage]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => ({ ...state, page: status }),

    [selectedCapacitationPage]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => ({ ...state, capacitationPage: status }),

    [resetPage]: () => defaultState
  },
  defaultState
)

export default reducer

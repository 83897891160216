export default [
  {
    name: 'Mode',
    label: 'Periodo',
    type: 'select',
    options: [
      { label: 'Anual', value: 'annual' },
      { label: 'Hasta la fecha', value: 'yearToday' },
    ],
  },
  {
    name: 'Year',
    label: 'Año',
    type: 'select',
    value: { label: new Date().getFullYear(), value: new Date().getFullYear() },
    collection: 'year',
  },
]

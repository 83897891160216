import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

import { getArrayOfId } from 'utils/selectorParser'
import { getUserInfo } from 'utils/session'

const {
  showingInstructors,

  fetchInstructorsStart,
  fetchInstructorsSuccess,
  fetchInstructorsError,
  instructorsReset,

  fetchInstructorByIdStart,
  fetchInstructorByIdSuccess,
  fetchInstructorByIdError,
  instructorByIdReset,

  enableOrDisableInstructorsStart,
  enableOrDisableInstructorsSuccess,
  enableOrDisableInstructorsError,
  enableOrDisableInstructorsReset,

  addInstructorStart,
  addInstructorSuccess,
  addInstructorError,
  addInstructorReset,

  deleteInstructorStart,
  deleteInstructorSuccess,
  deleteInstructorError,
  deleteInstructorErrorReset,

  modifyInstructorStart,
  modifyInstructorSuccess,
  modifyInstructorError,
  modifyInstructorReset
} = createActions({
  SHOWING_INSTRUCTORS: data => ({ data }),

  FETCH_INSTRUCTORS_START: () => {},
  FETCH_INSTRUCTORS_SUCCESS: data => ({ data }),
  FETCH_INSTRUCTORS_ERROR: error => ({ error }),
  INSTRUCTORS_RESET: () => {},

  FETCH_INSTRUCTOR_BY_ID_START: () => {},
  FETCH_INSTRUCTOR_BY_ID_SUCCESS: data => ({ data }),
  FETCH_INSTRUCTOR_BY_ID_ERROR: error => ({ error }),
  INSTRUCTOR_BY_ID_RESET: () => {},

  ENABLE_OR_DISABLE_INSTRUCTORS_START: () => {},
  ENABLE_OR_DISABLE_INSTRUCTORS_SUCCESS: () => {},
  ENABLE_OR_DISABLE_INSTRUCTORS_ERROR: error => ({ error }),
  ENABLE_OR_DISABLE_INSTRUCTORS_RESET: () => {},

  ADD_INSTRUCTOR_START: () => {},
  ADD_INSTRUCTOR_SUCCESS: data => ({ data }),
  ADD_INSTRUCTOR_ERROR: error => ({ error }),
  ADD_INSTRUCTOR_RESET: () => {},

  DELETE_INSTRUCTOR_START: () => {},
  DELETE_INSTRUCTOR_SUCCESS: () => {},
  DELETE_INSTRUCTOR_ERROR: error => ({ error }),
  DELETE_INSTRUCTOR_ERROR_RESET: () => {},

  MODIFY_INSTRUCTOR_START: () => {},
  MODIFY_INSTRUCTOR_SUCCESS: () => {},
  MODIFY_INSTRUCTOR_ERROR: error => ({ error }),
  MODIFY_INSTRUCTOR_RESET: () => {}
})
const setShowingInstructors = status => dispatch => dispatch(showingInstructors({ status }))

const fetchInstructors = () => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchInstructorsStart())
    const {
      selectedUserStatus,
      selectedUsersFilter: { inputNameValue, category },
      selectedFilter: { selectedBusiness }
    } = getState()

    const companyId = getUserInfo().companies
      ? getUserInfo().companies[0].id
      : selectedBusiness.value

    const body = {
      CompanyId: companyId,
      Name: inputNameValue ? inputNameValue : '',
      CategoryId: category ? category.value : 0
    }
    try {
      const response = await api.post(
        `${API_ROUTES.INSTRUCTORS}/${selectedUserStatus.selected}`,
        body
      )
      dispatch(fetchInstructorsSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchInstructorsError(error))
    }
  }
}

const resetInstructors = () => dispatch => dispatch(instructorsReset())

const fetchInstructorById = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchInstructorByIdStart())
    try {
      const response = await api.get(`${API_ROUTES.INSTRUCTORS}/${id}`)
      dispatch(fetchInstructorByIdSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchInstructorByIdError(error.data))
    }
  }
}

const resetInstructorById = () => dispatch => dispatch(instructorByIdReset())

const enableOrDisableInstructors = user => {
  return async dispatch => {
    const api = await getApi()
    dispatch(enableOrDisableInstructorsStart())
    const enableOrDisable = user.Enable ? `enableInstructor` : `disableInstructor`
    try {
      await api.patch(`${API_ROUTES.INSTRUCTORS}/${enableOrDisable}/${user.id}`)
      dispatch(enableOrDisableInstructorsSuccess())
    } catch (error) {
      dispatch(enableOrDisableInstructorsError(error.data))
    }
  }
}

const resetEnableOrDisableInstructors = () => dispatch =>
  dispatch(enableOrDisableInstructorsReset())

const addInstructor = data => {
  return async dispatch => {
    const api = await getApi()
    dispatch(addInstructorStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      UserName: data.userName,
      Password: data.passwordConfirmation,
      IsInternal: data.isInternal,
      CanAddCourses: data.canAddCourses,
      AvailableCourses:
        data.availableCourses && data.availableCourses.length
          ? getArrayOfId(data.availableCourses)
          : [],
      Categories:
        data.availableCategories && data.availableCategories.length
          ? getArrayOfId(data.availableCategories)
          : [],
      Mail: data.email,
      Companies: getArrayOfId(data.selectedBusiness)
    }
    try {
      const response = await api.post(`${API_ROUTES.INSTRUCTORS}`, body)
      dispatch(addInstructorSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addInstructorError(error.data))
    }
  }
}

const resetAddInstructor = () => dispatch => dispatch(addInstructorReset())

const deleteInstructor = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteInstructorStart())
    try {
      const response = await api.delete(`${API_ROUTES.INSTRUCTORS}/${id}`)
      dispatch(deleteInstructorSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteInstructorError(error.data))
    }
  }
}

const resetDeleteInstructorError = () => dispatch => dispatch(deleteInstructorErrorReset())

const modifyInstructor = (item, data) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyInstructorStart())
    const body = {
      FirstName: data.name,
      LastName: data.lastName,
      UserName: data.userName,
      IsInternal: data.isInternal,
      CanAddCourses: data.canAddCourses,
      AvailableCourses:
        data.availableCourses && data.availableCourses.length
          ? getArrayOfId(data.availableCourses)
          : [],
      Categories:
        data.availableCategories && data.availableCategories.length
          ? getArrayOfId(data.availableCategories)
          : [],
      Mail: data.email,
      Companies: getArrayOfId(data.selectedBusiness)
    }
    try {
      const response = await api.put(`${API_ROUTES.INSTRUCTORS}/${item.id}`, body)
      dispatch(modifyInstructorSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyInstructorError(error.data))
    }
  }
}

const resetModifyInstructor = () => dispatch => dispatch(modifyInstructorReset())

export {
  setShowingInstructors,
  showingInstructors,
  fetchInstructors,
  fetchInstructorsStart,
  fetchInstructorsSuccess,
  fetchInstructorsError,
  instructorsReset,
  resetInstructors,
  fetchInstructorById,
  fetchInstructorByIdStart,
  fetchInstructorByIdSuccess,
  fetchInstructorByIdError,
  instructorByIdReset,
  resetInstructorById,
  enableOrDisableInstructors,
  enableOrDisableInstructorsStart,
  enableOrDisableInstructorsSuccess,
  enableOrDisableInstructorsError,
  enableOrDisableInstructorsReset,
  resetEnableOrDisableInstructors,
  addInstructor,
  addInstructorStart,
  addInstructorSuccess,
  addInstructorError,
  resetAddInstructor,
  addInstructorReset,
  deleteInstructor,
  deleteInstructorStart,
  deleteInstructorSuccess,
  deleteInstructorError,
  resetDeleteInstructorError,
  deleteInstructorErrorReset,
  modifyInstructor,
  modifyInstructorStart,
  modifyInstructorSuccess,
  modifyInstructorError,
  resetModifyInstructor,
  modifyInstructorReset
}

import React from 'react'

import TextFieldMaterial from 'components/TextFieldMaterial'
import Selector from 'components/Selector'
import MySwitch from 'components/Switch'

import { capitalizeFirstLetter } from 'utils/strings'
import { reestructureDataArray, reestructureDataSimple } from 'utils/selectorParser'
import {
  setValuesAllCategories,
  setValuesAllCenters,
  setValuesAllCourses,
  setValuesCategory
} from 'utils/formikSetValues'
import { multiSelector } from 'utils/selectorStyles'
import isDanone from 'utils/isDanone'
import { getCompanyObject } from 'utils/getCompany'

import i18n from 'services/i18n'

import {
  employeeDirectInput,
  employeeIndirectInput,
  employeeCarrierInput
} from 'components/EmployeeInputs'

import {
  CustomCheckbox,
  StyledWrapper,
  Text,
  WrapperCheckbox,
  WrapperSelect,
  Wrapper,
  InputWrapper,
  Title,
  SwitchWrapper
} from './styled'

import usersRoles from 'constants/usersRoles'
import userKeys from 'constants/userKeys'
import { allToCaps } from '../../utils/strings'

export const directEmployeeInputs = (
  values,
  setFieldValue,
  errors,
  selectorCenters,
  selectorWorkSector,
  selectorWorkPosition,
  selectorLeader
) =>
  employeeDirectInput(
    values,
    setFieldValue,
    errors,
    selectorCenters,
    selectorWorkSector,
    selectorWorkPosition,
    selectorLeader
  )

export const indirectEmployeeInputs = (values, setFieldValue, errors, setValues) =>
  employeeIndirectInput(values, setFieldValue, errors, setValues)

export const carrierEmployeeInputs = (values, setFieldValue, errors) =>
  employeeCarrierInput(values, setFieldValue, errors)

export const capacitationInputs = (
  values,
  selectorCourses,
  selectorCategories,
  setFieldValue,
  errors,
  handleChangeCategory
) => (
  <Wrapper>
    <InputWrapper>
      <TextFieldMaterial
        label={capitalizeFirstLetter(
          `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_DURATIONS}`
        )}
        helperText={errors.duration}
        margin="normal"
        value={values.duration}
        error={errors.duration}
        onChange={({ target: value }) => setFieldValue('duration', value.value)}
        type="number"
      />
      <Title>{capitalizeFirstLetter(i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY)}</Title>
      <Selector
        placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_CATEGORY}`)}
        value={reestructureDataSimple(values.category.id, values.category.name)}
        options={reestructureDataArray(selectorCategories)}
        isLoading={false}
        onChange={target => {
          setFieldValue('category', { name: allToCaps(target.label), id: target.id })
          setFieldValue('course', {})
          handleChangeCategory({ name: allToCaps(target.label), id: target.id })
        }}
      />
    </InputWrapper>
    <InputWrapper>
      <TextFieldMaterial
        label={capitalizeFirstLetter(
          `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_OBSERVATIONS}`
        )}
        helperText={errors.observation}
        margin="normal"
        value={values.observation}
        error={errors.observation}
        onChange={({ target: value }) => setFieldValue('observation', value.value)}
        type="text"
      />

      <Title>{capitalizeFirstLetter(i18n('CAPACITATION').CAPACITATION_TABLE_COURSE)}</Title>
      <Selector
        placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_COURSE}`)}
        value={reestructureDataSimple(values.course.id, values.course.name)}
        options={selectorCourses.values ? reestructureDataArray(selectorCourses.values) : []}
        isLoading={selectorCourses.isFetching}
        onChange={target =>
          setFieldValue('course', { name: allToCaps(target.label), id: target.id })
        }
        disabled={selectorCourses.isFetching}
      />
    </InputWrapper>
  </Wrapper>
)

export const leaderInput = (
  values,
  setFieldValue,
  errors,
  selectorCenters,
  selectorWorkSector,
  selectorWorkPosition,
  selectorLeader
) =>
  employeeDirectInput(
    values,
    setFieldValue,
    errors,
    selectorCenters,
    selectorWorkSector,
    selectorWorkPosition,
    selectorLeader
  )

export const defaultInput = (values, setFieldValue, errors) => (
  <TextFieldMaterial
    label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`)}
    helperText={errors.name}
    margin="normal"
    value={values.name}
    error={errors.name}
    onChange={({ target: value }) => setFieldValue('name', value.value)}
    type="text"
  />
)

export const courseInputs = (values, setFieldValue, errors) => (
  <>
    <TextFieldMaterial
      label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`)}
      helperText={errors.name}
      margin="normal"
      value={values.name}
      error={errors.name}
      onChange={({ target: value }) => setFieldValue('name', value.value)}
      type="text"
    />
    <SwitchWrapper style={{ marginTop: '18px' }}>
      {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_DISABLE}`)}
      <MySwitch
        checked={values.isCourseActive}
        onClick={() => setFieldValue('isCourseActive', values.isCourseActive ? false : true)}
      />
    </SwitchWrapper>
  </>
)

export const generalAdminInputs = (values, setFieldValue, errors) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <StyledWrapper>
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`)}
        helperText={errors.name}
        margin="normal"
        value={values.name}
        error={errors.name}
        onChange={({ target: value }) => setFieldValue('name', value.value)}
        type="text"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_LASTNAME}`)}
        helperText={errors.lastName}
        margin="normal"
        value={values.lastName}
        error={errors.lastName}
        onChange={({ target: value }) => setFieldValue('lastName', value.value)}
        type="text"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}
        helperText={errors.userName}
        margin="normal"
        value={values.userName}
        error={errors.userName}
        onChange={({ target: value }) => setFieldValue('userName', value.value)}
        type="text"
        disabled={true}
      />
    </StyledWrapper>

    <StyledWrapper>
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}
        helperText={errors.email}
        margin="normal"
        value={values.email}
        error={errors.email}
        onChange={({ target: value }) => setFieldValue('email', value.value)}
        type="text"
      />
      <SwitchWrapper>
        {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_DISABLE}`)}
        <MySwitch
          checked={values.isUserActive}
          onClick={() => setFieldValue('isUserActive', values.isUserActive ? false : true)}
        />
      </SwitchWrapper>
    </StyledWrapper>
  </div>
)

export const administratorInputs = (
  values,
  setFieldValue,
  errors,
  usedByInstructorForm,
  business
) => (
  <div
    style={{ display: usedByInstructorForm ? 'contents' : 'flex', justifyContent: 'space-between' }}
  >
    <StyledWrapper>
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`)}
        helperText={errors.name}
        margin="normal"
        value={values.name}
        error={errors.name}
        onChange={({ target: value }) => setFieldValue('name', value.value)}
        type="text"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_LASTNAME}`)}
        helperText={errors.lastName}
        margin="normal"
        value={values.lastName}
        error={errors.lastName}
        onChange={({ target: value }) => setFieldValue('lastName', value.value)}
        type="text"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}
        helperText={errors.userName}
        margin="normal"
        value={values.userName}
        error={errors.userName}
        onChange={({ target: value }) => setFieldValue('userName', value.value)}
        type="text"
        disabled={true}
      />
    </StyledWrapper>
    {usedByInstructorForm ? (
      ''
    ) : (
      <StyledWrapper>
        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}
          helperText={errors.email}
          margin="normal"
          value={values.email}
          error={errors.email}
          onChange={({ target: value }) => setFieldValue('email', value.value)}
          type="text"
        />
        <div style={{ marginTop: '20px' }}>
          <Selector
            placeholder={capitalizeFirstLetter(i18n('SELECTOR').BUSINESS)}
            value={values.selectedBusiness}
            options={
              business.valuesAll &&
              business.valuesAll.length &&
              reestructureDataArray(business.valuesAll)
            }
            isLoading={business.isFetchingAll}
            disabled={business.isFetchingAll || business.errorAll}
            onChange={target => setFieldValue('selectedBusiness', target)}
            styles={multiSelector}
          />
        </div>
        <SwitchWrapper>
          {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_DISABLE}`)}
          <MySwitch
            checked={values.isUserActive}
            onClick={() => setFieldValue('isUserActive', values.isUserActive ? false : true)}
          />
        </SwitchWrapper>
      </StyledWrapper>
    )}
  </div>
)

const filterCoursesBySelectedCategories = (categories, courses) => {
  let categoryName = categories && categories.map(category => category.name)
  let result =
    courses &&
    categories &&
    categoryName.map(name => courses.filter(course => course.category.name === name))
  return [].concat.apply([], result)
}

export const instructorInputs = (
  values,
  setFieldValue,
  setValues,
  errors,
  business,
  selectorCategories,
  selectorCourses
) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Wrapper>
      {administratorInputs(values, setFieldValue, errors, true)}
      <StyledWrapper>
        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}
          helperText={errors.email}
          margin="normal"
          value={values.email}
          error={errors.email}
          onChange={({ target: value }) => setFieldValue('email', value.value)}
          type="text"
        />
        <SwitchWrapper style={{ marginTop: '18px' }}>
          {capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_INTERN}`)}
          <MySwitch
            checked={values.isInternal}
            onClick={() => setFieldValue('isInternal', values.isInternal ? false : true)}
          />
        </SwitchWrapper>
        <SwitchWrapper style={{ marginTop: '18px' }}>
          {capitalizeFirstLetter(`${i18n('USER_TYPES').PREVIEW_CAN_ADD_COURSES}`)}
          <MySwitch
            checked={values.canAddCourses}
            onClick={() => setFieldValue('canAddCourses', values.canAddCourses ? false : true)}
          />
        </SwitchWrapper>
        <SwitchWrapper style={{ marginTop: '18px' }}>
          {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_DISABLE}`)}
          <MySwitch
            checked={values.isUserActive}
            onClick={() => setFieldValue('isUserActive', values.isUserActive ? false : true)}
          />
        </SwitchWrapper>
      </StyledWrapper>
    </Wrapper>

    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
      <Selector
        placeholder={capitalizeFirstLetter(i18n('SELECTOR').BUSINESS)}
        value={blockDefaultBusiness(values.selectedBusiness, setFieldValue)}
        options={
          business.valuesAll &&
          business.valuesAll.length &&
          reestructureDataArray(business.valuesAll)
        }
        isMulti
        isLoading={business.isFetchingAll}
        disabled={business.errorAll || business.isFetchingAll}
        onChange={target => setFieldValue('selectedBusiness', target)}
        styles={multiSelector(450)}
      />
    </div>

    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
      <Selector
        placeholder={
          values.allCategories
            ? capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)
            : capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_CATEGORY}`)
        }
        value={
          values.allCategories
            ? null
            : values.availableCategories
            ? values.availableCategories
            : null
        }
        options={selectorCategories.values ? reestructureDataArray(selectorCategories.values) : []}
        isLoading={selectorCategories.isFetching}
        onChange={target => setValuesCategory(setValues, values, target)}
        isMulti
        isDisabled={values.allCategories || selectorCategories.isFetching}
        styles={multiSelector(450)}
      />
      <WrapperCheckbox style={{ marginTop: '5px' }}>
        <label style={{ margin: 'auto 0px auto 0px' }}>
          {capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
        </label>
        <CustomCheckbox
          style={{ padding: '2px 8px' }}
          checked={values.allCategories}
          onChange={() => setValuesAllCategories(setValues, values, selectorCategories)}
        />
      </WrapperCheckbox>
    </div>

    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
      <Selector
        placeholder={
          values.allCourses
            ? capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)
            : capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_COURSE}`)
        }
        value={values.allCourses ? null : values.availableCourses ? values.availableCourses : null}
        options={
          selectorCourses.valuesAll
            ? reestructureDataArray(
                filterCoursesBySelectedCategories(
                  values.availableCategories,
                  selectorCourses.valuesAll
                )
              )
            : []
        }
        isLoading={selectorCourses.isFetchingAll}
        onChange={target => setFieldValue('availableCourses', target)}
        isMulti
        isDisabled={
          selectorCategories.isFetching || values.allCourses || !values.availableCategories
        }
        styles={multiSelector(450)}
      />
      <WrapperCheckbox style={{ marginTop: '5px' }}>
        <label style={{ margin: 'auto 0px auto 0px' }}>
          {capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}
        </label>
        <CustomCheckbox
          style={{ padding: '2px 8px' }}
          checked={values.allCourses}
          disabled={!values.availableCategories}
          onChange={() =>
            setValuesAllCourses(
              setValues,
              values,
              filterCoursesBySelectedCategories(
                values.availableCategories,
                selectorCourses.valuesAll
              )
            )
          }
        />
      </WrapperCheckbox>
    </div>
  </div>
)

const blockDefaultBusiness = (selectedBusiness, setFieldValue) => {
  if (selectedBusiness) {
    if (selectedBusiness.find(x => x.value === getCompanyObject().Id)) {
      return selectedBusiness
    } else {
      setFieldValue('selectedBusiness', [
        reestructureDataSimple(getCompanyObject().Id, getCompanyObject().name),
        ...selectedBusiness
      ])
    }
  } else {
    setFieldValue('selectedBusiness', [
      reestructureDataSimple(getCompanyObject().Id, getCompanyObject().name)
    ])
  }
}

const isRolDirector = values => values.leaderLevel && values.leaderLevel.value === userKeys.DIRECTOR

const isRolManager = values => values.leaderLevel && values.leaderLevel.value === userKeys.MANAGER

export const leaderUserInputs = (
  values,
  setFieldValue,
  setValues,
  errors,
  selectorLeader,
  selectorCenters
) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Wrapper>
      <StyledWrapper style={{ marginTop: '25px' }}>
        <Selector
          placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ROLE}`)}
          value={values.leaderLevel}
          options={reestructureDataArray(usersRoles)}
          isLoading={false}
          onChange={target => setFieldValue('leaderLevel', target)}
        />

        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}
          helperText={errors.userName}
          margin="normal"
          value={values.userName}
          error={errors.userName}
          onChange={({ target: value }) => setFieldValue('userName', value.value)}
          type="text"
          disabled={true}
        />
      </StyledWrapper>
      <StyledWrapper
        style={{ marginTop: isRolDirector(values) || isRolManager(values) ? '7px' : '25px' }}
      >
        {isRolDirector(values) || isRolManager(values) ? (
          ''
        ) : (
          <Selector
            placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_LEADER}`)}
            value={values.leader}
            options={
              selectorLeader.valuesAll ? reestructureDataArray(selectorLeader.valuesAll) : []
            }
            isLoading={selectorLeader.isFetchingAll}
            onChange={target => setFieldValue('leader', target)}
          />
        )}
        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}
          helperText={errors.email}
          margin="normal"
          value={values.email}
          error={errors.email}
          onChange={({ target: value }) => setFieldValue('email', value.value)}
          type="text"
        />
        <SwitchWrapper>
          {capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_DISABLE}`)}
          <MySwitch
            checked={values.isUserActive}
            onClick={() => setFieldValue('isUserActive', values.isUserActive ? false : true)}
          />
        </SwitchWrapper>
      </StyledWrapper>
    </Wrapper>
    <WrapperSelect>
      <WrapperCheckbox>
        <CustomCheckbox
          style={{ padding: '2px 8px 2px 0px' }}
          checked={values.allCenters}
          onChange={() => setValuesAllCenters(setValues, values, selectorCenters)}
        />
        <Text>{capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)}</Text>
      </WrapperCheckbox>
      <Selector
        placeholder={
          values.allCenters
            ? capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_ALL}`)
            : capitalizeFirstLetter(
                `${
                  isDanone()
                    ? i18n('CAPACITATION').SELECTOR_MANAGEMENT
                    : i18n('CAPACITATION').SELECTOR_CENTER
                }`
              )
        }
        value={values.allCenters ? null : values.distributionCenters}
        options={selectorCenters.valuesAll ? reestructureDataArray(selectorCenters.valuesAll) : []}
        isLoading={selectorCenters.isFetchingAll}
        onChange={target => setFieldValue('distributionCenters', target)}
        isMulti
        isDisabled={values.allCenters}
        styles={multiSelector}
      />
    </WrapperSelect>
  </div>
)

import styled from 'styled-components'

export const StyledContainer = styled.div`
  min-width: 400px;
  width: 90%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  margin-left: auto;
  margin-right: auto;
  padding: 15px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-width: 800px;
`

export const StyledDivHeaderWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
`

export const CrossImage = styled.img`
  width: 15px;
  height: 15px;
  top: 15px;
  right: 20px;
  cursor: pointer;
`

export const ButtonWrapper = styled.div`
  margin-top: 15px;
  width: 100px;
  margin-left: auto;
`

export const StyledImgsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const Image = styled.img`
  height: 300px;
  width: 300px;
  border: 1px solid;
  color: ${({ theme }) => theme.colors.darkGrey};
`

export const NoImage = styled.div`
  height: 220px;
  width: 220px;
  border: 1px solid;
  padding-top: 100px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`

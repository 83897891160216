import { handleActions } from 'redux-actions'
import {
  showing,
  fetchCentersByNameStart,
  fetchCentersByNameSuccess,
  fetchCentersByNameError,
  centersByNameReset,
  fetchCentersByDateStart,
  fetchCentersByDateSuccess,
  fetchCentersByDateError,
  centersByDateReset,
  fetchCentersStart,
  fetchCentersSuccess,
  fetchCentersError,
  centersReset,
  modifyCenterStart,
  modifyCenterSuccess,
  modifyCenterError,
  modifyCenterReset,
  addCenterStart,
  addCenterSuccess,
  addCenterError,
  addCenterReset,
  deleteCenterStart,
  deleteCenterSuccess,
  deleteCenterError,
  deleteCenterErrorReset
} from 'actions/centers'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,

  valuesByDate: null,
  errorByDate: null,
  isFetchingByDate: false,

  isShowing: false,

  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false
}

const reducer = handleActions(
  {
    [showing]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [fetchCentersByNameStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchCentersByNameSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchCentersByNameError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [centersByNameReset]: () => defaultState,

    [fetchCentersByDateStart]: state => ({
      ...state,
      isFetchingByDate: true,
      errorByDate: null
    }),
    [fetchCentersByDateSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesByDate: values,
      isFetchingByDate: false,
      errorByDate: null
    }),
    [fetchCentersByDateError]: (state, { error }) => ({
      ...state,
      isFetchingByDate: false,
      errorByDate: error
    }),
    [centersByDateReset]: () => defaultState,

    [fetchCentersStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchCentersSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchCentersError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [centersReset]: () => defaultState,

    [modifyCenterStart]: state => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false
    }),
    [modifyCenterSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyCenterError]: (state, error) => ({
      ...state,
      isSubmiting: false,
      errorEdit: error,
      successEdit: false
    }),
    [modifyCenterReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false
    }),

    [addCenterStart]: state => ({
      ...state,
      isAdding: true,
      successAdd: false,
      errorAdd: false
    }),
    [addCenterSuccess]: state => ({
      ...state,
      isAdding: false,
      successAdd: true,
      errorAdd: false
    }),
    [addCenterError]: (state, error) => ({
      ...state,
      isAdding: false,
      successAdd: false,
      errorAdd: error
    }),
    [addCenterReset]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false
    }),
    [deleteCenterStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteCenterSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteCenterError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),
    [deleteCenterErrorReset]: state => ({
      ...state,
      isDeleting: false,
      errorDelete: false
    })
  },
  defaultState
)

export default reducer

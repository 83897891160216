import filtersCentersAndBosses from './filtersCentersAndBosses'

export default [
  {
    name: 'CourseCategories',
    label: 'Categorias',
    type: 'select',
    collection: 'coursecategories',
    isMulti: true,
  },
  {
    name: 'Courses',
    label: 'Cursos',
    type: 'select',
    collection: 'courses',
    dependency: 'CourseCategories',
    isMulti: true,
  },
  ...filtersCentersAndBosses.filter((x) => x.name !== 'States'),
]

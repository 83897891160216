import { handleActions } from 'redux-actions'
import { selectedUsersFilter, resetUsersFilter } from 'actions/selectedUsersFilter'

const defaultState = {
  userType: null,
  inputNameValue: '',
  category: null
}

const reducer = handleActions(
  {
    [selectedUsersFilter]: (state, { payload: { data: status } }) => ({
      ...state,
      ...status.status
    }),

    [resetUsersFilter]: () => defaultState
  },
  defaultState
)

export default reducer

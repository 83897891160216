import { createActions } from 'redux-actions'

const { selectedPage, selectedCapacitationPage, resetPage } = createActions({
  RESET_PAGE: () => {},
  SELECTED_PAGE: data => ({ data }),
  SELECTED_CAPACITATION_PAGE: data => ({ data })
})

const setSelectedPage = status => dispatch => dispatch(selectedPage({ status }))

const setSelectedCapacitationPage = status => dispatch =>
  dispatch(selectedCapacitationPage({ status }))

const resetSelectedPage = () => dispatch => dispatch(resetPage())

export {
  setSelectedPage,
  selectedPage,
  setSelectedCapacitationPage,
  selectedCapacitationPage,
  resetSelectedPage,
  resetPage
}

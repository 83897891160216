import styled from 'styled-components'

export const PaperStyles = {
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  maxHeight: '35px',
  boxShadow: 'none',
  border: 'solid',
  borderWidth: '1px',
  width: '100%',
  borderRadius: '30px'
}

export const InputStyles = {
  marginLeft: 8,
  flex: 1
}

export const StyledDivSearchWrapper = styled.div`
  display: flex;
`

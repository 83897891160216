import { handleActions } from 'redux-actions'
import { loginStart, loginSuccess, loginError, loginResetState } from 'actions/authentication'

const defaultState = {
  success: false,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [loginStart]: () => ({
      success: false,
      isFetching: true,
      error: null
    }),
    [loginSuccess]: () => ({
      success: true,
      isFetching: false,
      error: null
    }),
    [loginError]: (state, { payload: error }) => ({
      success: false,
      isFetching: false,
      error: error.error
    }),
    [loginResetState]: () => defaultState
  },
  defaultState
)

export default reducer

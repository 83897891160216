import { fetchCenters } from 'actions/centers'
import { fetchYears } from 'actions/activeYears'
import { getLeaderUsersByCenterIds } from 'actions/leaderUsersByCenterIds'
import { fetchCategories } from 'actions/categories'
import { getCoursesByCategoriesIds } from 'actions/coursesByCategoriesIds.js'

let selectorConfigs = []
selectorConfigs['centers'] = {
  fetch: fetchCenters,
  stateName: 'centers',
  isFetchingProp: 'isFetchingAll',
  map: (state) => {
    if (state.valuesAll) {
      return state.valuesAll.map((item) => ({ label: item.name, value: item.id }))
    }
    return []
  },
}
selectorConfigs['year'] = {
  fetch: fetchYears,
  stateName: 'activeYears',
  isFetchingProp: 'isFetching',
  map: (state) => {
    if (state.values) {
      return state.values.map((item) => ({ label: item, value: item }))
    }
    return []
  },
}
selectorConfigs['employees'] = {
  fetch: getLeaderUsersByCenterIds,
  stateName: 'leaderUsersByCenterIds',
  isFetchingProp: 'isFetching',
  map: (state) => {
    if (state.values) {
      return state.values
        .reduce((a, b) => [...a, ...b.items], [])
        .reduce((a, b) => (a.find((x) => x.id === b.id) ? a : [b, ...a]), [])
        .map((item) => ({ label: item.userName, value: item.id }))
    }
    return []
  },
}
selectorConfigs['coursecategories'] = {
  fetch: fetchCategories,
  stateName: 'categories',
  isFetchingProp: 'isFetching',
  map: (state) =>
    state.values ? state.values.map((item) => ({ label: item.name, value: item.id })) : [],
}
selectorConfigs['courses'] = {
  fetch: getCoursesByCategoriesIds,
  stateName: 'coursesByCategoriesIds',
  isFetchingProp: 'isFetching',
  map: (state) =>
    state.values
      ? state.values
          .reduce((a, b) => [...a, ...b.items], [])
          .map((item) => ({ label: item.name, value: item.id }))
      : [],
}

export default selectorConfigs

import { createActions } from 'redux-actions'
import getApi from 'services/api'

const { optionalStart, optionalSuccess, optionalError, optionalReset } = createActions({
  OPTIONAL_START: () => {},
  OPTIONAL_SUCCESS: (values, total) => ({ values, total }),
  OPTIONAL_ERROR: (error) => ({ error }),
  OPTIONAL_RESET: () => {},
})

const getOptional = (query) => {
  return async (dispatch) => {
    const api = await getApi()

    const body = {
      ...query,
      Sort: {
        Name: '',
        IsAscending: true,
      },
      Pager: {
        PageSize: 10,
        PageNumber: query.PageNumber ? query.PageNumber : 1,
      },
    }

    dispatch(optionalStart())

    return api
      .post('dashboard/optionaltrainingdetail', body)
      .then((response) => {
        const dataResponse = response.data.data
        dispatch(optionalSuccess(dataResponse.optionalCourses, dataResponse.totalCourses))
        return {
          values: dataResponse.optionalCourses,
          total: dataResponse.totalCourses,
        }
      })
      .catch((error) => {
        const result = error.data ? error.data.message : error.message
        dispatch(optionalError(result))
        return result
      })
  }
}

export { getOptional, optionalStart, optionalSuccess, optionalError, optionalReset }

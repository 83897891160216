import React, { Component } from 'react'
import BackgroundImage from 'assets/imgs/loginBackground.png'
import laserenisimaLogo from 'assets/imgs/laserenisimaLogo.png'
import danoneLogo from 'assets/imgs/danoneLogo.png'
import i18n from 'services/i18n'

import {
  Container,
  Wrapper,
  ImageWrapper,
  StyledImgDanone,
  StyledImgLaserenisima,
  PolicyWrapper,
  PolicyTitle,
  PolicyHint,
  PolicyText
} from './styles'

class Policy extends Component {
  state = {}
  render() {
    return (
      <Container
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <Wrapper>
          <ImageWrapper>
            <StyledImgDanone src={danoneLogo} />
            <StyledImgLaserenisima src={laserenisimaLogo} />
          </ImageWrapper>
          <PolicyWrapper>
            <PolicyTitle>{i18n('POLICY').POLICY_TITLE}</PolicyTitle>
            <PolicyText>{i18n('POLICY').BODY_1}</PolicyText>

            <PolicyHint>{i18n('POLICY').POLICY_HINT_1}</PolicyHint>
            <PolicyText>{i18n('POLICY').BODY_2}</PolicyText>

            <PolicyHint>{i18n('POLICY').POLICY_HINT_2}</PolicyHint>
            <PolicyText>{i18n('POLICY').BODY_3}</PolicyText>

            <PolicyHint>{i18n('POLICY').POLICY_HINT_3}</PolicyHint>
            <PolicyText>{i18n('POLICY').BODY_4}</PolicyText>

            <PolicyHint>{i18n('POLICY').POLICY_HINT_4}</PolicyHint>
            <PolicyText>{i18n('POLICY').BODY_5}</PolicyText>
          </PolicyWrapper>
        </Wrapper>
      </Container>
    )
  }
}

export default Policy

import React from 'react'

import TextFieldMaterial from 'components/TextFieldMaterial'
import Selector from 'components/Selector'

import { capitalizeFirstLetter } from 'utils/strings'
import isDanone from 'utils/isDanone'

import i18n from 'services/i18n'

import {
  StyledWrapper,
  StyledWrapperTwo,
  StyledSelector,
  StyledSelectorWrapper,
  ErrorWrap,
} from './styled'

import { reestructureDataArray, reestructureDataSimple } from 'utils/selectorParser'
import { setValuesBusinessIndirectEmployee } from 'utils/formikSetValues'

import { selectorBusiness, defaulBusiness } from 'constants/defaultBusiness'

import bossKeys from 'constants/bossTypeKeys'

export const employeeDirectInput = (
  values,
  setFieldValue,
  errors,
  selectorCenters,
  selectorWorkSector,
  selectorWorkPosition,
  selectorLeader,
  isLeader,
  fetchLeaders
) => {
  const handleLeaderTypeChange = async (target, setFieldValue) => {
    if (target.value === 3) {
      setFieldValue('leader', null)
    }

    switch (target.value) {
      case 1:
        fetchLeaders(bossKeys.MANAGER)
        break
      case 2:
        fetchLeaders(bossKeys.DIRECTOR)
        break
      default:
        break
    }

    setFieldValue('leaderType', target)
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <StyledWrapper>
          <TextFieldMaterial
            label={capitalizeFirstLetter(
              `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`
            )}
            helperText={errors.name}
            margin="normal"
            value={values.name}
            error={errors.name}
            onChange={({ target: value }) => setFieldValue('name', value.value)}
            type="text"
          />
          <TextFieldMaterial
            label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_LASTNAME}`)}
            helperText={errors.lastName}
            margin="normal"
            value={values.lastName}
            error={errors.lastName}
            onChange={({ target: value }) => setFieldValue('lastName', value.value)}
            type="text"
          />
          <TextFieldMaterial
            label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_FILE_NUMBER}`)}
            helperText={errors.fileNumber}
            margin="normal"
            value={values.fileNumber}
            error={errors.fileNumber}
            onChange={({ target: value }) => setFieldValue('fileNumber', value.value)}
            type="number"
          />
        </StyledWrapper>
        <StyledWrapper>
          <TextFieldMaterial
            label={capitalizeFirstLetter(
              `${
                isDanone()
                  ? i18n('CAPACITATION').CAPACITATION_TABLE_COST_MANAGEMENT
                  : i18n('CAPACITATION').CAPACITATION_TABLE_COST_CENTER
              }`
            )}
            helperText={errors.costCenter}
            margin="normal"
            value={values.costCenter}
            error={errors.costCenter}
            onChange={({ target: value }) => setFieldValue('costCenter', value.value)}
            type="text"
          />
          <TextFieldMaterial
            label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_CATEGORY}`)}
            helperText={errors.category}
            margin="normal"
            value={values.category}
            error={errors.category}
            onChange={({ target: value }) => setFieldValue('category', value.value)}
            type="text"
          />
          <TextFieldMaterial
            label={capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_EMPLOYEE_ID}`)}
            helperText={errors.effectiveStaffId}
            margin="normal"
            value={values.effectiveStaffId}
            error={errors.effectiveStaffId}
            onChange={({ target: value }) => setFieldValue('effectiveStaffId', value.value)}
            type="number"
          />
        </StyledWrapper>
      </div>
      <div style={{ display: 'flex' }}>
        <StyledWrapper>
          <Selector
            placeholder={capitalizeFirstLetter(
              `${
                isDanone() ? i18n('SELECTOR').SELECTOR_MANAGEMENT : i18n('SELECTOR').SELECTOR_CENTER
              }`
            )}
            value={values.distributionCenterId}
            options={
              selectorCenters.valuesAll ? reestructureDataArray(selectorCenters.valuesAll) : []
            }
            isLoading={selectorCenters.isFetchingAll}
            onChange={(target) => setFieldValue('distributionCenterId', target)}
          />
          <StyledSelectorWrapper>
            <Selector
              placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_WORK_SECTOR}`)}
              value={values.workSector}
              options={
                selectorWorkSector.valuesAll
                  ? reestructureDataArray(selectorWorkSector.valuesAll)
                  : []
              }
              isLoading={selectorWorkSector.isFetchingAll}
              onChange={(target) => setFieldValue('workSector', target)}
            />
          </StyledSelectorWrapper>
        </StyledWrapper>

        <StyledWrapper>
          <Selector
            placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_WORK_POS}`)}
            value={values.workPosition}
            options={
              selectorWorkPosition.valuesAll
                ? reestructureDataArray(selectorWorkPosition.valuesAll)
                : []
            }
            isLoading={selectorWorkPosition.isFetchingAll}
            onChange={(target) => setFieldValue('workPosition', target)}
          />
          <StyledSelectorWrapper>
            <Selector
              isDisabled={isLeader ? (values.leaderType?.value === 3 ? true : false) : false}
              placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_LEADER}`)}
              value={values.leader}
              options={
                selectorLeader.valuesAll
                  ? filterEditEmployee(values, reestructureDataArray(selectorLeader.valuesAll))
                  : []
              }
              isLoading={selectorLeader.isFetchingAll}
              onChange={(target) => setFieldValue('leader', target)}
            />
          </StyledSelectorWrapper>
          {isLeader && (
            <StyledSelectorWrapper>
              <Selector
                value={values.leaderType}
                options={[
                  { value: 1, label: 'Jefe' },
                  { value: 2, label: 'Manager' },
                  { value: 3, label: 'Director' },
                ]}
                placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_LEADERTYPE}`)}
                onChange={(target) => handleLeaderTypeChange(target, setFieldValue)}
              />
            </StyledSelectorWrapper>
          )}
        </StyledWrapper>
      </div>
    </div>
  )
}

const filterEditEmployee = (values, valuesAll) => {
  const index = valuesAll.findIndex((i) => i.fileNumber === values.fileNumber)
  if (index > -1) {
    valuesAll.splice(index, 1)
  }
  return valuesAll
}

export const employeeIndirectInput = (values, setFieldValue, errors, setValues, business) => {
  const businessOptions = business
    ? business
        .filter((x) => x.id != 2)
        .map((x) => {
          return { ...x, value: x.id, label: x.name }
        })
    : []
  return (
    <StyledWrapperTwo>
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`)}
        helperText={errors.name}
        margin="normal"
        value={values.name}
        error={errors.name}
        onChange={({ target: value }) => setFieldValue('name', value.value)}
        type="text"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_LASTNAME}`)}
        helperText={errors.lastName}
        margin="normal"
        value={values.lastName}
        error={errors.lastName}
        onChange={({ target: value }) => setFieldValue('lastName', value.value)}
        type="text"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_ID}`)}
        helperText={errors.documentNumber}
        margin="normal"
        value={values.documentNumber}
        error={errors.documentNumber}
        onChange={({ target: value }) => setFieldValue('documentNumber', value.value)}
        type="number"
      />
      <ErrorWrap error={!!errors.companies}>
        <StyledSelector
          placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_COMPANY}`)}
          value={values.companies ? values.companies : null}
          options={businessOptions}
          isMulti
          isLoading={false}
          onChange={(target) =>
            setValuesBusinessIndirectEmployee(target, setValues, values, 'companies')
          }
        />
        {errors.companies ? (
          <span className="error">{capitalizeFirstLetter(i18n('SELECTOR').SELECTOR_COMPANY)}</span>
        ) : (
          ''
        )}
      </ErrorWrap>
      <ErrorWrap error={!!errors.business}>
        <StyledSelector
          placeholder={capitalizeFirstLetter(`${i18n('SELECTOR').SELECTOR_BUSINESS}`)}
          value={
            values.business
              ? values.business.value
                ? values.business
                : reestructureDataSimple(values.business, values.business)
              : null
          }
          options={selectorBusiness}
          isLoading={false}
          onChange={(target) =>
            setValuesBusinessIndirectEmployee(target, setValues, values, 'business')
          }
        />
        {errors.business ? (
          <span className="error">{capitalizeFirstLetter(i18n('SELECTOR').SELECTOR_BUSINESS)}</span>
        ) : (
          ''
        )}
      </ErrorWrap>
      {values.business === defaulBusiness.OTHER ? (
        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('SELECTOR').BUSINESS_INPUT}`)}
          helperText={errors.businessInput}
          margin="normal"
          value={values.businessInput}
          error={errors.businessInput}
          onChange={({ target: value }) => setFieldValue('businessInput', value.value)}
          type="text"
        />
      ) : (
        ''
      )}
    </StyledWrapperTwo>
  )
}

export const employeeCarrierInput = (values, setFieldValue, errors) => (
  <div style={{ display: 'flex' }}>
    <StyledWrapper>
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`)}
        helperText={errors.name}
        margin="normal"
        value={values.name}
        error={errors.name}
        onChange={({ target: value }) => setFieldValue('name', value.value)}
        type="text"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_LASTNAME}`)}
        helperText={errors.lastName}
        margin="normal"
        value={values.lastName}
        error={errors.lastName}
        onChange={({ target: value }) => setFieldValue('lastName', value.value)}
        type="text"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_ID}`)}
        helperText={errors.documentNumber}
        margin="normal"
        value={values.documentNumber}
        error={errors.documentNumber}
        onChange={({ target: value }) => setFieldValue('documentNumber', value.value)}
        type="number"
      />
    </StyledWrapper>
    <StyledWrapper>
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_FILE_NUMBER}`)}
        helperText={errors.fileNumber}
        margin="normal"
        value={values.fileNumber}
        error={errors.fileNumber}
        onChange={({ target: value }) => setFieldValue('fileNumber', value.value)}
        type="number"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(
          `${i18n('CAPACITATION').CAPACITATION_TABLE_DISTRIBUTION_NUMBER}`
        )}
        helperText={errors.distributionNumber}
        margin="normal"
        value={values.distributionNumber}
        error={errors.distributionNumber}
        onChange={({ target: value }) => setFieldValue('distributionNumber', value.value)}
        type="number"
      />
      <TextFieldMaterial
        label={capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_ACCOUNT_NUMBER}`)}
        helperText={errors.accountNumber}
        margin="normal"
        value={values.accountNumber}
        error={errors.accountNumber}
        onChange={({ target: value }) => setFieldValue('accountNumber', value.value)}
        type="number"
      />
    </StyledWrapper>
  </div>
)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RadioGroup, FormControlLabel } from '@material-ui/core'

import Modal from 'components/Modal'
import CustomRadioMaterials from 'components/CustomRadio'
import Loader from 'components/Loaders/propagate'
import { setImportPopupStatus, resetImportPopupErrors } from 'actions/importPopup'
import { setDisplayToast } from 'actions/toast'
import {
  importExcelCourses,
  importExcelCapacitations,
  importExcelDirectEmployees,
  importExcelCarrierEmployees,
  importExcelFullTrainings,
} from 'actions/excelImport'

import {
  StyledContainer,
  StyledDivHeaderWrapper,
  CrossImage,
  StyledTextHeader,
  StyledDivFooterWrapper,
  StyledButtonsWrapper,
  Button,
  RadioGroupStyle,
  FormControlLabelStyle,
  ErrorLabel,
  LoaderWrapper,
  StyledDropZone,
} from './styled'

import CrossIcon from 'assets/icons/cross.png'

import importationKeys from 'constants/importationKeys'

import { capitalizeFirstLetter } from 'utils/strings'

import FlatFile from '../FlatFileButton'

import {
  coursesConfig,
  coursesCarrierEmployeesConfig,
  coursesDirectEmployeesConfig,
  coursesTrainingsConfig,
} from '../../constants/flatFileKey'

import i18n from 'services/i18n'

class ImportPopup extends Component {
  state = {
    fileToImport: null,
    selectedtype: null,
    flatFileObject: {
      type: '',
      fields: [],
      managed: true,
    },
    errors: [],
  }

  componentWillUnmount = () => {
    this.props.resetImportPopupErrors()
  }

  onClose = () => {
    this.props.setImportPopupStatus({ open: false })
  }

  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      this.setState({ fileToImport: acceptedFiles[0] })
    }
  }

  handleDelete = () => this.setState({ fileToImport: null, selectedtype: null })

  handleChangeFilter = (event) => {
    this.setState({ selectedtype: event.target.value })
    this.handleSelectedType(event.target.value)
  }

  hasFile = () => (this.state.fileToImport ? true : false)

  isDisabled = () => (this.state.selectedtype ? this.hasFile() : true)

  isImporting = () =>
    this.props.excelImport.courseLoading ||
    this.props.excelImport.directEmployeesLoading ||
    this.props.excelImport.carrierEmployeesLoading ||
    this.props.excelImport.fullTrainingLoading

  hasError = () =>
    this.props.excelImport.courseError ||
    this.props.excelImport.directEmployeesError ||
    this.props.excelImport.carrierEmployeesError ||
    this.props.excelImport.fullTrainingError

  setError = () => {
    switch (this.state.selectedtype) {
      case importationKeys.COURSES:
        this.setState({
          errors: this.props.excelImport.courseSuccess.errors,
        })
        break
      case importationKeys.FULLTRAININGS:
        this.setState({
          errors: this.props.excelImport.fullTrainingSuccess.errors,
        })
        break
      case importationKeys.E_DIRECT:
        this.setState({
          errors: this.props.excelImport.directEmployeesSuccess.errors,
        })
        break
      case importationKeys.E_CARRIER:
        this.setState({
          errors: this.props.excelImport.carrierEmployeesSuccess.errors,
        })
        break
    }
  }

  handleSelectedType = (value) => {
    switch (value) {
      case importationKeys.COURSES:
        this.setState({
          flatFileObject: {
            type: importationKeys.COURSES,
            fields: coursesConfig,
          },
        })
        break
      case importationKeys.FULLTRAININGS:
        this.setState({
          flatFileObject: {
            type: importationKeys.FULLTRAININGS,
            fields: coursesTrainingsConfig,
          },
        })
        break
      case importationKeys.E_DIRECT:
        this.setState({
          flatFileObject: {
            type: importationKeys.E_DIRECT,
            fields: coursesDirectEmployeesConfig,
          },
        })
        break
      case importationKeys.E_CARRIER:
        this.setState({
          flatFileObject: {
            type: importationKeys.E_CARRIER,
            fields: coursesCarrierEmployeesConfig,
          },
        })
        break
    }
  }

  render() {
    const { selectedtype, flatFileObject, errors } = this.state
    return (
      <Modal>
        <StyledContainer>
          <StyledDivHeaderWrapper>
            <StyledTextHeader>
              {capitalizeFirstLetter(`${i18n('IMPORT_POPUP').IMPORT}`)}
            </StyledTextHeader>
            <CrossImage onClick={() => this.onClose(true)} src={CrossIcon} />
          </StyledDivHeaderWrapper>
          {this.isImporting() ? (
            <StyledDropZone>
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            </StyledDropZone>
          ) : (
            <RadioGroup
              aria-label="Items"
              style={RadioGroupStyle}
              value={selectedtype}
              onChange={this.handleChangeFilter}
            >
              <FormControlLabel
                style={FormControlLabelStyle}
                value={importationKeys.COURSES}
                control={
                  <CustomRadioMaterials small checked={selectedtype === importationKeys.COURSES} />
                }
                label={capitalizeFirstLetter(`${i18n('PAGE_COURSES')}`)}
              />
              <FormControlLabel
                style={FormControlLabelStyle}
                value={importationKeys.FULLTRAININGS}
                control={
                  <CustomRadioMaterials
                    small
                    checked={selectedtype === importationKeys.FULLTRAININGS}
                  />
                }
                label={capitalizeFirstLetter(`${i18n('IMPORT_POPUP').CAPACITATIONS}`)}
              />
              <FormControlLabel
                style={FormControlLabelStyle}
                value={importationKeys.E_DIRECT}
                control={
                  <CustomRadioMaterials small checked={selectedtype === importationKeys.E_DIRECT} />
                }
                label={capitalizeFirstLetter(
                  `${i18n('CAPACITATION').CAPACITATION_TABLE_DIRECT_EMPLOYEE}`
                )}
              />
              <FormControlLabel
                style={FormControlLabelStyle}
                value={importationKeys.E_CARRIER}
                control={
                  <CustomRadioMaterials
                    small
                    checked={selectedtype === importationKeys.E_CARRIER}
                  />
                }
                label={capitalizeFirstLetter(
                  `${i18n('CAPACITATION').CAPACITATION_TABLE_CARRIER_EMPLOYEE}`
                )}
              />
            </RadioGroup>
          )}
          <StyledDivFooterWrapper>
            <StyledButtonsWrapper>
              <Button
                isDisabled={!this.isImporting()}
                onClick={() => (!this.isImporting() ? this.onClose() : '')}
              >
                Cancelar
              </Button>
              <FlatFile flatFileObject={flatFileObject} displayError={this.setError} />
            </StyledButtonsWrapper>
            <div style={{ height: '10px' }}></div>
            {errors.length > 0
              ? errors.map((element, index) => {
                  return <ErrorLabel key={index}>{element}</ErrorLabel>
                })
              : ''}
          </StyledDivFooterWrapper>
        </StyledContainer>
      </Modal>
    )
  }
}

const mapStateToProps = ({ importPopup, excelImport }) => ({
  importPopup,
  excelImport,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setImportPopupStatus,
      setDisplayToast,
      importExcelCourses,
      importExcelCapacitations,
      importExcelDirectEmployees,
      importExcelCarrierEmployees,
      importExcelFullTrainings,
      resetImportPopupErrors,
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(ImportPopup)

import { handleActions } from 'redux-actions'
import {
  showingIndirect,
  fetchIndirectEmployeeByFilterStart,
  fetchIndirectEmployeeByFilterSuccess,
  fetchIndirectEmployeeByFilterError,
  indirectEmployeeByFilterReset,
  fetchIndirectEmployeeByDateStart,
  fetchIndirectEmployeeByDateSuccess,
  fetchIndirectEmployeeByDateError,
  indirectEmployeeByDateReset,
  fetchIndirectEmployeeStart,
  fetchIndirectEmployeeSuccess,
  fetchIndirectEmployeeError,
  indirectEmployeeReset,
  addIndirectEmployeeStart,
  addIndirectEmployeeSuccess,
  addIndirectEmployeeError,
  addIndirectEmployeeReset,
  modifyIndirectEmployeeStart,
  modifyIndirectEmployeeSuccess,
  modifyIndirectEmployeeError,
  modifyIndirectEmployeeReset,
  deleteIndirectEmployeeStart,
  deleteIndirectEmployeeSuccess,
  deleteIndirectEmployeeError,
  deleteIndirectEmployeeErrorReset
} from 'actions/indirectEmployee'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,

  valuesByDate: null,
  errorByDate: null,
  isFetchingByDate: false,

  isShowing: false,

  valuesAll: null,
  isFetchingAll: false,
  errorAll: null,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false
}

const reducer = handleActions(
  {
    [showingIndirect]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,

    [fetchIndirectEmployeeStart]: state => ({
      ...state,
      isFetchingAll: true,
      errorAll: null
    }),
    [fetchIndirectEmployeeSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesAll: values,
      isFetchingAll: false,
      errorAll: null
    }),
    [fetchIndirectEmployeeError]: (state, { error }) => ({
      ...state,
      isFetchingAll: false,
      errorAll: error
    }),
    [indirectEmployeeReset]: () => defaultState,

    [fetchIndirectEmployeeByDateStart]: state => ({
      ...state,
      isFetchingByDate: true,
      errorByDate: null
    }),
    [fetchIndirectEmployeeByDateSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesByDate: values,
      isFetchingByDate: false,
      errorByDate: null
    }),
    [fetchIndirectEmployeeByDateError]: (state, { error }) => ({
      ...state,
      isFetchingByDate: false,
      errorByDate: error
    }),
    [indirectEmployeeByDateReset]: () => defaultState,

    [fetchIndirectEmployeeByFilterStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchIndirectEmployeeByFilterSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchIndirectEmployeeByFilterError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [indirectEmployeeByFilterReset]: () => defaultState,

    [modifyIndirectEmployeeStart]: state => ({
      ...state,
      isSubmiting: true,
      errorEdit: false,
      successEdit: false
    }),
    [modifyIndirectEmployeeSuccess]: state => ({
      ...state,
      isSubmiting: false,
      errorEdit: false,
      successEdit: true
    }),
    [modifyIndirectEmployeeError]: (state, { error }) => ({
      ...state,
      isSubmiting: false,
      errorEdit: error,
      successEdit: false
    }),
    [modifyIndirectEmployeeReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false
    }),

    [addIndirectEmployeeStart]: state => ({
      ...state,
      isAdding: true,
      errorAdd: false,
      successAdd: false
    }),
    [addIndirectEmployeeSuccess]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false,
      successAdd: true
    }),
    [addIndirectEmployeeError]: (state, { error }) => ({
      ...state,
      isAdding: false,
      errorAdd: error,
      successAdd: false
    }),
    [addIndirectEmployeeReset]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false
    }),

    [deleteIndirectEmployeeStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteIndirectEmployeeSuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteIndirectEmployeeError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),
    [deleteIndirectEmployeeErrorReset]: state => ({
      ...state,
      isDeleting: false,
      errorDelete: false
    })
  },
  defaultState
)

export default reducer

import styled from 'styled-components'

export const DivSearchWrapper = styled.div`
  border-radius: 4px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px;
  flex-direction: column;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
`

export const DivError = styled.div`
  color: ${({ theme }) => theme.colors.red};
  font-weight: 600;
`

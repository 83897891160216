import React from 'react'

import { Wrapper, WrapperEmployeeData, Text } from './styled'

import { capitalizeFirstLetter, allToLower } from 'utils/strings'
import isDanone from 'utils/isDanone'

import userKeys from 'constants/userKeys'

import CoursePreview from 'components/CoursePreview'

import i18n from 'services/i18n'

export const AdminUserData = data => (
  <Wrapper>
    <WrapperEmployeeData>
      <Text>
        {data.userName
          ? `${capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}: ${
              data.userName
            }`
          : ''}
      </Text>
      <Text>
        {data.firstName
          ? `${capitalizeFirstLetter(
              `${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_NAME}`
            )}: ${capitalizeFirstLetter(data.firstName)}`
          : ''}
      </Text>
      <Text>
        {data.company
          ? `${capitalizeFirstLetter(`${i18n('CAPACITATION').COMPANY}`)}: ${capitalizeFirstLetter(
              data.company.name
            )}`
          : ''}
      </Text>
    </WrapperEmployeeData>
    <WrapperEmployeeData>
      <Text>
        {data.lastName
          ? `${capitalizeFirstLetter(
              `${i18n('CAPACITATION').CAPACITATION_TABLE_LASTNAME}`
            )}: ${capitalizeFirstLetter(data.lastName)}`
          : ''}
      </Text>
      <Text>
        {data.mail
          ? `${capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}: ${capitalizeFirstLetter(
              data.mail
            )}`
          : ''}
      </Text>
    </WrapperEmployeeData>
  </Wrapper>
)

export const BaseUserData = data => (
  <Wrapper>
    <WrapperEmployeeData>
      <Text>
        {data.userName
          ? `${capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}: ${
              data.userName
            }`
          : ''}
      </Text>
      <Text>
        {`${capitalizeFirstLetter(`${i18n('USER_TYPES').PREVIEW_CAN_ADD_COURSES}`)}: `}
        {data.canAddCourses
          ? capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_AFFIRMATIVE}`)
          : capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_NEGATIVE}`)}
      </Text>
    </WrapperEmployeeData>
    <WrapperEmployeeData>
      <Text>
        {`${capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_INTERN}`)}: `}
        {data.isInternal
          ? capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_AFFIRMATIVE}`)
          : capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_NEGATIVE}`)}
      </Text>
      <Text>
        {data.mail
          ? `${capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}: ${capitalizeFirstLetter(
              data.mail
            )}`
          : ''}
      </Text>
    </WrapperEmployeeData>
  </Wrapper>
)

export const InstructorData = data => (
  <div>
    {BaseUserData(data)}
    {data.availableCourses ? (
      data.availableCourses.length ? (
        <CoursePreview data={data.availableCourses} />
      ) : (
        <Text>{capitalizeFirstLetter(`${i18n('HOME').HOME_PAGE_NO_COURSES}`)}</Text>
      )
    ) : (
      ''
    )}
  </div>
)

const role = role => {
  switch (role) {
    case userKeys.DIRECTOR:
      return capitalizeFirstLetter(`${i18n('USER_TYPES').ADMINISTRATOR}`)
    case userKeys.MANAGER:
      return capitalizeFirstLetter(`${i18n('USER_TYPES').MANAGER}`)
    case userKeys.BOSS:
      return capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`)
  }
}

export const LeaderUserData = data => (
  <Wrapper>
    <WrapperEmployeeData>
      <Text>
        {data.userName
          ? `${capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}: ${
              data.userName
            }`
          : ''}
      </Text>
      <Text>
        {data.leaderLevel
          ? `${capitalizeFirstLetter(`${i18n('USER_TYPES').ROLE}`)}: ${role(data.leaderLevel)}`
          : ''}
      </Text>
      <Text>
        {`${capitalizeFirstLetter(`${i18n('USER_TYPES').PREVIEW_CAN_ADD_COURSES}`)}: `}
        {data.canAddCourses
          ? capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_AFFIRMATIVE}`)
          : capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_NEGATIVE}`)}
      </Text>
      <Text>
        {data.mail
          ? `${capitalizeFirstLetter(`${i18n('CAPACITATION').EMAIL}`)}: ${capitalizeFirstLetter(
              data.mail
            )}`
          : ''}
      </Text>
    </WrapperEmployeeData>
    <WrapperEmployeeData>
      <Text>
        {`${capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_INTERN}`)}: `}
        {data.isInternal
          ? capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_AFFIRMATIVE}`)
          : capitalizeFirstLetter(`${i18n('CAPACITATION').CAPACITATION_PREVIEW_INTERN_NEGATIVE}`)}
      </Text>
      <Text>
        {data.leader
          ? `${capitalizeFirstLetter(
              `${i18n('CAPACITATION').CAPACITATION_TABLE_LEADER}`
            )}: ${`${capitalizeFirstLetter(allToLower(data.leader.firstName))} ,
            ${capitalizeFirstLetter(allToLower(data.leader.lastName))}`}`
          : ''}
      </Text>

      <Text>
        {capitalizeFirstLetter(
          `${
            isDanone()
              ? i18n('CAPACITATION').CAPACITATION_PREVIEW_HEADER_MANAGEMENT
              : i18n('CAPACITATION').CAPACITATION_TABLE_DISTRIBUTION_CENTER
          }`
        )}
        :
        {data.distributionCenters
          ? data.distributionCenters.map(
              (center, index) =>
                ` ${capitalizeFirstLetter(allToLower(center.name))}${
                  index + 1 === data.distributionCenters.length ? '' : ','
                } `
            )
          : ''}
      </Text>
    </WrapperEmployeeData>
  </Wrapper>
)

import { handleActions } from 'redux-actions'
import {
  pendingTrainingStart,
  pendingTrainingSuccess,
  pendingTrainingError,
  pendingTrainingReset,
} from 'actions/Dashboard/pendingTraining'

const defaultState = {
  success: false,
  values: [],
  total: 0,
  error: null,
  isLoading: false,
}

const reducer = handleActions(
  {
    [pendingTrainingStart]: (state) => ({
      ...state,
      success: false,
      isLoading: true,
      error: [],
    }),
    [pendingTrainingSuccess]: (state, { payload: { values, total } }) => ({
      ...state,
      success: true,
      values,
      total,
      isLoading: false,
    }),
    [pendingTrainingError]: (state, { payload: { error } }) => ({
      ...state,
      success: false,
      isLoading: false,
      error,
    }),
    [pendingTrainingReset]: () => ({ ...defaultState }),
  },
  defaultState
)

export default reducer

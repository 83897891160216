import { createActions } from 'redux-actions'
import getApi from 'services/api'

const {
  pendingTrainingStart,
  pendingTrainingSuccess,
  pendingTrainingError,
  pendingTrainingReset,
} = createActions({
  PENDING_TRAINING_START: () => {},
  PENDING_TRAINING_SUCCESS: (values, total) => ({ values, total }),
  PENDING_TRAINING_ERROR: (error) => ({ error }),
  PENDING_TRAINING_RESET: () => {},
})

const getPendingTraning = (query) => {
  return async (dispatch) => {
    const api = await getApi()

    const body = {
      ...query,
      Sort: {
        Name: '',
        IsAscending: true,
      },
      Pager: {
        PageSize: 10,
        PageNumber: query.PageNumber ? query.PageNumber : 1,
      },
    }

    dispatch(pendingTrainingStart())

    return api
      .post('dashboard/pendingtraining', body)
      .then((response) => {
        const dataResponse = response.data.data
        dispatch(pendingTrainingSuccess(dataResponse.pendingTraining, dataResponse.totalTrainings))
      })
      .catch((error) => {
        const result = error.data ? error.data.message : error.message
        dispatch(pendingTrainingError(result))
      })
  }
}

export {
  getPendingTraning,
  pendingTrainingStart,
  pendingTrainingSuccess,
  pendingTrainingError,
  pendingTrainingReset,
}

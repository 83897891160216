import React, { Component } from 'react'

import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import {
  ExpansionPanelStyles,
  ExpansionPanelSummaryStyles,
  ExpansionPanelDetailsStyles,
  ExpansionPanelSummaryDivWrapperStyles,
  StyledText,
  StyledImg,
  StyledButton
} from './styled'
import theme from 'config/theme'

import DownloadIcon from '@material-ui/icons/VerticalAlignBottom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/DeleteOutline'

import CircularProgress from '@material-ui/core/CircularProgress'

import iconFileFull from 'assets/icons/iconFileFull.png'

import { capitalizeFirstLetter, allToLower } from 'utils/strings'
import { isGeneralAdmin, isAdmin, isInstructor, getUserInfo } from 'utils/session'

import { withStyles } from '@material-ui/core/styles'

const styles = {
  content: { display: 'contents !important' }
}

class MaterialsExpansionPanel extends Component {
  state = {
    expanded: false
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  mySelf = username => {
    const { userName } = getUserInfo()
    return username === userName
  }

  generalAdminShouldInteract = generalAdminShouldInteract => {
    if (isGeneralAdmin()) {
      return generalAdminShouldInteract
    }
    return true
  }

  businessAdminShouldInteract = businessAdminShouldInteract => {
    if (isAdmin()) {
      return businessAdminShouldInteract
    }
    return true
  }

  render() {
    const {
      name,
      surname,
      username,
      children,
      onClick,
      expanded,
      withDownload,
      withEdit,
      withDelete,
      withOutline,
      onButtonDownloadClick,
      buttonDownloadLoading,
      onButtonEditClick,
      onButtonDeleteClick,
      backgroundColor,
      backgroundColorExpanded,
      doNotDisplay,
      generalAdminShouldInteract,
      businessAdminShouldInteract
    } = this.props
    return (
      <ExpansionPanel
        square
        onChange={this.handleChange({ name })}
        style={ExpansionPanelStyles(theme, withOutline, expanded, doNotDisplay)}
        expanded={expanded}
        onClick={e => onClick(e)}
      >
        <ExpansionPanelSummary
          style={ExpansionPanelSummaryStyles(
            theme,
            expanded,
            backgroundColor,
            backgroundColorExpanded
          )}
          classes={{ content: this.props.classes.content }}
        >
          <div style={ExpansionPanelSummaryDivWrapperStyles(withDownload, withEdit, withDelete)}>
            <StyledImg src={iconFileFull} />
            <StyledText>
              {name ? capitalizeFirstLetter(allToLower(name)) : ''}{' '}
              {surname ? capitalizeFirstLetter(allToLower(surname)) : ''}
            </StyledText>
            {withDownload ? (
              <StyledButton onClick={e => onButtonDownloadClick(e)}>
                {buttonDownloadLoading ? (
                  <CircularProgress size={15} color={theme.colors.white} />
                ) : (
                  <DownloadIcon />
                )}
              </StyledButton>
            ) : (
              ''
            )}
            {withEdit &&
            !isInstructor() &&
            this.generalAdminShouldInteract(generalAdminShouldInteract) &&
            this.businessAdminShouldInteract(businessAdminShouldInteract) &&
            !this.mySelf(username) ? (
              <StyledButton onClick={e => onButtonEditClick(e)}>
                <EditIcon />
              </StyledButton>
            ) : (
              ''
            )}

            {withDelete &&
            !isInstructor() &&
            this.generalAdminShouldInteract(generalAdminShouldInteract) &&
            this.businessAdminShouldInteract(businessAdminShouldInteract) &&
            !this.mySelf(username) ? (
              <StyledButton onClick={e => onButtonDeleteClick(e)}>
                <DeleteIcon />
              </StyledButton>
            ) : (
              ''
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={ExpansionPanelDetailsStyles(theme)}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(MaterialsExpansionPanel)

const getBaseUrl = () => {
  // PROD ENVIROMENT
  if (process.env.REACT_APP_ENVIROMENT === 'prod') {
    return 'https://capa-back-prod.terrand-audits.com/api/'
  }
  //
  if (process.env.REACT_APP_ENVIROMENT === 'qa') {
    return 'https://qa-capa.terrand-audits.com/api/'
  }
  if (process.env.REACT_APP_ENVIROMENT === 'docker') {
    return 'https://qa-capa.terrand-audits.com/api/'
  }
  // DEV ENVIROMENT / DEFAULT ENVIROMENT
  return 'https://qa-capa.terrand-audits.com/api/'
}
const API_ROUTES = {
  BASE_URL: getBaseUrl(),
  LOGIN: 'login/auth/login',
  GET_ALL_CATEGORIES: 'coursecategories/',
  GENERAL_ADMIN: 'generaladministrators',
  GET_COURSES_BY_CATEGORY_ID: 'bycategory/',
  GET_CAPACITATIONS_BY_COURSE_ID: 'trainings/query/',
  GET_DIRECT_EMPLOYEE_BY_FILTER: 'directemployees/query',
  GET_CARRIER_EMPLOYEE_BY_FILTER: 'carrieremployees/query',
  GET_INDIRECT_EMPLOYEE_BY_FILTER: 'indirectemployees/query',
  GET_COURSES_BY_EMPLOYEE_ID: 'trainings/query',
  GET_EXCEL_EXPORT: 'trainings/excelexport',
  GET_COURSES_BY_NAME: 'courses/byname',
  GET_LEADERS_BY_NAME: 'leaders/byname',
  GET_DISTRIBUTION_CENTER_BY_NAME: 'distributioncenters/byname',
  PUT_CATEGORY_NAME: 'coursecategories',
  PUT_COURSE_NAME: 'courses',
  PUT_LEADER_NAME: 'leaders',
  PUT_CENTER_NAME: 'distributioncenters',
  PUT_DIRECT_EMPLOYEE: 'directemployees',
  PUT_INDIRECT_EMPLOYEE: 'indirectemployees',
  PUT_CARRIER_EMPLOYEE: 'carrieremployees',
  PUT_CAPACITATION: 'trainings',
  WORK_SECTOR: 'worksectors',
  WORK_POSITION: 'workpositions',
  ADMINISTRATORS: 'administrators',
  INSTRUCTORS: 'instructors',
  LEADER_USERS: 'leadersusers',
  TRAINING_SUMMARY: 'dashboard/trainingsummary',
  TRAINING_DETAIL: 'dashboard/trainingdetail',
  TRAINING_PENDING: 'dashboard/pendingtraining',
  IMPORT_EXCEL_COURSES: 'fileupload/courses',
  IMPORT_EXCEL_CAPACITATIONS: 'fileupload/plannedtrainings',
  IMPORT_EXCEL_DIRECT_EMPLOYEES: 'fileupload/directemployees',
  IMPORT_EXCEL_CARRIER_EMPLOYEES: 'fileupload/carrieremployees',
  IMPORT_EXCEL_FULL_TRAININGS: 'fileupload/trainings',
  ACTIVE_YEARS: 'dashboard/yearsavailable',
  BY_DISTRIBUTION_CENTER: 'bydistributioncenter',
  BY_DATE: 'bydate',
  IMAGES: 'images',
  SET_DIRECT_EMPLOYEE: 'setdirectemployee',
  GET_ALL_CATEGORIES_BY_USER: 'byusertype',
  CREATE_PDF: 'createpdf',
  COMPANIES: 'companies',
}

export default API_ROUTES

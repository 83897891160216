import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'
import { getCompanyId } from 'utils/getCompany'

const {
  coursesByCategoriesIdsStart,
  coursesByCategoriesIdsSuccess,
  coursesByCategoriesIdsError,
} = createActions({
  COURSES_BY_CATEGORIES_IDS_START: () => {},
  COURSES_BY_CATEGORIES_IDS_SUCCESS: (values) => ({ values }),
  COURSES_BY_CATEGORIES_IDS_ERROR: (error) => ({ error }),
})

const getCoursesByCategoriesIds = (ids) => {
  return async (dispatch, getState) => {
    const api = await getApi()
    let urlFinal = `${API_ROUTES.PUT_COURSE_NAME}`
    const {
      coursesByCategoriesIds: { cache },
      selectedFilter: { selectedBusiness },
    } = getState()
    urlFinal = `${urlFinal}/bycategory/{categoryId}/company/`

    const companyUser = getCompanyId()
    if (selectedBusiness && selectedBusiness.value) {
      urlFinal += `${selectedBusiness.value}`
    } else if (companyUser) {
      urlFinal += `${companyUser.Id}`
    } else {
      urlFinal += `0`
    }
    dispatch(coursesByCategoriesIdsStart())
    return Promise.all(
      (ids instanceof Array ? ids : [ids]).map((id) => {
        const category = cache.find((item) => item.categoryId === id)
        if (category) {
          return new Promise((resolve) => {
            resolve(category)
          })
        } else {
          return api
            .get(urlFinal.replace('{categoryId}', id))
            .then((response) => ({ categoryId: id, items: response.data.data }))
        }
      })
    )
      .then((leaders) => {
        dispatch(coursesByCategoriesIdsSuccess(leaders))
      })
      .catch((error) => {
        dispatch(coursesByCategoriesIdsError(error.data ? error.data.message : error.message))
      })
  }
}
export {
  getCoursesByCategoriesIds,
  coursesByCategoriesIdsStart,
  coursesByCategoriesIdsSuccess,
  coursesByCategoriesIdsError,
}

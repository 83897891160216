const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    red: '#FF565A',
    blue: '#329fdb',
    purple: '#7f6ba5',
    grey: '#F4F4F4',
    orange: '#F5A827',
    blueGreen: '#19A289',
    transparent: '#FFFFFF',
    lightBlue: '#82d6ff',
    lightGrey: '#e4e4e4',
    darkGrey: '#929191',
    mastelloneGreen: '#37b580',
    darkBlue: '#2F4B8F',
    transparentBlack: 'rgba(0, 0, 0, 0.25)',
    dustyGray: '#9B9B9B',
    yellowLight: '#ffec9e',
    complianceGreen: '#85cc00',
    complianceYellow: '#ffbf00',
    complianceRed: '#ff2e00',
    disabledUserGrey: '#7d7d7d',
    disabledUserExpandedGrey: '#b8b8b8',
    error: '#f34336'
  },
  fonts: {
    roboto: `'Roboto', sans-serif`
  }
}

export default theme

import styled from 'styled-components'

export const CourseWrapper = styled.button`
  border: none;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};
  min-height: 20px;
  border-width: 1px;
  background: ${({ theme }) => theme.colors.purple};
  width: 100%;
`

export const PreviewWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.purple};
`

export const DivWrapper = styled.div`
  display: contents;
`

export const DivItemWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: 40px;
  padding: 7px;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.white};
`
export const DivHeaderItemWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  height: 40px;
  width: 100%;
`

export const DivItem = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: ${({ isTableItem }) => (isTableItem ? 'auto' : 'unset')};
  text-align: center;
  font-weight: bold;
  color: ${({ isTableItem, theme }) => (isTableItem ? theme.colors.white : theme.colors.darkGrey)};
`

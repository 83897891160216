import React from 'react'

import styled from 'styled-components'
import PropagateLoader from 'react-spinners/PropagateLoader'

import theme from 'config/theme'

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loader = () => (
  <LoaderWrapper>
    <PropagateLoader sizeUnit={'px'} size={15} color={theme.colors.blue} />
  </LoaderWrapper>
)

export default Loader

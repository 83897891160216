import { createActions } from 'redux-actions'

import { addCategoryReset } from 'actions/categories'
import { addCourseReset } from 'actions/courses'
import { addDirectEmployeeReset } from 'actions/directEmployee'
import { addCarrierEmployeeReset } from 'actions/carrierEmployee'
import { addIndirectEmployeeReset } from 'actions/indirectEmployee'
import { addCenterReset } from 'actions/centers'
import { addLeaderReset } from 'actions/leaders'
import { addLeaderUserReset } from 'actions/leaderUsers'
import { addInstructorReset } from 'actions/instructors'
import { addAdministratorReset } from 'actions/administrators'

const { addPopupStatus } = createActions({
  ADD_POPUP_STATUS: data => ({ data })
})

const setAddPopupStatus = status => dispatch => dispatch(addPopupStatus({ status }))

const resetAddPopupErrors = () => dispatch => {
  dispatch(addCategoryReset())
  dispatch(addCourseReset())
  dispatch(addDirectEmployeeReset())
  dispatch(addCarrierEmployeeReset())
  dispatch(addIndirectEmployeeReset())
  dispatch(addCenterReset())
  dispatch(addLeaderReset())
  dispatch(addLeaderUserReset())
  dispatch(addInstructorReset())
  dispatch(addAdministratorReset())
}
export { setAddPopupStatus, resetAddPopupErrors, addPopupStatus }

/* eslint-disable max-len */
import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

import { getCompanyId } from 'utils/getCompany'
import { getUserInfo } from 'utils/session'

// TODO ---> usa estos import para un if en la linea 67 para validar el ID de business. SI es un usuario de tipo
//  instructor o admin tiene que tomar el id de que viene en el getUserInfo dentro de company sino lo saca del selector

// import { isInstructor, getUserInfo } from 'utils/session'

const {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesError,
  categoriesReset,

  modifyCategoryStart,
  modifyCategorySuccess,
  modifyCategoryError,
  modifyCategoriesReset,

  addCategoryStart,
  addCategorySuccess,
  addCategoryError,
  addCategoryReset,

  deleteCategoryStart,
  deleteCategorySuccess,
  deleteCategoryError,
  deleteCategoryErrorReset
} = createActions({
  FETCH_CATEGORIES_START: () => {},
  FETCH_CATEGORIES_SUCCESS: data => ({ data }),
  FETCH_CATEGORIES_ERROR: error => ({ error }),
  CATEGORIES_RESET: () => {},

  MODIFY_CATEGORY_START: () => {},
  MODIFY_CATEGORY_SUCCESS: () => {},
  MODIFY_CATEGORY_ERROR: error => ({ error }),
  MODIFY_CATEGORIES_RESET: () => {},

  ADD_CATEGORY_START: () => {},
  ADD_CATEGORY_SUCCESS: () => {},
  ADD_CATEGORY_ERROR: error => ({ error }),
  ADD_CATEGORY_RESET: () => {},

  DELETE_CATEGORY_START: () => {},
  DELETE_CATEGORY_SUCCESS: () => {},
  DELETE_CATEGORY_ERROR: error => ({ error }),
  DELETE_CATEGORY_ERROR_RESET: () => {}
})

const fetchCategories = () => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchCategoriesStart())
    const {
      selectedFilter: { selectedBusiness }
    } = getState()
    const companyId = getUserInfo().companies
      ? getUserInfo().companies[0].id
      : selectedBusiness.value
    try {
      const response = await api.get(
        `${API_ROUTES.GET_ALL_CATEGORIES}${
          API_ROUTES.GET_ALL_CATEGORIES_BY_USER
        }/company/${companyId}`
      )
      dispatch(fetchCategoriesSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchCategoriesError(error))
    }
  }
}

const resetCategories = () => dispatch => dispatch(categoriesReset())

const modifyCategory = (item, name) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyCategoryStart())
    const body = {
      Name: name,
      Companies: [getCompanyId()]
    }
    try {
      const response = await api.put(`${API_ROUTES.PUT_CATEGORY_NAME}/${item.id}`, body)
      dispatch(modifyCategorySuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyCategoryError(error.data))
    }
  }
}

const resetModifyCategories = () => dispatch => dispatch(modifyCategoriesReset())

const addCategory = data => {
  return async dispatch => {
    const api = await getApi()
    dispatch(addCategoryStart())
    const body = {
      Name: data.name,
      Companies: [getCompanyId()]
    }
    try {
      const response = await api.post(`${API_ROUTES.GET_ALL_CATEGORIES}`, body)
      dispatch(addCategorySuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addCategoryError(error.data))
    }
  }
}

const resetAddCategory = () => dispatch => dispatch(addCategoryReset())

const deleteCategory = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteCategoryStart())
    try {
      const response = await api.delete(`${API_ROUTES.GET_ALL_CATEGORIES}${id}`)
      dispatch(deleteCategorySuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteCategoryError(error.data))
    }
  }
}

const resetDeleteCategoryError = () => dispatch => dispatch(deleteCategoryErrorReset())

export {
  fetchCategories,
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesError,
  resetCategories,
  categoriesReset,
  modifyCategory,
  modifyCategoryStart,
  modifyCategorySuccess,
  modifyCategoryError,
  resetModifyCategories,
  modifyCategoriesReset,
  addCategory,
  addCategoryStart,
  addCategorySuccess,
  addCategoryError,
  resetAddCategory,
  addCategoryReset,
  deleteCategory,
  deleteCategoryStart,
  deleteCategorySuccess,
  deleteCategoryError,
  resetDeleteCategoryError,
  deleteCategoryErrorReset
}

import * as Yup from 'yup'

import { capitalizeFirstLetter } from 'utils/strings'

import i18n from 'services/i18n'

export const ValidationsDirectOrLeader = () => ({
  name: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
  lastName: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_LASTNAME}`)),
  fileNumber: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_FILE}`)),
  costCenter: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)),
  category: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_CATEGORY}`)),
  distributionCenterId: Yup.string().required(),
  workSector: Yup.string().required(),
  workPosition: Yup.string().required(),
  leader: Yup.string().required(),
  effectiveStaffId: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_STAFF_ID}`))
})

export const ValidationsIndirectEmployee = () => ({
  name: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
  lastName: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_LASTNAME}`)),
  documentNumber: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_DOCUMENT}`)),
  companies: Yup.string().required(),
  business: Yup.string().required(),
  businessInput: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`))
})

export const ValidationsCarrierEmployee = () => ({
  name: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
  lastName: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_LASTNAME}`)),
  documentNumber: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_DOCUMENT}`)),
  fileNumber: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_FILE}`)),

  distributionNumber: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
  ).required(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_DISTRIBUTION_NUMBER}`)
  ),
  accountNumber: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_ACCOUNT}`))
})

export const ValidationsCapacitation = () => ({
  duration: Yup.number(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NUMBER}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_DURATION}`)),
  observation: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_OBSERVATION}`))
})

export const ValidationsDefault = () => ({
  name: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`))
})

export const ValidationsSimpleUser = () => ({
  userName: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_USERNAME}`)),
  name: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_NAME}`)),
  lastName: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_LASTNAME}`)),
  email: Yup.string(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`))
    .email(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_EMAIL}`))
    .required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_EMAIL}`))
})

export const ValidationBusinessAdmin = () => ({
  ...ValidationsSimpleUser(),
  selectedBusiness: Yup.string().required()
})

export const ValidationInstructor = () => ({
  ...ValidationsSimpleUser(),
  availableCategories: Yup.string().required(),
  availableCourses: Yup.string().required(),
  selectedBusiness: Yup.string().required()
})

export const validationUserName = () => ({
  userName: Yup.string(
    capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`)
  ).required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_USERNAME}`)),
  email: Yup.string(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_TEXT}`))
    .email(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_EMAIL}`))
    .required(capitalizeFirstLetter(`${i18n('EDIT_VIEW').EDIT_VIEW_ERROR_REQUIRED_EMAIL}`)),
  distributionCenters: Yup.string().required()
})

import React from 'react'
import { useSelector } from 'react-redux'

import Header from 'components/Header'
import PageBlock from 'components/PageBlock'

import { PageWrapper, Loading } from './styled'

const Page = ({ children, ...otherProps }) => {
  const loading = useSelector((state) => state.loading)
  return (
    <>
      {otherProps.withHeader && <Header />}
      {<Loading color="secondary" loading={loading} />}
      <PageWrapper {...otherProps}>
        <PageBlock {...otherProps}>{children}</PageBlock>
      </PageWrapper>
    </>
  )
}

export default Page

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'

import { getPendingTraning } from 'actions/Dashboard/pendingTraining'
import { loadingStart, loadingEnd } from 'actions/loading'

import FiltersUrl from 'components/FiltersUrl'
import PaginationUrl from 'components/PaginationUrl'
import Table from 'components/Table'
import filtersPendingTraining from 'config/Dashboard/Filters/filtersPendingTraining'
import columnsPendingTraining from 'config/Dashboard/Columns/columnsPendingTraining'

export default function PendingTraining() {
  const dispatch = useDispatch()
  const dataPendingTraining = useSelector((state) => state.pendingTraining)
  const location = useLocation()
  const query = queryString.parse(location.search)
  useEffect(() => {
    filtersPendingTraining
      .filter((x) => x.isMulti)
      .forEach((filterMulti) => {
        if (query[filterMulti.name] && !(query[filterMulti.name] instanceof Array)) {
          query[filterMulti.name] = [query[filterMulti.name]]
        }
      })
    dispatch(loadingStart())
    dispatch(getPendingTraning(query)).then(() => {
      dispatch(loadingEnd())
    })
  }, [location.search])

  return (
    <>
      <FiltersUrl filters={filtersPendingTraining} isLoading={dataPendingTraining.isLoading} />
      <Table columns={columnsPendingTraining} data={dataPendingTraining.values} />
      {dataPendingTraining.values.length ? (
        <PaginationUrl total={dataPendingTraining.total} size={10}></PaginationUrl>
      ) : (
        ''
      )}
    </>
  )
}

import React from 'react'
import styled from 'styled-components'

import DeleteIcon from '@material-ui/icons/DeleteOutline'

const Button = styled.button`
  max-width: 120px;
  min-width: 70px;
  background: ${({ theme, disabled }) => (disabled ? theme.colors.darkGrey : theme.colors.purple)};
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.white};
  width: 7%;
  border-radius: 30px;
`

export const ClearButton = ({ handleClear, disabled }) => (
  <Button onClick={() => handleClear()} disabled={!disabled}>
    <DeleteIcon />
  </Button>
)

export default ClearButton

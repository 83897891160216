import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

import { getArrayOfId } from 'utils/selectorParser'
import { getUserInfo } from 'utils/session'

import userKeys from 'constants/userKeys'

const {
  showingLeaderUsers,

  fetchLeaderUsersStart,
  fetchLeaderUsersSuccess,
  fetchLeaderUsersError,
  leaderUsersReset,

  enableOrDisableLeaderUsersStart,
  enableOrDisableLeaderUsersSuccess,
  enableOrDisableLeaderUsersError,
  enableOrDisableLeaderUsersReset,

  fetchLeaderUsersByIdStart,
  fetchLeaderUsersByIdSuccess,
  fetchLeaderUsersByIdError,
  leaderUsersByIdReset,

  fetchLeaderUsersByCenterIdStart,
  fetchLeaderUsersByCenterIdSuccess,
  fetchLeaderUsersByCenterIdError,
  leaderUsersByCenterIdReset,

  addLeaderUserStart,
  addLeaderUserSuccess,
  addLeaderUserError,
  addLeaderUserReset,

  deleteLeaderUserStart,
  deleteLeaderUserSuccess,
  deleteLeaderUserError,
  deleteLeaderUserErrorReset,

  modifyLeaderUserStart,
  modifyLeaderUserSuccess,
  modifyLeaderUserError,
  modifyLeaderUserReset
} = createActions({
  SHOWING_LEADER_USERS: data => ({ data }),

  FETCH_LEADER_USERS_START: () => {},
  FETCH_LEADER_USERS_SUCCESS: data => ({ data }),
  FETCH_LEADER_USERS_ERROR: error => ({ error }),
  LEADER_USERS_RESET: () => {},

  ENABLE_OR_DISABLE_LEADER_USERS_START: () => {},
  ENABLE_OR_DISABLE_LEADER_USERS_SUCCESS: () => {},
  ENABLE_OR_DISABLE_LEADER_USERS_ERROR: error => ({ error }),
  ENABLE_OR_DISABLE_LEADER_USERS_RESET: () => {},

  FETCH_LEADER_USERS_BY_ID_START: () => {},
  FETCH_LEADER_USERS_BY_ID_SUCCESS: data => ({ data }),
  FETCH_LEADER_USERS_BY_ID_ERROR: error => ({ error }),
  LEADER_USERS_BY_ID_RESET: () => {},

  FETCH_LEADER_USERS_BY_CENTER_ID_START: () => {},
  FETCH_LEADER_USERS_BY_CENTER_ID_SUCCESS: data => ({ data }),
  FETCH_LEADER_USERS_BY_CENTER_ID_ERROR: error => ({ error }),
  LEADER_USERS_BY_CENTER_ID_RESET: () => {},

  ADD_LEADER_USER_START: () => {},
  ADD_LEADER_USER_SUCCESS: data => ({ data }),
  ADD_LEADER_USER_ERROR: error => ({ error }),
  ADD_LEADER_USER_RESET: () => {},

  DELETE_LEADER_USER_START: () => {},
  DELETE_LEADER_USER_SUCCESS: () => {},
  DELETE_LEADER_USER_ERROR: error => ({ error }),
  DELETE_LEADER_USER_ERROR_RESET: () => {},

  MODIFY_LEADER_USER_START: () => {},
  MODIFY_LEADER_USER_SUCCESS: () => {},
  MODIFY_LEADER_USER_ERROR: error => ({ error }),
  MODIFY_LEADER_USER_RESET: () => {}
})

const setShowingLeaderUsers = status => dispatch => dispatch(showingLeaderUsers({ status }))

const fetchLeaderUsers = () => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(fetchLeaderUsersStart())
    const {
      selectedUserStatus,
      selectedUsersFilter: { inputNameValue, category },
      selectedFilter: { selectedBusiness }
    } = getState()

    const companyId = getUserInfo().companies
      ? getUserInfo().companies[0].id
      : selectedBusiness.value

    const body = {
      CompanyId: companyId,
      Name: inputNameValue ? inputNameValue : '',
      CategoryId: category ? category.id : 0
    }
    try {
      const response = await api.post(
        `${API_ROUTES.LEADER_USERS}/${selectedUserStatus.selected}`,
        body
      )
      dispatch(fetchLeaderUsersSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchLeaderUsersError(error))
    }
  }
}

const resetLeaderUsers = () => dispatch => dispatch(leaderUsersReset())

const enableOrDisableLeaderUsers = user => {
  return async dispatch => {
    const api = await getApi()
    dispatch(enableOrDisableLeaderUsersStart())
    const enableOrDisable = user.Enable ? `enableLeaderUser` : `disableLeaderUser`
    try {
      await api.patch(`${API_ROUTES.LEADER_USERS}/${enableOrDisable}/${user.id}`)
      dispatch(enableOrDisableLeaderUsersSuccess())
    } catch (error) {
      dispatch(enableOrDisableLeaderUsersError(error.data))
    }
  }
}

const resetEnableOrDisableLeaderUsers = () => dispatch =>
  dispatch(enableOrDisableLeaderUsersReset())

const fetchLeaderUsersById = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchLeaderUsersByIdStart())
    try {
      const response = await api.get(`${API_ROUTES.LEADER_USERS}/${id}`)
      dispatch(fetchLeaderUsersByIdSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchLeaderUsersByIdError(error.data))
    }
  }
}

const resetLeaderUsersById = () => dispatch => dispatch(leaderUsersByIdReset())

const fetchLeaderUsersByCenterId = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchLeaderUsersByCenterIdStart())
    try {
      const response = await api.get(
        `${API_ROUTES.LEADER_USERS}/${API_ROUTES.BY_DISTRIBUTION_CENTER}/${id}`
      )
      dispatch(fetchLeaderUsersByCenterIdSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(fetchLeaderUsersByCenterIdError(error.data))
    }
  }
}

const resetLeaderUsersByCenterId = () => dispatch => dispatch(leaderUsersByCenterIdReset())

const findLeaderCenters = (valuesAll, data) => valuesAll.find(i => i.id === data.leader.value)

const addLeaderUser = data => {
  return async (dispatch, getState) => {
    const api = await getApi()
    dispatch(addLeaderUserStart())

    const {
      leaders: { valuesAll }
    } = getState()

    const body = {
      UserName: data.userName,
      Password: data.passwordConfirmation,
      LeaderLevel: data.leaderLevel.value,
      Leader:
        data.leaderLevel.value === userKeys.DIRECTOR || data.leaderLevel.value === userKeys.MANAGER
          ? ''
          : { Id: data.leader.value },
      DistributionCenters:
        data.leaderLevel.value === userKeys.BOSS
          ? [{ Id: findLeaderCenters(valuesAll, data).distributionCenter.id }]
          : data.distributionCenters
          ? getArrayOfId(data.distributionCenters)
          : [],
      Mail: data.email
    }
    try {
      const response = await api.post(`${API_ROUTES.LEADER_USERS}`, body)
      dispatch(addLeaderUserSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(addLeaderUserError(error.data))
    }
  }
}

const resetAddLeaderUser = () => dispatch => dispatch(addLeaderUserReset())

const deleteLeaderUser = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(deleteLeaderUserStart())
    try {
      const response = await api.delete(`${API_ROUTES.LEADER_USERS}/${id}`)
      dispatch(deleteLeaderUserSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(deleteLeaderUserError(error.data))
    }
  }
}

const resetDeleteLeaderUserError = () => dispatch => dispatch(deleteLeaderUserErrorReset())

const modifyLeaderUser = (item, data) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyLeaderUserStart())
    const body = {
      UserName: data.userName,
      LeaderLevel: data.leaderLevel.value,
      Leader: { Id: data.leader.value },
      DistributionCenters: getArrayOfId(data.distributionCenters),
      Mail: data.email
    }
    try {
      const response = await api.put(`${API_ROUTES.LEADER_USERS}/${item.id}`, body)
      dispatch(modifyLeaderUserSuccess({ values: response.data.data }))
    } catch (error) {
      dispatch(modifyLeaderUserError(error.data))
    }
  }
}

const resetModifyLeaderUser = () => dispatch => dispatch(modifyLeaderUserReset())

export {
  setShowingLeaderUsers,
  showingLeaderUsers,
  fetchLeaderUsers,
  fetchLeaderUsersStart,
  fetchLeaderUsersSuccess,
  fetchLeaderUsersError,
  leaderUsersReset,
  resetLeaderUsers,
  enableOrDisableLeaderUsers,
  enableOrDisableLeaderUsersStart,
  enableOrDisableLeaderUsersSuccess,
  enableOrDisableLeaderUsersError,
  enableOrDisableLeaderUsersReset,
  resetEnableOrDisableLeaderUsers,
  fetchLeaderUsersById,
  fetchLeaderUsersByIdStart,
  fetchLeaderUsersByIdSuccess,
  fetchLeaderUsersByIdError,
  leaderUsersByIdReset,
  resetLeaderUsersById,
  fetchLeaderUsersByCenterId,
  fetchLeaderUsersByCenterIdStart,
  fetchLeaderUsersByCenterIdSuccess,
  fetchLeaderUsersByCenterIdError,
  resetLeaderUsersByCenterId,
  leaderUsersByCenterIdReset,
  addLeaderUser,
  addLeaderUserStart,
  addLeaderUserSuccess,
  addLeaderUserError,
  resetAddLeaderUser,
  addLeaderUserReset,
  deleteLeaderUser,
  deleteLeaderUserStart,
  deleteLeaderUserSuccess,
  deleteLeaderUserError,
  resetDeleteLeaderUserError,
  deleteLeaderUserErrorReset,
  modifyLeaderUser,
  modifyLeaderUserStart,
  modifyLeaderUserSuccess,
  modifyLeaderUserError,
  resetModifyLeaderUser,
  modifyLeaderUserReset
}

import { allToCaps } from 'utils/strings'
import i18n from 'services/i18n'

export const defaulBusiness = {
  ESTE: 'este',
  SECCO: 'secco',
  SODEXO: 'sodexo',
  TIGYA: 'tigya',
  OTHER: i18n('SELECTOR').SELECTOR_OTHER
}

export const selectorBusiness = [
  { value: defaulBusiness.ESTE, label: allToCaps(defaulBusiness.ESTE) },
  { value: defaulBusiness.SECCO, label: allToCaps(defaulBusiness.SECCO) },
  { value: defaulBusiness.SODEXO, label: allToCaps(defaulBusiness.SODEXO) },
  { value: defaulBusiness.TIGYA, label: allToCaps(defaulBusiness.TIGYA) },
  { value: defaulBusiness.OTHER, label: defaulBusiness.OTHER }
]

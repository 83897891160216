import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'

import FiltersUrl from 'components/FiltersUrl'
import filtersLegal from 'config/Dashboard/Filters/filtersLegal'
import Table from 'components/Table'
import columnsLegal from 'config/Dashboard/Columns/columnsLegal'
import PaginationUrl from 'components/PaginationUrl'

import { getLegal } from 'actions/Dashboard/TrainingDetail/legal'
import { loadingStart, loadingEnd } from 'actions/loading'

export default function Legal() {
  const dispatch = useDispatch()
  const dataLegal = useSelector((state) => state.legal)
  const location = useLocation()
  const query = queryString.parse(location.search)
  useEffect(() => {
    filtersLegal
      .filter((x) => x.isMulti)
      .forEach((filterMulti) => {
        if (query[filterMulti.name] && !(query[filterMulti.name] instanceof Array)) {
          query[filterMulti.name] = [query[filterMulti.name]]
        }
      })
    dispatch(loadingStart())
    dispatch(getLegal(query)).then(() => {
      dispatch(loadingEnd())
    })
  }, [location.search])

  return (
    <>
      <FiltersUrl filters={filtersLegal} isLoading={dataLegal.isLoading} />
      <Table columns={columnsLegal} data={dataLegal.values} />
      {dataLegal.values.length ? (
        <PaginationUrl total={dataLegal.total} size={10}></PaginationUrl>
      ) : (
        ''
      )}
    </>
  )
}

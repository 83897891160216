import { handleActions } from 'redux-actions'
import { selectedFilter, resetFilter } from 'actions/selectedFilter'

import i18n from 'services/i18n'

import { capitalizeFirstLetter } from 'utils/strings'

const defaultState = {
  filterCategory: '',
  filterItem: '',
  filterInput: '',
  selectedBusiness: {
    label: capitalizeFirstLetter(i18n('SELECTOR').SELECTOR_ALL),
    value: 0
  }
}

const reducer = handleActions(
  {
    [selectedFilter]: (state, { payload: { data: status } }) => ({ ...state, ...status.status }),

    [resetFilter]: () => defaultState
  },
  defaultState
)

export default reducer

import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  importExcelCoursesStart,
  importExcelCoursesSuccess,
  importExcelCoursesError,
  importExcelCapacitationsStart,
  importExcelCapacitationsSuccess,
  importExcelCapacitationsError,
  importExcelDirectEmployeesStart,
  importExcelDirectEmployeesSuccess,
  importExcelDirectEmployeesError,
  importExcelCarrierEmployeesStart,
  importExcelCarrierEmployeesSuccess,
  importExcelCarrierEmployeesError,
  importExcelFullTrainingsStart,
  importExcelFullTrainingsSuccess,
  importExcelFullTrainingsError,
  excelImportErrorsReset,
  excelImportSaveErrors,
} = createActions({
  IMPORT_EXCEL_COURSES_START: () => {},
  IMPORT_EXCEL_COURSES_SUCCESS: (data) => ({ data }),
  IMPORT_EXCEL_COURSES_ERROR: (error) => ({ error }),
  IMPORT_EXCEL_CAPACITATIONS_START: () => {},
  IMPORT_EXCEL_CAPACITATIONS_SUCCESS: (data) => ({ data }),
  IMPORT_EXCEL_CAPACITATIONS_ERROR: (error) => ({ error }),
  IMPORT_EXCEL_DIRECT_EMPLOYEES_START: () => {},
  IMPORT_EXCEL_DIRECT_EMPLOYEES_SUCCESS: (data) => ({ data }),
  IMPORT_EXCEL_DIRECT_EMPLOYEES_ERROR: (error) => ({ error }),
  IMPORT_EXCEL_CARRIER_EMPLOYEES_START: () => {},
  IMPORT_EXCEL_CARRIER_EMPLOYEES_SUCCESS: (data) => ({ data }),
  IMPORT_EXCEL_CARRIER_EMPLOYEES_ERROR: (error) => ({ error }),
  IMPORT_EXCEL_FULL_TRAININGS_START: () => {},
  IMPORT_EXCEL_FULL_TRAININGS_SUCCESS: (data) => ({ data }),
  IMPORT_EXCEL_FULL_TRAININGS_ERROR: (error) => ({ error }),
  EXCEL_IMPORT_ERRORS_RESET: () => {},
  EXCEL_IMPORT_SAVE_ERRORS: (error) => ({ error }),
})

const importExcelCourses = (data) => {
  return async (dispatch) => {
    const api = await getApi()
    dispatch(importExcelCoursesStart())

    const body = data

    try {
      const response = await api.post(`${API_ROUTES.IMPORT_EXCEL_COURSES}`, body)
      dispatch(importExcelCoursesSuccess({ values: response.data }))
    } catch (error) {
      dispatch(importExcelCoursesError(error))
    }
  }
}

const importExcelCapacitations = (fileBlob) => {
  return async (dispatch) => {
    const api = await getApi()
    dispatch(importExcelCapacitationsStart())

    try {
      const response = await api.post(`${API_ROUTES.IMPORT_EXCEL_CAPACITATIONS}`, fileBlob)
      dispatch(importExcelCapacitationsSuccess({ values: response.data }))
    } catch (error) {
      dispatch(importExcelCapacitationsError(error))
    }
  }
}

const importExcelDirectEmployees = (data) => {
  return async (dispatch) => {
    const api = await getApi()
    dispatch(importExcelDirectEmployeesStart())

    const body = data

    try {
      const response = await api.post(`${API_ROUTES.IMPORT_EXCEL_DIRECT_EMPLOYEES}`, body)
      dispatch(importExcelDirectEmployeesSuccess({ values: response.data }))
    } catch (error) {
      dispatch(importExcelDirectEmployeesError(error))
    }
  }
}

const importExcelCarrierEmployees = (data) => {
  return async (dispatch) => {
    const api = await getApi()
    dispatch(importExcelCarrierEmployeesStart())

    const body = data

    try {
      const response = await api.post(`${API_ROUTES.IMPORT_EXCEL_CARRIER_EMPLOYEES}`, body)
      dispatch(importExcelCarrierEmployeesSuccess({ values: response.data }))
    } catch (error) {
      dispatch(importExcelCarrierEmployeesError(error))
    }
  }
}

const importExcelFullTrainings = (data) => {
  return async (dispatch) => {
    const api = await getApi()
    dispatch(importExcelFullTrainingsStart())

    const body = data

    try {
      const response = await api.post(`${API_ROUTES.IMPORT_EXCEL_FULL_TRAININGS}`, body)
      dispatch(importExcelFullTrainingsSuccess({ values: response.data }))
    } catch (error) {
      console.log(error)
      dispatch(importExcelFullTrainingsError(error))
    }
  }
}

const resetExcelImportErrors = () => (dispatch) => dispatch(excelImportErrorsReset())

export {
  importExcelCourses,
  importExcelCoursesStart,
  importExcelCoursesSuccess,
  importExcelCoursesError,
  importExcelCapacitations,
  importExcelCapacitationsStart,
  importExcelCapacitationsSuccess,
  importExcelCapacitationsError,
  importExcelDirectEmployees,
  importExcelDirectEmployeesStart,
  importExcelDirectEmployeesSuccess,
  importExcelDirectEmployeesError,
  importExcelCarrierEmployees,
  importExcelCarrierEmployeesStart,
  importExcelCarrierEmployeesSuccess,
  importExcelCarrierEmployeesError,
  importExcelFullTrainings,
  importExcelFullTrainingsStart,
  importExcelFullTrainingsSuccess,
  importExcelFullTrainingsError,
  resetExcelImportErrors,
  excelImportErrorsReset,
  excelImportSaveErrors,
}

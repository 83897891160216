import { handleActions } from 'redux-actions'
import {
  fetchPendingTrainingStart,
  fetchPendingTrainingSuccess,
  fetchPendingTrainingError,
  pendingTrainingReset
} from 'actions/trainingPending'

const defaultState = {
  values: null,
  isFetching: false,
  error: null
}

const reducer = handleActions(
  {
    [fetchPendingTrainingStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchPendingTrainingSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values: values,
      isFetching: false,
      error: null
    }),
    [fetchPendingTrainingError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error: error
    }),
    [pendingTrainingReset]: () => defaultState
  },
  defaultState
)

export default reducer

import { createActions } from 'redux-actions'

import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchCapacitationDetailPdfStart,
  fetchCapacitationDetailPdfSuccess,
  fetchCapacitationDetailPdfError,
  capacitationDetailPdfDownloadReset
} = createActions({
  FETCH_CAPACITATION_DETAIL_PDF_START: () => {},
  FETCH_CAPACITATION_DETAIL_PDF_SUCCESS: data => ({ data }),
  FETCH_CAPACITATION_DETAIL_PDF_ERROR: error => ({ error }),
  CAPACITATION_DETAIL_PDF_DOWNLOAD_RESET: () => {}
})

const fetchCapacitationDetailPdf = (id, archiveName) => {
  return async dispatch => {
    const api = await getApi(true)
    dispatch(fetchCapacitationDetailPdfStart())

    try {
      const response = await api.post(
        `${API_ROUTES.PUT_CAPACITATION}/${API_ROUTES.CREATE_PDF}/${id}`
      )
      var fileBlob = new Blob([response.data], {
        type: 'application/pdf'
      })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(fileBlob, 'pdf')
      } else {
        var objectURL = URL.createObjectURL(fileBlob)
        const link = document.createElement('a')
        link.href = objectURL
        link.setAttribute('download', `${archiveName}.pdf`)
        document.body.appendChild(link)
        link.click()
      }

      dispatch(fetchCapacitationDetailPdfSuccess({ values: response.data }))
    } catch (error) {
      dispatch(fetchCapacitationDetailPdfError(error))
    }
  }
}

const resetCapacitationDetailPdfDownload = () => dispatch =>
  dispatch(capacitationDetailPdfDownloadReset())

export {
  fetchCapacitationDetailPdf,
  fetchCapacitationDetailPdfStart,
  fetchCapacitationDetailPdfSuccess,
  fetchCapacitationDetailPdfError,
  resetCapacitationDetailPdfDownload,
  capacitationDetailPdfDownloadReset
}

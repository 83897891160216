import { handleActions } from 'redux-actions'
import {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesError,
  categoriesReset,
  modifyCategoryStart,
  modifyCategorySuccess,
  modifyCategoryError,
  modifyCategoriesReset,
  addCategoryStart,
  addCategorySuccess,
  addCategoryError,
  addCategoryReset,
  deleteCategoryStart,
  deleteCategorySuccess,
  deleteCategoryError,
  deleteCategoryErrorReset
} from 'actions/categories'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,

  errorEdit: false,
  isSubmiting: false,
  successEdit: false,

  isAdding: false,
  errorAdd: false,
  successAdd: false,

  isDeleting: false,
  successDelete: false,
  errorDelete: false
}

const reducer = handleActions(
  {
    [fetchCategoriesStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchCategoriesSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchCategoriesError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [categoriesReset]: () => defaultState,
    [modifyCategoryStart]: state => ({
      ...state,
      isSubmiting: true,
      successEdit: false,
      errorEdit: false
    }),
    [modifyCategorySuccess]: state => ({
      ...state,
      successEdit: true,
      isSubmiting: false,
      errorEdit: false
    }),
    [modifyCategoryError]: (state, error) => ({
      ...state,
      isSubmiting: false,
      successEdit: false,
      errorEdit: error
    }),
    [modifyCategoriesReset]: state => ({
      ...state,
      errorEdit: false,
      isSubmiting: false,
      successEdit: false
    }),

    [addCategoryStart]: state => ({
      ...state,
      isAdding: true,
      successAdd: false,
      errorAdd: false
    }),
    [addCategorySuccess]: state => ({
      ...state,
      isAdding: false,
      successAdd: true,
      errorAdd: false
    }),
    [addCategoryError]: (state, error) => ({
      ...state,
      isAdding: false,
      successAdd: false,
      errorAdd: error
    }),
    [addCategoryReset]: state => ({
      ...state,
      isAdding: false,
      errorAdd: false,
      successAdd: false
    }),

    [deleteCategoryStart]: state => ({
      ...state,
      isDeleting: true,
      successDelete: false,
      errorDelete: false
    }),
    [deleteCategorySuccess]: state => ({
      ...state,
      isDeleting: false,
      successDelete: true,
      errorDelete: false
    }),
    [deleteCategoryError]: (state, error) => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: error
    }),
    [deleteCategoryErrorReset]: state => ({
      ...state,
      isDeleting: false,
      successDelete: false,
      errorDelete: false
    })
  },

  defaultState
)

export default reducer
